import { useCallback, useMemo } from 'react';
import { DocumentNode } from 'graphql';
import { useQuery } from '@apollo/client';

interface UseQueryModalDataParams {
  query: DocumentNode;
  queryKey: string;
  searchText: string;
  recordsLimit?: number;
}

export interface QueryResult<DataType> {
  [connectionKey: string]: {
    edges: Array<{ cursor: string; node: DataType }>;
    pageInfo: {
      hasNextPage: boolean;
      endCursor: string;
    };
  };
}

interface UseQueryModalDataReturnType<DataType> {
  isAllLoaded: boolean;
  loading: boolean;
  error: undefined | Error;
  data: DataType[];
  fetchMoreData: () => void;
  refetch: (shouldFetchAll?: boolean, onUpdate?: (data: DataType[]) => void) => void;
}

const mapResultToData = <DataType extends unknown>(result: QueryResult<DataType> | undefined, connectionKey: string) =>
  result ? result[connectionKey].edges.map((x) => x.node) : [];

export const useQueryModalData = <DataType extends unknown>(
  params: UseQueryModalDataParams
): UseQueryModalDataReturnType<DataType> => {
  const { query, queryKey, searchText, recordsLimit } = params;

  const connectionKey = useMemo(() => `${queryKey}Connection`, [queryKey]);

  const { loading, data, error, fetchMore } = useQuery<QueryResult<DataType>>(query, {
    variables: {
      cursor: null,
      limit: recordsLimit,
      filterText: searchText,
    },
  });

  const mappedData = useMemo(() => mapResultToData(data, connectionKey), [data, connectionKey]);

  const isAllLoaded = useMemo(() => !!data && !data[connectionKey].pageInfo.hasNextPage, [data, connectionKey]);
  const currentCursor = useMemo(() => (data ? data[connectionKey].pageInfo.endCursor : null), [data, connectionKey]);

  const refetch = useCallback(
    (fetchAll?: boolean, onUpdate?: (data: DataType[]) => void) => {
      // If connectionKey === 'Connection' then queryKey is falsy
      if (connectionKey !== 'Connection') {
        fetchMore({
          variables: {
            cursor: null,
            limit: fetchAll ? null : recordsLimit,
            filterText: fetchAll ? '' : searchText,
          },
          updateQuery: (_, { fetchMoreResult }) => {
            if (fetchMoreResult) {
              const newResult = {
                [connectionKey]: {
                  ...fetchMoreResult[connectionKey],
                  edges: [...fetchMoreResult[connectionKey].edges],
                },
              };
              if (onUpdate) onUpdate(mapResultToData(newResult, connectionKey));
              return newResult;
            }
            return {};
          },
        });
      }
    },
    [recordsLimit, searchText, connectionKey, fetchMore]
  );

  const fetchMoreData = useCallback(() => {
    // If connectionKey === 'Connection' then queryKey is falsy
    if (!isAllLoaded && connectionKey !== 'Connection') {
      fetchMore({
        variables: {
          cursor: currentCursor ?? null,
          limit: recordsLimit,
          filterText: searchText,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (fetchMoreResult) {
            const prevEdges = prev[connectionKey].edges;
            return {
              ...prev,
              [connectionKey]: {
                ...fetchMoreResult[connectionKey],
                edges: [...prevEdges, ...fetchMoreResult[connectionKey].edges],
              },
            };
          }
          return prev;
        },
      });
    }
  }, [currentCursor, recordsLimit, searchText, connectionKey, fetchMore, isAllLoaded]);

  return {
    isAllLoaded,
    loading,
    error,
    data: mappedData,
    fetchMoreData,
    refetch,
  };
};
