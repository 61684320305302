import { getSvgImageString } from '@terragotech/svg-symbol-lib';

export interface SymbolProps {
  primaryColor: string;
  secondaryColor?: string;
  alphaNumericCharacter?: string;
  selected?: boolean;
}

export const getSymbolImage = (symbolKey: string, size: number, font?: string): Promise<ImageData> => {
  let testImg = getSvgImageString(symbolKey, { globalScaleFactor: 1, embedFont: !font, symbolFontName: font });

  const convertImage = new Image(size, size);
  var canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;
  var context = canvas.getContext('2d');
  const promise = new Promise<ImageData>((resolve, reject) => {
    if (context !== null) {
      if (convertImage !== null) {
        convertImage.onload = () => {
          context!.drawImage(convertImage, 0, 0, size, size);
          var imageData = context!.getImageData(0, 0, size, size);

          resolve(imageData);
        };

        convertImage.src = testImg;
      } else {
        reject();
      }
    }
  });
  return promise;
};
export const styleProvider = (id: string, size: number, font?: string) => {
  if (id.startsWith('symbol')) {
    let [prefix] = id.split('_');
    if (prefix) {
      return getSymbolImage(id, size, font);
    }
  }
  // don't have any better ideas at this poiunt other than trying anyway
  return getSymbolImage(id, size, font);
};
