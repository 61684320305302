import { Box, IconButton, InputAdornment, TextField, Typography, makeStyles, useTheme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faPlus, faSliders } from '@fortawesome/pro-solid-svg-icons';
import { colors } from '../styles/theme';
import clsx from 'clsx';
import { MOBILE_HEADER_HEIGHT, PageDetails } from '../utils/utilityHelper';
import AppLogo from './AppLogo';
import { useContext, useRef, useState } from 'react';
import { ArrowBack, Close, Search } from '@material-ui/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import GlobalSearchSuggestion from './Map/component/ControlsOverlay/GlobalSearchSuggestion';
import _ from 'lodash';
import { FilterContext } from '../contexts/FilterContext/filterContext';
import { handleAddressSelected } from './Map/component/ControlsOverlay/boundsUtils';
import { AssetsDashboardContext, pages } from '../contexts/assetsDashboardContext';
import { useCommonStyles } from '../styles/commonStyles';
import { useUtils } from '../contexts/utilContext';

interface Props {
  openDrawerMenu: (value: boolean) => void;
  openVisibility: () => void;
  isMapView: boolean;
  currentPage: pages;
}

const preventBlur = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  e.preventDefault();
};

const MobileHeader = ({ openDrawerMenu, openVisibility, isMapView, currentPage }: Props) => {
  const classes = useStyles();
  const [searchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { setMapLocalBounds } = useContext(FilterContext);
  const { setIsAddDialogOpen, toggleMapView } = useContext(AssetsDashboardContext);
  const theme = useTheme();
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();

  const onAddressClick = async (address: string, placeId: string) => {
    toggleMapView(true);
    const bounds: number[][] = (await handleAddressSelected(address, placeId, setSearchText)) as number[][];
    setMapLocalBounds(bounds);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  const getPageTitle = (currentPage: pages) => {
    return PageDetails.find(detail => detail.key === currentPage)?.label;
  };
  return (
    <Box className={`${classes.container} ${currentPage !== 'home' ? classes.containerWithTitle : ''}`}>
      <IconButton className={classes.menuContainer} onClick={() => openDrawerMenu(true)}>
        <FontAwesomeIcon className={classes.icon} icon={faBars} />
      </IconButton>
      {currentPage === 'home' ? (
        <>
          <Box className={classes.logoContainer}>
            <AppLogo className={classes.logo} />
          </Box>
          <Box className={classes.searchBox}>
            <PlacesAutocomplete value={searchText} onChange={setSearchText} onSelect={onAddressClick}>
              {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                <div>
                  <TextField
                    {...getInputProps()}
                    variant="outlined"
                    className={clsx(
                      classes.input,
                      searchActive ? classes.activeSearch : classes.textInput,
                      isIOSSafari && commonClasses.safariInnerInputFontSize
                    )}
                    placeholder="Search"
                    onFocus={() => setSearchActive(true)}
                    onBlur={() => setSearchActive(false)}
                    InputProps={{
                      inputRef: inputRef,
                      startAdornment: (
                        <InputAdornment position="start">
                          {searchActive ? (
                            <IconButton>
                              <ArrowBack />
                            </IconButton>
                          ) : (
                            <Search className={classes.searchIcon} />
                          )}
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchText.length > 0 ? (
                            <IconButton onClick={() => setSearchText('')} onMouseDown={e => preventBlur(e)}>
                              <Close className={classes.clearIcon} />
                            </IconButton>
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                  {searchActive && (
                    <Box
                      className={classes.suggestionArea}
                      onMouseDown={e => preventBlur(e as React.MouseEvent<HTMLButtonElement, MouseEvent>)}
                    >
                      {!_.isEmpty(searchText) && (
                        <GlobalSearchSuggestion
                          setSearchText={setSearchText}
                          inputRef={inputRef.current}
                          searchText={searchText}
                          getSuggestionItemProps={getSuggestionItemProps}
                          suggestions={suggestions}
                          toggleMapView={toggleMapView}
                        />
                      )}
                    </Box>
                  )}
                </div>
              )}
            </PlacesAutocomplete>
          </Box>
          <IconButton className={classes.visibilityButton} onClick={openVisibility}>
            <FontAwesomeIcon className={classes.TuneIcon} icon={faSliders} />
          </IconButton>
          <Box className={classes.tableButton} onClick={() => toggleMapView()}>
            <Typography className={classes.buttonText}>{isMapView ? 'Table' : 'Map'}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Typography className={classes.pageTitle}>{getPageTitle(currentPage)}</Typography>
          {currentPage === 'usersAndRoles' && (
            <IconButton className={classes.iconContainer} onClick={() => setIsAddDialogOpen(true)}>
              <FontAwesomeIcon className={classes.AddIcon} icon={faPlus} color={theme.palette.primary.main} />
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

const centerFlex = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const PADDING_TOP = 15;
const PADDING_BOTTOM = 13;
const HORIZONTAL_PADDING = 15;

const useStyles = makeStyles(theme => ({
  container: {
    width: `calc(100% - ${HORIZONTAL_PADDING * 2}px)`,
    height: MOBILE_HEADER_HEIGHT - PADDING_TOP - PADDING_BOTTOM,
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${PADDING_TOP}px ${HORIZONTAL_PADDING}px ${PADDING_BOTTOM}px ${HORIZONTAL_PADDING}px`,
  },
  containerWithTitle: {
    justifyContent: 'space-between',
    gap: 0,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: '1px',
      backgroundColor: colors.black10,
    },
  },
  borderBottom: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: '1px',
      backgroundColor: colors.black10,
    },
  },
  pageTitle: {
    position: 'absolute',
    width: `calc(100% - ${HORIZONTAL_PADDING * 2}px)`,
    textAlign: 'center',
    zIndex: -1,
    fontSize: 18,
    fontWeight: 500,
  },
  menuContainer: {
    width: 24,
    height: 24,
    cursor: 'pointer',
    ...centerFlex,
  },
  icon: {
    color: colors.black54,
    fontSize: 20,
  },
  TuneIcon: {
    color: colors.gray,
    fontSize: 20,
  },
  AddIcon: {
    fontSize: 24,
  },
  iconContainer: {
    padding: 5,
  },
  logoContainer: {
    width: 29,
    height: 29,
    ...centerFlex,
  },
  logo: {
    width: 24,
    height: 24,
  },
  clearIcon: {
    height: 22,
    width: 22,
    color: colors.black54,
  },
  searchBox: {
    width: '70%',
  },
  searchIcon: {
    color: colors.black35,
    width: 22,
    height: 22,
  },
  input: {
    borderRadius: 4,
    width: '100%',
    padding: 0,
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.cultured,
      },
      '& input': {
        fontSize: 15,
        fontWeight: 400,
        color: colors.black,
      },
    },
  },
  textInput: {
    backgroundColor: colors.cultured,
    height: 35,
    '& .MuiInputBase-root': {
      height: 35,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  activeSearch: {
    backgroundColor: colors.white,
    height: 64,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    borderBottom: `1px solid ${colors.black10}`,
    '& .MuiInputBase-root': {
      height: 64,
    },
  },
  suggestionArea: {
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 64,
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
  },
  visibilityButton: {
    width: 36,
    height: 36,
    borderRadius: 5,
    backgroundColor: colors.cultured,
    cursor: 'pointer',
    ...centerFlex,
  },
  tableButton: {
    width: 50,
    height: 36,
    cursor: 'pointer',
    ...centerFlex,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: 500,
    fontFamily: 'Roboto',
    textAlign: 'center',
    color: theme.palette.primary.main,
  },
}));

export default MobileHeader;
