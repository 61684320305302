import React, { createContext, useContext, useState, FunctionComponent, useMemo, useCallback, SetStateAction } from 'react';
import { SortDirection } from '@terragotech/react-data-grid';
import { AssetType } from './AggregatesContext/types';
import { sort } from '../hooks/useTable';

export type AssetsTableStateContextType = {
  sortColumn: string;
  sortDirection: SortDirection;
  setSortColumn: (column: string) => void;
  setSortDirection: (direction: SortDirection) => void;
  sortAssets: (assets: ReadonlyArray<AssetType>) => ReadonlyArray<AssetType>;
  isSelectAll: boolean,
  setIsSelectAll: React.Dispatch<SetStateAction<boolean>>,
  limitAlert: boolean,
  setLimitAlert: React.Dispatch<SetStateAction<boolean>>,
  actionsMenuRefresh: number, 
  setActionsMenuRefresh: React.Dispatch<SetStateAction<number>>,
  dataLoading: boolean;
  setDataLoading: React.Dispatch<SetStateAction<boolean>>,
};

const AssetsTableStateContext = createContext<AssetsTableStateContextType>({
  sortColumn: '',
  sortDirection: 'NONE',
  setSortColumn: () => {},
  setSortDirection: () => {},
  sortAssets: () => [],
  isSelectAll: false,
  setIsSelectAll: () => {},
  limitAlert: false, 
  setLimitAlert: () =>{},
  actionsMenuRefresh: 0, 
  setActionsMenuRefresh: () => {},
  dataLoading: false,
  setDataLoading: () => {}
});
export const AssetsTableStateContextProvider: FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState<SortDirection>('NONE');
  const sortAssets = useCallback((assets: ReadonlyArray<AssetType>) => sort(assets, sortDirection, sortColumn), [
    sortDirection,
    sortColumn,
  ]);
  const [isSelectAll,setIsSelectAll] = useState(false)
  const [limitAlert, setLimitAlert] = useState(false)
  const [actionsMenuRefresh, setActionsMenuRefresh] = useState(0)
  const [dataLoading, setDataLoading] = useState(false)
  const value: AssetsTableStateContextType = useMemo(
    () => ({
      sortColumn,
      sortDirection,
      setSortColumn,
      setSortDirection,
      sortAssets,
      isSelectAll,
      setIsSelectAll,
      limitAlert, 
      setLimitAlert,
      actionsMenuRefresh, 
      setActionsMenuRefresh,
      dataLoading,
      setDataLoading,
    }),
    [sortColumn, sortDirection, sortAssets, setSortColumn, setSortDirection, isSelectAll, setIsSelectAll,limitAlert, setLimitAlert, actionsMenuRefresh, setActionsMenuRefresh, dataLoading,
      setDataLoading,]
  );
  return <AssetsTableStateContext.Provider value={value}>{props.children}</AssetsTableStateContext.Provider>;
};
export const useAssetsTableState = () => {
  const context = useContext(AssetsTableStateContext);
  if (!context) {
    throw new Error('useAssetsTableState should be used inside AssetsTableStateContext.Provider');
  }
  return context;
};
