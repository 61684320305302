import React from 'react';
import { makeStyles, Backdrop, CircularProgress } from '@material-ui/core';
import { LOADING_Z_INDEX } from '../../utils/layers';

const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: LOADING_Z_INDEX + 1,
    color: '#fff',
  },
}));

const UserRolesLoading = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default UserRolesLoading;
