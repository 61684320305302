import { TableData } from '../useTable';
import React, { useMemo, useCallback } from 'react';
import { Column as RDGColumn, HeaderRendererProps, SortDirection } from '@terragotech/react-data-grid';
import { Column } from './useColumns';

interface DraggableColumnsConfig<Data extends TableData> {
  columns: ReadonlyArray<Column<Data>>;
  visibleColumns: ReadonlyArray<RDGColumn<Data>>;
  hiddenColumns: ReadonlyArray<string>;
  headerRenderer: (
    props: HeaderRendererProps<Data, unknown> & {
      onColumnsReorder: (sourceKey: string, targetKey: string) => void;
      sortDirection?: string;
    }
  ) => JSX.Element;
  sortKey: string;
  sortDirection: SortDirection;
  handleColumnsMove: (fromIndex: number, toIndex: number) => void;
}

const useDraggableColumns = <Data extends TableData>(props: DraggableColumnsConfig<Data>) => {
  const { columns, handleColumnsMove, sortDirection, sortKey, visibleColumns } = props;
  const handleColumnsReorder = useCallback(
    (sourceKey: string, targetKey: string) => {
      const sourceIndex = columns.findIndex((c) => c.key === sourceKey);
      const destinationIndex = columns.findIndex((c) => c.key === targetKey);

      if (sourceIndex !== destinationIndex) {
        handleColumnsMove(sourceIndex, destinationIndex);
      }
    },
    [columns, handleColumnsMove]
  );

  const HeaderRenderer = useMemo(
    () => (p: HeaderRendererProps<Data, unknown>) => {
      return (
        <props.headerRenderer
          {...p}
          onColumnsReorder={handleColumnsReorder}
          sortDirection={sortKey === p.column.key ? sortDirection : 'NONE'}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortKey, sortDirection, handleColumnsReorder]
  );

  const draggableColumns = useMemo(() => {
    return visibleColumns.map((column) => {
      return { ...column, headerRenderer: column.headerRenderer ?? HeaderRenderer };
    });
  }, [visibleColumns, HeaderRenderer]);

  return { draggableColumns };
};

export default useDraggableColumns;
