import { useState } from 'react';

const defaultValue = {
  enabled: false,
  features: [],
};
const useDrawFilter = () => {
  const [drawFilter, setDrawFilter] = useState<Record<string, any>>(defaultValue);
  const enableDrawFilter = () => {
    setDrawFilter({
      ...drawFilter,
      enabled: true,
      apply: false,
      features: drawFilter.features.map((item: any) => {
        return { ...item, properties: { color: '#FF00F5' }, existing: true };
      }),
    });
  };
  return {
    drawFilter,
    setDrawFilter,
    enableDrawFilter,
  };
};

export default useDrawFilter;
