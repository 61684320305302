import { OptionObject as Option } from './TGOptionSelectorModal';

export const getValue = <DataType extends Option>(option: DataType, pathToValue?: string): string =>
  String(option[pathToValue ?? 'value'] ?? '');

export const areOptionsEqual = <DataType extends Option>(a: DataType, b: DataType, pathToValue?: string): boolean =>
  getValue(a, pathToValue) === getValue(b, pathToValue);

export const getLabel = <DataType extends Option>(option: DataType, pathToLabel?: string): string =>
  String(option[pathToLabel ?? 'label'] ?? '');

export const getOptionByValue = <DataType extends Option>(
  options: DataType[],
  value: string,
  pathToValue?: string
): DataType | undefined => options.find((option) => getValue(option, pathToValue) === value);
