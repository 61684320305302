export const moveFromRightToLeft = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => [
  ...columns.slice(0, destinationIndex),
  columns[sourceIndex],
  ...columns.slice(destinationIndex, sourceIndex),
  ...columns.slice(sourceIndex + 1),
];

export const moveFromLeftToRight = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => [
  ...columns.slice(0, sourceIndex),
  ...columns.slice(sourceIndex + 1, destinationIndex + 1),
  columns[sourceIndex],
  ...columns.slice(destinationIndex + 1),
];

export const moveColumns = <ColumnType extends unknown>(
  columns: ReadonlyArray<ColumnType>,
  sourceIndex: number,
  destinationIndex: number
) => {
  return sourceIndex > destinationIndex
    ? moveFromRightToLeft(columns, sourceIndex, destinationIndex)
    : moveFromLeftToRight(columns, sourceIndex, destinationIndex);
};
