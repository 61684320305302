import React, { useContext, useState } from 'react';
import SlideDialog from '../components/SlideDialog';

const dialogContext = React.createContext({
  openDialog: (contetnt: React.ReactNode) => {},
  closeDialog: () => {},
});

const useDialog = () => {
  const context = useContext(dialogContext);
  if (!context) {
    throw Error('useDialog must be within a UserProvider');
  }
  return context;
};

const DialogProvider = (props: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState((<div></div>) as React.ReactNode);

  const openDialog = (content: React.ReactNode) => {
    setIsOpen(true);
    setContent(content);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  return (
    <dialogContext.Provider
      value={{
        openDialog,
        closeDialog,
      }}
      {...props}
    >
      {props.children}
      <SlideDialog open={isOpen} onClose={() => {}}>
        {content}
      </SlideDialog>
    </dialogContext.Provider>
  );
};

export { useDialog, DialogProvider };
