import { Box, Checkbox, Link, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { useAggregates } from '../../../../../contexts/AggregatesContext';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import { tabProps, Accordion, AccordionDetails, AccordionSummary } from './Accordions';
import { colors } from '../../../../../styles/theme';
import { useCommonStyles } from './useStyles';
import { chain, sum } from 'lodash';
import { hasIncludes } from '../../../../../utils/utilityHelper';

const LayersTab = (props: tabProps) => {
  const { SearchbarSection } = props;
  const { setVisibleMapServiceKeys, visibleMapServiceKeys, mapServices, mapServiceLayers } = useAggregates();
  const sourceVisibility: Record<string, boolean> = {};
  [...mapServices, ...mapServiceLayers].forEach(x => (sourceVisibility[x.id] = visibleMapServiceKeys.includes(x.id)));
  const [searchText, setSearchText] = useState('');
  const [canFocusOut, setCanFocusOut] = useState(false);
  const [expanded, setExpanded] = useState<number | boolean>(0);

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const handleChange = (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const toggleVisibleMapServiceKey = useCallback(
    (id: string) => {
      if (visibleMapServiceKeys.includes(id)) {
        setVisibleMapServiceKeys(visibleMapServiceKeys.filter(key => key !== id));
      } else {
        setVisibleMapServiceKeys([...visibleMapServiceKeys, id]);
      }
    },
    [visibleMapServiceKeys, setVisibleMapServiceKeys]
  );

  const toggleSourceVisibility = useCallback(
    (value: string, checked: boolean) => {
      const service = mapServices.find(service => service.id === value);
      const layer = mapServiceLayers.find(layer => layer.id === value);

      if (service) {
        toggleVisibleMapServiceKey(service.id);
        // TODO: Handle checkboxes to sync all children. Also prevent parent expand/collapse when toggling (should this occur in CheckboxTreeSection.tsx?).
      } else if (layer) {
        toggleVisibleMapServiceKey(layer.id);
      }
    },
    [mapServices, mapServiceLayers, toggleVisibleMapServiceKey]
  );
  const toggleAll = () => {
    const allIds = [...mapServices.map(service => service.id), ...mapServiceLayers.map(layers => layers.id)];
    if (visibleMapServiceKeys && visibleMapServiceKeys.length === 0) {
      setVisibleMapServiceKeys(allIds);
    } else {
      setVisibleMapServiceKeys([]);
    }
  };

  const getLayers = useCallback(
    (layers: { layerName: string; layerTitle?: string; layerLegendUri?: string }[], label: string) => {
      const hasLayer = hasIncludes(label, searchText);
      const newLayers = layers.filter(layer => hasIncludes(layer?.layerTitle!, searchText));
      return hasLayer ? layers : newLayers;
    },
    [searchText]
  );

  const filteredMapServices = useMemo(() => {
    return searchText?.length > 0
      ? chain(mapServices)
          .map(o => ({ ...o, layers: getLayers(o.layers, o.label) }))
          .filter(o => o.layers.length > 0)
          .value()
      : mapServices;
  }, [searchText, mapServices, getLayers]);

  const selectedItems = chain(filteredMapServices)
    .map(service => {
      return sum(
        service.layers.map(layer => {
          return sourceVisibility[`${service.id}-${layer.layerName}`] ? 1 : 0;
        })
      );
    })
    .sum()
    .value();

  return (
    <Box className={commonClasses.container}>
      <SearchbarSection searchValue={searchText} searchOnChange={setSearchText} focusOut={canFocusOut} />
      <Box className={commonClasses.outer}>
        <Box className={commonClasses.detailContainer} onScroll={() => setCanFocusOut(true)}>
          <Link
            className={`${commonClasses.linkBtn} ${commonClasses.bp}`}
            component="button"
            underline="always"
            onClick={() => toggleAll()}
          >
            {visibleMapServiceKeys.length ? `Deselect All (${selectedItems})` : 'Select All'}
          </Link>
          {filteredMapServices.map((service, index) => (
            <Accordion key={`${index}`} expanded={expanded === index} onChange={handleChange(index)}>
              <AccordionSummary expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}>
                {service.label}
              </AccordionSummary>
              <AccordionDetails>
                {service.layers.map((layer, index) => (
                  <Box key={`${layer.layerName}-${index}`} className={classes.accordionSummary}>
                    <Checkbox
                      checked={sourceVisibility[`${service.id}-${layer.layerName}`]}
                      color="primary"
                      onChange={(e, checked) => toggleSourceVisibility(`${service.id}-${layer.layerName}`, checked)}
                    />
                    <Typography className={classes.label}>{layer.layerTitle}</Typography>
                  </Box>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummary: {
      display: 'flex',
      width: 'fit-content',
    },
    label: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black0,
    },
    expandIcon: {
      color: colors.title,
    },
  })
);
export default LayersTab;
