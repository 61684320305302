import { Checkbox, createStyles, withStyles, WithStyles, Theme, useTheme, Typography, Box } from '@material-ui/core';
import { FunctionComponent } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ReactComponent as NotPinnedIcon } from '../../../images/notPinnedIcon.svg';
import { ReactComponent as PinnedIcon } from '../../../images/pinnedIcon.svg';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import clsx from 'clsx';
import { colors } from '../../../styles/theme';

export interface ColumnFilterListElementProps extends WithStyles<typeof styles> {
  column: Column<AssetType>;
  togglePinned: (key: string) => void;
  index: number;
  isSelected: (key: string) => boolean;
  setIsSelected: (key: string, isSelected: boolean) => void;
}

const ColumnFilterListElement: FunctionComponent<ColumnFilterListElementProps> = props => {
  const { classes, column, index, isSelected, setIsSelected, togglePinned } = props;
  const theme = useTheme();
  const iconStyle = {
    fill: theme.palette.primary.main,
  };
  return (
    <Draggable draggableId={`column-${column.key}`} index={index}>
      {(provided, snapshot) => (
        <div className={classes.rootContainer}>
          <div
            className={clsx(classes.filterItemOption, snapshot.isDragging && classes.filterItemOptionDragging)}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Box className={classes.leftContent}>
              <DragIcon />
              <Checkbox
                checked={isSelected(column.key)}
                onChange={e => {
                  setIsSelected(column.key, e.target.checked);
                }}
                color="primary"
              />
              <Typography className={classes.label}>{column.name}</Typography>
            </Box>
            <div className={classes.dragItem}>
              {column.sticky && <PinnedIcon onClick={() => togglePinned(column.key)} style={iconStyle} />}
              {!column.sticky && <NotPinnedIcon onClick={() => togglePinned(column.key)} />}
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    filterItemOption: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      gap: 10,
    },
    leftContent: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    filterItemOptionDragging: {
      boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.2)',
    },
    label: {
      color: colors.black0,
      fontFamily: 'Roboto',
      fontSize: 15,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    rootContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    pb0: {
      paddingBottom: 0,
    },
    dragItem: {
      display: 'flex',
      alignItems: 'center',
    },
  });

export default withStyles(styles)(ColumnFilterListElement);

const DragIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M20 9H4V11H20V9ZM4 15H20V13H4V15Z" fill="black" fillOpacity="0.35" />
  </svg>
);
