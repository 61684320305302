import React, { useRef, useEffect, useCallback, useMemo, useContext, useState } from 'react';
import RDG, { CheckCellIsEditableEvent, DataGridHandle, SortDirection } from '@terragotech/react-data-grid';
import { Column as ColType } from '@terragotech/react-data-grid/lib/types';
import '@terragotech/react-data-grid/dist/react-data-grid.css';
import { DraggableHeaderRenderer } from './DraggableHeaderRenderer';
import StyledTableEditor from './Editors/StyledTableEditor';
import { useTable, TableData } from '../../hooks/useTable';
import StyledTableEmpty from './StyledTableEmpty';
import { makeStyles, CircularProgress } from '@material-ui/core';
import { Column, FilterRendererProps, ID_COLUMN_KEY } from '../../hooks/tableHooks/useColumns';
import { DIALOG_Z_INDEX } from '../../utils/layers';
import { EditModeContext } from '../../contexts/editModeContext';
import _, { isArray, isNil } from 'lodash';
import { BOTTOM_GAP, getLighterColorCode, getLightColor, MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { colors } from '../../styles/theme';
import { useRecordType } from '../../contexts/recordTypeContext';
import { useAggregates } from '../../contexts/AggregatesContext';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
import { useHistory } from 'react-router-dom';
import { MapAssetType } from '../../contexts/AggregatesContext/types';
import { useTableColumns } from '../../contexts/TableColumnContext';
import { useUtils } from "../../contexts/utilContext";

export const rowHeight = 39;
const headerRowHeight = 39;
const filterRowHeight = 39;
const minRecords = 20;
const moveToIndex = 3;

export interface StyledTableProps<Data extends TableData> {
  columns: ReadonlyArray<Column<Data>>;
  data: ReadonlyArray<Data>;
  width: number;
  height: number;
  onCellBlur?: (id: string, field: string, value: unknown) => void;
  onChange?: (id: string, field: string, value: unknown) => void;
  onLoad?: (firstRowIndex: number) => void | Promise<void>;
  onEditModeOn?: (row: Data) => void;
  backdrop?: boolean;
  selectedRowComparator?: (row: Data) => boolean;
  filterRenderer: (props: FilterRendererProps<Data>) => JSX.Element;
  onColumnsMove: (fromIndex: number, toIndex: number) => void;
  onBlur?: () => void;
  onInput?: () => void;
  emptyView?: () => JSX.Element;
  setSortColumn?: (column: string) => void;
  setSortDirection?: (direction: SortDirection) => void;
  editRowComparator?: (row: Data) => boolean;
  onCheckCellIsEditable?: (event: CheckCellIsEditableEvent<Data, unknown>) => boolean;
  assetTable?: boolean;
  isAssetTable?: boolean;
}

const StyledTable = <Data extends TableData>(props: StyledTableProps<Data>) => {
  const {
    onCellBlur,
    onChange,
    onLoad,
    selectedRowComparator,
    columns,
    data,
    onBlur,
    height,
    setSortColumn,
    setSortDirection,
    editRowComparator,
    onCheckCellIsEditable,
    assetTable,
    isAssetTable,
  } = props;
  const { isFirefox } = useUtils();
  const classes = useStyles({ isAssetTable, isFirefox });
  const handle = useRef<DataGridHandle>(null);
  const { rowIdxRef, isMobileView } = useContext(AssetsDashboardContext);
  const { currentAssetId } = useTableColumns();
  /*
    To prevent filters losing focus and unnecessary rerenders all arguments passed to useTable need to be declared using either useMemo, useCallback or useState.
    Otherwise changing references would cause columns to recompute and the table would reload.
  */
  const [dataToRender, setDataToRender] = useState<{ data: readonly Data[]; columns: Column<Data>[] }>({
    data: [],
    columns: [],
  });
  const {
    sortColumn,
    sortDirection,
    columnsToRender: columnsIntermediate,
    dataToRender: tableRows,
    setSort,
  } = useTable<Data>({
    columns,
    data,
    editable: !!props.onChange,
    filterRenderer: props.filterRenderer,
    headerRenderer: DraggableHeaderRenderer,
    editor: StyledTableEditor,
    handleColumnsMove: props.onColumnsMove,
    setEditModeOn: props.onEditModeOn,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isChunkLoading, setIsChunkLoading] = useState(false);
  const fetchingRef = useRef(false);
  const { fetchNextDataSet, initialAssetsFetch, lastScrolledRef, setSortingInfo, forceReloadCount } = useAggregates();
  const { selectedRecordType } = useRecordType();
  const [lastSortInfo, setLastSortInfo] = useState<{
    [key: string]: {
      sortColumn: string;
      sortDirection: string;
    };
  }>({});
  const history = useHistory();
  const holdFetchingFunction = useRef(false);
  const lastScrolledAsset = useRef<MapAssetType | undefined>();

  useEffect(()=>{
    initialAssetsFetch(false,sortColumn,sortDirection)
  },[forceReloadCount])

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      const assetId = location.pathname.split('/');
      if (!assetId[2]) {
        lastScrolledAsset.current = undefined;
      }
    });
    return () => {
      unlisten();
    };
  }, [history]);

  useEffect(() => {
    setDataToRender({
      data: tableRows.map((tableRow, idx) => {
        return { ...tableRow, [ID_COLUMN_KEY]: idx };
      }),
      columns: (columnsIntermediate as unknown) as Column<Data>[],
    });
  }, [tableRows, columnsIntermediate]);

  const [editedData, setEditedData] = useState<readonly Data[]>([]);
  const { editModeActive, editModeData } = useContext(EditModeContext);
  useEffect(() => {
    if (editModeActive && editedData.length === 0) {
      setEditedData(dataToRender.data);
    } else if (!editModeActive && editedData.length !== 0) {
      setEditedData([]);
    }
  }, [editModeActive, editedData, dataToRender]);

  const initialFetch = async (checkExistingData: boolean, sortColumn?: string, sortDirection?: SortDirection) => {
    setIsLoading(true);
    const scrollToRow = await initialAssetsFetch(checkExistingData, sortColumn, sortDirection);
    const scrollIndex = typeof scrollToRow === 'number' ? scrollToRow : 0;
    setTimeout(() => {
      handle.current?.scrollToRow(scrollIndex > 2 ? scrollIndex - 1 : 0);
      setIsLoading(false);
    }, 500);
  };
  useEffect(() => {
    setSortColumn && setSortColumn(sortColumn);
    setSortDirection && setSortDirection(sortDirection);
    if (setSortingInfo && assetTable) {
      setSortingInfo({ sortColumn, sortDirection });
    }
  }, [sortColumn, sortDirection, setSortColumn, setSortDirection]);

  const handleSetSort = async (columnKey: string, direction: SortDirection) => {
    setSort(columnKey, direction);
  };

  const getNewRow = useCallback(
    (rows: Data[]): (Data & { __changes: { [index: string]: string } }) | undefined =>
      ((rows as unknown) as (Data & { __changes: { [index: string]: string } })[]).find(
        (row: Data & { __changes: { [index: string]: string } }) => row.__changes
      ),
    []
  );
  const getColumn = useCallback((newRow: Data): string | null => newRow && Object.keys(newRow.__changes)[0], []);

  const handleOnCellBlur = useCallback(
    (rows: (Data & { __changes?: { [index: string]: string } })[]): void => {
      const newRow = rows.find((row: Data & { __changes?: { [index: string]: string } }) => {
        return row.__changes;
      });
      //we will assume only one row has changed. This will only work if the data model is rebuilt when changes are pushed.
      if (newRow) {
        let col = newRow.__changes && Object.keys(newRow.__changes)[0];
        col && newRow.__changes && onCellBlur && onCellBlur(newRow.id as string, col, newRow.__changes[col]);
        delete newRow.__changes;
        setEditedData(editedData.map(cell => (cell.id === newRow.id ? newRow : cell)));
        onBlur && onBlur();
      }
    },
    [onBlur, onCellBlur, editedData]
  );

  const handleOnChange = useCallback(
    (rows: Data[]): void => {
      const newRow = getNewRow(rows);
      if (newRow) {
        let col = getColumn(newRow);
        col && onChange && onChange(newRow.id as string, col, newRow.__changes[col]);
      }
    },
    [getColumn, getNewRow, onChange]
  );

  const selectedRowIndex = useMemo(
    () => (selectedRowComparator ? dataToRender.data.findIndex(row => selectedRowComparator(row)) : undefined),
    [selectedRowComparator, dataToRender]
  );

  const editRowIndices = useMemo(
    () =>
      editRowComparator
        ? (editedData.length === 0 ? dataToRender.data : editedData).reduce(
            (acc, curr, i) => (editRowComparator(curr) && [...acc, i]) || acc,
            [] as number[]
          )
        : undefined,
    [editRowComparator, dataToRender, editedData]
  );
  useEffect(() => {
    if (handle?.current && selectedRowIndex && selectedRowComparator) {
      const rowToBeScrolled = dataToRender.data.find(row => selectedRowComparator(row)) as MapAssetType | undefined;
      if (rowToBeScrolled?.id !== lastScrolledAsset.current?.id) {
        holdFetchingFunction.current = true;
        setTimeout(() => {
          lastScrolledAsset.current = rowToBeScrolled;
          if (currentAssetId.location.match(/^map|tab$/)) {
            handle.current?.scrollToRow(selectedRowIndex);
          }
        }, 800);
        setTimeout(() => {
          holdFetchingFunction.current = false;
        }, 300);
      }
    }
  }, [selectedRowIndex, currentAssetId.location]);

  const THRESHOLD_FOR_FETCH = 20;
  const checkIfReachedThreshold = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const currentTarget = e.currentTarget;
    const tolerance = 4;
    const scrollPosition = currentTarget.scrollTop + currentTarget.clientHeight;
    const totalHeight = currentTarget.scrollHeight;
    const lastTenRowsHeight = THRESHOLD_FOR_FETCH * rowHeight;
    const isBottom = scrollPosition + tolerance >= totalHeight - lastTenRowsHeight;
    if (isBottom) {
      return 'BOTTOM';
    } else if (Math.floor(currentTarget.scrollTop / rowHeight) <= THRESHOLD_FOR_FETCH) {
      return 'TOP';
    }
    return 'NONE';
  };

  const fetchInitialData = () => {
    const sortKey = `${sortColumn}_${sortDirection}`;
    const lastRecord = lastSortInfo[selectedRecordType];
    const sortInfo = `${lastRecord?.sortColumn}_${lastRecord?.sortDirection}`;
    if (sortColumn && sortKey !== sortInfo) {
      initialFetch(true, sortColumn, sortDirection);
    }
  };

  const handleSort = (columnKey: string, direction: 'ASC' | 'DESC' | 'NONE') => {
    const sortedRows =
      direction === 'NONE'
        ? tableRows.map((tableRow, idx) => ({ ...tableRow, [ID_COLUMN_KEY]: idx }))
        : [...dataToRender.data].sort((a, b) => {
            const isAsc = direction === 'ASC';
            if (a[columnKey] < b[columnKey]) {
              return isAsc ? -1 : 1;
            }
            if (a[columnKey] > b[columnKey]) {
              return isAsc ? 1 : -1;
            }
            return 0;
          });
    setDataToRender(o => ({ ...o, data: sortedRows }));
  };

  useEffect(() => {
    if (assetTable) {
      fetchInitialData();
    } else {
      handleSort(sortColumn, sortDirection);
    }
  }, [selectedRecordType, sortColumn, sortDirection]);

  useEffect(() => {
    if (!_.isEmpty(sortColumn) && assetTable) {
      setLastSortInfo({
        [selectedRecordType]: {
          sortColumn,
          sortDirection,
        },
      });
    }
  }, [selectedRecordType, sortColumn, sortDirection]);

  useEffect(() => {
    if (rowIdxRef.current && handle.current && isMobileView) {
      setTimeout(() => {
        handle?.current?.scrollToRow(rowIdxRef.current  + 1);
      }, 800);
    }
  }, [isMobileView]);

  const calculatePresentRow = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = e.currentTarget;
    const rowHeight = target.scrollHeight / dataToRender.data.length;
    const presentRow = e.currentTarget.scrollTop / rowHeight;
    rowIdxRef.current = Math.floor(presentRow);
    lastScrolledRef.current = Math.floor(presentRow);
    return Math.floor(presentRow);
  };

  const fetchNextChunk = async (fetchNext: boolean) => {
    fetchingRef.current = true;
    const length = await fetchNextDataSet(fetchNext);
    const lastIndex = rowIdxRef.current;
    let rowIdx = 0;
    if (length) {
      if (fetchNext) {
        const moveIndex = length > minRecords ? moveToIndex : 0;
        rowIdx = lastIndex - (length - moveIndex);
      } else {
        rowIdx = lastIndex + length;
      }
      handle?.current?.scrollToRow(rowIdx);
    }
    fetchingRef.current = false;
  };

  const loadNextChunk = async (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    calculatePresentRow(e);
    const thresholdPoint = checkIfReachedThreshold(e);
    if (fetchingRef.current || holdFetchingFunction.current) return;
    setIsChunkLoading(true);
    if (thresholdPoint === 'BOTTOM') {
      await fetchNextChunk(true);
    } else if (thresholdPoint === 'TOP') {
      await fetchNextChunk(false);
    }
    setIsChunkLoading(false);
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    if (assetTable && !holdFetchingFunction.current) {
      await loadNextChunk(event);
    }
    if (onLoad) {
      await onLoad(Math.floor(event.currentTarget.scrollTop / rowHeight));
    }
  };
  const handleCheckCellIsEditable = useCallback(
    (event: CheckCellIsEditableEvent<Data, unknown>) =>
      onCheckCellIsEditable
        ? onCheckCellIsEditable(event)
        : editModeActive &&
          (isArray(editModeData)
            ? editModeData.some(x => x.id === event?.row?.id)
            : event?.row?.id === editModeData?.id),
    [onCheckCellIsEditable, editModeActive, editModeData]
  );
  const tableHeight = {
    height: height - BOTTOM_GAP,
  };
  return (
    <div className={classes.rootContainer}>
      <div className={!editModeActive ? classes.root : `${classes.root} ${classes.editModeRoot}`}>
        <RDG
          enableFilters
          columns={dataToRender.columns as ColType<Data, unknown>[]}
          rows={editedData.length === 0 ? dataToRender.data : editedData}
          onCellBlur={handleOnCellBlur}
          onChange={handleOnChange}
          rowHeight={rowHeight}
          headerFiltersHeight={filterRowHeight}
          headerRowHeight={headerRowHeight}
          sortColumn={sortColumn}
          sortDirection={sortDirection}
          onSort={handleSetSort}
          emptyRowsRenderer={props.emptyView ? props.emptyView : StyledTableEmpty}
          onCheckCellIsEditable={handleCheckCellIsEditable}
          ref={handle}
          onScroll={handleScroll}
          rowKey={ID_COLUMN_KEY}
          selectedRows={
            editModeActive
              ? new Set((editRowIndices ?? []) as Data[typeof ID_COLUMN_KEY][])
              : new Set((!isNil(selectedRowIndex) ? [selectedRowIndex] : []) as Data[typeof ID_COLUMN_KEY][])
          }
          style={tableHeight}
        />
      </div>
      {(!!props.backdrop || isLoading) && (
        <div className={`${classes.loadingContainer} ${!isChunkLoading ? classes.loader : ''}`}>
          <CircularProgress color="primary" />
        </div>
      )}
      {isChunkLoading && (
        <div className={classes.container}>
          <CircularProgress size={22} color="primary" />
          <span className={classes.text}>Please wait, data is being loaded...</span>
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => {
  const lightPrimaryColor = getLightColor(theme.palette.primary.main, 80);
  const selectedRow = {
    backgroundColor: lightPrimaryColor,
    border: `0.5px solid ${theme.palette.primary.main}`,
  };
  const mobileBreakPoints = theme.breakpoints.down(MOBILE_BREAKPOINT + 1);
  return {
    loadingContainer: {
      zIndex: DIALOG_Z_INDEX,
      display: 'flex',
      bottom: 0,
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      width: '100%',
    },
    container: {
      display: 'inline-flex',
      padding: '10px 15px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '5px',
      background: colors.white,
      boxShadow: `0px 2px 6px 0px ${colors.black20}`,
      position: 'absolute',
      left: 15,
      bottom: 34,
      zIndex: DIALOG_Z_INDEX,
      [mobileBreakPoints]: {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        bottom: 10,
        width: 'max-content',
      },
    },
    text: {
      color: colors.black75,
      fontFamily: 'Roboto',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    loader: {
      top: 0,
      left: 0,
      height: '100%',
      background: colors.white,
    },
    rootContainer: { position: 'relative' },
    root: ({ isAssetTable, isFirefox }: { isAssetTable?: boolean, isFirefox: boolean }) => ({
      '& .rdg': {
        overflow: 'auto',
        marginRight: isFirefox ? -15 : 0,
        border: `1px solid ${colors.black10}`,
        '&::-webkit-scrollbar': {
          width: isAssetTable ? 0 : 6,
          height: 16,
        },
        '&::-webkit-scrollbar-track': {
          background: 'transparent',
          borderRadius: 6,
        },
        '&::-webkit-scrollbar-thumb': {
          background: colors.black35,
          borderRadius: 10,
        },
        '&::-webkit-scrollbar-thumb:vertical': {
          display: isAssetTable ? 'none' : 'block',
        },
        '&::-webkit-scrollbar-thumb:horizontal': {
          display: 'block',
          backgroundClip: 'padding-box',
          border: '5px solid transparent',
          boxShadow: `inset 0 0 0 1px ${colors.black10}`,
        },
        '&::-webkit-scrollbar-track:horizontal': {
          background: 'transparent',
          border: `1px solid ${colors.black10}`,
          borderRadius: 0,
        },
      },
      display: 'block',
      width: '100%',
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif',
      '& .rdg-editor-container': {
        marginTop: '-8px',
      },
      '& .rdg-cell-mask': {
        border: `1px solid ${theme.palette.secondary.main}`,
      },
      '& .rdg-cell': {
        fontWeight: '400 !important',
        fontSize: 13,
        color: colors.black0,
        fontStyle: 'normal',
        fontFamily: 'Roboto',
        borderRight: `1px solid ${colors.softShadow}`,
        borderBottom: 'none',
        paddingInline: 8,
        height: 39,
        '&.rdg-cell-frozen-last': {
          boxShadow: 'none',
        },
      },
      '& .rdg-row': {
        height: `${rowHeight}px`,
        fontSize: '13px',
      },
      '& .rdg-row:nth-of-type(2n)': {
        backgroundColor: colors.white,
        '&.rdg-row-selected': {
          ...selectedRow,
        },
      },
      '& .rdg-row:nth-of-type(2n+1)': {
        backgroundColor: colors.snowWhite,
        '&.rdg-row-selected': {
          ...selectedRow,
        },
      },
      '& .rdg-row-selected': {
        ...selectedRow,
      },
      '& .rdg-filter-row': {
        height: `${filterRowHeight}px`,
        fontSize: '13px',
        top: `${headerRowHeight}px`,
        background: colors.white,
        boxShadow: `0px 2px 4px 0px ${colors.veryLightBlack}`,
      },
      '& .rdg-header-row': {
        fontSize: '13px',
        fontWeight: 400,
        height: `${headerRowHeight}px`,
        backgroundColor: colors.lightGray,
        color: colors.black0,
        '& .rdg-cell': {
          fontWeight: 500,
          height: '100%',
          padding: '0',
          borderBottom: 'none',
          '& .rdg-header-sort-cell': {
            cursor: 'pointer',
            height: '100%',
            '& span:nth-of-type(2)': {
              display: 'none',
            },
          },
        },
      },
      '& .rdg-cell-selected': {
        boxShadow: 'none',
      },
    }),
    editModeRoot: {
      '& .rdg-row:nth-of-type(2n)': {
        backgroundColor: colors.backgroundLight,
        '&.rdg-row-selected': {
          marginLeft: -1,
          backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
          border: `1px solid ${theme?.palette?.primary?.main}`,
        },
      },
      '& .rdg-row:nth-of-type(2n+1)': {
        backgroundColor: colors.white,
        '&.rdg-row-selected': {
          marginLeft: -1,
          backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
          border: `1px solid ${theme?.palette?.primary?.main}`,
        },
      },
      '& .rdg-row-selected': {
        marginLeft: -1,
        backgroundColor: `${getLighterColorCode(`${theme?.palette?.primary?.main}`, 70)}`,
        border: `1px solid ${theme?.palette?.primary?.main}`,
      },
      '& .rdg-cell-selected': {
        boxShadow: 'none',
      },
    },
  };
});

export default StyledTable;
