import React, { useState, useCallback, useMemo, useEffect } from 'react';
import useUserPreferences from '../../hooks/useUserPreferences';
import { setStateWrapper, createInitialStates } from './utils';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';

export interface UseLocallyStoredFilterValueArgs<T> {
  selectedRecordType: string;
  aggregateDefinitions: AggregateDefinition[];
  defaultValue: T;
  preferenceKey: string;
}

export const useLocallyStoredFilterValue = <T extends unknown>(props: UseLocallyStoredFilterValueArgs<T>) => {
  const { selectedRecordType, aggregateDefinitions, defaultValue, preferenceKey } = props;
  const { setPreferenceForCurrentRecordType, getPreferenceForRecordType } = useUserPreferences();
  //Load any saved state
  const [values, setValues] = useState<Record<string, T>>({});

  const currentAggregateValue = useMemo(() => values[selectedRecordType] ?? defaultValue, [
    values,
    selectedRecordType,
    defaultValue,
  ]);
  const setCurrentAggregateValue: React.Dispatch<React.SetStateAction<T>> = useCallback(
    (arg) => {
      return setValues((prevState) => {
        return { ...prevState, [selectedRecordType]: setStateWrapper(arg, prevState[selectedRecordType]) };
      });
    },
    [setValues, selectedRecordType]
  );
  const setValueForRecordType = useCallback(
    (arg: any, recordType: string) => {
      return setValues((prevState) => {
        return { ...prevState, [recordType]: setStateWrapper(arg, prevState[recordType]) };
      });
    },
    [setValues]
  );
  const getValueForRecordType = useCallback((recordType: string) => values[recordType] ?? defaultValue, [
    values,
    defaultValue,
  ]);

  // Initialize with data found in local storage or defaultValue
  useEffect(() => {
    setValues(
      createInitialStates(
        aggregateDefinitions,
        (recordType: string) =>
          getPreferenceForRecordType<{ value: T }>({ key: preferenceKey, recordType })?.value ?? defaultValue
      )
    );
  }, [getPreferenceForRecordType, aggregateDefinitions, setValues, defaultValue, preferenceKey]);

  // Update local storage on change
  useEffect(() => {
    setPreferenceForCurrentRecordType(preferenceKey, { value: currentAggregateValue });
  }, [currentAggregateValue, setPreferenceForCurrentRecordType, preferenceKey]);

  // Update current value on selected record change
  useEffect(() => {
    setCurrentAggregateValue(getValueForRecordType(selectedRecordType));
    // We should change the value only when selected record type changes. Otherwise the infinite loop would take place
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecordType]);

  return {
    currentAggregateValue,
    getValueForRecordType,
    setCurrentAggregateValue,
    setValueForRecordType,
  };
};
