import React, { useState, useCallback, useMemo, useRef, useLayoutEffect } from 'react';
import useCarousel from '../../../hooks/useCarousel';
import { makeStyles } from '@material-ui/core';
import BottomBar from './MultipleAssetCards/BottomBar';
import CardHolder from './MultipleAssetCards/CardHolder';
import { colors } from '../../../styles/theme';
import { useEffect } from 'react';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { MAP_CARD_WIDTH } from '../../../utils/utilityHelper';
import { useCommonStyles } from '../../../styles/commonStyles';

const GAP = 15;
export const ANIMATION_TIME_IN_SECONDS = 0.75;

export interface MultipleAssetCardsProps {
  assets: MapAssetType[];
  onCloseClick?: () => void;
  onCardChanged?: (assetId: string) => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  onFormSubmit?: () => void;
  multiple?: boolean;
}

const MultipleAssetCards = (props: MultipleAssetCardsProps) => {
  const classes = useStyles();
  const {
    assets,
    onCloseClick,
    onCardChanged,
    onSelect,
    selectedAggregateId,
    selectableAggregateTypes,
    onFormSubmit,
    multiple,
  } = props;

  const { currentIndex, size, hasPrev, hasNext, goToNext, goToPrev } = useCarousel(assets.map(c => c.id));
  const cardRefs = useRef<(HTMLDivElement | null)[]>([]);
  const prevAssetsLength = useRef(assets.length);
  const [sizes, setSizes] = useState<{ width: number; height: number }[]>([]);
  const [firstCardLoaded, setFirstCardLoaded] = useState<boolean>(false);
  const commonClasses = useCommonStyles();
  useEffect(() => {
    if (assets.length !== prevAssetsLength.current) {
      cardRefs.current = Array(assets.length).fill(null);
      prevAssetsLength.current = assets.length;
    }
  }, [assets.length]);
  const maxCardHeight = useMemo(() => {
    return sizes[currentIndex]?.height || 0;
  }, [sizes, currentIndex]);

  useEffect(() => {
    onCardChanged && onCardChanged(assets[currentIndex].id);
  }, [currentIndex, onCardChanged, assets]);

  const getStyles = useCallback(
    (index: number): React.CSSProperties => {
      return {
        zIndex: index === currentIndex ? 1 : 0,
        width: 'auto',
        height: 'auto',
        opacity: index === currentIndex ? 1 : 0,
      };
    },
    [currentIndex, size]
  );

  useLayoutEffect(() => {
    const updateSizes = () => {
      cardRefs.current.forEach((ref, index) => {
        if (ref && cardRefs.current.length === assets.length) {
          const { offsetWidth: width, offsetHeight: height } = ref;
          setSizes(prevSizes => {
            const newSizes = [...prevSizes];
            newSizes[index] = { width, height };
            return newSizes;
          });
        }
      });
    };

    const observer = new ResizeObserver(updateSizes);

    cardRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    updateSizes();

    return () => {
      observer.disconnect();
    };
  }, [assets]);

  const handleRef = useCallback(
    (index: number) => (el: HTMLDivElement | null) => {
      cardRefs.current[index] = el;
    },
    []
  );
  const getClasses = useCallback(
    (index: number) => {
      const result = [];
      if (index === currentIndex) {
        result.push(classes.frontCardContainer);
      } else {
        result.push('MuiPaper-rounded');
      }
      return result.join(' ');
    },
    [currentIndex, classes]
  );
  return (
    <div className={firstCardLoaded ? commonClasses.show : commonClasses.hide}>
      <div className={classes.cardsRoot} style={{ height: `${maxCardHeight}px`, width: MAP_CARD_WIDTH }}>
        {assets.map((asset, index) => {
          return (
            <CardHolder
              index={index}
              key={asset.id}
              setSize={(width: number, height: number) => {
                setSizes(sizes => {
                  const newSizes = [...sizes];
                  newSizes[index] = { width, height };
                  return newSizes;
                });
              }}
              style={getStyles(index)}
              classes={{
                container: getClasses(index),
              }}
              assetId={asset.id}
              asset={asset}
              onCloseClick={onCloseClick}
              onSelect={onSelect}
              mapEditor={props.mapEditor}
              selectedAggregateId={selectedAggregateId}
              selectableAggregateTypes={selectableAggregateTypes}
              onFormSubmit={onFormSubmit}
              multiple={multiple}
              ref={handleRef(index)}
              setFirstCardLoaded={setFirstCardLoaded}
            />
          );
        })}
      </div>
      <BottomBar
        width={sizes[currentIndex]?.width || MAP_CARD_WIDTH}
        left={0}
        hasPrev={hasPrev}
        hasNext={hasNext}
        goToNext={goToNext}
        goToPrev={goToPrev}
        numberOfElements={size}
        currentIndex={currentIndex}
      />
    </div>
  );
};

//TODO: move this utility up to somewhere more accessible, since it seems very useful
export const convertToCSSTransition = (properties: string[], timeInSeconds: number = ANIMATION_TIME_IN_SECONDS) =>
  properties.map(property => `${property} ${timeInSeconds}s`).join(', ');

const useStyles = makeStyles(theme => ({
  cardsRoot: {
    position: 'relative',
  },
  frontCardContainer: {
    boxShadow: `0px 4px 4px 0px ${colors.black25}`,
  },
  backgroundCardContainer: {
    overflow: 'hidden',
    backgroundColor: colors.white,
    borderRight: `${GAP}px solid ${colors.white}`,
    boxSizing: 'border-box',
  },
}));

export default MultipleAssetCards;
