import { DraggableLocation } from 'react-beautiful-dnd';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';

export type ColumnsArray = Array<Column<AssetType>>;
export const PINNED_SECTION_ID = 'sticky';
export const NOT_PINNED_SECTION_ID = 'columns';

/*
  Moves element from position startIndex to endIndex in list
*/
export const reorder = <T extends unknown>(list: Array<T>, startIndex: number, endIndex: number): Array<T> => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/*
  Moves element from position sourceIndex in source to position destinationIndex in destination
*/
export const move = (
  source: ColumnsArray,
  destination: ColumnsArray,
  sourceIndex: number,
  destinationIndex: number
) => {
  const newSource = [...source.slice(0, sourceIndex), ...source.slice(sourceIndex + 1)];
  const newDestination = [
    ...destination.slice(0, destinationIndex),
    source[sourceIndex],
    ...destination.slice(destinationIndex),
  ];
  return {
    source: newSource,
    destination: newDestination,
  };
};

/*
  Moves element from position sourceIndex in source to position destinationIndex in destination and toggles its sticky property
*/
export const moveAndToggleSticky = (
  source: ColumnsArray,
  destination: ColumnsArray,
  sourceIndex: number,
  destinationIndex: number
) => {
  const itemToMoveKey = source[sourceIndex]?.key;

  const result = move(source, destination, sourceIndex, destinationIndex);
  const movedItem = result.destination.find((column) => column.key === itemToMoveKey);
  if (movedItem) {
    movedItem.sticky = !movedItem.sticky;
  }
  return result;
};

/*
  Moves element defined by droppableSource from pinned or notPinned to another, based on droppables ids
*/
export const moveDragged = (
  pinned: ColumnsArray,
  notPinned: ColumnsArray,
  droppableSource: DraggableLocation,
  droppableDestination: DraggableLocation
) => {
  if (droppableSource.droppableId === PINNED_SECTION_ID && droppableDestination.droppableId === NOT_PINNED_SECTION_ID) {
    const result = moveAndToggleSticky(pinned, notPinned, droppableSource.index, droppableDestination.index);
    return { pinned: result.source, notPinned: result.destination };
  } else if (
    droppableDestination.droppableId === PINNED_SECTION_ID &&
    droppableSource.droppableId === NOT_PINNED_SECTION_ID
  ) {
    const result = moveAndToggleSticky(notPinned, pinned, droppableSource.index, droppableDestination.index);
    return { pinned: result.destination, notPinned: result.source };
  }
  return { pinned, notPinned };
};
