import { createStyles, Theme, makeStyles, Box, Container } from '@material-ui/core';
import { FunctionComponent, useContext, useRef } from 'react';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import './TopSplitter.css';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { switchPointPercentage } from '../pages/AssetsDashboard/AssetsDashboard';
import MapOverviewContainer from '../containers/MapOverviewContainer';
import { LEFT_PANEL_WIDTH } from '../utils/utilityHelper';
import TableView from '../pages/AssetsDashboard/TableView';

const ANIMATION_TIME_IN_SECONDS = 0.3;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
    },
    topRightSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    tableView: {
      height: '100%',
      '& .splitter-layout': {
        width: `calc(100% - ${LEFT_PANEL_WIDTH}px)`,
      },
    },
    splitter2: {
      '& > .layout-pane:nth-child(1)': {
        transition: `width ${ANIMATION_TIME_IN_SECONDS}s`,
      },
    },
    mapRoot: {
      padding: 0,
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    },
  })
);

export interface TopSplitterProps {
  i?: Array<any>;
  inProp: boolean;
  onExited?: () => void;
  fullScreen?: boolean;
  height?: any;
  width: number;
  setWidth: any;
  onDragEnd?: (verticalPercentage: number) => void;
  minimizeAndExpandTable?: (value: number) => void;
  isMobileView?: boolean;
  isMapView?: boolean;
  isVertical: boolean;
}

const TopSplitter: FunctionComponent<TopSplitterProps> = props => {
  const { height, width, onDragEnd, minimizeAndExpandTable, isVertical } = props;
  const classes = useStyles();
  const { verticalPercentage, setVerticalPercentage, fullscreen, isMobileView } = useContext(AssetsDashboardContext);
  const splitterLayoutRef = useRef<SplitterLayout | null>(null);

  const handleDragEnd = () => {
    if (verticalPercentage >= switchPointPercentage) {
      splitterLayoutRef.current && splitterLayoutRef.current.setState({ secondaryPaneSize: 100 });
    }
    onDragEnd && onDragEnd(verticalPercentage!);
  };
  const BoxContainer = isMobileView ? Box : Container;
  return (
    <div className={fullscreen ? classes.tableView : classes.topRightSection}>
      {!fullscreen ? (
        <BoxContainer className={classes.mapRoot}>
          <MapOverviewContainer height={height} width={width} />
        </BoxContainer>
      ) : (
        <SplitterLayout
          ref={splitterLayoutRef}
          percentage
          secondaryInitialSize={props.isMobileView ? 0 : fullscreen ? (isVertical ? 99.45 : 99.75) : verticalPercentage}
          vertical={isVertical}
          customClassName="custom-splitter"
          onSecondaryPaneSizeChange={setVerticalPercentage}
          onDragEnd={handleDragEnd}
        >
          <div />
          {!props.isMobileView && (
            <TableView
              {...{
                isMobileView: false,
                isVertical,
                height: (verticalPercentage / 100) * window.innerHeight,
                minimizeAndExpandTable,
                classes,
              }}
            />
          )}
        </SplitterLayout>
      )}
    </div>
  );
};

export default TopSplitter;
