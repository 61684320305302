import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import magicText from 'i18next';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { Button, Typography } from '@material-ui/core';
import { gql, useQuery, useMutation } from '@apollo/client';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { DOC_API_URL } from '../../utils/docApiURL';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import { getFileExtensionFromBlob } from '../../utils/fileHelper';
import formLevelErrorsIcon from '../../images/importPage/formLevelErrorsIcon.svg';
import formLevelWarningsIcon from '../../images/importPage/formLevelWarningsIcon.svg';
import formLevelUpIcon from '../../images/importPage/formLevelUpIcon.svg';
import formLevelDownIcon from '../../images/importPage/formLevelDownIcon.svg';
import formLevelErrorsMenuIcon from '../../images/importPage/formLevelErrorsMenuIcon.svg';
import formLevelWarningsMenuIcon from '../../images/importPage/formLevelWarningsMenuIcon.svg';
import { CancelImportResponse, CancelImportVariables } from './PendingJobRow';
import SheetTable from './SheetTable';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { CommandAction, compileKeysOfObjectArray } from '@terragotech/gen5-shared-components';
import { colors } from '../../styles/theme';
import { ArrowBack } from '@material-ui/icons';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import clsx from 'clsx';
import { useAlert } from '../../contexts/AlertModalContext';

//Example query to get a single import record that includes processed data
export interface IndividualQueryResult {
  import: readonly {
    id: string;
    errorsWarnings: readonly {
      message: string;
      is_error: boolean;
      is_form_level: boolean;
      row: number;
      column: number;
      sheet: string;
      column_name: string;
    }[];
    aggregateType: string;
    commandName: string;
    committed?: boolean;
    filename: string;
    metadata?: object;
    data?: object;
    target: string;
    timestamp: Date;
    type: string;
    version: string;
    commandError?: number;
    commandErrorMessage?: string;
  }[];
}

export interface IndividualImportResult {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  success?: boolean;
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  isintegration?: boolean;
  filename: string;
  metadata?: object;
  data?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
  commandError?: number;
  commandErrorMessage?: string;
}

export interface ErrorWarningType {
  message: string;
  is_error: boolean;
  is_form_level: boolean;
  row: number;
  column: number;
  sheet: string;
  column_name: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    width: '100%',
    height: '100%',
    minHeight: 460,
    backgroundColor: colors.white,
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
  fileLabel: {
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 22,
    color: `${theme?.palette?.primary?.main || colors.defaultPrimary}`,
    '&:hover': {
      textDecoration: 'underline',
    },
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      fontSize: 20,
    },
  },
  backBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: 5,
    gap: 6,
    marginBottom: 31,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
      marginBottom: 16,
    },
  },
  backIcon: {
    height: 22,
    width: 22,
    color: colors.black54,
    gap: 6,
  },
  highlightOnHover: {
    color: `${theme?.palette?.primary?.main || colors.defaultPrimary}`,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: `${theme?.palette?.primary?.main || colors.defaultPrimary}`,
      color: colors.white,
    },
  },
  commonTabLabel: {
    fontSize: '18px',
    lineHeight: '27px',
    textAlign: 'center',
    fontFamily: 'Roboto',
  },
  sheetTabWrapper: {
    borderTop: `6px solid ${theme?.palette?.primary?.main || colors.defaultPrimary}`,
    borderRight: `1px solid ${colors.lightSilver}`,
    width: '159px',
    marginTop: '-1px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  sheetTabLabel: {
    color: `${theme?.palette?.primary?.main || colors.defaultPrimary}`,
    fontWeight: 500,
  },
  tabWrapper: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid ${colors.lightSilver}`,
    width: '159px',
    height: '41px',
    backgroundColor: colors.cultured,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabLabel: {
    color: colors.black0,
    fontWeight: 400,
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  containerWrapper: {
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 5,
    },
  },
  fileNameWrapper: {
    flexDirection: 'row',
  },
  reviewWrapper: {
    justifyContent: 'center',
  },
  errorsContainer: {
    cursor: 'pointer',
    backgroundColor: colors.mistyRose,
    width: '100%',
    marginTop: '11px',
  },
  errorWrapper: {
    height: 32,
    justifyContent: 'space-between',
  },
  errorIcon: {
    marginLeft: 15,
    marginRight: 10,
  },
  errorLabel: {
    fontWeight: 300,
    fontSize: 16,
  },
  formLevelContainer: {
    marginTop: '-5px',
  },
  formLevelIcon: {
    marginLeft: 15,
    marginRight: 10,
    marginTop: '-5px',
  },
  menuIconContainer: {
    marginLeft: '45px',
    height: 32,
  },
  menuIcon: {
    marginLeft: 15,
    marginRight: 10,
  },
  formContainer: {
    backgroundColor: colors.cornSilk,
  },
  tableWrapper: {
    width: '100%',
    display: 'flex',
    marginTop: 14,
  },
  tableBorder: {
    border: `1px solid ${colors.darkSilver}`,
    width: '100%',
  },
  tableTabs: {
    width: '100%',
    height: 41,
    display: 'flex',
    backgroundColor: colors.cultured,
  },
  tableContainer: {
    minHeight: 230,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 32,
    width: '100%',
  },
  cancelBtnStyle: {
    width: 178,
    color: `${colors.white} !important`,
    marginRight: 20,
    borderRadius: 7,
    backgroundColor: colors.grayLine,
  },
  completedCancelStyle: {
    backgroundColor: colors.romanSilver,
  },
  import: {
    width: 178,
    color: `${colors.white} !important`,
    borderRadius: 7,
  },
  pendingImport: {
    backgroundColor: colors.lavenderGray,
  },
}));

interface Props {
  onBack?: () => void;
  fileIdToView: string;
  setFileIdToView: (x: string | undefined) => void;
  commit: (x: string) => void;
  buttons: UseConditionalImport[] | undefined;
}

export const QUERY = (importId: string) => gql`
       query getImport{
         import(id: "${importId}") {
          id
          errorsWarnings {
            message
            is_error
            is_form_level
            row
            column
            sheet
            column_name
          }
          aggregateType
          commandName
          committed
          success
          isintegration
          filename
          metadata
          data
          target
          timestamp
          type
          version
          commandError
          commandErrorMessage
        }
      }
    `;

const CANCEL_IMPORT = gql`
  mutation cancelImport($importId: String!) {
    cancelImport(importId: $importId) {
      message
    }
  }
`;

export const downloadFile = async (fileUrl: string, fileName: string, isReview?: boolean, fileExtension?: string) => {
  try {
    const token = await AuthConnector.getToken();
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    });
    const blob = await response.blob();
    fileExtension = fileExtension || (await getFileExtensionFromBlob(blob));
    if (!fileExtension) {
      if (isReview) {
        fileExtension = 'xlsx';
      } else {
        fileExtension = 'csv';
      }
    }
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileExtension ? `${fileName}.${fileExtension}` : fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  } catch (err) {
    console.error(`Error while downloading file: ${err}`);
  }
};

const FileToView = (props: Props) => {
  const classes = useStyles();
  const { fileIdToView, setFileIdToView, commit, buttons } = props;
  const { data, loading } = useQuery<IndividualQueryResult>(QUERY(fileIdToView), {
    fetchPolicy: 'network-only',
  });
  const [cancelImport] = useMutation<CancelImportResponse, CancelImportVariables>(CANCEL_IMPORT);
  const [fetchedData, setFetchedData] = useState<IndividualImportResult>();
  const [sheetNumber, setSheetNumber] = useState<number>(0);
  const [errors, setErrors] = useState<ErrorWarningType[]>([]);
  const [warnings, setWarnings] = useState<ErrorWarningType[]>([]);
  const [formLevelErrors, setFormLevelErrors] = useState<ErrorWarningType[]>([]);
  const [formLevelWarnings, setFormLevelWarnings] = useState<ErrorWarningType[]>([]);
  const [formLevelErrorsOpen, setFormLevelErrorsOpen] = useState<boolean>(false);
  const [formLevelWarningsOpen, setFormLevelWarningsOpen] = useState<boolean>(false);
  const [commandDefinition, setCommandDefinition] = useState<UseConditionalImport>();

  const { openConfirmation } = useAlert();

  const sheets = useMemo(() => {
    let sheetNames: {
      label: string;
      key: string;
    }[] = [];
    const groupArray = (commandDefinition?.action as CommandAction)?.command?.template?.order;
    if (groupArray) {
      sheetNames = groupArray.map(comp => {
        return {
          key: comp,
          label: (commandDefinition?.action as CommandAction)?.command?.template?.components[comp]?.label || comp,
        };
      });
    } else {
      sheetNames = Object.keys(fetchedData?.data || {}).map(comp => {
        return {
          key: comp,
          label: comp,
        };
      });
    }
    return sheetNames;
  }, [commandDefinition, fetchedData]);

  useEffect(() => {
    if (data && data?.import && !loading) {
      //@ts-ignore
      setFetchedData(data?.import);
    }
  }, [data, loading]);

  useEffect(() => {
    if (fetchedData?.data && fetchedData?.errorsWarnings) {
      let errorsWarnings = [...fetchedData?.errorsWarnings];
      // Form level errors + warnings
      let formLevelWarnings = errorsWarnings.filter(x => !x.is_error && x.is_form_level);
      let formLevelErrors = errorsWarnings.filter(x => x.is_error && x.is_form_level);
      // Row errors + warnings by sheet
      let warningsArray = errorsWarnings.filter(
        x => !x.is_error && !x.is_form_level && sheets[sheetNumber].key === x.sheet
      );
      let errorsArray = errorsWarnings.filter(
        x => x.is_error && !x.is_form_level && sheets[sheetNumber].key === x.sheet
      );
      setFormLevelErrors(formLevelErrors);
      setFormLevelWarnings(formLevelWarnings);
      setWarnings(warningsArray);
      setErrors(errorsArray);
    }
  }, [fetchedData, sheetNumber, commandDefinition, sheets]);

  const length = useMemo(() => {
    if (fetchedData) {
      if (fetchedData?.data) {
        let totalLength = 0;
        Object.values(fetchedData?.data).forEach((x, index) => {
          if (index === sheetNumber) totalLength += x.length;
        });
        return totalLength;
      }
    }
    return 0;
  }, [fetchedData, sheetNumber]);

  const totalLength = useMemo(() => {
    if (fetchedData) {
      if (fetchedData?.data) {
        let totalLength = 0;
        Object.values(fetchedData?.data).forEach(x => {
          totalLength += x.length;
        });
        return totalLength;
      }
    }
    return 0;
  }, [fetchedData]);

  const hasErrors = useMemo(() => {
    if (
      (fetchedData && fetchedData?.errorsWarnings.filter(x => x?.is_error === true).length > 0) ||
      totalLength === 0
    ) {
      return true;
    }
    return false;
  }, [fetchedData, totalLength]);

  const hasWarnings: boolean = useMemo(() => {
    if (fetchedData && fetchedData?.errorsWarnings.filter(x => x?.is_error === false).length > 0) {
      return true;
    } else {
      return false;
    }
  }, [fetchedData]);

  const isCompleted = useMemo(() => {
    //@ts-ignore
    if (fetchedData?.success !== null) {
      return true;
    }
    return false;
  }, [fetchedData]);

  const isPending = useMemo(() => {
    if (fetchedData?.success === null) {
      return true;
    }
    return false;
  }, [fetchedData]);

  const onCancelImport = async () => {
    const keyPath = 'import.cancelConfirm';
    const status = await openConfirmation({
      title: magicText.t(`${keyPath}.primaryText`),
      question: magicText.t(`${keyPath}.secondaryText`),
      confirmationText: magicText.t(`${keyPath}.confirmationText`),
    });
    if (status === 'confirm') {
      await cancelImport({ variables: { importId: fileIdToView } });
      setFileIdToView(undefined);
    }
  };

  const onImport = async () => {
    const keyPath = 'import.confirm';
    const status = await openConfirmation({
      title: magicText.t(`${keyPath}.primaryText`),
      question: hasWarnings ? magicText.t('import.warningConfirm') : magicText.t(`${keyPath}.secondaryText`),
      confirmationText: magicText.t(`${keyPath}.confirmationText`),
    });
    if (status === 'confirm') {
      commit(fileIdToView);
      setFileIdToView(undefined);
    }
  };

  const Tabs = useMemo(() => {
    if (fetchedData && fetchedData?.data) {
      return sheets.map((tabName, index: number) => (
        <div key={index}>
          {index === sheetNumber ? (
            <div className={classes.sheetTabWrapper} key={index}>
              <Typography className={clsx([classes.commonTabLabel, classes.sheetTabLabel])}>{tabName.label}</Typography>
            </div>
          ) : (
            <div
              className={classes.tabWrapper}
              onClick={(event: React.MouseEvent<HTMLElement>) => setSheetNumber(index)}
              key={index}
            >
              <Typography className={clsx([classes.commonTabLabel, classes.tabLabel])}>{tabName.label}</Typography>
            </div>
          )}
        </div>
      ));
    } else {
      return <div></div>;
    }
  }, [fetchedData, sheetNumber, commandDefinition, sheets]);

  const tableHeaders: {
    label: string;
    key: string;
  }[] = useMemo(() => {
    const gettingData: object[] = Object.values(fetchedData?.data || {});

    const commandDefinitionCalc = buttons?.find(
      button =>
        //@ts-ignore
        (button.action?.commandName || '') === fetchedData?.type &&
        //@ts-ignore
        button?.action?.commandVersion === fetchedData?.version
    );
    setCommandDefinition(commandDefinitionCalc);
    if (commandDefinitionCalc) {
      //@ts-ignore
      const commandTemplate = commandDefinitionCalc?.action?.command?.template;
      const groupValue: string = (commandTemplate?.order)[sheetNumber];
      const groupDefinition = commandTemplate?.components[groupValue];
      // If group exists
      if (groupDefinition) {
        const keyArray = groupDefinition?.template?.order;
        const labelArray = keyArray?.map((keyString: string) => {
          if (groupDefinition?.template?.components[keyString]) {
            return {
              label: groupDefinition?.template?.components[keyString]?.label || keyString,
              key: keyString,
            };
          }
        });
        return labelArray;
      }
    }
    // If labelArray not defined, compile keys of data objects
    if (gettingData && gettingData[sheetNumber]) {
      return compileKeysOfObjectArray(gettingData[sheetNumber] as object[]).map(str => {
        return {
          key: str,
          label: str,
        };
      });
    }
    return [];
  }, [sheetNumber, fetchedData, buttons]);

  return (
    <div className={classes.root}>
      <Typography onClick={() => setFileIdToView(undefined)} className={classes.backBtn}>
        <ArrowBack className={classes.backIcon} /> Back
      </Typography>
      <div className={clsx([classes.flexContainer, classes.containerWrapper])}>
        <div className={clsx([classes.flexContainer, classes.fileNameWrapper])}>
          <Typography
            className={classes.fileLabel}
            onClick={() =>
              downloadFile(
                `${DOC_API_URL}${fileIdToView}`,
                `${fetchedData?.filename.substring(0, fetchedData?.filename.lastIndexOf('.')) || fetchedData?.filename}`
              )
            }
          >
            {fetchedData?.filename}
          </Typography>
        </div>
        {fetchedData?.errorsWarnings.length !== 0 && (
          <Button
            onClick={() =>
              downloadFile(
                `${DOC_API_URL}commented-${fileIdToView}`,
                `${
                  fetchedData?.filename.substring(0, fetchedData?.filename.lastIndexOf('.')) || fetchedData?.filename
                } - Review`,
                true
              )
            }
            color="primary"
            className={classes.highlightOnHover}
            variant="outlined"
          >
            <div className={clsx([classes.flexContainer, classes.reviewWrapper])}>
              <VerticalAlignBottomIcon />
              DOWNLOAD REVIEW
            </div>
          </Button>
        )}
      </div>
      {formLevelErrors.length >= 1 && (
        <div onClick={() => setFormLevelErrorsOpen(prev => !prev)} className={classes.errorsContainer}>
          <div className={clsx([classes.flexContainer, classes.errorWrapper])}>
            <div className={classes.flexContainer}>
              <img src={formLevelErrorsIcon} alt="Error Icon" className={classes.errorIcon} />
              <Typography className={classes.errorLabel}> Form Level Error(s)</Typography>
            </div>
            <div className={classes.formLevelContainer}>
              <img
                src={formLevelErrorsOpen ? formLevelUpIcon : formLevelDownIcon}
                alt={`${formLevelErrorsOpen ? 'Open' : 'Close'} Errors Icon`}
                className={classes.formLevelIcon}
              />
            </div>
          </div>
          {formLevelErrorsOpen &&
            formLevelErrors.map(x => (
              <div className={clsx([classes.flexContainer, classes.menuIconContainer])}>
                <img src={formLevelErrorsMenuIcon} alt="Open Errors Icon" className={classes.menuIcon} />
                <Typography className={classes.errorLabel}> {x.message}</Typography>
              </div>
            ))}
        </div>
      )}

      {formLevelWarnings.length >= 1 && (
        <div
          onClick={() => setFormLevelWarningsOpen(prev => !prev)}
          className={clsx([classes.errorsContainer, classes.formContainer])}
        >
          <div className={clsx([classes.flexContainer, classes.errorWrapper])}>
            <div className={classes.flexContainer}>
              <img src={formLevelWarningsIcon} alt="Warning Icon" className={classes.errorIcon} />
              <Typography className={classes.errorLabel}> Form Level Warning(s)</Typography>
            </div>
            <div className={classes.formLevelContainer}>
              <img
                src={formLevelWarningsOpen ? formLevelUpIcon : formLevelDownIcon}
                alt={`${formLevelWarningsOpen ? 'Open' : 'Close'} Warnings Icon`}
                className={classes.menuIcon}
              />
            </div>
          </div>
          {formLevelWarningsOpen &&
            formLevelWarnings.map(x => (
              <div className={clsx([classes.flexContainer, classes.menuIconContainer])}>
                <img src={formLevelWarningsMenuIcon} alt="Open Errors Icon" className={classes.menuIcon} />
                <Typography className={classes.errorLabel}> {x.message}</Typography>
              </div>
            ))}
        </div>
      )}
      <div className={classes.tableWrapper}>
        <div className={classes.tableBorder}>
          <div className={classes.tableTabs}>{Tabs}</div>
          <div className={classes.tableContainer}>
            <SheetTable
              tableHeaders={tableHeaders}
              length={length}
              fetchedData={fetchedData}
              errors={errors}
              warnings={warnings}
              sheetNumber={sheetNumber}
              sheets={sheets}
            />
          </div>
        </div>
      </div>
      <div className={classes.btnContainer}>
        <Button
          disabled={isCompleted}
          onClick={onCancelImport}
          className={clsx(classes.cancelBtnStyle, isCompleted && classes.completedCancelStyle)}
          variant="contained"
          disableElevation
        >
          Cancel Import
        </Button>
        <Button
          disabled={!isPending || hasErrors}
          className={clsx(classes.import, !isPending && classes.pendingImport)}
          variant="contained"
          color="primary"
          onClick={onImport}
        >
          Import
        </Button>
      </div>
    </div>
  );
};

export default FileToView;
