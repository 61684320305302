import { createStyles, Divider, IconButton, Input, InputAdornment, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import React, { FunctionComponent } from 'react';
import { colors } from '../../../styles/theme';
import magicText from 'i18next';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ColumnFilterHeaderProps extends WithStyles<typeof styles> {
  handleSearchText: (value: string) => void;
  subChildern?: React.ReactElement;
  bottomBar?: React.ReactElement;
  handleClose?: () => void;
}

const ColumnFilterHeaders: FunctionComponent<ColumnFilterHeaderProps> = props => {
  const { classes, handleSearchText, subChildern, handleClose } = props;

  return (
    <>
      <div className={classes.Root}>
        <div className={classes.header}>
          <div className={classes.headerContainer}>
            <Typography className={classes.headerText}>{magicText.t('ColumnFilter.AssetAttributes') as string}</Typography>
            <IconButton onClick={handleClose} className={classes.closeRoot}>
              <FontAwesomeIcon icon={faClose as IconProp} className={classes.closeIcon} />
            </IconButton>
          </div>
        </div>
        <div className={classes.searchBar}>
          <Input
            startAdornment={
              <InputAdornment position="start">
                <Search className={classes.searchIcon} />
              </InputAdornment>
            }
            disableUnderline
            placeholder={magicText.t('ColumnFilter.Search')}
            className={classes.input}
            onChange={e => handleSearchText(e.target.value)}
          />
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.bodyContent}>{subChildern}</div>
      {props.bottomBar}
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    Root: {
      padding: '18px 30px',
      paddingTop: 24,
      display: 'flex',
      rowGap: 14,
      flexDirection: 'column',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '18px 22px',
        paddingTop: 24,
      },
    },
    bodyContent: {
      overflow: 'hidden',
      height: '100%',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        position: 'relative',
      },
    },
    header: {
      background: colors.white,
    },
    headerText: {
      color: colors.black0,
      textTransform: 'none',
      alignSelf: 'center',
      fontSize: 19,
      fontWeight: 500,
    },
    searchBar: {
      backgroundColor: colors.cultured7,
      color: colors.black0,
      display: 'inline-flex',
      width: '100%',
      minHeight: 38,
      borderRadius: 4,
    },
    searchIcon: {
      height: 22,
      width: 22,
      color: colors.black35,
    },
    input: {
      width: '100%',
      fontSize: 16,
      fontWeight: 'normal',
      padding: '8px 12px',
      height: 38,
    },
    button: {
      color: colors.white,
      '& input': {
        color: colors.white,
      },
    },
    divider: {
      backgroundColor: colors.black10,
    },
    closeRoot: {
      width: 24,
      height: 24,
    },
    closeIcon: {
      fontSize: 20,
      color: colors.black54,
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }
  });

export default withStyles(styles)(ColumnFilterHeaders);
