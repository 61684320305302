import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 20">
      <rect x="0" y="0" width="32" height="20" rx="2" />
      <svg x="3" y="3">
        <rect x="0" y="0" width="17" height="3" fill="#fff" />
        <rect x="0" y="5" width="26" height="2" fill="#fff" />
        <rect x="0" y="9" width="26" height="2" fill="#fff" />
      </svg>
    </SvgIcon>
  );
};
