import React, { useState, useMemo, useEffect, useContext, useCallback } from 'react';
import { TableData } from '../../hooks/useTable';
import { Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import FluidStyledTable from './FluidStyledTable';
import { moveColumns } from './utils';
import { DialogProvider } from '../../hooks/useDialog';
import { TableDataContext } from '../../hooks/useTableData';
import StyledTableFilterInput from './StyledTableFilterInput';
import { StyledTableProps } from './StyledTable';
import { CheckCellIsEditableEvent } from '@terragotech/react-data-grid';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';

export interface FluidStyledTableWithDataProps<Data extends TableData> {
  columns: readonly Column<Data>[];
  data: readonly Data[];
  height: number;
  searchBar: () => JSX.Element;
  searchBarHeight: number;
  onEditModeOn?: StyledTableProps<Data>['onEditModeOn'];
  onChange?: StyledTableProps<Data>['onChange'];
  onLoad?: StyledTableProps<Data>['onLoad'];
  editRowComparator?: (row: Data) => boolean;
  onCheckCellIsEditable?: (event: CheckCellIsEditableEvent<Data, unknown>) => boolean;
}

const getNumberOfStickyColumns = <Data extends TableData>(columns: ReadonlyArray<Column<Data>>) => {
  return columns.reduce<number>((r, x) => r + (x.sticky ? 1 : 0), 0);
};

const filterRenderer = <Data extends TableData>({ column }: FilterRendererProps<Data>) => (
  <StyledTableFilterInput name={column.name} keyName={column.key} options={column.filterInputOptions} />
);

const FluidStyledTableWithData = <Data extends TableData>(props: FluidStyledTableWithDataProps<Data>) => {
  const { columns, data, searchBarHeight, height, onEditModeOn, onChange, editRowComparator } = props;

  const { setData, filteredData } = useContext(TableDataContext);
  const { setSortColumn, setSortDirection } = useAssetsTableState();

  useEffect(() => setData(data), [data, setData]);

  const [localColumns, setLocalColumns] = useState(columns);
  const localData = useMemo(() => filteredData as Data[], [filteredData]);

  const [isEditModeOn, setIsEditModeOn] = useState(false);

  const handleEditModeOn = useCallback(
    (row: Data) => {
      if (!isEditModeOn) {
        setIsEditModeOn(true);
        if (onEditModeOn) onEditModeOn(row);
      }
    },
    [onEditModeOn, isEditModeOn]
  );

  const handleColumnsMove = useCallback(
    (from: number, to: number) => {
      const newColumns = moveColumns(localColumns, from, to);
      const numberOfPinnedColumns = getNumberOfStickyColumns(localColumns);
      const numberOfPinnedColumnsDelta = (() => {
        if (from >= numberOfPinnedColumns && to < numberOfPinnedColumns) return 1;
        if (from < numberOfPinnedColumns && to >= numberOfPinnedColumns) return -1;
        return 0;
      })();
      setLocalColumns(
        newColumns.map((column, index) => ({
          ...column,
          sticky: index < numberOfPinnedColumns + numberOfPinnedColumnsDelta,
        }))
      );
    },
    [localColumns]
  );

  useEffect(() => setLocalColumns(columns), [columns]);

  return (
    <DialogProvider>
      <props.searchBar />
      <FluidStyledTable
        columns={localColumns}
        data={localData}
        height={height - searchBarHeight}
        onColumnsMove={handleColumnsMove}
        filterRenderer={filterRenderer}
        onEditModeOn={handleEditModeOn}
        onChange={onChange}
        onLoad={props.onLoad}
        editRowComparator={editRowComparator}
        setSortColumn={setSortColumn}
        setSortDirection={setSortDirection}
      />
    </DialogProvider>
  );
};

export default FluidStyledTableWithData;
