import React, { createContext, FunctionComponent, useContext } from 'react';
import { SnackbarConfig, useSnackbar } from './useSnackbar';


interface InfoContextType {
  openSnackbar: (config: SnackbarConfig) => void;
}

const InfoContext = createContext<InfoContextType>({
  openSnackbar: () => {},
});

export const InfoContextProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const { openSnackbar, component: snackbarComponent } = useSnackbar();

  const value: InfoContextType = { openSnackbar };

  return (
    <InfoContext.Provider value={value}>
      {props.children}

      {snackbarComponent}
    </InfoContext.Provider>
  );
};

export const useInfoContext = () => {
  const context = useContext(InfoContext);
  if (!context) {
    throw new Error('useInfoContext should be used inside InfoContextProvider');
  }
  return context;
};
