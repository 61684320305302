import React, { createContext, FunctionComponent, useState, useCallback, useContext, useMemo } from 'react';
import { noop } from 'lodash';
import useUserPreferences from '../hooks/useUserPreferences';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useProjects } from './projectsContext';
import { PROJECT_SELECTED_KEY } from '../utils/utilityHelper';

const PREFERENCE_KEY = 'selectedProjects';

export type ProjectArray = Array<{
  id: string;
  label: string;
}>;

export interface FolderProp {
  id: string;
  label: string;
  projects: ProjectArray;
  subFolders?: FolderProp[];
  parentFolderId?: string;
  open?: boolean;
}

interface SelectedProjectsType {
  selectedProjects: Array<string>;
  toggleSelectedProject: (projectId: string) => void;
  interacted: boolean;
  setSelectedProjects: (projs: Array<string>) => void;
  selectAllProjects: (allProjectIds: string[]) => void;
  getAllProjectIds: () => string[];
}
export const SelectedProjectContext = createContext<SelectedProjectsType>({
  selectedProjects: [],
  toggleSelectedProject: noop,
  interacted: false,
  setSelectedProjects: noop,
  selectAllProjects: noop,
  getAllProjectIds: () => [],
});

export const SelectedProjectProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = props => {
  const { getPreference, setPreference, getPreferenceValue, setPreferenceValue } = useUserPreferences();
  const [selectedProjects, setSelectedProjects] = useState<Array<string>>(
    (getPreference(PREFERENCE_KEY) as Array<string>) || []
  );
  const [hasInteracted, setHasInteracted] = useState(false);
  const uiConfig = useConfig();
  const { folders, projects } = useProjects();
  const selectedProjectsMaxRecords = useMemo(() => uiConfig.selectedProjectsMaxRecords, [uiConfig]);

  const toggleSelectedProject = useCallback(
    (projectId: string) => {
      let projectIndex = selectedProjects.findIndex(proj => proj === projectId);
      setPreferenceValue(PROJECT_SELECTED_KEY, 'partial');
      let updatedProjects = [];
      if (projectIndex >= 0) {
        updatedProjects = [...selectedProjects.filter(o => o !== projectId)];
      } else {
        updatedProjects = [...selectedProjects, projectId];
      }
      setSelectedProjects(updatedProjects);
      if (!hasInteracted) {
        setHasInteracted(true);
      }
    },
    [
      selectedProjects,
      setSelectedProjects,
      setHasInteracted,
      hasInteracted,
      selectedProjectsMaxRecords,
    ]
  );
  const selectAllProjects = (allProjectIds: string[], setAll?: boolean) => {
    if (allProjectIds.length === 0) {
      setSelectedProjects([]);
      return;
    }
    const selectionStatus = getPreferenceValue(PROJECT_SELECTED_KEY) as 'all' | 'partial' | 'none' | null;
    setSelectedProjects(selected => {
      if ((setAll && (selectionStatus === 'all' || selectionStatus === null)) || !setAll) {
        return allProjectIds;
      }
      return selected;
    });
  };
  React.useEffect(() => {
    setPreference(PREFERENCE_KEY, selectedProjects);
  }, [selectedProjects, setPreference]);

  const getAllProjectIds = useCallback((): string[] => projects.map(p => p.id), [projects]);

  React.useEffect(() => {
    const allProjectIds = getAllProjectIds();
    if (allProjectIds.length > 0) {
      selectAllProjects(allProjectIds, true);
    }
  }, [folders]);

  return (
    <SelectedProjectContext.Provider
      value={{
        selectedProjects,
        toggleSelectedProject,
        interacted: hasInteracted,
        setSelectedProjects,
        selectAllProjects,
        getAllProjectIds,
      }}
    >
      {props.children}
    </SelectedProjectContext.Provider>
  );
};
export const useSelectedProject = () => useContext(SelectedProjectContext);
