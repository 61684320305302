import React, { useMemo } from 'react';
import { Button, Typography, makeStyles } from '@material-ui/core';
import magicText from 'i18next';
import { colors } from '../../../../styles/theme';

export interface TGOptionSelectorModalProps {
  multiSelect: boolean;
  state: unknown[];
  isNothingSelected: boolean;
  totalCount: number;
  handleClearAll: () => void;
  selectAllAssets: () => void;
}

const TGOptionLazyModalActions = (props: TGOptionSelectorModalProps) => {
  const { multiSelect, state, isNothingSelected, totalCount, handleClearAll, selectAllAssets } = props;

  const classes = useStyles();

  const numberOfElements = useMemo(() => {
    if (isNothingSelected) {
      return totalCount;
    }
    return state.length;
  }, [isNothingSelected, totalCount, state]);

  const button = useMemo(() => {
    if (multiSelect) {
      if (isNothingSelected) {
        return (
            <Button onClick={selectAllAssets} className={classes.btn}>
              <>{magicText.t('Common.selectAllLabel')}</>
            </Button>
        );
      } else {
        return (
            <Button onClick={handleClearAll} className={classes.btn}>
              <>{magicText.t('Common.deselectAllLabel')}</>
            </Button>
        );
      }
    } else {
      return (
          <Button onClick={handleClearAll} className={classes.btn}>
            <>{magicText.t('Common.clearSelectionLabel')}</>
          </Button>
      );
    }
  }, [multiSelect, isNothingSelected, handleClearAll, selectAllAssets]);

  // Brackets are inside spans in order to center them vertically with the text (Roboto by default does not do that)
  if (multiSelect) {
    return (
        <div className={classes.actionsContainer}>
          {button}
          <Typography className={classes.numberOfElements}>
            <span>(</span>
            {numberOfElements}
            <span>)</span>
          </Typography>
        </div>
    );
  }

  return button;
};

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  numberOfElements: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      height: 21.5,
    },
  },
  btn: {
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    padding: 0,
    fontSize: 16,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    marginRight: 4,
  },
}));

export default TGOptionLazyModalActions;
