import React, { FunctionComponent, useCallback } from 'react';
import { Checkbox } from '@material-ui/core';
import { ReactComponent as ThreeDotMenu } from '../../images/threeDotMenu.svg';
import ActionsMenuUI, { ActionsMenuButton } from '../ActionsMenuUI';
import { useAssetsTableState } from '../../contexts/assetsTableStateContext';
export interface ThreeDotMenuCellProps {
  actions: readonly ActionsMenuButton[];
  statusText?: string;
  isMultiSelectActive: boolean;
  isSelected: boolean;
  toggleSelected: () => void;
  onOpen?: () => void;
  isClosed?: boolean;
}

const ThreeDotMenuCellRenderer: FunctionComponent<ThreeDotMenuCellProps> = props => {
  const { actions, statusText, isMultiSelectActive, isSelected, toggleSelected, onOpen, isClosed } = props;

  // Handle Actions Menu
  const [actionsMenuAnchor, setActionsMenuAnchor] = React.useState<Element | null>(null);
  const {dataLoading} = useAssetsTableState()
  const handleActionsMenuClose = useCallback(() => {
    setActionsMenuAnchor(null);
  }, []);

  // Handle Action Click
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    setActionsMenuAnchor(event.currentTarget);
    onOpen && onOpen();
  };

  return isMultiSelectActive ? (
    <Checkbox checked={isSelected} color="primary" onClick={toggleSelected} disabled = {dataLoading}/>
  ) : (
    <>
      <ThreeDotMenu onClick={handleClick} style={styles.threeDotsMenu} />
      <ActionsMenuUI
        statusText={statusText}
        actions={actions}
        anchorEl={isClosed ? null : actionsMenuAnchor}
        onClose={handleActionsMenuClose}
      />
    </>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  threeDotsMenu: {
    minWidth: 28,
    cursor: 'pointer',
  },
};
export default ThreeDotMenuCellRenderer;
