import React, { FunctionComponent, useEffect } from 'react';
import { ZoomControl, RotationControl } from 'react-mapbox-gl';
import { makeStyles } from '@material-ui/core/styles';
import MapComponentControl from './MapComponent';
import { MOBILE_BREAKPOINT } from '../../../../utils/utilityHelper';
import { colors } from '../../../../styles/theme';

interface MapControlsOverlayProps {
  setBounds: (bounds: any) => void;
}

const MapControlsOverlay: FunctionComponent<MapControlsOverlayProps> = props => {
  const classes = useStyles();

  useEffect(() => {
    const domElements: Array<HTMLElement> = [];
    const touchHandler = (ev: Event, preventDefault: boolean = true) => {
      // @ts-ignore
      ev?.target?.click?.();
      if (preventDefault) ev?.preventDefault();
    };
    const zpCtrl = document.getElementById('zoomIn');
    if (zpCtrl) {
      domElements.push(zpCtrl);
      zpCtrl.addEventListener('touchend', touchHandler);
    }
    const zmCtrl = document.getElementById('zoomOut');
    if (zmCtrl) {
      domElements.push(zmCtrl);
      zmCtrl.addEventListener('touchend', touchHandler);
    }
    const rtCtrl = document.getElementsByClassName(classes.rotateControls)?.[0]?.getElementsByTagName('button')?.[0];
    if (rtCtrl) {
      domElements.push(rtCtrl);
      rtCtrl.addEventListener('touchend', touchHandler);
    }
    return () => {
      domElements.forEach(domelement => {
        domelement.removeEventListener('touchend', touchHandler);
      });
    };
  }, []);
  return (
    <div className={classes.mapBody}>
      <div className={classes.mapLeftControls}>
        <ZoomControl position="top-right" className={[classes.zoomRotateControls, classes.zoomIn].join(' ')} />
        <RotationControl
          position="top-right"
          className={[classes.zoomRotateControls, classes.rotateControls].join(' ')}
        />
      </div>
      <div className={classes.mapRightControls}>
        <div className={classes.mapControl}>
          <MapComponentControl />
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapBody: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  mapLeftControls: {
    height: '100%',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      pointerEvents: 'none',
    },
  },
  mapControl: {
    position: 'absolute',
    right: 25,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT)]: {
      right: 16,
    },
  },
  mapRightControls: {
    height: '100%',
  },
  zoomRotateControls: {
    background: colors.white,
    borderRadius: '5px !important',
    boxShadow: `0px 2px 4px 0px ${colors.black15} !important`,
    border: 'none !important',
    marginTop: 58,
    marginLeft: 0,
    width: 34,
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
    right: '25px !important',

    '& > button': {
      height: '34px !important',
      width: '34px !important',
      paddingInline: 5,
      backgroundRepeat: 'no-repeat',
      backgroundColor: `${colors.white} !important`,
      '&:hover': {
        backgroundColor: `${colors.black05} !important`,
      },
      '&#zoomOut': {
        borderBottomLeftRadius: '5px !important',
        borderBottomRightRadius: '5px !important',
      },
      '&#zoomIn': {
        borderTopLeftRadius: '5px !important',
        borderTopRightRadius: '5px !important',
      },
    },
    '& svg > polygon:first-child': {
      fill: colors.black54,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginTop: 59,
      right: '16px !important',
      '& > button': {
        top: 8,
        '& svg': {
          pointerEvents: 'none',
        },
      },
    },
  },
  rotateControls: {
    marginTop: 84,
    borderWidth: 0,
    '& > button': {
      height: '34px !important',
      width: '34px !important',
      borderWidth: 0,
      borderBottomLeftRadius: '5px !important',
      borderBottomRightRadius: '5px !important',
      borderTopLeftRadius: '5px !important',
      borderTopRightRadius: '5px !important',
      paddingBottom: 0,
      '& span': {
        width: '24px !important',
        height: '24px !important',
        position: 'relative !important',
        top: '2px !important',
      },
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      top: '62px !important',
    },
  },
  zoomIn: {
    height: 68,
  },
}));

export default MapControlsOverlay;
