import { alpha, createTheme, Theme } from '@material-ui/core';
import { colors } from '../../styles/theme';

export const calendarTheme = (theme: Theme) => {
  const primaryColor = theme.palette.primary.main;
  const primaryColor54 = alpha(primaryColor, 0.54);
  return createTheme({
    palette: {
      primary: {
        main: primaryColor,
        dark: primaryColor,
        light: colors.white,
      },
    },
    overrides: {
      // @ts-ignore
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: primaryColor54,
        },
        toolbarBtnSelected: {
          color: primaryColor,
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: colors.ghostWhite,
        },
      },
      MuiPickerDTTabs: {
        tabs: {
          backgroundColor: colors.ghostWhite,
          color: primaryColor,
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: primaryColor,
        },
      },
      MuiPickersStaticWrapper: {
        staticWrapperRoot: {
          minWidth: 300,
          boxShadow: `0px 2px 4px 0px ${colors.black15}`,
        },
      },
      MuiTypography: {
        h4: {
          fontSize: 34,
        },
      },
    },
  });
};
