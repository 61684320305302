import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography, alpha, useTheme } from '@material-ui/core';
import { useLayoutContext, LayoutType } from './LayoutContext';
import { colors } from '../styles/theme';
import React, { useMemo } from 'react';

const DrawButton = ({
  onClick,
  srcImage,
  title,
  anchorEl,
  isMobileView,
  enabled,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  srcImage: IconProp;
  title: string;
  anchorEl?: boolean;
  isMobileView?: boolean;
  enabled?: boolean;
}) => {
  const { layout } = useLayoutContext();
  const type = 'Horizontal' as LayoutType;
  const isLayout = title === 'Layout';
  const isHorizontal = type === layout;
  const theme = useTheme();

  const Icon = useMemo(() => {
    return <FontAwesomeIcon icon={anchorEl ? faAngleUp : faAngleDown} style={styles.icon} />;
  }, [anchorEl]);

  const primary = theme.palette.primary.main;
  const badge = {
    backgroundColor: alpha(primary, 0.15),
    color: primary,
    marginLeft: isMobileView || !isHorizontal ? 3 : 0,
  };

  return (
    <Button
      style={{ ...styles.buttonContainer, ...(isMobileView || !isHorizontal ? styles.p5 : {}) }}
      onClick={onClick}
    >
      <div style={styles.container}>
        <FontAwesomeIcon icon={srcImage} style={styles.layoutIcon} />
        {isHorizontal && !isMobileView ? <Typography style={styles.titleTxt}>{title}</Typography> : null}
        {enabled && <Box style={{ ...styles.enabled, ...badge }}>On</Box>}
        {isLayout && (!isHorizontal || !isMobileView) ? Icon : null}
      </div>
    </Button>
  );
};
const styles: { [key: string]: React.CSSProperties } = {
  icon: {
    color: colors.black54,
    position: 'relative',
    width: 11,
  },
  titleTxt: {
    fontSize: 14,
    fontWeight: 400,
    padding: '2px 5px',
    color: colors.black54,
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  buttonContainer: {
    border: `1px solid ${colors.black10}`,
    alignItems: 'center',
    background: colors.white,
    justifyContent: 'center',
    display: 'flex',
    padding: '5px 8px',
    borderRadius: 5,
    minWidth: 34,
    height: '34px',
  },
  layoutIcon: {
    height: 18.857,
    width: 22,
    color: colors.black54,
  },
  p5: {
    padding: 5,
  },
  enabled: {
    padding: '2px 4px',
    fontSize: 12,
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: 4,
    textTransform: 'none',
  },
};
export default DrawButton;
