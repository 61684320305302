import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import magicText from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@material-ui/core';

interface AlertDialogProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  confirmButtonTitle?: string;
  alertIcon?: any;
  closeIcon?: any;
  rootStyle?: React.CSSProperties;
  titleStyle?: React.CSSProperties;
  maintitleStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  onConfirm: () => void;
  onCancel?: () => void;
  onClose?: () => void;
  primaryColor?: string;
  divider?: boolean;
  className?: string;
}

export default function TGAlertDialog({
  open,
  title,
  children,
  confirmButtonTitle,
  onConfirm,
  onCancel,
  titleStyle,
  maintitleStyle,
  alertIcon,
  buttonStyle,
  rootStyle,
  onClose,
  closeIcon,
  contentStyle,
  primaryColor,
  divider,
  className,
}: AlertDialogProps) {
  return (
    <div>
      <Dialog open={open} className={className}>
        <div style={rootStyle}>
          <DialogTitle id="alert-dialog-title" style={maintitleStyle || undefined}>
            {title}
          </DialogTitle>
          {closeIcon && (
            <div style={titleStyle || undefined}>
              <FontAwesomeIcon icon={closeIcon} onClick={onClose} />
            </div>
          )}
        </div>
        {divider && <Divider />}
        <DialogContent style={contentStyle}>
          <DialogContentText id="alert-dialog-description" component="div">
            {alertIcon ? (
              <div style={{ display: 'flex' }}>
                <FontAwesomeIcon
                  icon={alertIcon}
                  style={{ height: 45, color: primaryColor || '#56CCF2', marginRight: 15 }}
                />
                <div>{children}</div>
              </div>
            ) : (
              <div>{children}</div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {onCancel && (
            <Button onClick={onCancel} color="primary" style={buttonStyle}>
              Cancel
            </Button>
          )}
          <Button
            onClick={onConfirm}
            color="primary"
            autoFocus
            focusRipple={false}
            style={buttonStyle ? { ...buttonStyle, color: '#FFF', background: primaryColor || '#56CCF2' } : undefined}
          >
            <> {confirmButtonTitle ?? magicText.t('Confirm')}</>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
