import React, { useContext, useState } from 'react';

interface ImportModalContextType {
  openImport: () => void;
  closeImport: () => void;
  isImportOpen: boolean;
}

const importModalContext = React.createContext<ImportModalContextType>({
  openImport: () => {},
  closeImport: () => {},
  isImportOpen: false,
});

const useImportModal = () => {
  const context = useContext(importModalContext);
  if (!context) {
    throw Error('useImportModal must be within a UserProvider');
  }
  return context;
};

const ImportModalProvider = (props: { children: React.ReactNode }) => {
  const [isImportOpen, setIsImportOpen] = useState(false);
  const openImport = () => {
    setIsImportOpen(true);
  };
  const closeImport = () => {
    setIsImportOpen(false);
  };

  return (
    <importModalContext.Provider
      value={{
        isImportOpen,
        openImport,
        closeImport,
      }}
      {...props}
    >
      {props.children}
    </importModalContext.Provider>
  );
};

export { useImportModal, ImportModalProvider };
