import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import useUserPreferences from '../hooks/useUserPreferences';

export type LayoutType = 'Horizontal' | 'Vertical';

interface LayoutContextType {
  layout: LayoutType;
  setLayout: (layout: LayoutType) => void;
}

const defaultLayout: LayoutType = 'Horizontal';

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const useLayoutContext = () => {
  const context = useContext(LayoutContext);
  if (!context) {
    throw new Error('useLayoutContext must be used within a LayoutProvider');
  }
  return context;
};

interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutProvider: React.FC<LayoutProviderProps> = ({ children }) => {
  const [layout, setLayout] = useState<LayoutType>(defaultLayout);
  const { getPreference, setPreference } = useUserPreferences();

  useEffect(() => {
    const layoutValue = getPreference('layout') as null | { layout: LayoutType };
    if (layoutValue) {
      setLayout(layoutValue.layout);
    }
  }, [getPreference]);

  useEffect(() => {
    setPreference('layout', { layout });
  }, [layout, setPreference]);

  const contextValue: LayoutContextType = {
    layout,
    setLayout,
  };

  return <LayoutContext.Provider value={contextValue}>{children}</LayoutContext.Provider>;
};
