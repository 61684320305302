import { AggregateDefinition } from '@terragotech/gen5-shared-components';

export const DATE_TIME_PROPERTY = 'DateTime';
export const MAX_ASSET_LIMIT_MULTISELECT = 5000

const dateTimeFilterName = 'dateContains';
const stringFilterName = 'includesInsensitive';

type searchFilterArray = Array<
  { [key: string]: { [key: string]: string } } | { [key: string]: { label: { includesInsensitive: string } } }
>;

export const getSortKey = (pascalString: string) => {
  return pascalString
    .replace(/([A-Z])/g, '_$1')
    .replace(/^_/, '')
    .toLowerCase();
};

export const getTimeZoneDifference = () => {
  const now = new Date();
  const offset = now.getTimezoneOffset();
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset > 0 ? '-' : '+';
  return `${sign}${hours} hours ${minutes} minutes`;
};

export const createSearchFilterArray = (aggregateDefinition: AggregateDefinition, searchText: string) => {
  let filterObj: searchFilterArray = [];
  aggregateDefinition.propertyDefinitions.map(property => {
    if (property.type === 'String') {
      filterObj.push({ [property.field]: { [stringFilterName]: searchText } });
    } else if (property.relationshipType === 'ONE-TO-ONE' || property.relationshipType === 'ONE_TO_ONE') {
      filterObj.push({ [property.field]: { label: { includesInsensitive: searchText } } });
    } else if (property.type === DATE_TIME_PROPERTY) {
      filterObj.push({ [property.field]: { [dateTimeFilterName]: searchText } });
    }
  });
  return filterObj;
};
