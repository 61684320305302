import React from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { ActionsMultiSelectMenuButton } from '../hooks/useMultiSelectActionsMenu';
import { AlertDialog } from '@terragotech/gen5-shared-components';
import { AssetType } from '../contexts/AggregatesContext/types';

export interface MultiActionDialogProps {
  show: boolean;
  validRecords: Array<AssetType>;
  exceptionCount: number;
  actionsMultiSelectMenuButton?: ActionsMultiSelectMenuButton;
  reset: () => void;
}

const MultiActionDialog = (props: MultiActionDialogProps) => {
  const { show, actionsMultiSelectMenuButton, reset, validRecords, exceptionCount } = props;
  const { selectedRecordTypeDefinition } = useRecordType();

  return show ? (
    <AlertDialog
      title={`Caution, ${actionsMultiSelectMenuButton?.label} is not available for some of the selected records`}
      okText={'Continue'}
      cancelText={'Cancel'}
      onOkPress={() => {
        setTimeout(() => {
          actionsMultiSelectMenuButton?.onClick(this, validRecords as any);
        });
        reset();
      }}
      disabled={!validRecords.length}
      onCancelPress={reset}
    >
      <div>
        "{actionsMultiSelectMenuButton?.label}" will run for
        <span style={{ fontWeight: 500 }}> {validRecords?.length || 0} </span>
        out of the
        <span style={{ fontWeight: 500 }}> {(validRecords?.length || 0) + exceptionCount}</span> selected
        {' ' + selectedRecordTypeDefinition?.plural}
      </div>
    </AlertDialog>
  ) : (
    <React.Fragment></React.Fragment>
  );
};

export default MultiActionDialog;
