import React, { useState, useEffect, useMemo } from 'react';
import StyledTable, { StyledTableProps, rowHeight } from './StyledTable';
import { TableData } from '../../hooks/useTable';
import { makeStyles } from '@material-ui/core';
import scrollbarWidth from './scrollbarWidth';
import { BOTTOM_GAP } from '../../utils/utilityHelper';

const useStyles = makeStyles(theme => ({
  fluidContainer: {
    display: 'block',
    width: '100%',
    maxHeight: '400px',
  },
}));

type FluidStyledTableProps<Data extends TableData> = Omit<StyledTableProps<Data>, 'width'> & {
  assetTable?: boolean;
  isAssetTable?: boolean;
};

const FluidStyledTable = <Data extends TableData>(props: FluidStyledTableProps<Data>) => {
  const { columns } = props;
  const classes = useStyles();

  const [container, setContainer] = useState(null as HTMLDivElement | null);
  const [width, setWidth] = useState(0);

  const [sumWidth, numberOfColumnsWithWidth] = useMemo(
    () =>
      columns.reduce<[number, number]>(
        ([width, numberOfColumnsWithWidth], column) => [
          width + (column.width || column.minWidth || 0),
          numberOfColumnsWithWidth + (column.width || column.minWidth ? 1 : 0),
        ],
        [0, 0]
      ),
    [columns]
  );

  useEffect(() => {
    if (container) {
      const containerWidth = container.offsetWidth;
      setWidth(containerWidth);
      const listener = () => {
        setWidth(container.offsetWidth);
      };
      window.addEventListener('resize', listener);
      return () => {
        window.removeEventListener('resize', listener);
      };
    }
  }, [container]);

  const freeWidth = useMemo(() => {
    const rightGap = 2;
    const verticalScrollbarVisible = props.data.length * rowHeight > props.height;
    return width - sumWidth - rightGap - (verticalScrollbarVisible ? scrollbarWidth() : 0);
  }, [props, sumWidth, width]);

  const processedColumns = useMemo(() => {
    if (freeWidth) {
      const leftColumns = columns.length - numberOfColumnsWithWidth;
      if (freeWidth > 0 && leftColumns > 0) {
        const columnWidth = freeWidth / leftColumns;
        return columns.map(column => {
          if (column.width || column.minWidth) {
            return column;
          }
          return {
            ...column,
            width: columnWidth,
          };
        });
      }
    }
    return columns;
  }, [freeWidth, numberOfColumnsWithWidth, columns]);

  const processedProps = useMemo(() => {
    return {
      ...props,
      columns: processedColumns,
    };
  }, [props, processedColumns]);

  const style: React.CSSProperties = {};
  if (props.height) style.maxHeight = `${props.height - BOTTOM_GAP}px`;
  return (
    <div ref={el => setContainer(el)} className={classes.fluidContainer} style={style}>
      <StyledTable {...processedProps} width={width} />
    </div>
  );
};

export default FluidStyledTable;
