/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, CardContent, makeStyles, Typography } from '@material-ui/core';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { MapBoundsContext, trimTrailingSlash, useConfig } from '@terragotech/gen5-shared-components';
import {
  MAP_SERVICE_TYPE
} from '@terragotech/gen5-shared-utilities';
import { Polygon, Properties } from '@turf/helpers';
import { Feature } from 'geojson';
import { History } from 'history';
import * as MapboxGl from 'mapbox-gl';
import { GeolocateControl, MapLayerEventType } from 'mapbox-gl';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import useRouteParams from '../components/Common/useRouteParams';
import AssetCardRenderer, { AssetCardRendererRef } from '../components/Map/AssetCard/AssetCardRenderer';
import { MaxZoomClickHandler } from '../components/Map/component/Map';
import useDrawFeature from '../components/Map/component/useDrawFeature';
import TGPageMapField from '../components/Page/TGMapField';
import { useAggregates } from '../contexts/AggregatesContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useAssetCards } from '../contexts/assetCardContext';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';
import { FilterContext, useFilter } from '../contexts/FilterContext/filterContext';
import { useRecordType } from '../contexts/recordTypeContext';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import { useTableColumns } from '../contexts/TableColumnContext';
import { mapLabelSelector } from '../recoil/atoms';
import { mapBoundsState } from '../recoil/atoms/mapMaintenance';
import { colors } from '../styles/theme';
import { arcGisParams, rasterSourceData, wmsParams } from '../utils/mapInfo';

export interface MapOverviewContainerProps {
  height?: unknown;
  width?: number;
  classes: any;
}

/**
 * @remarks Obtains variables for props, returns Map with props
 *
 * @param props
 */
const MapOverviewContainer: React.FunctionComponent<MapOverviewContainerProps> = props => {
  const { height, width } = props;
  const { selectedRecordType } = useRecordType();
  const mapLabelVisibility = useRecoilValue(mapLabelSelector);

  const match: { params: { assetId?: string } } = useRouteMatch({
    path: `/${selectedRecordType}/:assetId?/:comparison(map|data|edit)?`,
  }) || { params: {} };
  const [mapAssets, setMapAssets] = useState<MapAssetType[]>([]);
  const { setAssetsIds, setCoordinates, assetIds } = useAssetCards();
  const assetIdsLength = useRef(assetIds.length);
  const history: History = useHistory();
  const [assetId, setAssetId] = useState('');
  const [selectedClusterId, setSelectedClusterId] = useState<number | null>(null);
  const { handleAssetClick } = useAggregates();
  const [mapInstance, setMapInstance] = useState<MapboxGl.Map | null>(null);
  const [mapViewBox, setMapViewBox] = useState<Feature<Polygon, Properties>>();
  const [mapBounds, setMapBounds] = useRecoilState(mapBoundsState);
  const [mapRasterSources, setMapRasterSources] = useState<Record<string, MapboxGl.RasterSource>>();
  const { applyDrawFilter, undoRecentChanges, clearAll, isDrawActive } = useDrawFeature(mapInstance);
  const { setMapCenter, currentZoomLevel, isLatLngChanged, mapCenter } = useContext(MapBoundsContext);
  const assetCardRef = useRef<AssetCardRendererRef>(null);
  const { setCurrentAssetId } = useTableColumns();
  // Adding custom mapbox map control for current location button, because it is not available in our mapbox-react library
  // https://docs.mapbox.com/mapbox-gl-js/api/markers/#geolocatecontrol.event:trackuserlocationstart
  const onMapLoad: MapEvent = map => {
    setMapInstance(map);
    map.addControl(
      new GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      }),
      'top-right'
    );
  };

  useEffect(() => {
    assetIdsLength.current = assetIds.length;
  }, [assetIds]);

  const configContext = useConfig();
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const { selectedProjects } = useSelectedProject();

  const filters = useContext(FilterContext);
  const [zoom] = useState(isLatLngChanged ? currentZoomLevel : configContext.initialMapExtents.zoom);
  const lat = parseFloat(configContext.initialMapExtents.lat) || 0;
  const lon = parseFloat(configContext.initialMapExtents.lon) || 0;

  const [center] = useState<[number, number]>(isLatLngChanged ? mapCenter : [lon, lat]);
  const assetRecord = useAggregates();
  let assetData = assetRecord.filteredAssets;
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType,
  });
  const getAssetId = () => {
    const pattern = new RegExp(`/${selectedRecordType}/?(.+)?/?(map|data|edit)?`);
    const pathMatch = history.location.pathname.match(pattern);
    if (pathMatch) {
      const match = pathMatch?.[1]?.split('/');
      setAssetId(match?.[0] || '');
    }
  };
  const { setIsCardVisible } = useAssetCards();
  useEffect(() => {
    if (!isAssetDetailsOpen) {
      getAssetId();
      setIsCardVisible(true);
    }
  }, [selectedRecordType, match.params?.assetId, isAssetDetailsOpen, setIsCardVisible]);
  const handleMultipleCardsClose = () => {
    if (assetIdsLength.current !== 0) {
      assetCardRef.current?.handleClose();
    }
  };

  const getRecordType = useCallback(
    (mapData: MapAssetType[], feature: MapboxGl.MapboxGeoJSONFeature | undefined, assetId: string) => {
      if (assetId) {
        const type = mapData.find(x => x.id === assetId)?.recordTypeKey;
        if (type) {
          const selectedDefinitionName = aggregateDefinitions.find(x => x.queryKey === type)?.name;
          return selectedDefinitionName ?? '';
        } else {
          const aggregateTypeDefinitionName = aggregateDefinitions.find(
            x => x.queryKey === feature?.properties?.aggregateType
          )?.name;
          return aggregateTypeDefinitionName ?? '';
        }
      }
      return '';
    },
    [assetId, aggregateDefinitions]
  );

  //Various event handlers
  const handleSymbolClick = useCallback(
    (e: MapLayerEventType['click' | 'touchend' | 'touchstart']) => {
      setSelectedClusterId(0);
      handleMultipleCardsClose();
      const feature = e.features?.[0];
      if (feature) {
        let recordType = selectedRecordType;
        const assetId = feature.properties?.assetId ?? '';
        const selectedAsset = mapAssets.find(asset => asset.id === assetId);
        const assetDataRecordType = getRecordType(assetData, feature, assetId);
        const newRecordType =
          assetDataRecordType?.length > 0 ? assetDataRecordType : getRecordType(mapAssets, feature, assetId);
        recordType = newRecordType.length > 0 ? newRecordType : recordType;
        if (history.location.pathname === `/${recordType}/${assetId}`) {
          history.push(`/${recordType}`);
        } else {
          selectedAsset && handleAssetClick(selectedAsset);
          history.push(`/${recordType}/${assetId}`);
        }
      }
    },
    [history, selectedRecordType, assetData, mapAssets, getRecordType]
  );
  const handleMaxZoomClusterClick = useCallback<MaxZoomClickHandler>((map, feature, coordinate) => {
    if (map && feature && feature.id && feature.geometry.type === 'Point') {
      const clusterId = typeof feature.id === 'string' ? parseFloat(feature.id) : feature.id;
      const source = map.getSource('symbolSource') as MapboxGl.GeoJSONSource;
      source.getClusterLeaves(clusterId, Infinity, 0, (error, features) => {
        if (error) {
          console.error(error);
          return;
        }
        const assetIds = features.map(feature => (feature.properties ? feature.properties.assetId : ''));
        setAssetsIds(assetIds);
        setCoordinates(coordinate);
      });
    }
  }, []);

  useEffect(() => {
    // For now, handling all layers as individual sources so each layer can be toggled.
    const rasterSources: Record<string, MapboxGl.RasterSource> = {};
    assetRecord.mapServiceLayers.forEach(sl => {
      let serviceParams: Partial<MapboxGl.RasterSource> = {};
      switch (sl.serviceType) {
        case MAP_SERVICE_TYPE.WMS:
          serviceParams = {
            // Use the tiles option to specify a WMS tile source URL. https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/
            tiles: [
              `${sl.serviceUri}?bbox=${wmsParams.bbox}&${new URLSearchParams({
                ...(Object.fromEntries(
                  Object.entries(wmsParams).filter(([k, v]) => k !== 'bbox' && v != null)
                ) as Record<string, string>),
                layers: sl.layer.layerName, // sl.layers.map(x => x.id).join(','), // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                //styles: sl.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
              }).toString()}`,
            ],
          };
          break;
        case MAP_SERVICE_TYPE.ArcGIS:
          serviceParams = {
            tiles: [
              `${trimTrailingSlash(sl.serviceUri)}/export?bbox=${arcGisParams.bbox}&${new URLSearchParams({
                ...(Object.fromEntries(
                  Object.entries(arcGisParams).filter(([k, v]) => k !== 'bbox' && v != null)
                ) as Record<string, string>),
                layers: `show:${sl.layer.layerName}`, // `show:${s.layers.map(x => x.id).join(',')}`, // Reimplement when multiple layer names (within each toggle) are supported by the aggregate.
                //styles: s.layers.map(x => x.style || '').join(','), // Reimplement when styles are added supported by the aggregate.
              }).toString()}`,
            ],
          };
          break;
        default:
          console.error(`Map Service Layer ${sl.id} has an invalid service type: ${sl.serviceType}`);
          break;
      }

      if (Object.keys(serviceParams).length) {
        rasterSources[sl.id] = { ...rasterSourceData, ...serviceParams };
      }
    });
    setMapRasterSources(rasterSources);
  }, [assetRecord.mapServiceLayers, setMapRasterSources]);

  const onCardClose = () => {
    setCurrentAssetId(o => ({ ...o, id: '' }));
    if (history.location.pathname.match(/\/\w+\//)) {
      history.push(history.location.pathname.match(/(\/\w+)\//)?.[1] || '');
    }
    setSelectedClusterId(0);
  };

  return (
    <>
      <TGPageMapField
        hideVisibility={false}
        setMapAssets={setMapAssets}
        height="100%"
        mapBounds={mapBounds}
        setMapBounds={setMapBounds}
        aggregates={aggregateDefinitions
          .map(d => {
            const filterState = filters.getFilterState(d.name);
            if (!assetRecord.visibleAggregateTypesNames.includes(d.name)) {
              return undefined;
            }

            return {
              type: 'AggregateLoader',
              aggregateType: d.name,
              mandatoryFilter: {
                filter: {
                  type: 'GroupFilter',
                  conjunction: 'AND',
                  condition: [
                    ...(selectedProjects.length
                      ? [
                          {
                            type: 'SimpleFilter',
                            key: 'project',
                            operator: 'INCLUDE',
                            options: selectedProjects,
                          },
                        ]
                      : []),
                    ...Object.keys(filterState)
                      .map(field => {
                        const actualField = field.split('-')[0];
                        const currType = d?.propertyDefinitions.find(obj => obj.field === actualField);

                        // Date only filtering
                        if (currType && (currType.type === 'Date' || currType.type === 'DateTime')) {
                          return {
                            type: 'SimpleFilter',
                            key: actualField,
                            operator: 'BETWEEN',
                            lowerBound: new Date(filterState[field][0]),
                            upperBound: new Date(filterState[field][1]),
                          };
                        }
                        if (field.endsWith('-QuickFilter')) {
                          return {
                            type: 'SimpleFilter',
                            key: currType && currType.relationshipType === 'ONE_TO_ONE' ? 'label' : actualField,
                            foreignTable:
                              currType && currType.relationshipType === 'ONE_TO_ONE'
                                ? `"ref${actualField}"`
                                : undefined,
                            operator: 'LIKE',
                            options: `%${filterState[field]}%`,
                          };
                        } else {
                          return {
                            type: 'SimpleFilter',
                            key: currType && currType.relationshipType === 'ONE_TO_ONE' ? 'label' : actualField,
                            foreignTable:
                              currType && currType.relationshipType === 'ONE_TO_ONE'
                                ? `"ref${actualField}"`
                                : undefined,
                            operator: 'INCLUDE',
                            options: filterState[field].length ? filterState[field] : ['INVALIDVALUEDONOTRETURN'],
                          };
                        }
                      })
                      .filter(a => a),
                  ],
                },
              },
            } as any;
          })
          .filter(a => a)}
        mapParams={{
          onMaxZoomClusterClick: handleMaxZoomClusterClick,
          height: height,
          width: width,
          desiredZoom: zoom,
          desiredCenter: center,
          onStyleLoad: onMapLoad,
          onSymbolClick: handleSymbolClick,
          showMapControls: true,
          showRightControls: true,
          limitedRightControls: false,
          forceDisableClustering: false,
          showMapLabels: mapLabelVisibility,
          setMapViewBox: setMapViewBox,
          mapViewBox: mapViewBox,
          setMapCenter: setMapCenter,
          mapRasterSources: mapRasterSources,
          mapVisibleMapServiceKeys: assetRecord.visibleMapServiceKeys,
          selectedClusterId: selectedClusterId,
          setSelectedClusterId: setSelectedClusterId,
          handleMultipleCardsClose: handleMultipleCardsClose,
        }}
      />
      <AssetCardRenderer
        selectedAssetType={selectedRecordType}
        selectedAssetId={assetId}
        mapInstance={mapInstance}
        forceCards
        ref={assetCardRef}
        onCardClose={onCardClose}
        assetIds={assetIds}
        setAssetsIds={setAssetsIds}
      />
      <DrawFilterActions
        onApply={applyDrawFilter}
        onCancel={undoRecentChanges}
        onClear={clearAll}
        isDrawActive={isDrawActive}
      />
    </>
  );
};

interface DrawActionProps {
  onClear: () => void;
  onCancel: () => void;
  onApply: () => void;
  isDrawActive: boolean;
}
const useStyles = makeStyles(theme => ({
  card: {
    position: 'absolute',
    zIndex: 999,
    bottom: 20,
    transform: 'translate(-50%,1px)',
    padding: '4px 18px',
    left: '50%',
    background: colors.white95,
    borderRadius: 5,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    display: 'inline-flex',
    gap: 18,
    alignItems: 'center',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 18,
    padding: 0,
    '&.MuiCardContent-root:last-child': {
      paddingBottom: 0,
    },
  },
  typography: {
    fontSize: 15,
    fontWeight: 400,
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    lineHeight: 'normal',
    color: colors.black0,
  },
  buttonCancel: {
    padding: '6px 10px',
    fontSize: 15,
    fontWeight: 500,
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    lineHeight: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
  },
  clearBtn: {
    padding: '6px 5px',
  },
  buttonContainer: {
    display: 'flex',
  },
}));

const DrawFilterActions: React.FunctionComponent<DrawActionProps> = props => {
  const { drawtoolFilter, drawFilter } = useFilter();
  const assetRecord = useAggregates();
  const { onClear, onCancel, isDrawActive } = props;
  const classes = useStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);

  const disableDrawMode = useCallback(() => {
    onClear();
    onCancel();
  }, [onClear, onCancel]);

  useEffect(() => {
    if (!drawtoolFilter) {
      onCancel();
    }
  }, [drawtoolFilter]);

  useEffect(() => {
    if (isMobileView) {
      disableDrawMode();
    }
  }, [isMobileView]);
  if (drawFilter.enabled) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.typography}>
            {isDrawActive ? 'Double-click to complete the drawn shape.' : 'Draw Shapes on the map to filter your data.'}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button onClick={disableDrawMode} className={classes.buttonCancel}>
              Cancel
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
  if (drawFilter.apply && drawFilter.features.length) {
    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.typography}>
            {`${assetRecord.filteredAssets.length} Record${assetRecord.filteredAssets.length > 1 ? 's' : ''} Selected`}
          </Typography>
          <div className={classes.buttonContainer}>
            <Button onClick={onClear} className={[classes.buttonCancel, classes.clearBtn].join(' ')}>
              Clear Drawing
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
  return null;
};
const styles = () =>
  createStyles({
    popupWrapper: {
      '& .mapboxgl-popup-content': {
        backgroundColor: '#333333',
        padding: '5px',
      },
      '& .mapboxgl-popup-tip': {
        borderTopColor: '#333333',
      },
      '&:hover': {
        zIndex: `99999 !important`,
      },
    },
    popupItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#fff',
    },
    popupItemValue: {
      fontSize: '13px',
    },
    emptyValue: {
      color: '#A8A8A8',
      fontSize: '13px',
    },
  });
export default withStyles(styles)(MapOverviewContainer);
