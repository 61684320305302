import { Box, Button } from '@material-ui/core';
import distance from '@turf/distance';
import { LineString, Polygon, Properties, Units } from '@turf/helpers';
import { Feature as MapFeature } from 'geojson';
import * as MapboxGl from 'mapbox-gl';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Dispatch, SetStateAction, useMemo, useRef, useState, useContext, useEffect } from 'react';
import { Feature, Layer, Marker, Source } from 'react-mapbox-gl';
import { MapEvent } from 'react-mapbox-gl/lib/map-events';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import {
  useConfig,
  getUnitAbbreviation,
  round,
  MapBoundsContext,
  useCurrentLocation,
  LatLon,
} from '@terragotech/gen5-shared-components';
import Map, { MapItem, MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL } from '../../Map/component/Map';
import { BaseLocationMapProps, useLocationStyles } from './CommonEditorUtils';
import { useRecordType } from '../../../contexts/recordTypeContext';
import MapButtons from './MapButtons';
import HideNonSelectableButtons from '../../Map/component/HideNonSelectableButton';
import useHideNonSelectable from '../../Map/component/useHideNonSelectable';
import { colors } from '../../../styles/theme';

interface TGLineEditorProps extends BaseLocationMapProps {
  lineLocation: GeoJSON.LineString | null | undefined;
  setLineLocation: Dispatch<SetStateAction<GeoJSON.LineString | null | undefined>>;
  isMobileView: boolean;
}
const HEADER_FOOTER_PADDING = 127;
export const TGLineEditor: React.FC<TGLineEditorProps> = props => {
  const { lineLocation, setLineLocation, setIsValidLocation, isMobileView } = props;
  const [pointToDelete, setPointToDelete] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  // Need a ref because the onClick passed to react-mapbox-gl will not update when state changes
  const pointToDeleteRef = useRef<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  const hoverOnFeature = useRef(false);
  const [desiredZoom, setDesiredZoom] = useState(18);
  const [mapViewBox, setMapViewBox] = useState<MapFeature<Polygon, Properties>>();
  const { mapCenter, currentZoomLevel } = useContext(MapBoundsContext);
  const [showMeasurement, setShowMeasurement] = useState(true);
  const [snackbarStatus, setSnackbarStatus] = useState(true);
  const currentLocation = useCurrentLocation();

  // get all assets to display based on filters
  const { aggregateDefinitions, line, geographic, initialMapExtents } = useConfig();
  const { polylineRoundingPrecision, polylineUnitOfMeasurement } = geographic || {
    polylineRoundingPrecision: undefined,
    polylineUnitOfMeasurement: undefined,
  };
  const configRoundingPrecision: 'ones' | 'tenths' | 'hundredths' | 'thousandths' = polylineRoundingPrecision || 'ones';
  const configUnits: Units = polylineUnitOfMeasurement || ('feet' as Units);
  const unitsAbbreviation = getUnitAbbreviation(configUnits);
  const lineEditColor = line?.editColor && line.editColor.includes('#') ? line.editColor : '#00FFF0';
  const { assetData, handleHidingNonSelectableRecords, hiding } = useHideNonSelectable();
  const { selectedRecordType } = useRecordType();
  useEffect(() => {
    const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === selectedRecordType);
    setShowMeasurement(
      typeof aggregateDefinition?.showMeasurementOnMap === 'undefined' ||
        aggregateDefinition?.showMeasurementOnMap === null
        ? true
        : aggregateDefinition?.showMeasurementOnMap
    );
  }, [aggregateDefinitions, selectedRecordType]);

  const mapItems: Array<MapItem> = assetData
    .filter(
      (asset: AssetType) =>
        asset.id &&
        asset.primaryLocation?.type &&
        asset.primaryLocation?.coordinates &&
        !aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView
    )
    .map(asset => {
      return {
        location: asset.primaryLocation,
        styleKey: asset.symbolKey,
        id: asset.id,
        selected: false,
        aggregateType: asset.recordTypeKey,
      };
    });
  const mapRef = useRef<MapboxGl.Map | null>(null);
  const locationStyles = useLocationStyles();

  const [selectedPoint, setSelectedPoint] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);

  // set map ref and set center to midpoint of line if we have one
  const onMapLoad: MapEvent = map => {
    // We slightly adjust this map parameter, so that the pins get rendered properly
    //https://github.com/alex3165/react-mapbox-gl/issues/904#issuecomment-782748173
    mapRef.current = map;
    if (lineLocation && lineLocation.coordinates) {
      map.setCenter(getMidpoint(lineLocation.coordinates));
      setDesiredZoom(18.001);
    } else {
      map.setCenter([
        parseFloat(mapCenter[0]) || parseFloat(initialMapExtents.lon) || 0,
        parseFloat(mapCenter[1]) || parseFloat(initialMapExtents.lat) || 0,
      ]);
      setDesiredZoom(currentZoomLevel || 18.001);
    }
  };

  // we need to capture the selected point in a ref because the onClick passed to react-mapbox-gl will not update with our state hook changes; this lets us read that state instead of stale props
  const psp = useRef<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  psp.current = selectedPoint;

  const updateLineLocation = ({ lng, lat, indexToUpdate }: { lng: number; lat: number; indexToUpdate: number }) => {
    setLineLocation(prevLine => {
      if (prevLine) {
        let newLine = {
          type: 'LineString',
          coordinates: [
            ...prevLine.coordinates.slice(0, indexToUpdate),
            [lng, lat],
            ...prevLine.coordinates.slice(indexToUpdate + 1),
          ],
        } as GeoJSON.LineString;
        return newLine;
      }
    });
    // since the map is visual editing only, we can presume locations to be valid after editing the first time
    setIsValidLocation(true);
    // if we have a point selected, we need to move it along with the update to the line
    if (psp.current) {
      setSelectedPoint({ coordinates: [lng, lat], indexOfCoords: psp.current.indexOfCoords });
    }
  };

  const addLineLocation = (lng: number, lat: number) => {
    setLineLocation(prevLine => {
      let newLine = {
        type: 'LineString',
        coordinates: [...(prevLine?.coordinates ? prevLine.coordinates : []), [lng, lat]],
      } as GeoJSON.LineString;
      return newLine;
    });
    setIsValidLocation(true);
  };

  const onClick = (_map: MapboxGl.Map, evt: MapboxGl.EventData) => {
    const { lat, lng } = evt.lngLat;
    if (!hoverOnFeature.current) {
      if (!pointToDeleteRef.current) {
        addLineLocation(lng, lat);
      } else if (pointToDeleteRef.current) {
        pointToDeleteRef.current = undefined;
        setPointToDelete(undefined);
        if (psp.current) {
          updateLineLocation({ lng, lat, indexToUpdate: psp.current.indexOfCoords });
        }
      }
    }
  };

  const handleDrag = (index: number) => (evt: any) => {
    const { lat, lng } = evt.lngLat;
    updateLineLocation({ lng, lat, indexToUpdate: index });
  };
  const buildSourceDataFromItems = (
    lineString: LineString,
    showPolylineMeasurement: boolean | undefined,
    configUnits: any,
    configRoundingPrecision: any,
    unitsAbbreviation: string
  ): [GeoJSON.FeatureCollection] => {
    let lineMeasurement: any[] = [];
    if (showPolylineMeasurement) {
      let previousLocation: number[] | undefined;
      let totalDistance = 0;
      (lineString?.coordinates || []).forEach((location: number[]) => {
        if (previousLocation) {
          let midPoint = getMidpoint([location, previousLocation]);
          let distanceInUnits = distance(location, previousLocation, { units: configUnits });
          totalDistance += distanceInUnits;
          let roundedDistance = round(distanceInUnits, configRoundingPrecision);
          const measurementItem = {
            geometry: {
              type: 'Point',
              coordinates: midPoint,
            } as GeoJSON.Point,
            type: 'Feature',
            properties: {
              measurement: `${roundedDistance}${unitsAbbreviation}`,
            },
          } as const;
          lineMeasurement.push(measurementItem);
        }
        previousLocation = location;
      });
      //push a new item into lineMeasurement
      const measurementItem = {
        geometry: {
          type: 'Point',
          coordinates: lineString?.coordinates[0],
        } as GeoJSON.Point,
        type: 'Feature',
        properties: {
          measurement: `Total: ${round(totalDistance, configRoundingPrecision)}${unitsAbbreviation}`,
        },
      } as const;
      lineMeasurement.push(measurementItem);
    }
    return [{ type: 'FeatureCollection', features: lineMeasurement }];
  };

  const [lineMeasurement] = useMemo(() => {
    return lineLocation
      ? buildSourceDataFromItems(lineLocation, showMeasurement, configUnits, configRoundingPrecision, unitsAbbreviation)
      : [null];
  }, [lineLocation, mapRef.current]);

  const editLineGeoJsonSource = useMemo(
    () => ({
      type: 'geojson',
      data: lineLocation || undefined,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    }),
    [lineLocation]
  );

  const lineMeasurementGeoJsonSource = useMemo(() => {
    return {
      type: 'geojson',
      data: lineMeasurement || undefined,
      maxzoom: Math.max(MAX_ZOOM, MAX_ZOOM_CLUSTERING_LEVEL),
    };
  }, [lineMeasurement]);

  const onMidPointClick = (index: number, location: number[]) => {
    setLineLocation(prevLine => {
      if (mapRef.current) {
        if (prevLine) {
          let newLine = {
            ...prevLine,
            coordinates: [
              ...prevLine.coordinates.slice(0, index + 1),
              location,
              ...prevLine.coordinates.slice(index + 1, prevLine.coordinates.length),
            ],
          };
          return newLine;
        }
      } else {
        return prevLine;
      }
    });
  };

  const midpoints = useMemo(() => {
    if (lineLocation) {
      const getMidPoints = () => {
        let midPoints: number[][] = [];
        let previousLocation: number[] | undefined;
        lineLocation.coordinates.forEach((location: number[]) => {
          if (previousLocation) {
            midPoints.push(getMidpoint([location, previousLocation]));
          }
          previousLocation = location;
        });
        return midPoints;
      };

      return getMidPoints().map((location, index) => (
        <Feature
          key={index}
          coordinates={location}
          onMouseEnter={() => mouseEnterFeature()}
          onMouseLeave={() => mouseLeaveFeature()}
          onClick={() => {
            onMidPointClick(index, location);
          }}
        />
      ));
    }
  }, [mapRef.current, setLineLocation, lineLocation]);

  const onDeleteLine = () => {
    setLineLocation(prevLine => {
      let newLine = {
        type: 'LineString',
        coordinates: [...(prevLine?.coordinates ? prevLine.coordinates : [])],
      } as GeoJSON.LineString;
      newLine.coordinates.splice(pointToDelete!.indexOfCoords, 1);
      return newLine;
    });
    setIsValidLocation(true);
    pointToDeleteRef.current = undefined;
    setPointToDelete(undefined);
    hoverOnFeature.current = false;
    if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'crosshair';
  };

  const lineLayerLayout = {
    'line-join': 'round',
    'line-cap': 'round',
  };
  const lineLayerPaint = {
    'line-color': lineEditColor,
    'line-width': 4,
  };

  const midpointLayerPaint = {
    'circle-color': colors.white,
    'circle-radius': 7,
    'circle-stroke-color': lineEditColor,
    'circle-stroke-width': 1,
  };

  const selectedPointPaint = {
    'circle-color': colors.white,
    'circle-radius': 14,
    'circle-stroke-color': lineEditColor,
    'circle-stroke-width': 3,
  };
  const linePointPaint = {
    'circle-color': colors.white,
    'circle-radius': 10,
    'circle-stroke-color': lineEditColor,
  };

  const lineMarkerLayerPaint = {
    'text-color': colors.white,
    'text-halo-color': colors.black0,
    'text-halo-width': 2,
  };

  const lineMarkerLayerLayout = {
    'text-field': ['get', 'measurement'],
    'text-letter-spacing': 0.1,
    'text-size': 14,
    'text-offset': [0, -1],
    'text-justify': 'center',
    'text-font': ['Arial Unicode MS Bold'],
  };
  const MapRef = useRef<HTMLDivElement | null>(null);

  const flyTo = (center: LatLon) => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [center?.longitude, center?.latitude],
      });
    }
  };

  const captureDeviceLocation = () => {
    if (currentLocation) {
      flyTo(currentLocation);
    }
  };

  const mouseEnterFeature = () => {
    hoverOnFeature.current = true;
    if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'pointer';
  };

  const mouseLeaveFeature = () => {
    hoverOnFeature.current = false;
    if (mapRef.current) mapRef.current.getCanvas().style.cursor = 'crosshair';
  };

  return (
    <div ref={MapRef} style={{ height: '100%' }}>
      {!isMobileView && (
        <HideNonSelectableButtons
          buttonCaption="Other Records"
          paddingRight={13}
          paddingBottom={34}
          {...{ handleHidingNonSelectableRecords, hiding }}
        />
      )}
      <Box
        className={
          isMobileView
            ? `${locationStyles.mapContainer} ${locationStyles.formPolygonMapContainer}`
            : `${locationStyles.mapContainer} ${locationStyles.formPolygonMapContainerWeb}`
        }
      >
        {isMobileView && (
          <HideNonSelectableButtons
            buttonCaption="Other Records"
            paddingRight={13}
            paddingBottom={12}
            {...{ handleHidingNonSelectableRecords, hiding }}
          />
        )}
        <Map
          height={
            MapRef.current?.clientHeight !== undefined ? MapRef?.current?.clientHeight - HEADER_FOOTER_PADDING : '100%'
          }
          width={MapRef?.current?.clientWidth}
          desiredZoom={desiredZoom}
          items={mapItems}
          onStyleLoad={onMapLoad}
          onClick={onClick}
          setMapViewBox={setMapViewBox}
          mapViewBox={mapViewBox}
        >
          <>
            <Source id="editLineSource" geoJsonSource={editLineGeoJsonSource} />
            <Source id="lineMeasurementSource" geoJsonSource={lineMeasurementGeoJsonSource} />
            <Layer paint={linePointPaint} type="circle" id="edit line layer vertices">
              {lineLocation?.coordinates.map((coords: number[], index: number) => {
                return (
                  <Feature
                    coordinates={coords}
                    key={index}
                    draggable
                    onMouseEnter={mouseEnterFeature}
                    onMouseLeave={mouseLeaveFeature}
                    onDrag={handleDrag(index)}
                    onClick={() => {
                      if (!pointToDelete) {
                        setPointToDelete({ coordinates: coords, indexOfCoords: index });
                        pointToDeleteRef.current = { coordinates: coords, indexOfCoords: index };
                      } else {
                        pointToDeleteRef.current = undefined;
                        setPointToDelete(undefined);
                        if (selectedPoint) {
                          if (selectedPoint.coordinates === coords) {
                            setSelectedPoint(undefined);
                          } else {
                            setSelectedPoint({ coordinates: coords, indexOfCoords: index });
                          }
                        } else {
                          setSelectedPoint({ coordinates: coords, indexOfCoords: index });
                        }
                      }
                    }}
                  />
                );
              })}
            </Layer>
            <Layer paint={selectedPointPaint} type="circle" id="selected point" before="edit line layer vertices">
              {selectedPoint && <Feature coordinates={selectedPoint.coordinates} />}
            </Layer>
            <Layer
              layout={lineLayerLayout}
              paint={lineLayerPaint}
              type="line"
              id="edit line layer"
              sourceId="editLineSource"
              before="edit line layer vertices"
            />
            <Layer
              type="symbol"
              id={'line-editor-measurement'}
              sourceId="lineMeasurementSource"
              paint={lineMarkerLayerPaint}
              layout={lineMarkerLayerLayout}
            />
            <Layer paint={midpointLayerPaint} type="circle" id="midpoints">
              {/* memoized midpoint values, recalculates as needed  */}
              {midpoints}
            </Layer>
          </>

          {pointToDelete && (
            <Marker
              anchor={'bottom'}
              coordinates={pointToDelete.coordinates}
              onMouseEnter={mouseEnterFeature}
              onMouseLeave={mouseLeaveFeature}
              style={styles.mt50}
            >
              <Button variant="contained" startIcon={<DeleteIcon />} style={styles.button} onClick={onDeleteLine}>
                Delete
              </Button>
            </Marker>
          )}
        </Map>
      </Box>
      <MapButtons
        {...{
          snackbarStatus,
          setSnackbarStatus,
          captureDeviceLocation,
          message:
            'Click on the map to draw a polyline. Midpoints can also be clicked and dragged to create additional line segments.',
        }}
      />
    </div>
  );
};

const getMidpoint = (coords: number[][]): [number, number] => {
  const xMidPoint = (coords[0][0] + coords[1][0]) / 2;
  const yMidPoint = (coords[0][1] + coords[1][1]) / 2;
  return [xMidPoint, yMidPoint];
};

const styles = {
  mt50: {
    marginTop: 50,
  },
  button: { backgroundColor: colors.white, color: colors.red },
};
