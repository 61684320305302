import React, { useContext, useState } from 'react';

interface AccountModalContextType {
  openUserAndRoles: () => void;
  closeUserAndRoles: () => void;
  isUserAndRolesOpen: boolean;
}

const accountModalContext = React.createContext<AccountModalContextType>({
  openUserAndRoles: () => {},
  closeUserAndRoles: () => {},
  isUserAndRolesOpen: false,
});

const useAccountModal = () => {
  const context = useContext(accountModalContext);
  if (!context) {
    throw Error('useAccountModal must be within a UserProvider');
  }
  return context;
};

const AccountModalProvider = (props: { children: React.ReactNode }) => {
  const [isUserAndRolesOpen, setIsUserAndRolesOpen] = useState(false);

  const openUserAndRoles = () => {
    setIsUserAndRolesOpen(true);
  };
  const closeUserAndRoles = () => {
    setIsUserAndRolesOpen(false);
  };

  return (
    <accountModalContext.Provider
      value={{
        isUserAndRolesOpen,
        openUserAndRoles,
        closeUserAndRoles,
      }}
      {...props}
    >
      {props.children}
    </accountModalContext.Provider>
  );
};

export { useAccountModal, AccountModalProvider };
