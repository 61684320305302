import React, { createContext, FunctionComponent, useState } from 'react';

export const SelectedAssetContext = createContext<any>(undefined);
export const SelectedAssetProvider: FunctionComponent<{ children?: React.ReactNode }> = (props) => {
  const currentAsset = {
    id: '',
    changeSelectedAsset: (assetId: string) => {
      setSelectedAsset({ ...selectedAsset, id: assetId });
    },
  };
  const [selectedAsset, setSelectedAsset] = useState(currentAsset);
  return <SelectedAssetContext.Provider value={selectedAsset}>{props.children}</SelectedAssetContext.Provider>;
};
