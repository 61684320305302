import React from 'react';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from "../../../utils/utilityHelper";
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: colors.white,
    },
    container: {
        display: 'flex',
        width: '550px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '20px',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            width: '350px',
        },
    },
    innerContainer: {
        position: 'absolute',
        top: 117,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 15,
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            top: 90,
        },
    },
    title: {
        color: `var(--Schemes-Shadow, ${colors.black0})`,
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '25px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
            fontSize: '22px',
        },
    },
    bodyText: {
        color: colors.black75,
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
    },
    button: {
        color: theme.palette.primary.main,
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        textTransform: 'none',
        padding: '5px 0px',
        borderBottom: `1.5px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
        '&:hover': {
            background: 'transparent',
        }
    },
    svgIcon: {
        width: '120px',
        height: '120px',
        color: colors.Malachite,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 6
    }
}));

const PasswordUpdated: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const BackToLogin = () => {
        history.push('/')
    }
    return (
        <Box className={classes.root}>
            <Box className={classes.innerContainer}>
                <Container className={classes.container}>
                    <FontAwesomeIcon icon={faCircleCheck as IconProp} className={classes.svgIcon} />
                    <Box className={classes.titleContainer}>
                        <Typography className={classes.title}>Password Updated</Typography>
                        <Typography className={classes.bodyText}>
                            Your password has been successfully changed. Please return to the login
                            page and use your new password to log in.
                        </Typography>
                    </Box>
                </Container>
                <Button className={classes.button} variant="text" onClick={BackToLogin}>
                    Back to Login
                </Button>
            </Box>
        </Box>
    );
};

export default PasswordUpdated;
