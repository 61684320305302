import React, { useState, useMemo, useCallback, useRef } from 'react';
import TGLazyModal from '../TGLazyModal';
import RowRenderer, { GeneralProps, ROW_HEIGHT } from './TGOptionModalRowRenderer';
import { getLabel, areOptionsEqual } from '../getterUtils';
import { OptionObject as Option } from '../TGOptionSelectorModal';
import TGOptionLazyModalActions from './TGOptionLazyModalActions';

export const RECORDS_DEFAULT_LIMIT = 100;

export interface TGOptionSelectorModalProps<DataType extends Option> {
  title: string;
  open: boolean;
  value: DataType[];
  data: DataType[] | undefined;
  pathToLabel?: string;
  pathToValue?: string;
  multiSelect?: boolean;
  filterPlaceHolder: string;
  searchText: string;
  loading?: boolean;
  error?: unknown;
  isAllLoaded: boolean;
  totalCount: number;
  handleSearchText: (text: string) => void;
  onCancel: () => void;
  onDone: (value: DataType[]) => void;
  fetchMoreData: () => void;
  refetch: (shouldFetchAll?: boolean, onUpdate?: (data: DataType[]) => void) => void;
  setOpen: (open: boolean) => void;
}

const TGOptionLazyModal = <DataType extends Option>(props: TGOptionSelectorModalProps<DataType>) => {
  const { data, value, multiSelect, pathToValue, pathToLabel, searchText, refetch, setOpen, onDone } = props;
  const [state, setState] = useState<DataType[]>(value);

  const setStateWrapper = useCallback(
    (newState: DataType[]) => {
      setState(newState);
      if (!multiSelect) {
        setTimeout(() => {
          setOpen(false);
          onDone(newState);
        });
      }
    },
    [setOpen, multiSelect, onDone]
  );

  const [listDataKey, setListDataKey] = useState(0);

  const refresh = useCallback(() => setListDataKey((prev) => prev + 1), []);

  const handleDone = () => {
    onDone(state);
  };

  const isNothingSelected = state.length === 0;

  const handleClearAll = () => {
    setStateWrapper([]);
    refresh();
  };

  const generalRowRendererProps = useMemo(
    () => ({
      multiSelect: !!multiSelect,
      pathToValue,
      pathToLabel,
    }),
    [multiSelect, pathToValue, pathToLabel]
  );

  // It is used to update row position in the list only when the search text changes
  const prevSearchText = useRef<string>(searchText);
  const prevTopOptions = useRef<DataType[]>(value);

  // Moves selected records to the top
  const transformedData = useMemo(() => {
    if (!data) return [];
    const filteredData = data.filter((option) =>
      getLabel(option, pathToLabel).toLowerCase().includes(searchText.toLowerCase())
    );
    let topOptions = prevTopOptions.current;
    if (searchText !== prevSearchText.current) {
      topOptions = state.filter((option) =>
        getLabel(option, pathToLabel).toLowerCase().includes(searchText.toLowerCase())
      );
      prevSearchText.current = searchText;
      prevTopOptions.current = topOptions;
    }
    const rest = filteredData.filter(
      (option) => !topOptions.find((topOption) => areOptionsEqual(option, topOption, pathToValue))
    );
    return [...topOptions, ...rest];
  }, [pathToValue, pathToLabel, data, searchText, state]);

  const selectAllAssets = useCallback(() => {
    refetch(true, (data) => {
      setStateWrapper([...data]);
    });
  }, [refetch, setStateWrapper]);

  return (
    <TGLazyModal<DataType, DataType[], GeneralProps>
      {...props}
      state={state}
      setState={setStateWrapper}
      actions={
        <TGOptionLazyModalActions
          state={state}
          totalCount={props.totalCount}
          multiSelect={!!multiSelect}
          isNothingSelected={isNothingSelected}
          handleClearAll={handleClearAll}
          selectAllAssets={selectAllAssets}
        />
      }
      rowRenderer={RowRenderer}
      rowRendererProps={generalRowRendererProps}
      listDataKey={listDataKey}
      onDone={multiSelect ? handleDone : undefined}
      rowHeight={ROW_HEIGHT}
      searchText={searchText}
      handleSearchText={props.handleSearchText}
      data={transformedData}
      fetchMoreData={props.fetchMoreData}
      loading={props.loading}
      error={props.error}
      isAllLoaded={props.isAllLoaded}
    />
  );
};

export default TGOptionLazyModal;
