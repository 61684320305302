import {
  Box,
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { ArrowDownward, ArrowUpward } from '@material-ui/icons';
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { NodeMapDefinition } from '@terragotech/gen5-datamapping-lib';
import { colors, TGLabelWrapperProps, useConfig } from '@terragotech/gen5-shared-components';
import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import { getDateFormat, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';

type ArrayElementType<T extends any[]> = T extends (infer U)[] ? U : any;

type ColumnDef = {
  field: string;
  type: string;
  headerName: string;
};

export type TGListFieldProps = TGLabelWrapperProps & {
  value?: any;
  height?: string;
  columns?: ColumnDef[];
  computedMap?: NodeMapDefinition;
};

const styles = StyleSheet.create({
  columnCell: {
    color: colors.black75,
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'auto',
  },
  headerCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.black75,
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    paddingTop: '5px',
    paddingBottom: '5px',
    width: 'auto',
  },
  table: {
    border: '1px solid rgba(200, 200, 200, 1)',
  },
  headerTableCell: {
    backgroundColor: colors.veryLightBlack,
    padding: '0 8px',
  },
  bodyTableCell: {
    textAlign: 'left',
    padding: '0 8px',
  },
  tableRow: {
    backgroundColor: 'rgb(251, 251, 251)',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
    alignItems: 'center',
  },
  paginationSelect: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
  },
  pageSizeSelect: {
    width: '50px',
  },
  sortIcon: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export const TGListField: React.FC<TGListFieldProps> = ({ columns: inputColumns, value, height }) => {
  type inferredValueType = ArrayElementType<typeof value.values>;

  const columnHelper = React.useMemo(() => createColumnHelper<inferredValueType>(), []);
  const { defaultDateTimeFormat } = useConfig();

  const columns = React.useMemo(
    () =>
      inputColumns?.map((currColumn, index) =>
        columnHelper.accessor(currColumn.field, {
          id: index.toString(),
          cell: info => {
            const cellValue = info.getValue();
            const formattedValue =
              currColumn.type === 'Date'
                ? moment
                    .utc(String(cellValue))
                    .format(
                      getDateFormat(
                        defaultDateTimeFormat?.dateFormatType,
                        defaultDateTimeFormat?.dateFormat,
                        defaultDateTimeFormat?.dateSeperator
                      )
                    )
                : (currColumn.type === 'DateTime'
                ? moment
                    .utc(String(cellValue))
                    .format(
                      getDateTimeFormat(
                        defaultDateTimeFormat?.dateFormatType,
                        defaultDateTimeFormat?.dateFormat,
                        defaultDateTimeFormat?.dateSeperator,
                        defaultDateTimeFormat?.timeFormat
                      )
                    )
                : (currColumn.type === 'Number' && typeof cellValue === 'number'
                ? cellValue.toLocaleString()
                : String(cellValue)));

            return <Typography style={styles.columnCell}>{formattedValue}</Typography>;
          },
          header: () => <Box sx={styles.headerCell}>{currColumn.headerName}</Box>,
          sortingFn: currColumn.type === 'String' || currColumn.type === 'Number' ? 'alphanumeric' : 'datetime',
        })
      ) || [],
    [inputColumns, columnHelper]
  );

  const data = useMemo(() => [...(value?.values || [])], [value?.values]);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(20);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    pageCount: Math.ceil(data.length / pageSize),
    state: { pagination: { pageIndex, pageSize }, sorting },
    onSortingChange: setSorting,
    onPaginationChange: updater => {
      const newPagination = typeof updater === 'function' ? updater({ pageIndex, pageSize }) : updater;
      setPageIndex(newPagination.pageIndex);
      setPageSize(newPagination.pageSize);
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: false,
  });

  const renderSortIcon = (isSorted: boolean | 'asc' | 'desc') => {
    if (!isSorted) return null;
    return isSorted === 'desc' ? <ArrowDownward fontSize="inherit" /> : <ArrowUpward fontSize="inherit" />;
  };

  const handlePreviousPage = useCallback(() => table.previousPage(), [table]);
  const handleNextPage = useCallback(() => table.nextPage(), [table]);

  return !value?.values?.length ? <div>No results</div> : (
    <Box sx={{ my: '10px' }}>
      <div style={{ overflowX: 'scroll' }}>
        <Table style={styles.table}>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} style={styles.headerTableCell}>
                    <Box onClick={header.column.getToggleSortingHandler()} sx={styles.sortIcon}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {renderSortIcon(header.column.getIsSorted())}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map((row, index) => (
              <TableRow key={row.id} style={index % 2 === 1 ? styles.tableRow : {}}>
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id} style={styles.bodyTableCell}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {table.getPageCount() > 1 && 
      <Box sx={styles.paginationContainer}>
        <Button variant="contained" color="primary" onClick={handlePreviousPage} disabled={!table.getCanPreviousPage()}>
          Previous
        </Button>
        <Typography variant="body2">
          Page {table.getPageCount() ? table.getState().pagination.pageIndex + 1 : 0} of {table.getPageCount()}
        </Typography>
        <div style={styles.paginationSelect}>
          <Select
            value={pageSize}
            onChange={e => {
              const value = e.target.value as number;
              setPageSize(value);
              setPageIndex(0);
            }}
            style={styles.pageSizeSelect}
          >
            {[10, 20, 30, 40, 50].map(size => (
              <MenuItem key={size} value={size}>
                {size}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="body2">items per page</Typography>
        </div>
        <Button variant="contained" color="primary" onClick={handleNextPage} disabled={!table.getCanNextPage()}>
          Next
        </Button>
      </Box>}
    </Box>
  );
};
