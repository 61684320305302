import React, { forwardRef, useState, useRef } from 'react';
import LinkedResourceSelectionContainer, {
  SelectedLinkedAssetType,
} from '../../../containers/LinkedResourceSelectionContainer';
import useStyles, { getClasses } from './Common';
import LinkedCellRenderer from '../../AssetTable/LinkedCellRenderer';
import { StyledTableEditorRef, StyledTableEditorProps } from '../../../hooks/tableHooks/useColumns';

/**
 * Component for editing which assets are linked to a particular asset.
 * Applicable ag-grid documentation: https://www.ag-grid.com/javascript-grid-cell-editor/
 *
 * @param value // Current value of the cell in the table
 * @param singleSelect // Is the selected asset attribute a one to one relationship
 * @param aggregateTypeName // The aggregate type that the user is selecting from in the list
 *
 */

interface LinkedAssetsEditorProps extends StyledTableEditorProps {
  //stopEditing: (value: boolean) => void;
  singleSelect: boolean;
  aggregateTypeName: string;
  //value: selectedLinkedAssetType;
}

export default forwardRef<StyledTableEditorRef, LinkedAssetsEditorProps>((props, ref) => {
  const { aggregateTypeName, row, column, onRowChange } = props;
  const value = row[column.key];
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  // need to use a ref since the value is extracted
  const newValueRef = useRef<SelectedLinkedAssetType | null | undefined>(value as SelectedLinkedAssetType);

  const handleOnClick = () => {
    setOpen(true);
  };
  const closeEditor = () => {
    setOpen(false);
  };

  return (
    <div className={getClasses(props, classes).join(' ')}>
      <LinkedCellRenderer
        aggregateTypeName={aggregateTypeName}
        data={{ id: 'dont matter' }}
        handleTitleClick={handleOnClick}
        value={newValueRef.current || ''}
      />
      <LinkedResourceSelectionContainer
        onCancel={closeEditor}
        open={open}
        selectedLinkedAssets={newValueRef.current || undefined}
        onSelectedLinkedAssetChanged={(newValue) => {
          newValueRef.current = newValue;
          if (newValue) {
            onRowChange({ ...row, [column.key]: newValue, __changes: { [column.key]: newValue } });
          }
          closeEditor();
        }}
        singleSelect={props.singleSelect}
        aggregateTypeName={props.aggregateTypeName}
        setOpen={setOpen}
      />
    </div>
  );
});
