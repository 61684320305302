import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import { Symbol } from '../../../components/Legend';
import LegendContainer from '../../../containers/LegendContainer';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
export interface RightControlsProps {
  symbols?: Symbol[];
  limitedRightControls?: boolean;
  wmsControls?: boolean;
}

const RightControls: FunctionComponent<RightControlsProps> = props => {
  const { limitedRightControls, symbols, wmsControls } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.mapLegend)}>
      <LegendContainer symbols={symbols} symbolsOnly={limitedRightControls && !wmsControls} />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapLegend: {
    bottom: 32,
    position: 'absolute',
    right: 25,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      right: 16,
    },
  },
}));

export default RightControls;
