import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useAuthContext, useCurrentLocation, useUserInfo } from '@terragotech/gen5-shared-components';
import { NIL as NIL_UUID } from 'uuid';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import magicText from 'i18next';
import { useSelectedProject } from '../contexts/selectedProjectContext';

export interface ImportType {
  target: string;
  aggregateType: string;
  commandName: string;
  version: number;
  fileName: string;
  metadata?: object;
  isSynchronous: boolean;
  hasNoAggregateIdCommand: boolean;
}
interface GraphQLImportType {
  target: string;
  aggregateType: string;
  commandName: string;
  version: number;
  fileName: string;
  metadata?: object;
}

interface AuthRecord {
  username: string;
  email: string;
  givenName: string;
  familyName: string;
}
const useSubmitImport = () => {
  const authContext = useAuthContext();
  const { openSnackbar } = useInfoContext();
  const bestLocation = useCurrentLocation(); // best location is used to determine proximity
  const { permissionString, permissions, roles, roleString } = useUserInfo();
  const { selectedProjects } = useSelectedProject();

  const timeOpened = new Date(); // we collect this data for analytics

  const mutation = gql`
    mutation submitImport($data: Import!) {
      submitImport(data: $data) {
        message
      }
    }
  `;
  const stage = gql`
    mutation stageImport($data: Import!) {
      stageImport(data: $data) {
        message
      }
    }
  `;
  const comm = gql`
    mutation commitImport($id: String!) {
      commitImport(importId: $id) {
        message
      }
    }
  `;
  const [addImport] = useMutation<{}, { data: GraphQLImportType }>(mutation);
  const [stgImport] = useMutation<{}, { data: GraphQLImportType }>(stage);
  const submitImport = useCallback(
    (imp: ImportType) => {
      const cmdSendFunction = (auth?: AuthRecord) => {
        if (auth) {
          const { familyName, givenName, username, email } = auth;
          const securedCommands = {
            ...imp,
            target: NIL_UUID,
            commandId: imp.target, // all commands require a uuid now.
            metadata: {
              timeFormOpened: timeOpened,
              latitude: bestLocation?.latitude,
              longitude: bestLocation?.longitude,
              locationAccuracy: bestLocation?.accuracy,
              userInfo: {
                familyName,
                givenName,
                userName: username,
                email,
                roles,
                roleString,
                permissionString,
                permissions,
                authPayload: authContext,
                authExpiration: authContext && authContext.authExpiration,
              },
              selectedProjects,
            },
            isSynchronous: undefined,
            hasNoAggregateIdCommand: undefined,
          };
          return addImport({ variables: { data: securedCommands } })
            .then(result => {
              return result;
            })
            .catch(e => {
              throw e;
            });
        } else {
          //TODO: inform the user somehow, but it's complicated...
          console.error('Could not submit command due to missing auth');
        }
      };
      const { familyName, givenName, username, email } = authContext;
      if (username && email) {
        if (!familyName || !givenName) console.error('No family or given name. This error does not crash the app.');
        return Promise.resolve(
          cmdSendFunction({ familyName: familyName ?? '', givenName: givenName ?? '', username, email })
        );
      }
      return authContext.loadExistingAuth().then(cmdSendFunction);
    },
    [addImport, authContext, bestLocation, openSnackbar, roles, roleString, timeOpened]
  );
  const stageImport = useCallback(
    (imp: ImportType) => {
      const cmdSendFunction = (auth?: AuthRecord) => {
        if (auth) {
          const { familyName, givenName, username, email } = auth;
          const securedCommands = {
            ...imp,
            target: NIL_UUID,
            commandId: imp.target, // all commands require a uuid now.
            metadata: {
              timeFormOpened: timeOpened,
              latitude: bestLocation?.latitude,
              longitude: bestLocation?.longitude,
              locationAccuracy: bestLocation?.accuracy,
              userInfo: {
                familyName,
                givenName,
                userName: username,
                email,
                roles,
                roleString,
                permissionString,
                permissions,
                authPayload: authContext,
                authExpiration: authContext && authContext.authExpiration,
              },
              selectedProjects,
            },
            isSynchronous: undefined,
            hasNoAggregateIdCommand: undefined,
          };
          return stgImport({ variables: { data: securedCommands } })
            .then(result => {
              openSnackbar({ title: magicText.t('action completed successfully'), type: 'SUCCESS' });
              return result;
            })
            .catch(e => {
              openSnackbar({ title: magicText.t('action failed'), type: 'ERROR' });
              throw e;
            });
        } else {
          //TODO: inform the user somehow, but it's complicated...
          console.error('Could not submit command due to missing auth');
        }
      };
      const { familyName, givenName, username, email } = authContext;
      if (username && email) {
        if (!familyName || !givenName) console.error('No family or given name. This error does not crash the app.');
        return Promise.resolve(
          cmdSendFunction({ familyName: familyName ?? '', givenName: givenName ?? '', username, email })
        );
      }
      return authContext.loadExistingAuth().then(cmdSendFunction);
    },
    [stgImport, authContext, bestLocation, openSnackbar, roles, roleString, timeOpened, permissionString, permissions]
  );
  const [commit] = useMutation<{}, { id: string }>(comm);
  const commitImport = useCallback(
    (id: string) => {
      const cmdSendFunction = (auth?: AuthRecord) => {
        if (auth) {
          return commit({ variables: { id: id } })
            .then(result => {
              openSnackbar({ title: magicText.t('action completed successfully'), type: 'SUCCESS' });
              return result;
            })
            .catch(e => {
              openSnackbar({ title: magicText.t('action failed'), type: 'ERROR' });
              throw e;
            });
        } else {
          //TODO: inform the user somehow, but it's complicated...
          console.error('Could not submit command due to missing auth');
        }
      };
      const { familyName, givenName, username, email } = authContext;
      if (username && email) {
        if (!familyName || !givenName) console.error('No family or given name. This error does not crash the app.');
        return Promise.resolve(
          cmdSendFunction({ familyName: familyName ?? '', givenName: givenName ?? '', username, email })
        );
      }
      return authContext.loadExistingAuth().then(cmdSendFunction);
    },
    [commit, authContext, openSnackbar]
  );

  return {
    stageImport,
    submitImport,
    commitImport,
  };
};

export default useSubmitImport;
