import React, { createContext, FunctionComponent, useCallback, useEffect, useState } from 'react';

export interface EditModeData {
  id: string;
  label: string;
}
interface EditModeType {
  editModeActive: boolean;
  setEditModeActive: (active: boolean) => void;
  assetEditModeActive: boolean;
  setAssetEditModeActive: (active: boolean) => void;
  isEditModeOnUser: boolean;
  setIsEditModeOnUser: (active: boolean) => void;
  saveActive: boolean;
  setSaveActive: (active: boolean) => void;
  editModeData: EditModeData | EditModeData[] | null;
  setEditModeData: (val: EditModeData | EditModeData[] | null) => void;
}

export const EditModeContext = createContext<EditModeType>({
  editModeActive: false,
  setEditModeActive: () => null,
  assetEditModeActive: false,
  setAssetEditModeActive: () =>null,
  saveActive: false,
  setSaveActive: () => null,
  editModeData: null,
  setEditModeData: () => null,
  isEditModeOnUser: false,
  setIsEditModeOnUser: () => null,
});
export const EditModeProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const [editModeActive, setEditModeActive] = useState(false);
  const [isEditModeOnUser, setIsEditModeOnUser] = useState(false);
  const [saveActive, setSaveActive] = useState(false);
  const [editModeData, setEditModeData] = useState<EditModeData | EditModeData[] | null>(null);
  const [assetEditModeActive, setAssetEditModeActive] = useState(false);
  return (
    <EditModeContext.Provider value={{ 
      editModeActive, 
      setEditModeActive, 
      saveActive, 
      setSaveActive, 
      editModeData, 
      setEditModeData,
      isEditModeOnUser,
      setIsEditModeOnUser,
      assetEditModeActive,
      setAssetEditModeActive,
      }}>
      {props.children}
    </EditModeContext.Provider>
  );
};
