import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { useRouteMatch } from "react-router-dom";
import { useRecordType } from "../../../contexts/recordTypeContext";
import useRouteParams from '../../Common/useRouteParams';

const AssetCardBackdrop = (props: { children: React.ReactNode; multiple?: boolean; mapEditor?: boolean, classes?: string }) => {
  const { selectedRecordType } = useRecordType();
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType
  });
  const classes = useStyles({isAssetDetail: isAssetDetailsOpen});
  return <div className={clsx(classes.dialog, props.classes || '')}>{props.children}</div>;
};

const useStyles = makeStyles(theme => ({
  dialog: (props: {isAssetDetail: boolean}) => ({
    position: 'absolute',
    top: props.isAssetDetail ? 20 : 73,
    left: props.isAssetDetail ? '50%' : 23,
    zIndex: 12,
    transform: props.isAssetDetail ? 'translateX(-50%)' : 'none',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      bottom: 18,
      transform: 'translateX(-50%)',
      left: '50%',
      top: 'unset',
}})
}));
export default AssetCardBackdrop;
