export const getFileExtensionFromBlob = async (blob: Blob) => {
  const stream = await blob.arrayBuffer();
  var arr = new Uint8Array(stream).subarray(0, 4);
  var header = '';
  for (var i = 0; i < arr.length; i++) {
    header += arr[i].toString(16);
  }
  let type = '';
  switch (header) {
    case '89504e47':
      type = 'png';
      break;
    case '47494638':
      type = 'gif';
      break;
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      type = 'jpeg';
      break;
    case '504b34': //Spot checking, this looks like the only header for excel files - all files I have tested have had this.
      type = 'xlsx';
      break;
    default:
      type = '';
      break;
  }
  return type;
};
