import { Button, makeStyles, Snackbar, Tooltip, Typography } from '@material-ui/core';
import { useCurrentLocation } from '@terragotech/gen5-shared-components';
import { useLocationStyles } from './CommonEditorUtils';
import { Dispatch, SetStateAction } from 'react';
import { colors } from '../../../styles/theme';

interface Props {
  snackbarStatus: boolean;
  setSnackbarStatus: Dispatch<SetStateAction<boolean>>;
  captureDeviceLocation: () => void;
  message: string;
}

const CaptureButton = ({ onClick, toolTip }: { onClick?: () => void; toolTip?: boolean }) => {
  const locationStyles = useLocationStyles();
  const buttonText = <Typography>capture device location</Typography>;
  const withToolTip = (
    <Tooltip title="This device does not have a location" placement="top">
      {buttonText}
    </Tooltip>
  );
  return (
    <Button
      className={`${toolTip ? locationStyles.captureLocationBtnDis : locationStyles.captureLocationBtn} ${
        locationStyles.bottomTopRightBtn
      }`}
      variant="contained"
      onClick={onClick}
    >
      {toolTip ? withToolTip : buttonText}
    </Button>
  );
};

const MapButtons = ({ snackbarStatus, setSnackbarStatus, captureDeviceLocation, message }: Props) => {
  const currentLocation = useCurrentLocation();
  const locationStyles = useLocationStyles();
  const classes = useStyles();

  return (
    <>
      {snackbarStatus && (
        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          ContentProps={{
            classes: {
              root: classes.root,
            },
          }}
          message={message}
          className={`${locationStyles.bottomCenterBtn}`}
          action={
            <Typography component={'span'} className={locationStyles.closeBtn} onClick={() => setSnackbarStatus(false)}>
              CLOSE
            </Typography>
          }
          open={snackbarStatus}
        />
      )}
      {currentLocation ? <CaptureButton {...{ onClick: captureDeviceLocation }} /> : <CaptureButton toolTip />}
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.white95,
    padding: '14px 18px',
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
    '& .MuiSnackbarContent-message': {
      padding: 0,
      lineHeight: 'normal',
      fontSize: 14,
      fontWeight: 400,
      color: 'black',
    },
    '& .MuiSnackbarContent-action': {
      margin: 0,
      padding: 0,
    },
    flexWrap: 'nowrap',
    gap: 10,
    justifyContent: 'space-between',
  },
}));

export default MapButtons;
