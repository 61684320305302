import React, { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import useUserPreferences from '../hooks/useUserPreferences';
import { Action } from '../components/FloatingActionButton';
import MapFab from '../components/MapFab';
import { ControlPosition } from 'react-draggable';
import { useActionsExecutor } from '../hooks/useActionsExecutor';
import { v4 as uuid4 } from 'uuid';
import { UseConditionalFabButton, useConditionalFabButtons } from '../hooks/useConditionalFabButtons';
import { useSelectedLocation } from '../contexts/selectedLocationContext';
import { CommandAction, useAuthState } from '@terragotech/gen5-shared-components';
import _ from 'lodash';

const PREFERENCE_KEY = 'pageFABPosition';

interface PageFABContainerProps {
  buttons?: UseConditionalFabButton[];
  isHidden?: boolean;
  containerKey?: string;
}

const PageFABContainer = (props: PageFABContainerProps) => {
  const { buttons: allButtons, isHidden, containerKey } = props;
  const { getPreference, setPreference } = useUserPreferences();
  const { processAction } = useActionsExecutor();
  const {selectedLocation,setSelectedLocation} = useSelectedLocation();
  const [forceFab,setForceFab] = useState(!!selectedLocation);
  const auth = useAuthState()

  const { buttons } = useConditionalFabButtons(allButtons);
  
  useEffect(()=>{
    setForceFab(!!selectedLocation);
  },[selectedLocation])

  const fabPreferenceKey = useMemo(() => (containerKey ? `${PREFERENCE_KEY}-${containerKey}` : PREFERENCE_KEY), [
    containerKey,
  ]);

  const savedPosition: ControlPosition = useMemo(() => {
    const pref = getPreference<ControlPosition>(fabPreferenceKey);

    return {
      x: pref?.x ?? window.innerWidth - 100,
      y: pref?.y ?? window.innerHeight - 100,
    };
  }, [getPreference, fabPreferenceKey]);

  const handlePositionChange = useCallback(
    (newPosition: ControlPosition) => {
      setPreference(fabPreferenceKey, newPosition);
    },
    [setPreference]
  );
  const myTimeout = useRef<any>()
  const actions: Action[] = useMemo(
    () =>
    {
      const result = buttons
        ? buttons.map(command => {
            return ((command?.action?.__typename === 'CommandReference' || _.has(command?.action, 'commandName' as keyof CommandAction)) && (!(command.action as CommandAction).isPublic && !auth.username)) ? undefined : {
              icon: command.icon,
              name: command.label,
              onClick: () => {
                processAction(command, [], uuid4(), () => {}, true, command.aggregate.name, ()=>{setSelectedLocation(undefined)})?.onClick();
              },
              isDisabled: command.state !== 'enabled',
            };
          }).filter(b=>b) as Action[]
        : []
      if(result.length === 0 && selectedLocation && !myTimeout.current){
        myTimeout.current = setTimeout(()=>{setSelectedLocation(undefined);myTimeout.current = undefined},1000)
      }
      if(result.length>0 && myTimeout.current){
        clearTimeout(myTimeout.current);
      }
      return result;
    },
    [buttons, processAction]
  );

  if (isHidden || actions.length === 0) {
    return <></>;
  }

  return (
    <MapFab
      icon="fas-bolt"
      closeIcon="fas-times"
      actions={actions}
      onPositionChange={handlePositionChange}
      defaultPosition={savedPosition}
      requestOpen={forceFab}
    />
  );
};

export default PageFABContainer;
