import { FunctionComponent, useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { EditModeContext } from '../../contexts/editModeContext';
import { useTheme } from '@material-ui/core';

export interface PhotoCellRendererProps {
  images: any;
  handlePhotoClick: (value: string, editMode: boolean) => void;
  editable: boolean;
}

const PhotoCellRenderer: FunctionComponent<PhotoCellRendererProps> = (props) => {
  const { images: value, handlePhotoClick } = props;
  const classes = useStyles();
  const { editModeActive, setEditModeActive } = useContext(EditModeContext);
  const theme = useTheme();

  const handleClick = () => {
    if (value && value.length) handlePhotoClick(value, editModeActive);
  };

  return (
    <div
      className={classes.container}
      style={{ color: `${theme.palette.primary.main}`, width: '100%' }}
      onClick={editModeActive ? handleClick : undefined}
    >
      {value && !editModeActive && Array.isArray(value) && value.length > 0 && (
        <div
          style={{ color: `${theme.palette.primary.main}`, position: 'absolute', width: '95%' }}
          onClick={handleClick}
        >
          ({value.length}) {value.length === 1 ? 'photo' : 'photos'}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}));

export default PhotoCellRenderer;
