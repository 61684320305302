import React, {
  createContext,
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';
import { useUserPreferences } from '../hooks/useUserPreferences';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import useScrollbarSize from 'react-scrollbar-size';

const PREFERENCE_KEY = 'fullscreen';

interface FullscreenObject {
  fullscreen: boolean;
}

export type pages = 'home' | 'usersAndRoles' | 'analytics' | 'import' | 'about' | 'Fab';

interface AssetsDashboardType {
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
  verticalPercentage: number;
  setVerticalPercentage: (percents: number) => void;
  prevVerticalPercentage: number;
  setPrevVerticalPercentage: (prevVerticalPercent: number) => void;
  isMobileView: boolean;
  currentPage: pages;
  setCurrentPage: Dispatch<SetStateAction<pages>>;
  openDrawer: boolean;
  setOpenDrawer: Dispatch<SetStateAction<boolean>>;
  toggleMapView: (value?: boolean) => void;
  isMapView: boolean;
  organizeTableOpen: boolean;
  setOrganizeTableOpen: (value: boolean) => void;
  handleOrganizeTableClick: () => void;
  rowIdxRef: { current: number };
  mapContainerElementRef?: { current: HTMLDivElement | null };
  hideMapCard: boolean;
  setHideMapCard: (val: boolean) => void;
  isAddDialogOpen: boolean;
  setIsAddDialogOpen: (val: boolean) => void;
  scrollBarHeight: number;
  minimizedTableView: boolean;
  setMinimizedTableView: (val: boolean) => void;
  minimizedHeight: number;
  setMinimizedHeight: (val: number) => void;
  tableTopSearchTxt: string;
  setTableTopSearchTxt: React.Dispatch<React.SetStateAction<string>>;
  visibilityModal: boolean;
  setVisibilityModal: React.Dispatch<React.SetStateAction<boolean>>;
  mapFabOpen: boolean;
  setMapFabOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mapCardHeight: number,
  setMapCardHeight: React.Dispatch<React.SetStateAction<number>>
}

export const AssetsDashboardContext = createContext<AssetsDashboardType>({
  fullscreen: false,
  setFullscreen: () => {},
  verticalPercentage: 43,
  setVerticalPercentage: () => {},
  prevVerticalPercentage: 43,
  setPrevVerticalPercentage: () => {},
  isMobileView: false,
  currentPage: 'home',
  setCurrentPage: () => {},
  openDrawer: false,
  setOpenDrawer: () => {},
  toggleMapView: () => {},
  isMapView: true,
  organizeTableOpen: false,
  setOrganizeTableOpen: () => {},
  handleOrganizeTableClick: () => {},
  rowIdxRef: { current: 0 },
  mapContainerElementRef: { current: null },
  hideMapCard: false,
  setHideMapCard: () => {},
  isAddDialogOpen: false,
  setIsAddDialogOpen: () => {},
  scrollBarHeight: 0,
  minimizedHeight: 0,
  setMinimizedHeight: () => {},
  minimizedTableView: false,
  setMinimizedTableView: () => {},
  tableTopSearchTxt: '',
  setTableTopSearchTxt: () => {},
  visibilityModal: false,
  setVisibilityModal: () => {},
  mapFabOpen: false,
  setMapFabOpen: () => {},
  mapCardHeight: 0, 
  setMapCardHeight: () => {}
});

export const AssetsDashboardProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = props => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [mapFabOpen, setMapFabOpen] = useState<boolean>(false);
  const { setPreference, getPreference } = useUserPreferences();
  const [verticalPercentage, setVerticalPercentage] = useState(43);
  const [fullscreen, setFullscreen] = useState(false);
  const [prevVerticalPercentage, setPrevVerticalPercentage] = useState<number>(43);
  const [currentPage, setCurrentPage] = useState<pages>('home');
  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  const [isMapView, setIsMapView] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [organizeTableOpen, setOrganizeTableOpen] = useState(false);
  const [hideMapCard, setHideMapCard] = useState(false);
  const scrollBarHeight = useScrollbarSize().height;
  const [minimizedHeight, setMinimizedHeight] = useState(((174 + scrollBarHeight) / window.innerHeight) * 100);
  const [minimizedTableView, setMinimizedTableView] = useState(false);
  const [visibilityModal, setVisibilityModal] = useState(false);
  const [tableTopSearchTxt, setTableTopSearchTxt] = useState<string>('');
  const [mapCardHeight, setMapCardHeight] = useState(0)
  const rowIdxRef = useRef(0);
  const mapContainerElementRef = useRef<HTMLDivElement | null>(null);
  const handleOrganizeTableClick = useCallback(() => {
    setOrganizeTableOpen(true);
  }, [setOrganizeTableOpen]);
  useEffect(() => {
    if (!isMobileView) {
      setIsMapView(true);
      setFullscreen(false);
    }
  }, [isMobileView]);

  useEffect(() => {
    const fullscreenObject = getPreference(PREFERENCE_KEY) as FullscreenObject;
    if (fullscreenObject && !isMobileView) {
      setFullscreen(fullscreenObject.fullscreen);
    } else {
      setFullscreen(false);
    }
  }, [getPreference, isMobileView]);

  useEffect(() => {
    setPreference(PREFERENCE_KEY, { fullscreen });
    if (fullscreen) {
      setVerticalPercentage(100);
    }
  }, [fullscreen, setVerticalPercentage, setPreference]);

  const toggleMapView = (value?: boolean) => {
    setIsMapView(v => (typeof value === 'boolean' ? value : !v));
  };

  const value = useMemo(() => {
    return {
      fullscreen,
      verticalPercentage,
      setFullscreen,
      setVerticalPercentage,
      prevVerticalPercentage,
      setPrevVerticalPercentage,
      isMobileView,
      currentPage,
      setCurrentPage,
      openDrawer,
      setOpenDrawer,
      toggleMapView,
      isMapView,
      organizeTableOpen,
      setOrganizeTableOpen,
      handleOrganizeTableClick,
      rowIdxRef,
      mapContainerElementRef,
      hideMapCard,
      setHideMapCard,
      isAddDialogOpen,
      setIsAddDialogOpen,
      minimizedHeight,
      setMinimizedHeight,
      minimizedTableView,
      setMinimizedTableView,
      scrollBarHeight,
      tableTopSearchTxt,
      setTableTopSearchTxt,
      visibilityModal,
      setVisibilityModal,
      mapFabOpen,
      setMapFabOpen,
      mapCardHeight,
      setMapCardHeight
    };
  }, [
    fullscreen,
    verticalPercentage,
    prevVerticalPercentage,
    isMobileView,
    currentPage,
    setCurrentPage,
    openDrawer,
    setOpenDrawer,
    toggleMapView,
    isMapView,
    organizeTableOpen,
    setOrganizeTableOpen,
    handleOrganizeTableClick,
    rowIdxRef,
    mapContainerElementRef,
    hideMapCard,
    setHideMapCard,
    isAddDialogOpen,
    setIsAddDialogOpen,
    minimizedHeight,
    setMinimizedHeight,
    minimizedTableView,
    setMinimizedTableView,
    scrollBarHeight,
    tableTopSearchTxt,
    setTableTopSearchTxt,
    visibilityModal,
    setVisibilityModal,
    mapFabOpen,
    setMapFabOpen,
    mapCardHeight, 
    setMapCardHeight
  ]);

  return <AssetsDashboardContext.Provider value={value}>{props.children}</AssetsDashboardContext.Provider>;
};
