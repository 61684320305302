import React from 'react';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import MapIcon from '@material-ui/icons/Map';
import magicText from 'i18next';
import { MapTypes } from '../../contexts/mapStyle';
import { Box, Theme } from '@material-ui/core';
import { colors } from '../../styles/theme';

export interface MapTypeProp {
  label: string;
  value: MapTypes;
  img?: string | undefined;
}
export interface MapTypeSectionProps {
  allowedTypes: ReadonlyArray<MapTypeProp>;
  currentType: string;
  onChange: (value: string) => void;
  theme?: Theme;
}

export const mapTypeSection = (props: MapTypeSectionProps): DrawerItem => {
  const seletedStyle = (mapType: string): React.CSSProperties => ({
    border: `2.5px solid ${mapType === props.currentType ? props.theme?.palette.primary.main : 'transparent'}`,
  });

  return {
    icon: <MapIcon />,
    iconMenu: (
      <>
        {props.allowedTypes.map(type => (
          <div key={type.value} onClick={() => props.onChange(type.value)}>
            {type.value}
          </div>
        ))}
      </>
    ),
    title: magicText.t('map.mapType.header'),
    content: (
      <div style={styles.root}>
        {props.allowedTypes.map(type => {
          const mapType = type.label;
          return (
            <div key={type.value} style={styles.childRoot}>
              <div>
                <img
                  id={type.value}
                  src={type.img}
                  style={mapType === props.currentType ? {...styles.selectedImg, ...seletedStyle(mapType)} : styles.Img}
                  onClick={() => {
                    props.onChange(type.label);
                  }}
                />
              </div>
              <div style={styles.txt}>{mapType}</div>
            </div>
          );
        })}
      </div>
    ),
  };
};
const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '30.5px 25px 21.5px 23px',
    gap: '28px 30px',
  },
  childRoot: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  Img: {
    height: 68,
    width: 68,
    borderRadius: 5,
    cursor: 'pointer',
  },
  selectedImg: {
    height: 59,
    width: 59,
    borderRadius: 5,
    padding: 2,
    cursor: 'pointer',
  },
  txt: {
    width: 60,
    fontSize: 13,
    fontWeight: 400,
    marginTop: 2,
    lineHeight: 'normal',
    color: colors.black0,
    fontFamily: 'Roboto',
    alignSelf: 'center',
  },
};