import React, { useState, useCallback, useMemo } from 'react';
import DeleteDialog from '../../components/UsersAndRoles/DeleteConfirmationDialog';

interface UseDeleteDialogArgs<TRecord extends unknown> {
  onDelete: (records: TRecord[]) => void;
  resourceNameKey: string;
  getRecordName: (record: TRecord) => string;
}

export const useDeleteDialog = <TRecord extends unknown>({
  onDelete,
  resourceNameKey,
  getRecordName,
}: UseDeleteDialogArgs<TRecord>) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [recordsToDelete, setRecordsToDelete] = useState<TRecord[]>([]);

  const openDeleteDialog = useCallback((records: TRecord[]) => {
    setIsDeleteDialogOpen(true);
    setRecordsToDelete(records);
  }, []);

  const deleteDialog = useMemo(
    () => (
      <DeleteDialog
        resourceNameKey={resourceNameKey}
        open={isDeleteDialogOpen && recordsToDelete.length > 0}
        onCancel={() => {
          setRecordsToDelete([]);
          setIsDeleteDialogOpen(false);
        }}
        onDelete={() => {
          onDelete(recordsToDelete);
          setIsDeleteDialogOpen(false);
        }}
        toDelete={recordsToDelete.map((record) => getRecordName(record))}
      />
    ),
    [resourceNameKey, isDeleteDialogOpen, recordsToDelete, onDelete, getRecordName]
  );

  return {
    openDeleteDialog,
    deleteDialog,
  };
};
