import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import React, { createContext, useContext, useMemo } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';

export interface ProjectType {
  id: string;
  nodeId: string;
  label: string;
  folderId?: string;
}
export interface FolderType {
  id: string;
  nodeId: string;
  label: string;
  parentFolderId?: string;
  projects: Array<ProjectType>;
  subFolders: Array<FolderType>;
}
interface ProjectContextType {
  projects: Array<ProjectType>;
  folders: Array<FolderType>;
  refetch: () => void;
}

interface FolderRow extends FolderType {
  parentFolderId: string;
}

const ProjectsContext = createContext<ProjectContextType | undefined>(undefined);

const findSubFolders = (folderId: string, folderArray: Array<FolderRow>) => {
  const subFolders = folderArray.filter(folder => folder.parentFolderId === folderId);

  // Recursively find subfolders of each subfolder
  subFolders.forEach(subFolder => {
    subFolder.subFolders = findSubFolders(subFolder.id, folderArray);
  });

  return subFolders;
};

//The projects provider will keep track of the list of projects and folders available from the server
//IT will download projects from the server in 100 project chunks
const ProjectsProvider: React.FC<{ children?: React.ReactNode }> = props => {
  const { aggregateDefinitions } = useConfig();
  // use the aggregate definition to figure out if there are folders available
  const hasFolders = aggregateDefinitions.findIndex(def => def.name === 'Folder') >= 0;

  //build a project fragment
  const projectFragment = gql`
    fragment ProjectFragment on Project {
      nodeId
      label
      id
      folderId
    }
  `;

  const query = useMemo(() => {
    const query = (() => {
      if (hasFolders) {
        return 'folders { label id nodeId parentFolderId parentFolder { title } projects { ...ProjectFragment } } projects(filter: {folderExists: false}) { ...ProjectFragment }';
      } else return 'projects { ...ProjectFragment }';
    })();
    return gql`query { ${query} } ${projectFragment}`;
  }, [hasFolders, projectFragment]);

  //query
  const { data, refetch } = useQuery<
    | {
        folders: Array<FolderRow>;
      }
    | { projects: Array<ProjectType> }
  >(query, {
    fetchPolicy: 'network-only',
  });

  const value = useMemo(() => {
    let projects: Array<ProjectType> = [];
    const folders: Array<FolderType> = [];
    if (hasFolders && data && 'folders' in data) {
      data.folders.forEach(folder => {
        projects = [...projects, ...folder.projects];
        folders.push({
          id: folder.id,
          label: folder.label,
          nodeId: folder.nodeId,
          parentFolderId: folder?.parentFolderId,
          projects: folder.projects,
          subFolders: findSubFolders(folder.id, data.folders),
        });
      });
    }
    if (data && 'projects' in data) {
      projects = [...projects, ...data.projects];
    }
    return {
      folders: folders,
      projects: projects,
      refetch: refetch,
    };
  }, [data, hasFolders]);

  return <ProjectsContext.Provider value={value} {...props} />;
};

const useProjects = (): ProjectContextType => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjects must be used within an AssetProvider');
  }

  return context;
};

export { ProjectsProvider, useProjects };
