import React, { useCallback, useState } from 'react';
import { Dialog, makeStyles, createStyles, Button, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import PasswordChangeView from './PasswordChangeView';
import magicText from 'i18next';

export interface TempPasswordDialogProps {
  title: string;
  okText?: string;
  onOkPress?: (password: string) => void;
  error?: string;
}

const TempPasswordDialog: React.FC<TempPasswordDialogProps> = (props) => {
  const classes = useStyles();
  const { onOkPress, okText, title } = props;
  const [password, setPassword] = useState<string | undefined>();
  const handlePassword = useCallback((newPassword?: string) => {
    setPassword(newPassword);
  }, []);
  return (
    <Dialog
      open={true}
      className={classes.dialogBox}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <PasswordChangeView {...props} onPasswordChange={handlePassword} />
      </DialogContent>
      <DialogActions>
        {password && onOkPress && (
          <Button className={classes.btn} onClick={() => onOkPress(password)} color="primary">
            <> {okText || magicText.t('Change Password')}</>
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    dialogBox: {
      '& .MuiPaper-root.MuiDialog-paper': {
        minWidth: 500,
      },
    },
    btn: {
      letterSpacing: '1.25px',
      fontWeight: 500,
      fontSize: 14,
    },
  })
);

export default TempPasswordDialog;
