import { useAuthContext } from '@terragotech/gen5-shared-components';
import { useCallback } from 'react';
import { DOC_API_URL } from '../utils/docApiURL';
export const useFileUpload = () => {
  const authState = useAuthContext();

  const uploadFile = useCallback(
    (file: { id: string; file: any }) => {
      if (!file.file) return;
      if(!authState.token){
        return new Promise<void>((res,rej)=>{
          const formData = new FormData();
          formData.append('file', file.file!);
          fetch(`${DOC_API_URL}public/${file.id}`, {
            method: 'POST',
            body: formData,
          })
          .then((result) => {
            console.log('Public Success:', result);
            res();
          })
          .catch((error) => {
            console.error('Public Error:', error);
            rej();
          });
        })
      }
      return authState.token().then((token) => {
        const formData = new FormData();
        formData.append('file', file.file!);
        fetch(`${DOC_API_URL}${file.id}`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((result) => {
            console.log('Success:', result);
          })
          .catch((error) => {
            console.error('Error:', error);
            throw error;
          });
      });
    },
    [authState]
  );
  return {
    uploadFile,
  };
};
