import React, { createContext, useState, useEffect } from 'react';

interface NetworkStatus {
  isOnline: boolean;
}

export const NetworkStatusContext = createContext<NetworkStatus>({
  isOnline: true,
});

export const NetworkStatusProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    window.addEventListener('offline', handleNetworkStatusChange);
    window.addEventListener('online', handleNetworkStatusChange);
    return () => {
      window.removeEventListener('offline', handleNetworkStatusChange);
      window.removeEventListener('online', handleNetworkStatusChange);
    };
  }, []);

  const handleNetworkStatusChange = () => setIsOnline(navigator.onLine);

  return <NetworkStatusContext.Provider value={{ isOnline }}>{children}</NetworkStatusContext.Provider>;
};
