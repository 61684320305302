import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useMemo } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';
import { colors } from '../styles/theme';
import ActionIcon from './ActionIcon';
import { AlertDialog } from '@terragotech/gen5-shared-components';
import TGTooltip from './TGTooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      '& .MuiPaper-root': {
        padding: '2px 0',
        borderRadius: 5,
        marginTop: 3,
      },
      '& .MuiList-padding': {
        padding: 0,
      },
      '& .MuiPaper-elevation8': {
        boxShadow: `0px 2px 8px 0px ${colors.black15}`,
      },
    },
    topMenuItem: {
      padding: '10px 8px',
      marginBottom: 3,
      borderBottom: `1px solid ${colors.black10}`,
    },

    menuItem: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      minHeight: 0,
      borderRadius: 4,
      padding: 10,
      '&:hover': {
        backgroundColor: colors.black04,
      },
    },
    divider: {
      width: '100%',
      height: 2,
      backgroundColor: colors.grayLine,
      alignSelf: 'center',
    },
    exportLink: {
      textDecoration: 'none',
    },
    text: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black0,
      textTransform: 'none',
      fontFamily: 'Roboto',
      lineHeight: 'normal',
    },
    selectedText: {
      fontSize: 14,
      fontWeight: 400,
      color: colors.black54,
      padding: '2px 8px',
      fontFamily: 'Roboto',
      lineHeight: 'normal',
    },
    close: {
      padding: theme.spacing(0.5),
    },
    message: {
      display: 'flex',
      alignItems: 'center',
    },
    checkIcon: {
      opacity: 0.9,
      marginRight: theme.spacing(1),
    },
    iconHolder: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fill: colors.grayDescription,
      height: 24,
      width: 22,
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 8px',
      width: 254,
      gap: 3,
    },
  })
);
export interface ActionsMenuButton {
  state?: string;
  disabledMessage?: string;
  label?: string;
  icon?: string;
  onClick: () => void;
  color?: string;
  limitMaxOccurrence?: number;
}
interface ActionsMenuProps {
  anchorEl: any;
  onClose: () => void;
  statusText?: string;
  actions: ReadonlyArray<ActionsMenuButton>;
  selectedCount?: number;
}

/**
 * ActionsMenu
 *
 * Displays options to either 'delete' or 'export .csv' assetData
 *
 * @param headers
 * @param assetData
 * @param anchorEl
 * @param handleClose
 */

const ActionsMenu: FunctionComponent<ActionsMenuProps> = props => {
  const { anchorEl, onClose, statusText, actions, selectedCount } = props;
  const classes = useStyles();
  const open = Boolean(anchorEl);
  const id = open ? 'actions-menu' : undefined;

  const { selectedRecordTypeDefinition } = useRecordType();
  const [statusTextModel, setStatusTextModel]: any = React.useState(0);
  const menuOptionActions = useMemo(() => {
    return (actions || []).filter(x => !(x?.state === 'hidden'));
  }, [actions]);

  const showAlertPopup = (e: any) => {
    setStatusTextModel(e);
  };
  return (
    <React.Fragment>
      <Menu
        className={classes.menu}
        id={id}
        open={open}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {statusText && (
          <Box className={classes.topMenuItem}>
            <Typography className={classes.selectedText}>{statusText}</Typography>
          </Box>
        )}
        <Box className={classes.menuContainer}>
          <>
            {menuOptionActions.length > 0 ? (
              menuOptionActions.map((menuOption, index) => (
                <TGTooltip key={index} title={menuOption?.disabledMessage || ''} placement="right">
                  <MenuItem
                    className={classes.menuItem}
                    disabled={menuOption?.state === 'disabled'}
                    onClick={() =>
                      menuOption?.limitMaxOccurrence !== null &&
                      menuOption?.limitMaxOccurrence !== undefined &&
                      (selectedCount || 0) > menuOption?.limitMaxOccurrence
                        ? showAlertPopup(index + 1)
                        : menuOption?.onClick()
                    }
                  >
                    {menuOption?.icon && (
                      <Box style={styles.icon}>
                        <ActionIcon
                          name={menuOption?.icon}
                          color={menuOption?.color || colors.black54}
                          style={styles.icon}
                        />
                      </Box>
                    )}
                    <Typography
                      style={{
                        color: menuOption?.color,
                      }}
                      className={classes.text}
                    >
                      {menuOption?.label}
                    </Typography>
                  </MenuItem>
                </TGTooltip>
              ))
            ) : (
              <div style={styles.noActionsContainer}>No multi-select actions available</div>
            )}
          </>
        </Box>
      </Menu>
      {statusTextModel !== 0 && (
        <AlertDialog
          title={`Error, too many ${selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} selected`}
          okText={'Ok'}
          cancelText={'OK'}
          onOkPress={() => {
            setStatusTextModel(0);
          }}
        >
          <div>
            You currently have {selectedCount} {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} selected, but
            you can only run this command on {menuOptionActions[statusTextModel - 1]?.limitMaxOccurrence}{' '}
            {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} at a time. Please select fewer{' '}
            {selectedRecordTypeDefinition.plural?.toLocaleLowerCase()} and try again.
          </div>
        </AlertDialog>
      )}
    </React.Fragment>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  icon: {
    width: 18,
    height: 18,
  },
  noActionsContainer: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    color: colors.black35,
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    borderRadius: 3,
  },
};

export default ActionsMenu;
