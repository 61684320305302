import { gql, useApolloClient } from '@apollo/client';
import turfBbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import * as MapboxGl from 'mapbox-gl';
import * as React from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { useAssetCards } from '../../../contexts/assetCardContext';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { cardViewSelector } from '../../../recoil/atoms';
import { cardIdsState } from '../../../recoil/atoms/mapMaintenance';
import { isLatitudeLongitudeValid } from '../../../utils/utilityHelper';
import MultipleAssetCardsContainer from './MultipleAssetCardsContainer';
import PositionedAssetCardContainer from './PositionedAssetCard';
const DEFAULT_CLICK_ZOOM = 16;

export interface AssetCardRendererProps {
  items?: MapAssetType[];
  mapInstance: MapboxGl.Map | null;
  selectedAssetId: string | undefined;
  selectedAssetType?: string | undefined;
  selectableAggregateTypes?: string[];
  onCardClose?: () => void;
  onCardDisplayed?: (assetId: string) => void;
  onSelect?: (tem: any) => void;
  mapEditor?: boolean;
  forceCards?: boolean;
  selectedAggregateId?: string;
  onFormSubmit?: () => void;
  assetIds: string[];
  setAssetsIds: (ids: string[]) => void;
}
export interface AssetCardRendererRef {
  handleClose: () => void;
}
const AssetCardRenderer: React.ForwardRefRenderFunction<unknown, AssetCardRendererProps> = (props, ref) => {
  //This is a higher order function that adds card rendering ability to Maps using a rencer prop
  const {
    items,
    mapInstance,
    selectedAssetId,
    selectedAssetType,
    onCardDisplayed,
    selectableAggregateTypes,
    onSelect,
    mapEditor,
    selectedAggregateId,
    forceCards,
    onFormSubmit,
    onCardClose,
    assetIds,
    setAssetsIds,
  } = props;

  const { coordinates, areCardsDefined, refresh, setRefresh, isCardVisible, setIsCardVisible } = useAssetCards();
  const setCardIds = useSetRecoilState(cardIdsState);

  const [prevId, setPrevId] = React.useState(selectedAssetId);
  const [prevIds, setPrevIds] = React.useState(assetIds);
  const [currentType, setCurrentType] = React.useState('single');
  const areCardsActive = useRecoilValue(cardViewSelector);
  const client = useApolloClient();
  const [assets, setAssets] = React.useState<any[]>(items || []);
  const prev = React.useRef('');
  const prevAssets = React.useRef([]);
  const [fetchingAssets, setFetchingAssets] = React.useState(false);
  const { mapCardHeight, isMobileView } = React.useContext(AssetsDashboardContext);
  const MAP_SYMBOL_SIZE = 50;
  const MAP_CARD_BOTTOM_PADDING = 18;
  const [cardAssetId, setCardAssetId] = React.useState<string | undefined>(undefined);
  const showCard = useCallback(() => setTimeout(() => setIsCardVisible(true), 300), []);
  React.useEffect(() => {
    if (JSON.stringify(assetIds) !== JSON.stringify(prevIds)) {
      setPrevIds(assetIds);
      setCurrentType('cluster');
    }
    if (JSON.stringify(selectedAssetId) !== JSON.stringify(prevId)) {
      setPrevId(selectedAssetId);
      setCurrentType('single');
    }
    setIsCardVisible(false);
    setAssets([]);
  }, [assetIds, selectedAssetId]);

  const getToppadding = (map: mapboxgl.Map) => {
    if (isMobileView) {
      const mapHeight = map.getContainer().clientHeight;
      const remainingHeight = mapHeight - (mapCardHeight + MAP_CARD_BOTTOM_PADDING);
      return -(remainingHeight - MAP_SYMBOL_SIZE);
    }
    return 0;
  };

  const getBottomPadding = (map: MapboxGl.Map) => {
    if (isMobileView) {
      const mapContainerHeight = map.getContainer().clientHeight;
      const totalMapCardHeight = mapCardHeight + MAP_CARD_BOTTOM_PADDING;
      const mapCenter = mapContainerHeight / 2;
      let value = 0;
      if (totalMapCardHeight > mapCenter) {
        value = totalMapCardHeight - mapCenter;
      } else if (totalMapCardHeight === mapCenter) {
        return 0;
      } else {
        value = -(mapCenter - totalMapCardHeight);
      }
      return value * 2 + MAP_SYMBOL_SIZE;
    }
    return 0;
  };

  const setCenterToBelowCard = useCallback(
    (initialCoords: { lng: number; lat: number }) => {
      /**** Centering horizontally and 50px from bottom ****/
      if (mapInstance) {
        const maxZoom = mapInstance.getZoom() < DEFAULT_CLICK_ZOOM ? DEFAULT_CLICK_ZOOM : mapInstance.getZoom();
        if (isLatitudeLongitudeValid(initialCoords?.lat, initialCoords?.lng)) {
          const newBounds = mapInstance.cameraForBounds(
            [initialCoords.lng, initialCoords.lat, initialCoords.lng, initialCoords.lat],
            {
              padding: {
                top: getToppadding(mapInstance),
                bottom: getBottomPadding(mapInstance),
                left: 0,
                right: 0,
              },
              maxZoom,
            }
          );

          if (newBounds) {
            mapInstance.easeTo(newBounds);
          }
        } else {
          console.error(`Asset has invalid location ${initialCoords?.lat} ${initialCoords?.lng}`);
        }
      }
    },
    [mapInstance, mapCardHeight]
  );

  useEffect(() => {
    if (items) {
      setAssets(items);
    }
  }, [items]);

  useEffect(() => {
    const ids = currentType === 'cluster' ? assetIds : selectedAssetId ? [selectedAssetId] : [];
    if (!items && (ids.length || refresh)) {
      const stringed = JSON.stringify(ids.sort((a, b) => (a > b ? 1 : -1)));
      if (stringed !== prev.current || refresh) {
        setFetchingAssets(true);
        prev.current = stringed;
        client
          .query({
            query: gql`
              query aggregatesById($ids: [String]!) {
                aggregatesById(ids: $ids)
              }
            `,
            fetchPolicy: 'no-cache',
            variables: {
              ids: ids,
            },
          })
          .then(result => {
            setFetchingAssets(false);
            setRefresh(false);
            const assetData = result.data.aggregatesById
            setAssets(assetData);
            prevAssets.current = assetData;
          })
          .catch(e => {
            setFetchingAssets(false);
            console.log('fetch error', e);
          });
      } else if (prevAssets.current?.length > 0) {
        setAssets(prevAssets.current);
      }
    }
  }, [selectedAssetType, assetIds, selectedAssetId, currentType, refresh]);

  useEffect(() => {
    if (!selectedAssetId) {
      setCardAssetId(undefined);
    }
  }, [selectedAssetId]);
  const selectedAsset = useMemo(() => {
    if (selectedAssetId && currentType === 'single') {
      setIsCardVisible(false);
    }
    return assets.find(item => item.id === selectedAssetId);
  }, [assets, selectedAssetId, currentType]);

  useEffect(() => {
    setCardIds((assets || []).map(a=>a.id))
  }, [assets]);


  //get the location of the currently selected asset, zoom is imperative, so we have state just to track the current value
  //TODO: this currently reshows the card when the assets change, we need to change this to only show the card when the selectedAssetId changes.
  useEffect(() => {
    if (!selectedAsset && selectedAssetId) {
      setCardAssetId(selectedAssetId);
      showCard();
      return;
    }
    switch (selectedAsset?.primaryLocation.type) {
      case 'Point':
        const pointCoords = {
          lng: selectedAsset.lon,
          lat: selectedAsset.lat,
        };
        setCenterToBelowCard(pointCoords);
        setCardAssetId(selectedAsset.id);
        showCard();
        break;
      case 'LineString':
        let coords = selectedAsset.primaryLocation.coordinates;
        if (coords && coords.length >= 2) {
          let line = lineString(coords);
          let box = turfBbox(line);
          if (mapInstance) {
            mapInstance.fitBounds([
              [box[0], box[1]],
              [box[2], box[3]],
            ]);
            // data structure format
            // [
            //   [lowerLon, lowerLat],
            //   [upperLon, upperLat],
            // ]
          }
        }
        // TODO: need to implement this with bounding box, maybe use part of current function to just find y offset
        // setCenterToBelowCard();
        setCardAssetId(selectedAsset.id);
        showCard();
        break;
      // if we cannot determine the type, try lat/lon
      default:
        if (selectedAsset && selectedAsset.lat && (selectedAsset.lon || selectedAsset.lon)) {
          const initialCoords = {
            lng: selectedAsset.lon,
            lat: selectedAsset.lat,
          };
          setCenterToBelowCard(initialCoords);
          setCardAssetId(selectedAsset.id);
          showCard();
        }
        break;
    }
  }, [mapInstance, selectedAsset, setCenterToBelowCard, showCard, selectedAssetId, mapCardHeight]);

  React.useEffect(() => {
    if (assetIds.length > 0 && coordinates) {
      setCenterToBelowCard(coordinates);
      showCard();
    }
  }, [assetIds, showCard, coordinates, setCenterToBelowCard]);
  const handleClose = useCallback(() => {
    setIsCardVisible(false);
    setAssetsIds([]);
    setAssets([]);
    setCardAssetId(undefined);
    prev.current = "";
    onCardClose && onCardClose();
  }, [setAssetsIds]);

  useImperativeHandle(ref, () => ({
    handleClose,
  }));
  //TODO: add a reference to the cards in order to return the size of the displayed cards for offset purposes
  if (areCardsDefined && (areCardsActive || forceCards) && isCardVisible) {
    if (currentType === 'cluster') {
      if (assetIds.length > 1) {
        return (
          <MultipleAssetCardsContainer
            assets={assets}
            onCloseClick={handleClose}
            onCardChanged={onCardDisplayed}
            selectableAggregateTypes={selectableAggregateTypes}
            onSelect={onSelect}
            mapEditor={mapEditor}
            selectedAggregateId={selectedAggregateId}
            relative={forceCards}
            onFormSubmit={onFormSubmit}
          />
        );
      } else if (assetIds.length === 1) {
        return (
          <PositionedAssetCardContainer
            onFormSubmit={onFormSubmit}
            asset={selectedAsset}
            assetId={assetIds[0]}
            mapEditor={mapEditor}
            onCloseClick={handleClose}
            fetchingAssets={fetchingAssets}
          />
        );
      }
    }
    if (currentType === 'single' && selectedAssetId) {
      return (
        <PositionedAssetCardContainer
          onFormSubmit={onFormSubmit}
          asset={selectedAsset}
          assetId={cardAssetId ?? ''}
          mapEditor={mapEditor}
          onCloseClick={handleClose}
          fetchingAssets={fetchingAssets}
        />
      );
    }
  }
  return null;
};
export default forwardRef(AssetCardRenderer);
