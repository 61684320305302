import { makeStyles } from "@material-ui/core";

export const useCommonStyles = makeStyles((theme) => ({
    safariDefaultFontSize: {
        fontSize: '16px !important'
    },
    safariInnerInputFontSize: {
        '& input': {
            fontSize: '16px !important'
        }
    },
    safariMuiInputFontSize: {
        '& .MuiInputBase-input': {
            fontSize: '16px !important'
        }
    },
    hide: {
        opacity: 0
    },
    show: {
        opacity: 1
    }
}));