import React, { useContext, useState } from 'react';

interface AnalyticsModalContextType {
  openAnalytics: () => void;
  closeAnalytics: () => void;
  isAnalyticsOpen: boolean;
}

const analyticsModalContext = React.createContext<AnalyticsModalContextType>({
  openAnalytics: () => {},
  closeAnalytics: () => {},
  isAnalyticsOpen: false,
});

const useAnalyticsModal = () => {
  const context = useContext(analyticsModalContext);
  if (!context) {
    throw Error('useAnalyticsModal must be within a UserProvider');
  }
  return context;
};

const AnalyticsModalProvider = (props: { children: React.ReactNode }) => {
  const [isAnalyticsOpen, setIsAnalyticsOpen] = useState(false);

  const openAnalytics = () => {
    setIsAnalyticsOpen(true);
  };
  const closeAnalytics = () => {
    setIsAnalyticsOpen(false);
  };

  return (
    <analyticsModalContext.Provider
      value={{
        isAnalyticsOpen,
        openAnalytics,
        closeAnalytics,
      }}
      {...props}
    >
      {props.children}
    </analyticsModalContext.Provider>
  );
};

export { useAnalyticsModal, AnalyticsModalProvider };
