import * as React from 'react';
import { Document, Page, Text, StyleSheet, View } from '@react-pdf/renderer';

interface AssetDetailPageItem {
  attributes: Record<string, string>;
  title: string;
}
export interface AssetDetailDocumentProps {
  items: Array<AssetDetailPageItem>;
}

export const AssetDetailDocument: React.FunctionComponent<AssetDetailDocumentProps> = props => {
  const { items } = props;
  return (
    <Document>
      {items.map((item, index) => {
        return (
          <Page style={styles.body} key={index}>
            <Text fixed style={styles.title}>
              {item.title}
            </Text>
            <View style={styles.attributePage}>
              <Text style={styles.attributePageTitle}>Attributes</Text>
              <View style={styles.attributeCellFrame}>
                {Object.keys(item.attributes).map((key, index) => (
                  <View key={index} style={styles.attributeCell} wrap={false}>
                    <Text style={styles.attributeCellTitle}>{key}</Text>
                    <Text style={styles.attributeCellValue}>{item.attributes[key] || ' '}</Text>
                  </View>
                ))}
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};
export default AssetDetailDocument;
const LEFT_PADDING = '10pt' as const;
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    width: '100%',
    borderBottom: '1pt solid #ccc',
    paddingBottom: '15pt',
  },
  attributePage: {
    width: '100%',
  },
  attributePageTitle: {
    width: '100%',
    border: '1pt solid #eee',
    fontSize: '12pt',
    paddingLeft: LEFT_PADDING,
    paddingBottom: '8pt',
    paddingTop: '8pt',
    marginTop: '14pt',
  },
  attributeCellFrame: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: 1,
    borderLeft: '1pt solid #eee',
  },
  attributeCell: {
    borderRight: '1pt solid #eee',
    borderBottom: '1pt solid #eee',
    width: '33.333%',
  },
  attributeCellTitle: {
    fontSize: '9pt',
    color: '#888',
    marginLeft: LEFT_PADDING,
    marginTop: '5pt',
  },
  attributeCellValue: {
    fontSize: '10pt',
    marginLeft: LEFT_PADDING,
    marginRight: LEFT_PADDING,
    marginBottom: '6pt',
    marginTop: '3pt',
  },
});
