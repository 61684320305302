import React, { useMemo, useState, useImperativeHandle, useEffect, useCallback, useContext } from 'react';
import { Column } from '../../hooks/tableHooks/useColumns';
import { DashboardView, Project, RecordType, Role, RolesTableRef } from './types';
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client';
import {
  QUERY,
  ADD_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  AddRoleResponse,
  AddRoleVariables,
  UpdateRoleResponse,
  UpdateRoleVariables,
  DeleteRoleResponse,
  DeleteRoleVariables,
  QueryResult,
  SetMultiPermissionsResponse,
  SetMultiPermissionsVariables,
  SET_PERMISSIONS_FOR_MULTIPLE_ROLES,
} from './graphql';
import {
  mapQueryResultToData,
  getRoleName,
  convertRoleToAddMutationVariables,
  convertRolesToSetPermissionsVariables,
} from './utils';
import useRowChanges from '../../hooks/useRowChanges';
import UsersAndRolesTable from '../../components/UsersAndRoles/UsersAndRolesTable';
import { ActionsMenuButton } from '../../components/ActionsMenuUI';
import AddNewRoleDialog from '../../components/UsersAndRoles/AddNewRoleDialog';
import { useTheme, Theme } from '@material-ui/core';
import { useProcessing } from '../../hooks/userAdministrationHooks/useProcessing';
import { useErrorDialog } from '../../contexts/errorDialogContext';
import { useDeleteDialog } from '../../hooks/userAdministrationHooks/useDeleteDialog';
import { generateColumns } from './columnsGenerator';
import { useConfig, AuthConnector } from '@terragotech/gen5-shared-components';
import { isArray, isEqual } from 'lodash';
import { TableDataProvider } from '../../hooks/useTableData';
import { EditModeData } from '../../contexts/editModeContext';

interface RolesTableProps {
  height: number;
  onUpdate: (numberOfChanges: number, changedData?: EditModeData) => void;
  onEditModeOn: () => void;
  refresh: () => void;
  isEditModeOn: boolean;
  isAddDialogOpen: boolean;
  setIsAddDialogOpen: (value: boolean) => void;
}

const generateGetActions = (theme: Theme, openDeleteDialog: (roles: Role[]) => void) => (
  roles: Role[]
): ActionsMenuButton[] => [
  {
    label: 'Delete Role',
    icon: 'fa-trash',
    onClick: () => {
      openDeleteDialog(roles);
    },
    color: theme.palette.error.main,
  },
];

const RolesTable = React.forwardRef((props: RolesTableProps, ref) => {
  const { height, onUpdate, onEditModeOn, refresh, isEditModeOn, isAddDialogOpen, setIsAddDialogOpen } = props;
  const { error, data, loading } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'network-only' });
  const { handleRowChange } = useRowChanges();
  const theme = useTheme();
  const configContext = useConfig();
  const [roles, setRoles] = useState<Role[]>([]);

  const handleDeleteRoles = async (roles: Role[]) => {
    startProcessing();
    for (const role of roles) {
      await deleteRole({ variables: { roleId: role.id } });
    }
    stopProcessing();
  };

  const { isProcessing, startProcessing, stopProcessing } = useProcessing({
    onComplete: refresh,
  });
  const { setErrorDialogMessage, setErrorDialogTitle } = useErrorDialog();
  const { openDeleteDialog, deleteDialog } = useDeleteDialog({
    resourceNameKey: 'UsersAdministration.role',
    onDelete: handleDeleteRoles,
    getRecordName: getRoleName,
  });

  const [projects, setProjects] = useState([] as readonly Project[]);
  const [recordTypes, setRecordTypes] = useState([] as readonly RecordType[]);
  const [dashboardViews, setDashboardViews] = useState([] as readonly DashboardView[]);
  const analyticsTrustedServerUrl = useMemo(() => configContext.analyticsTrustedServerUrl, [configContext]);
  const [isLoadingDashboardViews, setIsLoadingDashboardViews] = useState(true);

  const initialData: {
    projects: readonly Project[];
    roles: readonly Role[];
    recordTypes: readonly RecordType[];
  } = useMemo(() => mapQueryResultToData(data), [data]);

  const retrieveDashboardViews = async (): Promise<Array<DashboardView>> => {
    try {
      const response = await fetch(`${analyticsTrustedServerUrl}/tableau/dashboardViews`, {
        method: 'POST',
        headers: await AuthConnector.getTrustedApiHeaders(),
      });
      const responseJson = await response.json();
      if (!response.ok || responseJson.error) {
        console.error(`An error has occurred: `, responseJson.error ?? response.status ?? responseJson);
        return Array<DashboardView>();
      } else {
        return responseJson;
      }
    } catch (error) {
      console.error(error);
      return Array<DashboardView>();
    }
  };

  useEffect(() => {
    setProjects(initialData.projects);
    setRecordTypes(initialData.recordTypes);
    setRoles([...initialData.roles]);
  }, [initialData, data]);

  useEffect(() => {
    (async function () {
      const data = await retrieveDashboardViews();
      setDashboardViews(data.map(d => ({
        ...d,
        toString: () => d.name,
      })));
      setIsLoadingDashboardViews(false);
    })();
  }, []);

  const getMutationOptions = <TResponse, TVariables>(): MutationHookOptions<TResponse, TVariables> => ({
    onError: (error) => {
      setErrorDialogMessage(error.message);
      setErrorDialogTitle(error.name);
    },
  });

  const [setRolePermissions] = useMutation<SetMultiPermissionsResponse, SetMultiPermissionsVariables>(
    SET_PERMISSIONS_FOR_MULTIPLE_ROLES,
    getMutationOptions()
  );
  const [deleteRole] = useMutation<DeleteRoleResponse, DeleteRoleVariables>(DELETE_ROLE, getMutationOptions());
  const [updateRole] = useMutation<UpdateRoleResponse, UpdateRoleVariables>(UPDATE_ROLE, getMutationOptions());

  const save: RolesTableRef['save'] = useCallback(async () => {
    startProcessing();
    //we should only call updateRole on the role(s) whose name has changed
    //so cycle through each role in the initialdata roles
    for (const initialRole of initialData.roles) {
      // look at the current value in roles that has the same is
      const newRole = roles.find((role) => role.id === initialRole.id);
      // if the name is different, update the role
      if (newRole && (newRole.name !== initialRole.name || newRole.data !== initialRole.data || !isEqual([...newRole.dashboards ?? []].sort(), [...initialRole.dashboards ?? []].sort()))) {
        await updateRole({
          variables: { roleId: initialRole.id, roleName: newRole.name, recordPermissionsIds: [], dashboards: newRole.dashboards, isDataRole: newRole.data },
        });
      }
    }

    await setRolePermissions({
      variables: convertRolesToSetPermissionsVariables(roles, recordTypes, projects),
    });
    stopProcessing();
  }, [startProcessing, initialData.roles, setRolePermissions, recordTypes, projects, stopProcessing, updateRole, roles]);

  /* 
    It necessary here as we have bar with save button in the higher component. 
  */
  useImperativeHandle(
    ref,
    (): RolesTableRef => ({
      save,
    })
  );

  const projectsLabel = useMemo(
    () => configContext.aggregateDefinitions.find((def) => def.name === 'Project')?.plural || '',
    [configContext]
  );
  const dashboardViewsLabel = 'Dashboards';
  const columns: readonly Column<Role>[] = useMemo(() => generateColumns(configContext, projects, projectsLabel, dashboardViews, dashboardViewsLabel), [
    configContext,
    projects,
    projectsLabel,
    dashboardViews,
    dashboardViewsLabel,
  ]);

  const handleUpdate = (id: string, columnId: string, _value: unknown) => {
    const value = _value as any;
    const roleIndex = roles.findIndex((role) => role.id === parseInt(id));
    if (roleIndex >= 0) {
      const newRole = { ...roles[roleIndex] };
      newRole[columnId] = isArray(value) ? value.map((item: any) => item.id) : value;
      setRoles([...roles.slice(0, roleIndex), newRole, ...roles.slice(roleIndex + 1)]);
      onUpdate(handleRowChange(roleIndex), { id, label: newRole.name });
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getActions = useCallback(generateGetActions(theme, openDeleteDialog), [theme, openDeleteDialog]);

  const handleEditModeOn = useCallback((_row: Role) => {
    onEditModeOn && onEditModeOn();
  }, [onEditModeOn]);

  return (
    <>
      <TableDataProvider>
        {!isLoadingDashboardViews && <UsersAndRolesTable<Role>
          // key={projects.length + recordTypes.length}
          data={roles}
          columns={columns}
          height={height}
          onEditModeOn={handleEditModeOn}
          onChange={handleUpdate}
          loading={loading || isProcessing}
          error={error ?? null}
          getActions={getActions}
          fabIconName="roles-plus"
          onFabClick={() => setIsAddDialogOpen(true)}
          singular="role"
          isEditModeOn={isEditModeOn}
        />}
        {deleteDialog}
      </TableDataProvider>
    </>
  );
});

export default RolesTable;
