import PositionedAssetCard from './PositionedAssetCard';
import MultipleAssetCards from './MultipleAssetCards';
import AssetCardBackdrop from './AssetCardBackdrop';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';

interface MultipleAssetCardsContainerProps {
  assets?: MapAssetType[];
  onCloseClick?: () => void;
  onCardChanged?: (assetId: string) => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  relative?: boolean;
  onFormSubmit?: () => void;
}

const EmptyCard = () => <PositionedAssetCard assetId={''} />;

const MultipleAssetCardsContainer = ({
  assets,
  onCloseClick,
  onCardChanged,
  onSelect,
  mapEditor,
  selectedAggregateId,
  selectableAggregateTypes,
  relative,
  onFormSubmit,
}: MultipleAssetCardsContainerProps) => {
  if (!assets || assets.length === 0) {
    return EmptyCard();
  }
  return (
    <AssetCardBackdrop multiple mapEditor={mapEditor}>
      <MultipleAssetCards
        assets={assets}
        onCloseClick={onCloseClick}
        onCardChanged={onCardChanged}
        onSelect={onSelect}
        mapEditor={mapEditor}
        selectedAggregateId={selectedAggregateId}
        selectableAggregateTypes={selectableAggregateTypes}
        onFormSubmit={onFormSubmit}
        multiple
      />
    </AssetCardBackdrop>
  );
};

export default MultipleAssetCardsContainer;
