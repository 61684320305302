import { Box, Typography, makeStyles } from '@material-ui/core';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import moment from 'moment';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { colors } from '../styles/theme';
import { DEFAULT_SYMBOL_OPTION } from '../utils/utilityHelper';
import { useAuthContext, useConfig } from '@terragotech/gen5-shared-components';
import { useCardMapper } from '../hooks/useCardMapper';
import { Asset } from './Map/AssetCard/AssetAttributeElement';
import {
  DATETIME_TOKEN_CONVERSION,
  getDateFormat,
  getDateTimeFormat,
  middleTruncate,
} from '@terragotech/gen5-shared-utilities';
import PagePage from './Page/PagePage';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useEffect, useRef, useState } from 'react';

export const RECORD_WIDTH = 360;

const AssetRecord = ({ record }: { record: MapAssetType }) => {
  const classes = useStyles();
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [cardSize, setCardSize] = useState<null | number>(30);
  const { pathname } = useLocation();
  const history = useHistory();
  const { id, label, __typename: name, symbolKey, recordTypeKey } = record;

  const { token } = useAuthContext();
  const { cardProps } = useCardMapper({
    assetId: id,
    asset: record,
    isAuthenitcated: !!token,
  });

  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const isDate = (asset: Asset) => {
    let updatedAsset: Asset = { ...asset };
    const definition = aggregateDefinitions.find(d => d.queryKey === recordTypeKey);
    if (definition?.propertyDefinitions) {
      _.mapKeys(asset, function (value, key) {
        const property = _.find(definition.propertyDefinitions, function (o) {
          return o.label === key || o.field === key;
        });
        if (property && property.type === 'DateTime' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment(String(dateValue)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                )
              )
            : '';
          updatedAsset[key] = dateValue;
        } else if (property && property.type === 'Date' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment
                .utc(String(dateValue))
                .format(
                  getDateFormat(
                    defaultDateTimeFormat?.dateFormatType,
                    defaultDateTimeFormat?.dateFormat,
                    defaultDateTimeFormat?.dateSeperator
                  )
                )
            : '';
          updatedAsset[key] = dateValue;
        }
      });
    }
    return updatedAsset;
  };

  const onNavigate = () => {
    history.push(`${pathname}/${name}/${id}`);
  };

  function handleResize() {
    if (cardRef.current) {
      const size = cardRef.current?.clientWidth;
      const trimValue = Math.floor(size / 9);
      setCardSize(trimValue);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box className={classes.record} onClick={onNavigate}>
      <Box className={classes.recordHeader}>
        <img className={classes.assetIcon} src={getSvgImageString(symbolKey || '', DEFAULT_SYMBOL_OPTION)} alt="icon" />
        <div ref={cardRef} className={classes.aggregateInfo}>
          <Typography className={classes.singularName}>{name.toString()}</Typography>
          <Typography className={classes.label}>
            {middleTruncate(label, cardSize) as string}
          </Typography>
        </div>
      </Box>
      <Box className={classes.body}>
        {record?.pageName ? (
          <PagePage target={record} page={record.pageName.toString()} isAssetCard fabContainerKey="AssetCard" />
        ) : (
          cardProps?.attributes.map(data => {
            const assetValue = isDate(cardProps.asset);
            const dataValue = assetValue[data.key];
            const value = dataValue ? dataValue : '(None)';
            return data.isVisible ? (
              <Box key={data.key} className={classes.row}>
                <Typography className={`${classes.cellData} ${classes.keyName}`}>{`${data.title}:`}</Typography>
                <Typography
                  className={`${classes.cellData} ${classes.value}`}
                  style={!dataValue && data.color === colors.black ? { color: colors.title } : undefined}
                >
                  {value}
                </Typography>
              </Box>
            ) : null;
          })
        )}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  record: {
    width: '100%',
    borderRadius: 5,
    flexShrink: 0,
    cursor: 'pointer',
    padding: '8px 0',
    backgroundColor: colors.whiteSmoke,
    boxShadow: `0px 2px 4px 0px ${colors.black15}`,
  },
  showAll: {
    width: '100%',
  },
  recordHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 18px',
    backgroundColor: colors.whiteSmoke,
    gap: 10,
  },
  assetIcon: {
    width: 45,
    height: 45,
    borderRadius: 45 / 2,
    boxShadow: `0px 2px 4px ${colors.black10}`,
    backgroundColor: colors.white,
  },
  aggregateInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    color: colors.black0,
    gap: 5,
    width: '90%',
    letterSpacing: -0.5,
  },
  singularName: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '130.769%',
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: '85%',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 18px',
    gap: 5,
    minHeight: 100,
    backgroundColor: colors.white,
  },
  cellData: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: colors.black0,
    lineHeight: 'normal',
  },
  keyName: {
    fontWeight: 400,
  },
  value: {
    fontWeight: 300,
    marginLeft: 5,
  },
  row: {
    display: 'flex',
  },
}));

export default AssetRecord;
