import { createStyles, InputLabel, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import { colors } from '../../styles/theme';

export interface LoginField {
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeholder?: string;
}

interface LoginFieldsProps {
  errorMessage: string | undefined;
  fields: Array<LoginField>;
  children?: React.ReactNode;
}

const LoginFields: React.FC<LoginFieldsProps> = (props: LoginFieldsProps) => {
  const { fields, errorMessage, children } = props;
  const classes = useStyles();
  return (
    <div className={classes.loginContainer}>
      {fields.map((field: LoginField, index: number) => {
        return (
          <div key={index} className={classes.container}>
            <InputLabel className={classes.label}>{field.label}</InputLabel>
            <TextField
              className={classes.input}
              placeholder={field.placeholder}
              value={field.value}
              onChange={field.onChange}
              error={!!errorMessage}
              type={'' || field.type}
              key={index}
              variant="outlined"
              inputProps={{
                style: styles.inputStyle,
                form: {
                  autoComplete: 'off',
                },
              }}
            />
          </div>
        );
      })}
      {children}
    </div>
  );
};
const styles = {
  inputStyle: { WebkitBoxShadow: `0 0 0 1000px ${colors.white} inset` },
};
const useStyles = makeStyles(() =>
  createStyles({
    input: {
      display: 'flex',
      width: '100%',
      '& .MuiInputBase-input': {
        color: colors.black0,
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        opacity: 1,
        '&::placeholder': {
          color: colors.black35,
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '11px 18px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `1px solid ${colors.black25}`,
          borderRadius: 5,
        },
        '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.black25}`,
          borderRadius: 5,
        },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.black25}`,
        },
      },
    },
    loginContainer: { display: 'flex', gap: 18, flexDirection: 'column', marginBottom: 22 },
    label: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: colors.black54,
      fontStyle: 'normal',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    }
  })
);

export default LoginFields;
