"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isV2ValidationComponent = exports.isV2RepeatableComponent = void 0;
function isV2RepeatableComponent(component) {
    const temp = component;
    return temp.repeats === true;
}
exports.isV2RepeatableComponent = isV2RepeatableComponent;
function isV2ValidationComponent(component) {
    const temp = component;
    return temp.errorMap !== undefined || temp.warningMap !== undefined;
}
exports.isV2ValidationComponent = isV2ValidationComponent;
