import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const MapVisibilityIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 25">
      <path d="M6.75 0.236816C3.30375 0.236816 0.5 3.04057 0.5 6.48682C0.5 10.4218 5.27363 15.8756 5.81738 16.4844C6.43863 17.2881 7.05893 17.2881 7.68018 16.4844C8.22518 15.8756 13 10.4218 13 6.48682C13 3.04057 10.1962 0.236816 6.75 0.236816ZM6.75 3.98682C8.13125 3.98682 9.25 5.10557 9.25 6.48682C9.25 7.86807 8.13125 8.98682 6.75 8.98682C5.36875 8.98682 4.25 7.86807 4.25 6.48682C4.25 5.10557 5.36875 3.98682 6.75 3.98682ZM19.25 7.73682C15.8038 7.73682 13 10.5406 13 13.9868C13 17.9218 17.7736 23.3756 18.3174 23.9844C18.9386 24.7881 19.5589 24.7881 20.1802 23.9844C20.7252 23.3756 25.5 17.9218 25.5 13.9868C25.5 10.5406 22.6963 7.73682 19.25 7.73682ZM19.25 11.4868C20.6313 11.4868 21.75 12.6056 21.75 13.9868C21.75 15.3681 20.6313 16.4868 19.25 16.4868C17.8687 16.4868 16.75 15.3681 16.75 13.9868C16.75 12.6056 17.8687 11.4868 19.25 11.4868Z" />
    </SvgIcon>
  );
};
