import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { CommandAction } from '@terragotech/gen5-shared-components';
import { Typography, Radio, RadioGroup, FormControlLabel, Box } from '@material-ui/core';
import _ from 'lodash';
import ImportDropZone from './ImportDropZone';
import { FileWithPath } from 'react-dropzone';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';

const borderWidth = 2;
const buttonHeight = 58;
const borderHeight = 2;
const useStyles = makeStyles(theme => ({
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    width: `calc(100% - ${borderWidth}px)`,
    height: `calc(100% - ${buttonHeight}px)`,
    backgroundColor: colors.white,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      height: `100%`,
    },
  },

  flexContainer: {
    display: 'flex',
    border: `1px solid ${colors.black10}`,
    borderRadius: 5,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      flexDirection: 'column',
      height: `calc(100% - ${borderHeight}px)`,
    },
  },
  typeContainer: {
    flexBasis: '24%',
    minWidth: 180,
    borderRight: `1px solid ${colors.black10}`,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      border: 'none',
    },
  },
  typeTtile: {
    height: 22,
    padding: '15px 18px',
    borderBottom: `1px solid ${colors.black10}`,
    fontSize: 18,
    fontWeight: 400,
  },
  btnContainer: {
    gap: 18,
    padding: '24px 0px 0px 18px',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      padding: '24px 0px 29px 18px',
    },
  },
  dropContainer: {
    flexBasis: '76%',
    borderRadius: '0px 5px 5px 0px',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      height: '55%',
      display: 'block',
    },
  },
  radioBtnWrapper: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
  },
  radioContainer: {
    minHeight: 24,
    gap: 8,
    marginLeft: 0,
  },
  downloadBtn: {
    marginLeft: 31,
    width: 'fit-content',
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.primary.main,
    paddingBottom: 2,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    marginTop: 13,
    cursor: 'pointer',
  },

  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    lineHeight: 'normal',
    minHeight: 18,
  },
  radioIcon: {
    width: 24,
    height: 24,
    padding: 0,
  },
  toolTip: {
    fontSize: 20,
  },
}));

interface Props {
  onBack?: () => void;
  downloadFile: (fileUrl: string, fileName: string) => Promise<void>;
  picked: UseConditionalImport | undefined;
  buttons: UseConditionalImport[] | undefined;
  setPicked: (x: UseConditionalImport) => void;
  index: string;
  setIndex: (x: string) => void;
  downloadTemplate: (aggregateate: string, commandName: string, version: number, filename: string) => Promise<void>;
  isSelected: boolean;
  selectedFile: FileWithPath | undefined;
  changeHandler: (file: FileWithPath[]) => void;
  uploadFile: (fileName: string) => void;
  clearSelection: () => void;
}

const ImportFileScreen = (props: Props) => {
  const {
    picked,
    buttons,
    downloadTemplate,
    setPicked,
    index,
    setIndex,
    isSelected,
    selectedFile,
    clearSelection,
    changeHandler,
  } = props;
  const classes = useStyles();
  const dropZoneDisabled = useMemo(() => {
    return _.isEmpty(index);
  }, [index]);
  return (
    <Box className={classes.root}>
      <Box className={classes.flexContainer}>
        <Box className={classes.typeContainer}>
          <Typography className={classes.typeTtile}>Import type</Typography>
          <Box className={classes.radioBtnWrapper}>
            {buttons && (
              <RadioGroup
                value={index}
                defaultValue={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = buttons[(e.target.value as unknown) as number];
                  setPicked(value);
                  setIndex(e.target.value as string);
                  clearSelection();
                }}
                className={classes.btnContainer}
              >
                {buttons &&
                  buttons
                    .filter((opt: UseConditionalImport) => opt.state === 'enabled')
                    .map((option: UseConditionalImport, idx: number) => (
                      <Box key={idx}>
                        <FormControlLabel
                          className={classes.radioContainer}
                          value={String(idx)}
                          control={<Radio color={'primary'} className={classes.radioIcon} />}
                          label={
                            <div>
                              <Typography className={classes.radioText}>{option.label}</Typography>
                            </div>
                          }
                          key={idx}
                        />
                        {index !== '' && !picked?.isIntegrationImport && option.label === picked?.label && (
                          <Typography
                            className={classes.downloadBtn}
                            onClick={() => {
                              if (picked)
                                downloadTemplate(
                                  picked.aggregate.name,
                                  (picked.action as CommandAction).commandName,
                                  (picked.action as CommandAction).commandVersion,
                                  picked.label + ' - Template'
                                );
                            }}
                          >
                            Download Template
                          </Typography>
                        )}
                      </Box>
                    ))}
              </RadioGroup>
            )}
          </Box>
        </Box>
        <Box className={classes.dropContainer}>
          <ImportDropZone
            changeHandler={changeHandler}
            isSelected={isSelected}
            selectedFile={selectedFile}
            clearSelection={clearSelection}
            disabled={dropZoneDisabled}
            selected={picked || undefined}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImportFileScreen;
