import { IconButton, Input, InputAdornment, makeStyles } from '@material-ui/core';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Search } from '@material-ui/icons';
import clsx from 'clsx';
import magicText from 'i18next';

import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { useUtils } from '../../contexts/utilContext';
import { useCommonStyles } from '../../styles/commonStyles';

interface Props {
  displayName: string;
  onClose?: () => void;
  handleFilterSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const ModalHeader = ({ displayName, onClose, handleFilterSearch }: Props) => {
  const classes = useStyles();
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  return (
    <div className={classes.header}>
      <div className={classes.headContainer}>
        <div className={classes.headerText}>{displayName}</div>
        <IconButton onClick={() => onClose && onClose()} className={classes.closeRoot}>
          <FontAwesomeIcon icon={faClose} className={classes.closeIcon} />
        </IconButton>
      </div>

      <div className={classes.searchBar}>
        <Input
          startAdornment={
            <InputAdornment position="start">
              <Search className={classes.searchIcon} />
            </InputAdornment>
          }
          placeholder={`${magicText.t('Search')}`}
          className={clsx(classes.input, isIOSSafari && commonClasses.safariDefaultFontSize)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterSearch(e)}
        />
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: '18px 30px',
    gap: 15,
    borderBottom: `1px solid ${colors.black10}`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '18px 22px',
    },
  },
  headContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerText: {
    color: colors.black0,
    textTransform: 'none',
    fontSize: 19,
    fontWeight: 500,
    lineHeight: 'normal',
  },
  closeRoot: {
    width: 24,
    height: 24,
  },
  closeIcon: {
    fontSize: 20,
    color: colors.black54,
  },
  searchBar: {
    backgroundColor: colors.cultured7,
    color: colors.black35,
    width: '100%',
    height: 38,
    borderRadius: 4,
    '& .MuiInputBase-input': {
      fontSize: 14,
      fontWeight: 400,
    },
  },
  searchIcon: {
    height: 22,
    width: 22,
    paddingLeft: '12px',
    color: colors.black35,
  },
  input: {
    position: 'unset',
    width: '100%',
    fontSize: 14,
    fontWeight: 'normal',
    '& .MuiInputBase-input': {
      padding: '8px 0',
      paddingRight: 12,
      height: 22,
    },
  },
}));

export default ModalHeader;
