import { FormHelperText, IconButton, TextField, Typography, useTheme } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import magicText from 'i18next';
import { makeStyles } from '@material-ui/core/styles';
import { isCoordinatesValid } from '@terragotech/gen5-shared-utilities';
import _ from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import ActionIcon from '../../ActionIcon';
import PinCircleSidebar from './PinCircleSidebar';
import { useAlert } from '../../../contexts/AlertModalContext';
import { colors } from '../../../styles/theme';
import { PositionOptions } from 'mapbox-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { faCircleXmark } from '@fortawesome/pro-light-svg-icons';

const useInputStyles = makeStyles(theme => ({
  root: (props: { index: number }) => ({
    width: '100%',
    paddingTop: props.index === 0 ? 0 : 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  }),
  input: {
    '& > *': {
      minWidth: '275px',
      height: '36px',
      marginLeft: '8px',
      padding: '8px 15px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]:{
        minWidth: 257,
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.black15}`,
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.black15}`,
    },
    width: '100%',

  },
  adornedStart: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
  },
  changePadding: {
    paddingLeft: 2,
    paddingRight: 0,
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${theme.palette.primary.main || colors.defaultPrimary}`,
  },
  closeIcon: {
    padding: 0,
    paddingLeft: 8,
  },
  circleIcon: {
    display: 'flex',
  },
  dragIcon: {
    width: 18,
    height: 14.786,
    flexShrink: 0,
    color: colors.black54,
  },
  closeCircleIcon:{
    width: 22,
    height: 22,
    color: colors.black54,
  }
}));

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    flex: 9,
  },
  inputContainer: {
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    Position: 'relative',
    top: 3,
    marginRight: 2,
  },
  inputItem: {
    position: 'absolute',
    zIndex: 1,
    top: 6,
    left: 4,
  },
  input: {
    color: 'white',
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  error: {
    color: 'red',
    position: 'absolute',
    paddingLeft: 15,
    fontStyle: 'italic',
  },
};

const TGCoordinateTextField = (props: any) => {
  const theme = useTheme();
  const {
    index,
    specificLocation,
    capturedLocation,
    setCapturedLocation,
    setBlankField,
    blankField,
    selectedPoint,
    setSelectedPoint,
    selectedPointRef,
  } = props;
  const classes = useInputStyles({ index });
  // Local Coords is the string manipulated while focused
  const [localCoords, setLocalCoords] = useState<string | null>(null);
  const [hasError, setHasError] = useState(false);
  const { openConfirmation } = useAlert();

  const getCoordinateFromLocalCoord = (coordinates: string | null | undefined) => {
    if (!coordinates) {
      return undefined;
    }
    let _s = coordinates.split(',').map(x => Number(x));
    if (!_.isEmpty(_s) && Array.isArray(_s)) {
      return _.reverse(_s);
    }
    return undefined;
  };

  useEffect(() => {
    if (_.isEmpty(localCoords)) {
      setHasError(false);
    } else {
      const values = getCoordinateFromLocalCoord(localCoords);
      setHasError(
        !values ||
          !Array.isArray(values) ||
          !_.isNumber(values[1]) ||
          !_.isNumber(values[0]) ||
          !isCoordinatesValid(values[1], values[0])
      );
    }
  }, [localCoords]);

  const onDeleteLocation = async () => {
    if (hasError) {
      return;
    }
    if (blankField) {
      setBlankField(index === 0);
      setSelectedPoint(undefined);
      selectedPointRef.current = undefined;
      return;
    }
    const keyPath = 'alerts.deleteLocationConfirmation';
    const resp = await openConfirmation({
      title: magicText.t(`${keyPath}.primaryText`),
      question: magicText.t(`${keyPath}.secondaryText`, { index: index + 1 }),
      confirmationText: magicText.t(`${keyPath}.confirmationText`),
    });
    if (resp === 'confirm') {
      const temp = [...capturedLocation.slice(0, index), ...capturedLocation.slice(index + 1)];
      setCapturedLocation(temp);
      if (!blankField && temp.length === 0) {
        setBlankField(true);
      }
      setSelectedPoint(undefined);
      selectedPointRef.current = undefined;
    }
  };

  const isSelectedPoint: boolean = selectedPoint?.indexOfCoords === index;
  return (
    <div key={index} className={classes.root}>
      <div style={styles.container}>
        <FontAwesomeIcon icon={faBars as IconProp} className={classes.dragIcon}/>
      </div>
      <div style={styles.content}>
        <TextField
          className={classes.input}
          // If localCoords is not null, display localCoords
          // If localCoords is null, check if blankField is true which means specificLocation is undefined,
          // if blank field display empty string, if not display specificLocation normally
          value={
            !localCoords && localCoords !== ''
              ? blankField
                ? ``
                : `${specificLocation?.coordinates[1]}, ${specificLocation?.coordinates[0]}`
              : localCoords
          }
          id="outlined-basic"
          onBlur={() => {
            //If no error displayed with localCoords, then set the state with the new coords in object
            if (localCoords && !hasError) {
              setCapturedLocation((prevState: any) => {
                if (blankField) {
                  if (prevState && Array.isArray(prevState) && localCoords) {
                    const temp = [...prevState];
                    // const parsedCoords = localCoords.split(',').map(x => Number(x));
                    return [...temp, { type: 'Point', coordinates: getCoordinateFromLocalCoord(localCoords) }];
                  } else {
                    return [
                      {
                        type: 'Point',
                        coordinates: getCoordinateFromLocalCoord(localCoords),
                      },
                    ];
                  }
                } else if (prevState && Array.isArray(prevState)) {
                  const temp = [...prevState];
                  if (temp[index] && temp[index]?.coordinates && localCoords) {
                    temp[index].coordinates = getCoordinateFromLocalCoord(localCoords);
                    return [...temp];
                  }
                } else {
                  return [
                    {
                      type: 'Point',
                      coordinates: getCoordinateFromLocalCoord(localCoords),
                    },
                  ];
                }
              });
            }
            setLocalCoords(null);
            setBlankField(index === 0);
          }}
          error={hasError}
          onChange={e => setLocalCoords(e.target.value)}
          placeholder={'Location Coordinates'}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div style={styles.inputContainer}>
                  <div className={classes.circleIcon}>
                    <PinCircleSidebar fill={theme.palette.primary.main || colors.defaultPrimary} />
                  </div>
                  <Typography style={styles.input as CSSProperties}>{index + 1}</Typography>
                </div>
              </InputAdornment>
            ),
            classes: {
              adornedStart: classes.adornedStart,
              input: classes.changePadding,
              notchedOutline: isSelectedPoint ? classes.notchedOutline : undefined,
            },
          }}
        />
        <FormHelperText style={styles.error as CSSProperties}>{hasError && 'Coordinates are invalid'}</FormHelperText>
      </div>
      <IconButton
        color="default"
        aria-label="Delete Location"
        disabled={hasError}
        onClick={onDeleteLocation}
        className={classes.closeIcon}
      >
        <FontAwesomeIcon icon={faCircleXmark as IconProp} className={classes.closeCircleIcon}/>
      </IconButton>
    </div>
  );
};
export default TGCoordinateTextField;