import { useCallback } from 'react';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { useLocallyStoredFilterValue } from './useLocallyStoredFilterValue';

export const QUICK_SEARCH_PREFERENCE_KEY = 'quickSearch';

export interface UseQuickSearchArgs {
  selectedRecordType: string;
  aggregateDefinitions: AggregateDefinition[];
}

const defaultValue = '';

export const useQuickSearch = (props: UseQuickSearchArgs) => {
  const {
    currentAggregateValue: quickSearch,
    getValueForRecordType: getQuickSearchForRecordType,
    setCurrentAggregateValue: setQuickSearch,
    setValueForRecordType: setQuickSearchForMap,
  } = useLocallyStoredFilterValue({
    ...props,
    preferenceKey: QUICK_SEARCH_PREFERENCE_KEY,
    defaultValue,
  });

  const resetQuickSearch = useCallback(() => {
    setQuickSearch(defaultValue);
  }, [setQuickSearch]);

  return {
    quickSearch,
    getQuickSearchForRecordType,
    setQuickSearch,
    setQuickSearchForMap,
    resetQuickSearch,
  };
};
