import StyledTableSelectEditor from '../../components/StyledTable/Editors/StyledTableSelectEditor';
import { DashboardView, Project, Role } from './types';
import { Column } from '../../hooks/tableHooks/useColumns';
import React from 'react';
import magicText from 'i18next';
import { WebUIConfig } from '@terragotech/gen5-shared-components';
import { Checkbox } from '@material-ui/core';

export const projectMapper = (_project: unknown) => {
  const project = _project as Project;
  return { value: project.id || project, name: project.title || project };
};

export const dashboardViewMapper = (_dashboardView: unknown) => {
  const dashboardView = _dashboardView as DashboardView;
  return { value: dashboardView.id || dashboardView, name: dashboardView.name || dashboardView };
};

export const generateColumns: (
  configContext: WebUIConfig,
  projects: readonly Project[],
  projectsLabel: string,
  dashboardViews?: readonly DashboardView[],
  dashboardViewsLabel?: string
) => Column<Role>[] = (configContext, projects, projectsLabel, dashboardViews, dashboardViewsLabel) => {
  return [
    { name: 'Role Title', key: 'name', editable: true, dataType: 'String' },
    {
      name: 'Is Data Role?',
      key: 'data',
      editable: true,
      cellRenderer: ({ row }) => {
        const selectedItems = row?.data ?? true;
        return <div>{selectedItems ? 'Yes' : 'No'}</div>;
      },
      cellEditor: React.forwardRef((editorProps, ref) => {
        const forced = configContext.aggregateDefinitions
          .filter(
            aggregateDefinition =>
              aggregateDefinition.name !== 'Project' &&
              aggregateDefinition.name !== 'Folder' &&
              !aggregateDefinition.isReferenceType
          )
          .reduce((prev, aggregateDefinition) => {
            return prev || (editorProps.row?.[aggregateDefinition.name] as string[]).length > 0;
          }, false);
        return (
          <div>
            <Checkbox
              color="primary"
              disabled={forced}
              style={styles.checkbox as React.CSSProperties}
              defaultChecked={editorProps.row.data as boolean}
              onChange={() =>
                editorProps.onRowChange(
                  { ...editorProps.row, data: !editorProps.row.data, __changes: { data: !editorProps.row.data } },
                  true
                )
              }
            />
          </div>
        );
      }),
    },
    ...configContext.aggregateDefinitions
      .filter(
        (aggregateDefinition: any) =>
          aggregateDefinition.name !== 'Project' &&
          aggregateDefinition.name !== 'Folder' &&
          !aggregateDefinition.isReferenceType
      )
      .map((aggregateDefinition: any) => {
        return {
          name: aggregateDefinition.plural,
          key: aggregateDefinition.name,
          cellRenderer: ({ row }: any) => {
            const selectedItems = row?.[aggregateDefinition.name];
            return (
              <div>
                {selectedItems.length === projects.length
                  ? `${magicText.t('Simple.All')} ${projectsLabel}`
                  : selectedItems
                      .map((item: any) =>
                        typeof item === 'object' && 'title' in item
                          ? item.title
                          : projects.find(project => project.id === item)
                      )
                      .join(', ')}
              </div>
            );
          },
          cellEditor: React.forwardRef((editorProps: any, ref: any) => {
            return (
              <StyledTableSelectEditor
                {...editorProps}
                ref={ref}
                mapper={projectMapper}
                additionalMapper={(row: any, val: any) => {
                  if (row.data || !val.length) {
                    return {};
                  }
                  return {
                    data: true,
                  };
                }}
                lookup={[...projects]}
                label={aggregateDefinition.name}
              />
            );
          }),
          editable: true,
          filterInputOptions: {
            labelConverter: (label: string): string => {
              return projects.find(project => project.id === label)?.title ?? label;
            },
          },
        };
      }),
    ...(configContext.enabledFeatures.analyticsModule && dashboardViews?.length
      ? [
          {
            name: 'Available Dashboards',
            key: 'dashboards',
            editable: true,
            cellRenderer: ({ row }: any) => {
              const selectedItems = row?.dashboards ?? [];
              return (
                <div>
                  {dashboardViews.every(dv =>
                    selectedItems.some((si: any) => dv.id === (typeof si === 'object' && 'id' in si ? si.id : si))
                  )
                    ? `${magicText.t('Simple.All')} ${dashboardViewsLabel}`
                    : selectedItems
                        .map((item: any) =>
                          typeof item === 'object' && 'name' in item
                            ? item.name
                            : dashboardViews.find(dashboardView => dashboardView.id === item)
                        )
                        .filter((item: any) => !!item)
                        .join(', ')}
                </div>
              );
            },
            cellEditor: React.forwardRef((editorProps: any, ref: any) => {
              return (
                <StyledTableSelectEditor
                  {...editorProps}
                  ref={ref}
                  mapper={dashboardViewMapper}
                  lookup={[...dashboardViews]}
                  label="Available Dashboards"
                  retainUnavailableValues={true}
                />
              );
            }),
            filterInputOptions: {
              labelConverter: (label: string): string => {
                return dashboardViews.find(dv => dv.id === label)?.name ?? label;
              },
            },
          },
        ]
      : []),
  ];
};

const styles = {
  checkbox: {
    padding: 0,
    position: 'absolute',
    top: 6,
  },
};
