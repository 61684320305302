import * as React from 'react';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';
import AboutMenuItemModal from '../components/AboutMenuItemModal';

const styles = (theme: Theme) => createStyles({});

export interface AboutMenuItemModalContainerProps extends WithStyles<typeof styles> {
  openAbout: boolean;
  closeAboutModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export interface AboutMenuItemModalContainerState {}

class AboutMenuItemModalContainer extends React.Component<
  AboutMenuItemModalContainerProps,
  AboutMenuItemModalContainerState
> {
  constructor(props: AboutMenuItemModalContainerProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { openAbout, closeAboutModal } = this.props;
    return <AboutMenuItemModal openAbout={openAbout} closeAboutModal={closeAboutModal} />;
  }
}

export default withStyles(styles)(AboutMenuItemModalContainer);
