// Adapted from https://v4.mui.com/components/tabs/#full-width

import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { createStyles, makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Tabs, { TabsTypeMap } from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Divider } from '@material-ui/core';
import { colors } from '../../styles/theme';

const INNER_CONTENT_WIDTH = 320;
const TAB_HEIGHT = 33;
const TAB_WIDTH = 70;

interface StyledTabProps {
  label: string;
}

const StyledTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'capitalize',
      minWidth: TAB_WIDTH,
      minHeight: TAB_HEIGHT,
      fontSize: 15,
      fontWeight: 400,
      color: colors.black0,
      fontStyle: 'normal',
    },
    selected: {},
  })
)((props: StyledTabProps) => <Tab {...props} />);

const StyledTabs = withStyles({
  root: {
    minHeight: TAB_HEIGHT,
    margin: '0 15px',
  },
  indicator: {
    width: `${TAB_WIDTH}px !important`,
    bottom: 2,
    height: 3,
  },
})((props: StyledTabsProps) => <Tabs {...props} />);

interface StyledTabsProps {
  value: number;
  onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
  children: React.ReactElement<SwipeableTabPanelProps, typeof SwipeableTabPanel>[];
}

interface SwipeableTabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index?: number;
  value?: number;
  label: string;
  hideTab?: boolean;
}

export function SwipeableTabPanel(props: SwipeableTabPanelProps) {
  const { children, value, index, hideTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`swipeable-tabpanel-${index}`}
      aria-labelledby={`swipeable-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `swipeable-tab-${index}`,
    'aria-controls': `swipeable-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: INNER_CONTENT_WIDTH,
  },
  divider: { backgroundColor: colors.black10 },
  indicator: {
    bottom: 0,
  },
}));

export interface SwipeableTabsProps {
  children: React.ReactElement<SwipeableTabPanelProps, typeof SwipeableTabPanel>[];
  selectedTabIndex: number;
  setSelectedTabIndex: (index: number) => void;
  hideTabs?: boolean;
}

export default function SwipeableTabs(props: TabsTypeMap<SwipeableTabsProps>['props']) {
  const { selectedTabIndex, setSelectedTabIndex, hideTabs, children, ...other } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <StyledTabs
        value={selectedTabIndex}
        onChange={(_: React.ChangeEvent<{}>, newValue: number) => setSelectedTabIndex(newValue)}
        indicatorColor="primary"
        aria-label="swipeable tabs"
        classes={{
          indicator: classes.indicator,
        }}
        {...other}
      >
        {children
          .filter(x => !x.props.hideTab)
          .map((t, i) => (
            <StyledTab key={i} label={t.props.label} {...a11yProps(i)} />
          ))}
      </StyledTabs>
      <Divider className={classes.divider} />
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={selectedTabIndex}
        onChangeIndex={i => setSelectedTabIndex(i)}
        style={{
          width: '100%',
        }}
      >
        {children
          .filter(x => !x.props.hideTab)
          .map((t, i) => (
            <t.type key={i} value={selectedTabIndex} index={i} dir={theme.direction} {...t.props} />
          ))}
      </SwipeableViews>
    </div>
  );
}
