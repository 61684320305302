import React, { useMemo } from 'react';
import { DocumentNode } from 'graphql';
import { OptionObject as Option } from '../TGOptionSelectorModal';
import { useQueryModalData } from '../../../../hooks/useLazyModalData';
import { useSearch } from '@terragotech/gen5-shared-components';
import TGOptionLazyModal from './TGOptionLazyModal';
import { gql, useQuery } from '@apollo/client';

export const RECORDS_DEFAULT_LIMIT = 100;

export interface TGOptionSelectorModalProps<DataType extends Option> {
  title: string;
  open: boolean;
  value: DataType[];
  query: DocumentNode;
  queryKey: string;
  recordsPerPage?: number;
  pathToLabel?: string;
  pathToValue?: string;
  multiSelect?: boolean;
  filterPlaceHolder: string;
  onCancel: () => void;
  onDone: (value: DataType[]) => void;
  setOpen: (open: boolean) => void;
}

interface TotalCountQueryResult {
  [index: string]: {
    totalCount: number;
  };
}

const TGOptionQueryLazyModal = <DataType extends Option>(props: TGOptionSelectorModalProps<DataType>) => {
  const { query, queryKey, recordsPerPage } = props;

  const { searchText, handleSearchText } = useSearch();

  const { data, fetchMoreData, refetch, error, loading, isAllLoaded } = useQueryModalData<DataType>({
    query,
    queryKey,
    recordsLimit: recordsPerPage ?? RECORDS_DEFAULT_LIMIT,
    searchText,
  });

  const connectionKey = useMemo(() => `${queryKey}Connection`, [queryKey]);

  const totalCountQuery = useMemo(
    () =>
      gql`
        query TotalCountQuery{
          ${connectionKey} {
            totalCount
          }
        }
      `,
    [connectionKey]
  );

  const { data: totalCountData } = useQuery<TotalCountQueryResult>(totalCountQuery);

  const totalCount = useMemo(() => (totalCountData ? totalCountData[connectionKey].totalCount : 0), [
    connectionKey,
    totalCountData,
  ]);

  return (
    <TGOptionLazyModal<DataType>
      {...props}
      searchText={searchText}
      handleSearchText={handleSearchText}
      data={data}
      loading={loading}
      error={error}
      isAllLoaded={isAllLoaded}
      fetchMoreData={fetchMoreData}
      refetch={refetch}
      totalCount={totalCount}
    />
  );
};

export default TGOptionQueryLazyModal;
