import { forwardRef, useEffect } from 'react';
import { Editor2Props } from '@terragotech/react-data-grid';
import { TableData } from '../../hooks/useTable';
import { usePhotoViewer } from '../../hooks/usePhotoViewer';

/**
 * PhotoEditor - Displays Carousel PhotoEditor
 *
 * @param options
 */
export default forwardRef((props: Editor2Props<TableData>, ref) => {
  const { row, column, onRowChange } = props;
  const photoViewer = usePhotoViewer();
  const value = (row[column.key] as string[]) || [];
  const handleOnClick = () => {
    photoViewer.open({
      images: value,
      editMode: true,
      onClose: (images: string[]) => {
        (row as any).__photos = images;
        onRowChange({ ...row, [column.key]: images, __changes: { [column.key]: images } }, true);
      },
    });
  };

  // I definitely don't like this. I run it on mount only in order to open a cell that has been double clicked. The value check is so that empty cells go to edit mode instead of previewing nothing
  useEffect(() => {
    if (value && value.length) {
      handleOnClick();
    }
  }, []);

  return (
    <div
      onClick={handleOnClick}
      style={{
        width: props.column.width,
        height: props.rowHeight,
      }}
    >
      {value &&
        Array.isArray(value) &&
        value.length > 0 &&
        `(${value.length}) ${value.length === 1 ? 'photo' : 'photos'}`}
    </div>
  );
});
