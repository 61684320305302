import * as React from 'react';
import { useMemo } from 'react';
import { useRecordType } from '../contexts/recordTypeContext';

const getLocalStorageKeyForRecordType = ({ key, recordType }: { key: string; recordType?: string }) => {
  return `userPrefs_${recordType + '_'}${key}`;
};
const getLocalStorageKey = (key: string) => {
  return `userPrefs_${key}`;
};
const loadPref = (key: string): unknown => {
  try {
    const oldPrefString = localStorage.getItem(key);

    if (oldPrefString) {
      return JSON.parse(oldPrefString);
    }
  } catch (e) {}

  return null;
};
const savePref = (key: string, value: object): void => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {}
};

export const useUserPreferences = () => {
  const { selectedRecordType } = useRecordType();
  const setPreferenceForRecordType = React.useCallback(
    ({ key, value, recordType }: { key: string; value: object; recordType: string }) => {
      savePref(getLocalStorageKeyForRecordType({ key, recordType }), value);
    },
    []
  );
  const setPreferenceForCurrentRecordType = React.useCallback(
    (key: string, value: object) => {
      setPreferenceForRecordType({ key, value, recordType: selectedRecordType });
    },
    [selectedRecordType, setPreferenceForRecordType]
  );

  const getPreferenceForRecordType = React.useCallback(
    <T extends object>({ key, recordType }: { key: string; recordType: string }): T | null => {
      return loadPref(getLocalStorageKeyForRecordType({ key, recordType })) as T | null;
    },
    []
  );
  const getPreferenceForCurrentRecordType = React.useCallback(
    <T extends object>(key: string): T | null => {
      return getPreferenceForRecordType({ key, recordType: selectedRecordType });
    },
    [getPreferenceForRecordType, selectedRecordType]
  );
  const setPreference = React.useCallback((key: string, value: object) => {
    savePref(getLocalStorageKey(key), value);
  }, []);
  const getPreference = React.useCallback(<T extends unknown>(key: string): T | null => {
    return loadPref(getLocalStorageKey(key)) as T | null;
  }, []);
  const getPreferenceValue = React.useCallback(
    <T extends unknown>(key: string): T | null => {
      return getPreference<{ value: T }>(key)?.value ?? null;
    },
    [getPreference]
  );
  const setPreferenceValue = React.useCallback(
    <T extends unknown>(key: string, value: T) => {
      setPreference(key, { value });
    },
    [setPreference]
  );
  return useMemo(
    () => ({
      setPreferenceForCurrentRecordType,
      setPreferenceForRecordType,
      getPreferenceForRecordType,
      getPreferenceForCurrentRecordType,
      setPreference,
      getPreference,
      getPreferenceValue,
      setPreferenceValue,
    }),
    [
      getPreferenceForRecordType,
      setPreferenceForRecordType,
      getPreferenceForCurrentRecordType,
      setPreferenceForCurrentRecordType,
      setPreference,
      getPreference,
      getPreferenceValue,
      setPreferenceValue,
    ]
  );
};

export default useUserPreferences;
