import React, { useMemo, useState, useEffect, useCallback, useImperativeHandle } from 'react';
import { Project, RecordType, Role } from '../../containers/RolesContainer/types';
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client';
import {
    QUERY as ROLESQUERY,
    ADD_ROLE,
    AddRoleResponse,
    AddRoleVariables,
    QueryResult as RolesQueryResult,
} from '../../containers/RolesContainer/graphql';
import {
    mapQueryResultToData,
    convertRoleToAddMutationVariables,
} from '../../containers/RolesContainer/utils';
import { NewRoleFormData } from '../../components/UsersAndRoles/AddNewRoleDialog';
import { useProcessing } from '../../hooks/userAdministrationHooks/useProcessing';
import { useErrorDialog } from '../../contexts/errorDialogContext';
import AddNewDialog, { AddNewFormField } from '../../components/UsersAndRoles/AddNewDialog';
import magicText from 'i18next';
import { Role as UserRoles, QueryResult, UserWithoutId } from './types';
import {
    QUERY,
    ADD_USER,
    AddUserResponse,
    AddUserVariables,
} from './graphql';
import { mapQueryResultToRoles } from './utils';
import { NewUserFormData } from '../../components/UsersAndRoles/AddNewUserDialog';

export type ActionType = 'addRoles' | 'addUser';
interface props {
    refresh: () => void;
    isAddDialogOpen: boolean;
    setIsAddDialogOpen: (value: boolean) => void;
}
const CommonUserAndRoles = React.forwardRef((props: props, ref) => {
    const { refresh, isAddDialogOpen, setIsAddDialogOpen } = props;
    const { data: RolesData } = useQuery<RolesQueryResult>(ROLESQUERY, { fetchPolicy: 'network-only' });
    const { startProcessing, stopProcessing } = useProcessing({
        onComplete: refresh,
    });
    const { setErrorDialogMessage, setErrorDialogTitle } = useErrorDialog();
    const [projects, setProjects] = useState([] as readonly Project[]);
    const initialData: {
        projects: readonly Project[];
        roles: readonly Role[];
        recordTypes: readonly RecordType[];
    } = useMemo(() => mapQueryResultToData(RolesData), [RolesData]);

    useEffect(() => {
        setProjects(initialData.projects);
    }, [initialData, RolesData]);

    const getMutationOptions = <TResponse, TVariables>(): MutationHookOptions<TResponse, TVariables> => ({
        onError: (error) => {
            setErrorDialogMessage(error.message);
            setErrorDialogTitle(error.name);
        },
    });

    const [addRoles] = useMutation<AddRoleResponse, AddRoleVariables>(ADD_ROLE, getMutationOptions());
    const handleAddDone = async (role: Role) => {
        setIsAddDialogOpen(false);
        startProcessing();
        await addRoles({
            variables: convertRoleToAddMutationVariables(role, projects.length),
        });
        stopProcessing();
    };
    const handleDone = useCallback(
        async (_data: unknown) => {
            const data = _data as NewRoleFormData;
            const role: Role = {
                id: -1,
                name: data.roleTitle,
                data: data.data,
                dashboards: [],
            };
            await handleAddDone(role);
        },
        [handleAddDone]
    );
    const { data } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'network-only' });
    const roles: UserRoles[] = useMemo(() => mapQueryResultToRoles(data), [data]);
    const getMutationUserOptions = <TResponse, TVariables>(): MutationHookOptions<TResponse, TVariables> => ({
        onCompleted: stopProcessing,
        onError: (error) => {
            setErrorDialogMessage(error.message);
            setErrorDialogTitle(error.name);
        },
    });
    const [addUser] = useMutation<AddUserResponse, AddUserVariables>(ADD_USER, getMutationUserOptions());
    const handleAddDialogDone = async (user: UserWithoutId & { password: string }) => {
        setIsAddDialogOpen(false);
        startProcessing();
        await addUser({
            variables: {
                ...user,
                rolesIds: user.roles.map((role) => role.id),
            },
        });
    };
    const handleUserDone = useCallback(
        async (_data: unknown) => {
            const data = _data as NewUserFormData;
            const user: UserWithoutId & { password: string } = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                // Note that we are using the user's email as the username, which may not work for all user pools
                username: data.email,
                roles: data.roles
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    .map(({ value }) => roles.find((role) => role.id === parseInt(value))!)
                    .filter((role) => !!role),
                password: data.password,
                enabled: true,
                adminPermissions: {
                    isUserAdmin: false,
                    isRoleAndDataAdmin: false,
                    isAnalyticsUser: false,
                    isMapServicesUser: false
                },
            };
            await handleAddDialogDone(user);
        },
        [roles, handleAddDialogDone]
    );
    const fields: AddNewFormField[] = [
        { type: 'text', label: magicText.t('UsersAdministration.role_title'), required: true, name: 'roleTitle', formField: 'Roles' },
        { type: 'email', label: 'Email', required: true, name: 'email', formField: 'Users' },
        { type: 'text', label: 'First Name', required: true, name: 'firstName', formField: 'Users' },
        { type: 'text', label: 'Last Name', required: true, name: 'lastName', formField: 'Users' },
        {
            type: 'selectModal',
            label: 'Roles',
            values: roles.map((role) => ({
            label: role.name,
            value: String(role.id),
            })),
            name: 'roles',
            allSelectedMessage: magicText.t('UsersAdministration.allRoles'),
            formField: 'Users'
        },
        {
            type: 'radio',
            label: 'Is Data Role?',
            name: 'data',
            initialValue: false,
            value: false,
            options: [
                { label: 'Yes', value: true },
                { label: 'No', value: false }
            ],
            formField: 'Roles'
        },
    ];

    const handleClick = async (type: ActionType, data: unknown) => {
        if (type === 'addRoles') {
            await handleDone(data);
        } else {
            await handleUserDone(data);
        }
    };
    return (
        <>
            <AddNewDialog
                open={isAddDialogOpen}
                onDone={handleClick}
                title={magicText.t('Add')}
                fields={fields}
                onCancel={() => setIsAddDialogOpen(false)} />
        </>
    )
});
export default CommonUserAndRoles;