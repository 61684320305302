import { useContext } from 'react';
import { MapTypeProp, mapTypeSection } from '../../components/MapRightDrawer/MapTypeSection';
import { MapStyleContext, MapTypes } from '../../contexts/mapStyle';
import magicText from 'i18next';
import { useConfig } from '@terragotech/gen5-shared-components';
import Light from '../../../src/components/Map/img/Light.png';
import Dark from '../../../src/components/Map/img/dark.png';
import Saterllite from '../../../src/components/Map/img/Saterllite.png';
import Streets from '../../../src/components/Map/img/Streets.png';
import NearMap from '../../../src/components/Map/img/nearMap.png';
import HybridSatellite from '../../../src/components/Map/img/HybridSatellite.png';
import { useTheme } from '@material-ui/core';

export const useMapTypeSection = () => {
  const { setMapType: setMapStyle, mapType } = useContext(MapStyleContext);
  const { integrations } = useConfig();
  const theme = useTheme();
  const defaultMapStyles: ReadonlyArray<{ label: string; value: MapTypes, img: string }> = [
    { label: magicText.t('map.styles.light'), value: 'light', img: Light },
    { label: magicText.t('map.styles.dark'), value: 'dark', img: Dark  },
    { label: magicText.t('map.styles.satellite'), value: 'satellite', img: Saterllite  },
    { label: magicText.t('map.styles.satellite-hybrid'), value: 'satellite-hybrid', img: HybridSatellite  },
    { label: magicText.t('map.styles.streets'), value: 'streets',img: Streets  },
  ];
  const mapStylesPairs: ReadonlyArray<MapTypeProp> = integrations?.nearMaps
    ? [...defaultMapStyles, { label: magicText.t('map.styles.nearMaps'), value: 'nearmaps', img: NearMap }]
    : defaultMapStyles;
  const setMapType = (label: string) => {
    const value = mapStylesPairs.find((x) => x.label === label)?.value;
    if (value) {
      setMapStyle(value);
    }
  };
  const currentMapTypeLabel = mapStylesPairs.find((x) => x.value === mapType)?.label;
  const section = mapTypeSection({
    allowedTypes: mapStylesPairs,
    currentType: currentMapTypeLabel ?? '',
    onChange: setMapType,
    theme,
  });
  return { section };
};
