import { useState, useCallback, useRef, useEffect } from 'react';
import { OperationVariables, ApolloQueryResult, QueryOptions } from '@apollo/client';
import { DocumentNode, GraphQLError } from 'graphql';
import { useApolloClient } from '@apollo/client';

export type DataBasic = Record<string, unknown>;

export interface UseLazyQueriesReturnObject<TData extends DataBasic> {
  loading: boolean;
  error: Error | ReadonlyArray<GraphQLError> | null;
  called: boolean;
  refetch: (variables: VariablesRecord, queries: Record<string, DocumentNode>) => Promise<unknown>;
  variables: VariablesRecord | null;
  data: TData | null;
  fetchRecordData: (query: DocumentNode, variables: VariablesRecord, queryKey: string) => Promise<unknown>;
}

// a keyed record of the query key to variables
export type VariablesRecord = Record<string, OperationVariables>;

export const useLazyQueries = <TData extends DataBasic>(
  //queries are a keyed record of query keys to the actual query
  queries: Record<string, DocumentNode>,
  options: Partial<QueryOptions<OperationVariables>>,
  initialData: TData
): UseLazyQueriesReturnObject<TData> => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | ReadonlyArray<GraphQLError> | null>(null);
  const [called, setCalled] = useState(false);
  const [variables, setVariables] = useState<VariablesRecord | null>(null);
  const [data, setData] = useState<TData | null>(null);
  const existing = useRef<boolean>(true);

  useEffect(() => {
    return () => {
      existing.current = false;
    };
  }, []);
  const fetchRecordData = async (query: DocumentNode, variables: VariablesRecord, queryKey: string) => {
    let fetchedData:Record<string,unknown> = {};

    await client
      .query({
        ...options,
        query: query,
        variables: variables,
      })
      .then((result) => {
        const data = result.data[queryKey];
        fetchedData[queryKey] = { data };
      })
      .catch(e => {
        console.log('fetchError', e);
      });
    return fetchedData;
  };
  const refetch = (
    refetchVariablesRecord: VariablesRecord,
    queries: Record<string, DocumentNode>
  ) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        throw new Error('Cannot refetch while waiting for results');
      }
      //Don't let any previous data taint the current query
      setData(null);
      setVariables(refetchVariablesRecord);
      // if we have at least one key to query
      if (Object.keys(refetchVariablesRecord).length > 0) {
        setLoading(true);
        //keep track of the number of query keys we're trying to query against
        const currentQueries = Object.entries(queries).map(
          ([key, query]): Promise<{ key: string; result: ApolloQueryResult<unknown> }> => {
            return client
              .query({
                fetchPolicy: 'no-cache',
                query: query,
                variables: refetchVariablesRecord,
              })
              .then(result => {
                return { key, result };
              })
              .catch(e => {
                console.log('fetch error', e);
                return {
                  key,
                  result: {
                    errors: e,
                    data: { [key]: { edges: [], pageInfo: { hasNextPage: false } } },
                    loading: false,
                    networkStatus: 7,
                  },
                };
              });
          }
        );
        Promise.all(currentQueries)
          .then(results => {
            const newData: DataBasic = {} as TData;
            results.forEach(resultRecord => {
              const { key, result } = resultRecord;
              if (result.errors) {
                setError(result.errors);
              }
              const data = (result.data as TData)[key];
              newData[key] = { data };
            });
            setLoading(false);
            resolve(newData as TData);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };

  return {
    loading,
    error,
    called,
    variables,
    data,
    refetch,
    fetchRecordData,
  };
};
