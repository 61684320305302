import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { CircularProgress, makeStyles } from '@material-ui/core';
import { colors } from '../styles/theme';

export interface RowRendererProps<DataType = unknown> {
  row: DataType;
}

interface RecyclerListProps<DataType> {
  data: DataType[];
  rowHeight: number;
  width: CSSProperties['width'];
  height: CSSProperties['height'];
  rowRenderer: (props: RowRendererProps<DataType>) => JSX.Element;
  minFetchedBufferSize?: number;
  onShouldFetchData?: () => Promise<void> | void;
  isAllDataFetched?: boolean;
  dataKey?: unknown;
  setScroll?: (scroll: number) => void;
  expectedScroll?: number;
}

const RecyclerList = <DataType extends unknown>(props: RecyclerListProps<DataType>) => {
  const {
    width,
    height,
    rowHeight,
    data,
    rowRenderer: RowRenderer,
    minFetchedBufferSize,
    onShouldFetchData,
    isAllDataFetched,
    dataKey,
  } = props;

  const classes = useStyles();

  const [scroll, setScroll] = useState(0);
  const [loading, setLoading] = useState(false);
  const containerNode = useRef<HTMLDivElement | null>(null);

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const target = event.target as HTMLDivElement;
    if (target.scrollTop !== scroll) {
      setScroll(target.scrollTop);
      if (props.setScroll) props.setScroll(target.scrollTop);
    }
  };

  useEffect(() => {
    if (props.expectedScroll !== undefined) {
      if (containerNode.current) {
        containerNode.current.scrollTop = props.expectedScroll;
      }
    }
  }, [props.expectedScroll]);

  const [size, setSize] = useState({ width: 0, height: 0 });
  const containerRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setSize({ width: node.getBoundingClientRect().width, height: node.getBoundingClientRect().height });
    }
    containerNode.current = node;
  }, []);

  useEffect(() => {
    const resizeList = () => {
      if (containerNode.current) {
        setSize({
          width: containerNode.current.getBoundingClientRect().width,
          height: containerNode.current.getBoundingClientRect().height,
        });
      }
    };
    window.addEventListener('resize', resizeList);
    return () => {
      window.removeEventListener('resize', resizeList);
    };
  }, []);

  const numberOfElementsToRender = useMemo(() => Math.ceil(size.height / rowHeight) + 1, [size, rowHeight]);
  const totalHeight = useMemo(() => rowHeight * data.length, [rowHeight, data]);

  const offsetIndex = useMemo(() => Math.floor(scroll / rowHeight), [scroll, rowHeight]);
  const beforeSpacerHeight = useMemo(() => offsetIndex * rowHeight, [offsetIndex, rowHeight]);

  useEffect(() => {
    if (minFetchedBufferSize && onShouldFetchData) {
      const bufferSize = data.length - (offsetIndex + numberOfElementsToRender);
      if (bufferSize < minFetchedBufferSize) {
        setLoading(true);
        Promise.resolve(onShouldFetchData()).then(() => setLoading(false));
      }
    }
  }, [minFetchedBufferSize, onShouldFetchData, data, offsetIndex, numberOfElementsToRender]);

  const renderedRows = useMemo(
    () =>
      new Array(numberOfElementsToRender).fill(0).map((_, index) => (
        <div style={{ width: '100%', height: `${rowHeight}px` }} key={`${dataKey}-${index}`}>
          {offsetIndex + index < data.length && <RowRenderer row={data[offsetIndex + index]} />}
        </div>
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [numberOfElementsToRender, offsetIndex, data, rowHeight, dataKey]
  );

  return (
    <div className={classes.recyclerListRoot} style={{ width, height }}>
      <div ref={containerRef} className={classes.recyclerListContainer} onScroll={handleScroll}>
        <div className={classes.recyclerListContent} style={{ height: totalHeight }}>
          <div className={classes.recyclerListGap} style={{ top: `${beforeSpacerHeight}px` }}>
            {renderedRows}
          </div>
        </div>
      </div>
      {loading && !isAllDataFetched && (
        <div className={classes.loadingBackdrop}>
          <CircularProgress color="primary" />
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(() => ({
  recyclerListRoot: {
    position: 'relative',
  },
  recyclerListContainer: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
        width: 7,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: colors.scrollBar,
      },
  },
  recyclerListContent: {
    width: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  recyclerListGap: {
    position: 'absolute',
    left: 0,
  },
  loadingBackdrop: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    backgroundColor: '#00000077',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));

export default RecyclerList;
