import successCheckEmpty from '../../images/importPage/successCheckEmpty.svg';
import successCheckFilled from '../../images/importPage/successCheckFilled.svg';
import warningCheckEmpty from '../../images/importPage/warningCheckEmpty.svg';
import warningCheckFilled from '../../images/importPage/warningCheckFilled.svg';
import errorCheckEmpty from '../../images/importPage/errorCheckEmpty.svg';
import errorCheckFilled from '../../images/importPage/errorCheckFilled.svg';

interface Props {
  successFlag: boolean;
  warningFlag: boolean;
  errorFlag: boolean;
  openSuccess: () => void;
  openWarnings: () => void;
  openErrors: () => void;
}

const ImportStatusViewer = (props: Props) => {
  const styles: { [key: string]: React.CSSProperties } = {
    StatusIconContainerStyle: {
      display: 'flex',
      alignItems: 'center',
    },
    iconStyle: {
      cursor: 'pointer',
      marginLeft: 6,
    },
    emptyIcon: {
      marginLeft: 6,
    },
  };

  return (
    <span style={styles.StatusIconContainerStyle}>
      <img
        src={!props.successFlag ? successCheckEmpty : successCheckFilled}
        alt="Status"
        onClick={props.successFlag ? props.openSuccess : () => {}}
        style={props.successFlag ? styles.iconStyle : styles.emptyIcon}
      />
      <img
        src={!props.warningFlag ? warningCheckEmpty : warningCheckFilled}
        alt="Status"
        onClick={props.warningFlag ? props.openWarnings : () => {}}
        style={props.warningFlag ? styles.iconStyle : styles.emptyIcon}
      />
      <img
        src={!props.errorFlag ? errorCheckEmpty : errorCheckFilled}
        alt="Status"
        onClick={props.errorFlag ? props.openErrors : () => {}}
        style={props.errorFlag ? styles.iconStyle : styles.emptyIcon}
      />
    </span>
  );
};

export default ImportStatusViewer;
