import React, { Dispatch, SetStateAction, useRef, useState, useEffect, useContext, useCallback } from 'react';
import clsx from 'clsx';
import {
  FormControl,
  Typography,
  Box,
  InputLabel,
  FormHelperText,
  InputBaseComponentProps,
  Tabs,
  Tab,
  Divider,
} from '@material-ui/core';
import { useStyles } from '../Common';
import { ValidationFunction } from '../../Workflow/FormHelpers';
import _ from 'lodash';
import DialogueWrapper, { ModalProps } from '../../Common/DialogueWrapper';
import { TGPointEditor } from './TGPointEditor';
import { TGMultiplePointsAdder } from './TGMultiplePointsAdder';
import { TGLineEditor } from './TGLineEditor';
import HideNonSelectableButton from '../../Map/component/HideNonSelectableButton';
import TGMultipleLocationSidebar, { CONTAINER_HEIGHT_MOB, PADDING_SPACE } from './TGMultipleLocationSidebar';
import magicText from 'i18next';
import {
  LatLon,
  TGLabelWrapperWeb,
  TGLabelWrapperProps,
  TGTextFieldRootWeb,
  FieldHeader,
  useConfig,
} from '@terragotech/gen5-shared-components';
import { makeStyles } from '@material-ui/core/styles';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { colors } from '../../../styles/theme';
import { useAlert } from '../../../contexts/AlertModalContext';
import TGLineSidebar, { measurementDataProps } from './TGLineSidebar';
import { LineString } from '@turf/helpers';
import useHideNonSelectable from '../../Map/component/useHideNonSelectable';
import { calculateMeasurement } from './measurementUtils';

const WEB_SIDEBAR_WIDTH = 400;
const MOBILE_HEADER_HEIGHT = 60;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const styles = {
  container: {
    height: '100%',
  },
};
const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, ...other }) => {
  if (value !== index) {
    return null;
  }

  return (
    <div role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other} style={styles.container}>
      {children}
    </div>
  );
};
export interface TGLocationFieldProps extends TGLabelWrapperProps {
  value: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null | undefined;
  label: string;
  placeholder: string;
  onChange: (options: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null) => void;
  readOnly?: boolean;
  validator?: ValidationFunction;
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  helperText?: string;
  multiplePoints?: { enabled: boolean; maximum: number; unlimited: boolean };
}

//to be render at 'enter manually' radion option

const ModifiedBreakPoint = MOBILE_BREAKPOINT + 1;

const useWorkflowMapStyles = makeStyles(theme => {
  const mobileBreakpoint = theme.breakpoints.down(ModifiedBreakPoint);
  return {
    mapContainerWrapper: {
      height: '100%',
      display: 'flex',
      [mobileBreakpoint]: {
        flexDirection: 'column-reverse',
      },
    },
    pointAdderContainer: {
      flex: 10,
      height: '100%',
      width: '100%',
      position: 'relative',
    },
    multiPointLocContainer: {
      height: '100%',
    },
    multiPointLocContainers: {
      height: '100%',
    },
    captureCount: {
      fontSize: 19,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 'normal',
      color: colors.black54,
      marginLeft: 14,
      marginTop: 2,
    },
    multipointRot: {
      position: 'relative',
      padding: '6px 0px 6px 0px',
      [mobileBreakpoint]: {
        padding: '0px',
        height: `calc(100% - ${PADDING_SPACE}px)`,
      },
    },
    multipointHead: {
      display: 'flex',
      height: '100%',
      [mobileBreakpoint]: {
        flexDirection: 'column-reverse',
        height: `calc(100% - ${CONTAINER_HEIGHT_MOB}px)`,
        overflowY: 'scroll',
      },
    },
    txtField: {
      marginBottom: 20,
    },
    inputFieldsContainerRoot: {
      position: 'relative',
      width: 400,
      maxWidth: 400,
      padding: '6px 0px 6px 0px',
      [mobileBreakpoint]: {
        width: 'auto',
        padding: '0px',
        overflowY: 'auto',
      },
    },
    inputFieldsContainerRoot2: {
      padding: '22px 30px',
      [mobileBreakpoint]: {
        padding: '20px 22px',
      },
    },
    mapContainer: {
      width: `calc(100% - ${WEB_SIDEBAR_WIDTH}px)`,
      position: 'relative',
      display: 'flex',
      [mobileBreakpoint]: {
        padding: 0,
        flexBasis: '100%',
        position: 'relative',
        justifyContent: 'center',
      },
    },
    mapContainerMob: {
      flexBasis: '71%',
      display: 'flex',
      [mobileBreakpoint]: {
        padding: 0,
        flexBasis: '100%',
        position: 'relative',
        justifyContent: 'center',
        height: `calc(100% - ${MOBILE_HEADER_HEIGHT}px)`,
      },
    },
    footContainer: {
      padding: '14px 30px',
      display: 'flex',
      flexDirection: 'row',
      gap: 14,
      alignItems: 'center',
      justifyContent: 'end',
      height: 39,
      position: 'absolute',
      bottom: 6,
      right: 0,
      left: 0,
      borderTop: `1px solid ${colors.black10}`,
      background: colors.white,
      [mobileBreakpoint]: {
        padding: '14px 22px',
        boxShadow: ` 0px -2px 4px 0px ${colors.black05}`,
        borderTop: 'none',
        background: colors.white,
        zIndex: 99,
        position: 'fixed',
      },
    },
  };
});

const useCoordsValidation = (manualMode: boolean) => {
  const [location, setLocation] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [latLon, setLatLon] = useState<LatLon | null>(null);
  useEffect(() => {
    if (!manualMode) {
      return setError(null);
    }
    if (location) {
      const coords = location.split(',');
      if (coords.length) {
        const lat = _.toNumber(coords[0]);
        const lon = _.toNumber(coords[1]);
        if (lat && lon) {
          if (lat >= -90 && lat <= 90 && lon >= -180 && lon <= 180) {
            setLatLon({
              latitude: lat,
              longitude: lon,
            });
            return setError(null);
          }
        }
      }
      setError(`Uh oh…This isn't a valid location`);
    }
  }, [location, manualMode]);
  return { location, setLocation, error, latLon: latLon, setLatLon: setLatLon };
};

const getLocationStringFromCoords = (location: LatLon) => {
  return `${location.latitude},${location.longitude}`;
};
const TabStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    background: colors.veryLightGrey,
    height: 42,
    minHeight: 42,
  },
  indicator: {
    top: 0,
    backgroundColor: theme.palette.primary.main,
  },
  tabRoot: {
    flex: '1 0 0',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.black75,
    padding: '12px 10px',
    background: colors.veryLightGrey,
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal',
    textTransform: 'capitalize',
    minHeight: 42,
    '&$selected': {
      color: theme.palette.primary.main,
      background: colors.white,
      fontWeight: 500,
    },
  },
  selected: {},
  divider: {
    borderColor: colors.black10,
  },
}));
//to be render at 'enter manually' radio option
interface LocationInputFieldProps {
  capturedLocation: GeoJSON.Point | GeoJSON.LineString | null | undefined;
  manualMode: boolean;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  setIsValidLocation: Dispatch<SetStateAction<boolean>>;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | GeoJSON.LineString | null | undefined>>;
  placeholder: string;
  readOnly?: boolean;
  children?: React.ReactElement;
}

const LocationInputFields: React.FC<LocationInputFieldProps> = props => {
  const {
    capturedLocation,
    manualMode,
    setManualMode,
    setCapturedLocation,
    setIsValidLocation,
    placeholder,
    readOnly,
    children,
  } = props;
  /* use hook useCoordsValidation once for each text field */
  const { location, setLocation, error, latLon, setLatLon } = useCoordsValidation(manualMode);
  const { location: reEnterLocation, setLocation: setReEnterLocation, error: reEnterError } = useCoordsValidation(
    manualMode
  );
  const locationRef = useRef<InputBaseComponentProps | null>(null);
  const reEnterLocationRef = useRef<InputBaseComponentProps | null>(null);
  const [manualEntryMatchError, setManualEntryMatchError] = useState<string>('');
  const mapStyles = useWorkflowMapStyles();

  /* validating Enter location and re enter location */
  useEffect(() => {
    if (manualMode && reEnterLocation && location && reEnterLocation !== location) {
      setManualEntryMatchError('Uh oh…This entry doesnt match');
    } else {
      setManualEntryMatchError('');
    }
  }, [location, reEnterLocation, manualMode]);

  // Set input validation
  useEffect(() => {
    if (manualMode) {
      if (error || reEnterError || manualEntryMatchError) {
        setIsValidLocation(false);
      } else {
        setIsValidLocation(true);
        if (latLon) {
          if (
            capturedLocation?.coordinates[0] !== latLon.longitude ||
            capturedLocation?.coordinates[1] !== latLon.latitude
          ) {
            setCapturedLocation({
              type: 'Point',
              coordinates: [latLon.longitude, latLon.latitude],
            });
          }
        }
      }
    } else if (capturedLocation && capturedLocation.type === 'Point') {
      const locationString = getLocationStringFromCoords({
        longitude: capturedLocation.coordinates[0],
        latitude: capturedLocation.coordinates[1],
      });
      setLocation(locationString);
      setReEnterLocation(locationString);
      setIsValidLocation(true);
    }
  }, [
    manualMode,
    error,
    reEnterError,
    manualEntryMatchError,
    setIsValidLocation,
    capturedLocation,
    latLon,
    setCapturedLocation,
    setLocation,
    setReEnterLocation,
  ]);
  const onTextInputClick = () => {
    if (!manualMode) {
      if (capturedLocation) {
        let capturedLocationLatLon: LatLon = {
          latitude: Number(capturedLocation.coordinates[0]) || 0,
          longitude: Number(capturedLocation.coordinates[1]) || 0,
        };
        setLatLon(capturedLocationLatLon);
      }
      setManualMode(true);
    }
  };

  return (
    <>
      <Box className={mapStyles.inputFieldsContainerRoot}>
        {children}
        <div className={mapStyles.inputFieldsContainerRoot2}>
          <Box className={mapStyles.txtField}>
            <TGTextFieldRootWeb
              fullWidth
              label={'Coordinates'}
              placeholder={placeholder}
              inputRef={locationRef}
              value={location}
              error={!!error}
              helperText={error}
              onChange={(x: React.ChangeEvent<HTMLInputElement>) => setLocation(x.target.value)}
              onClick={onTextInputClick}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
          <Box>
            <TGTextFieldRootWeb
              fullWidth
              label={`Re-enter Coordinates`}
              placeholder={placeholder}
              inputRef={reEnterLocationRef}
              value={reEnterLocation}
              error={!!reEnterError || !!manualEntryMatchError}
              helperText={manualEntryMatchError || reEnterError}
              onChange={(x: React.ChangeEvent<HTMLInputElement>) => setReEnterLocation(x.target.value)}
              onClick={onTextInputClick}
              InputProps={{
                readOnly,
              }}
            />
          </Box>
        </div>
      </Box>
    </>
  );
};
type LocationFieldProps = TGLocationFieldProps & {
  toggleModal: ModalProps['toggleModal'];
  locationType: string | undefined;
};
const TGLocationField: React.FC<LocationFieldProps> = props => {
  const { value, label, placeholder, onChange, readOnly, toggleModal, multiplePoints, locationType } = props;
  const [capturedLocation, setCapturedLocation] = useState<any>(value);
  const [manualMode, setManualMode] = useState<boolean>(false);
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false);
  const multiplePointsLocation: boolean | undefined =
    multiplePoints && multiplePoints?.enabled && (multiplePoints?.unlimited || multiplePoints?.maximum > 1);
  const [openAddLocationAlert, setOpenAddLocationAlert] = useState<boolean>(false);
  const mapStyles = useWorkflowMapStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { openConfirmation } = useAlert();
  const [selectedPoint, setSelectedPoint] = useState<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  const [tabValue, setValue] = React.useState<number>(0);
  const { handleHidingNonSelectableRecords, hiding, assetData, visibleAggregateTypesNames } = useHideNonSelectable();
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const isLineStringLocation = locationType === 'LineString';
  const tabStyle = TabStyles();
  const onDonePress = () => {
    if (capturedLocation) {
      onChange(capturedLocation);
    }
    toggleModal && toggleModal();
  };
  const onCancelPress = () => {
    toggleModal && toggleModal();
  };

  const selectedPointRef = useRef<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >(undefined);
  const handleOpenDialog = useCallback(async () => {
    const status = await openConfirmation({
      title: magicText.t('multiplePoints.limitReached'),
      question: magicText.t('multiplePoints.limitReachedInfo'),
      cancellationText: 'Ok',
      type: 'error',
      showSubmit: false,
    });
    if (status === 'cancel') {
      setOpenAddLocationAlert(false);
    }
  }, [openConfirmation, setOpenAddLocationAlert, magicText]);

  useEffect(() => {
    if (openAddLocationAlert) {
      handleOpenDialog();
    }
  }, [openAddLocationAlert, handleOpenDialog]);
  const RenderFieldHeader = ({ children }: { children?: React.ReactElement | null }) => (
    <FieldHeader
      type="location"
      title={label}
      canSave
      onDonePress={onDonePress}
      onCancelPress={onCancelPress}
      style={mapStyles.footContainer}
      disableCloseButton={true}
      doneButtonText="Add"
      canSaveActive={isValidLocation}
      children={children}
    />
  );
  const RenderLocationInputFields = () => (
    <LocationInputFields
      placeholder={placeholder}
      capturedLocation={capturedLocation}
      manualMode={manualMode}
      setManualMode={setManualMode}
      setIsValidLocation={setIsValidLocation}
      setCapturedLocation={setCapturedLocation}
      readOnly={readOnly}
      children={isMobileView ? <></> : <RenderFieldHeader />}
    />
  );
  const TGLocationComponent = (
    <Box className={mapStyles.pointAdderContainer}>
      <TGMultiplePointsAdder
        capturedLocation={capturedLocation}
        setCapturedLocation={setCapturedLocation}
        setIsValidLocation={setIsValidLocation}
        maximum={multiplePoints?.maximum}
        unlimited={multiplePoints?.unlimited}
        selectedPoint={selectedPoint}
        setSelectedPoint={setSelectedPoint}
        setManualMode={setManualMode}
        selectedPointRef={selectedPointRef}
        setOpenAddLocationAlert={setOpenAddLocationAlert}
        isMobileView={isMobileView}
      />
    </Box>
  );
  const TGLocationLineComponent = (
    <Box className={mapStyles.pointAdderContainer}>
      <TGLineEditor
        lineLocation={capturedLocation}
        setLineLocation={setCapturedLocation}
        setIsValidLocation={setIsValidLocation}
        isMobileView={isMobileView}
      />
    </Box>
  );
  const TGLineLocationSidebar = (
    <Box mb={0} className={mapStyles.multipointRot}>
      <TGLineSidebar header={<RenderFieldHeader />} lineString={capturedLocation} isMobileView={isMobileView} />
    </Box>
  );
  const TGSidebar = (
    <Box mb={0} className={mapStyles.multipointRot}>
      <TGMultipleLocationSidebar
        value={value}
        capturedLocation={capturedLocation}
        setCapturedLocation={setCapturedLocation}
        maximum={multiplePoints?.maximum}
        unlimited={multiplePoints?.unlimited}
        setIsValidLocation={setIsValidLocation}
        selectedPoint={selectedPoint}
        setSelectedPoint={setSelectedPoint}
        setManualMode={setManualMode}
        selectedPointRef={selectedPointRef}
        setOpenAddLocationAlert={setOpenAddLocationAlert}
        children={
          isMobileView ? (
            <></>
          ) : (
            <RenderFieldHeader
              children={
                <Typography className={mapStyles.captureCount}>
                  {Array.isArray(capturedLocation) ? capturedLocation.length : 0}/
                  {multiplePoints?.unlimited ? '∞' : multiplePoints?.maximum}
                </Typography>
              }
            />
          )
        }
      />
    </Box>
  );
  return (
    <FormControl className={mapStyles.multiPointLocContainer}>
      {isMobileView && (
        <>
          <RenderFieldHeader
            children={
              multiplePointsLocation ? (
                <Typography className={mapStyles.captureCount}>
                  {Array.isArray(capturedLocation) ? capturedLocation.length : 0}/
                  {multiplePoints?.unlimited ? '∞' : multiplePoints?.maximum}
                </Typography>
              ) : null
            }
          />
          <Tabs
            value={tabValue}
            onChange={handleChange}
            classes={{ root: tabStyle.root, indicator: tabStyle.indicator }}
          >
            <Tab label="Map" classes={{ root: tabStyle.tabRoot, selected: tabStyle.selected }} />
            <Tab
              label={isLineStringLocation ? 'Segment' : 'Coordinates'}
              classes={{ root: tabStyle.tabRoot, selected: tabStyle.selected }}
            />
          </Tabs>
          <Divider className={tabStyle.divider} />
        </>
      )}

      {multiplePointsLocation ? (
        <Box m={0} pt={0} pl={0} pr={0} className={mapStyles.multipointHead}>
          {isMobileView ? (
            <>
              <TabPanel value={tabValue} index={0}>
                {TGLocationComponent}
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                {TGSidebar}
              </TabPanel>
            </>
          ) : (
            <>
              {TGLocationComponent}
              {TGSidebar}
            </>
          )}
        </Box>
      ) : (
        <Box className={mapStyles.multiPointLocContainers}>
          <Box className={mapStyles.mapContainerWrapper}>
            {isMobileView ? (
              <>
                <TabPanel value={tabValue} index={0}>
                  {!isLineStringLocation ? (
                    <TGPointEditor
                      capturedLocation={capturedLocation}
                      setCapturedLocation={setCapturedLocation}
                      setManualMode={setManualMode}
                      setIsValidLocation={setIsValidLocation}
                      mapContainerStyle={mapStyles.mapContainerMob}
                      {...{ assetData, visibleAggregateTypesNames }}
                      children={
                        <HideNonSelectableButton
                          buttonCaption={'other records'}
                          paddingRight={13}
                          paddingBottom={12}
                          {...{ handleHidingNonSelectableRecords, hiding }}
                        />
                      }
                    />
                  ) : (
                    <>{TGLocationLineComponent}</>
                  )}
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                  {!isLineStringLocation ? <RenderLocationInputFields /> : <>{TGLineLocationSidebar}</>}
                </TabPanel>
              </>
            ) : !isLineStringLocation ? (
              <>
                <TGPointEditor
                  capturedLocation={capturedLocation}
                  setCapturedLocation={setCapturedLocation}
                  setManualMode={setManualMode}
                  setIsValidLocation={setIsValidLocation}
                  mapContainerStyle={mapStyles.mapContainer}
                  {...{ assetData, visibleAggregateTypesNames }}
                  children={
                    <HideNonSelectableButton
                      buttonCaption={'other records'}
                      paddingRight={13}
                      paddingBottom={34}
                      {...{ handleHidingNonSelectableRecords, hiding }}
                    />
                  }
                />
                <RenderLocationInputFields />
              </>
            ) : (
              <>
                {TGLocationLineComponent}
                {TGLineLocationSidebar}
              </>
            )}
          </Box>
        </Box>
      )}
    </FormControl>
  );
};

const DefaultView: React.FC<TGLocationFieldProps> = props => {
  const { label, value, required, readOnly, error, warning, helperText, info, onChange } = props;
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);
  const [measurementData, setMeasurementData] = useState<measurementDataProps | null>(null);
  const { geographic } = useConfig();
  const [locationType, setLocationType] = useState<'Point' | 'LineString' | undefined>('Point');
  useEffect(() => {
    if (!!value && !Array.isArray(value)) {
      setLocationType(value.type);
    }
  }, [value]);
  useEffect(() => {
    if (!!value && !Array.isArray(value) && value.type === 'LineString') {
      const data = calculateMeasurement(value, geographic);
      setMeasurementData(data as measurementDataProps);
    }
  }, [value, geographic]);
  const { segments = [], measurement = null, configUnits = null } = measurementData || {};

  return (
    <div onMouseEnter={() => readOnly && setTooltip(true)} onMouseLeave={() => readOnly && setTooltip(false)}>
      <DialogueWrapper
        {...props}
        clearValue={() => {
          if (!!value && !Array.isArray(value)) {
            setLocationType(value.type);
          }
          onChange(null);
        }}
        type={'location'}
        position={'top'}
        renderHeader={() => {
          return (
            <InputLabel error={error} className={clsx(warning && classes.warningText)}>
              <TGLabelWrapperWeb
                required={required}
                readOnly={readOnly}
                label={label}
                info={info}
                tooltip={tooltip}
                error={error}
              />
            </InputLabel>
          );
        }}
        errors={() => (
          <FormHelperText error={error} className={clsx(classes.error, warning && classes.warningText)}>
            {helperText}
          </FormHelperText>
        )}
        renderValue={() => {
          return (
            <>
              {!!value && !Array.isArray(value) && value.type === 'Point' && (
                <Typography className={classes.value}>{`${value.coordinates[1]}, ${value.coordinates[0]}`}</Typography>
              )}
              {!!value && Array.isArray(value) && (
                <Typography className={classes.value}>{`${value.length} location(s) selected`}</Typography>
              )}
              {!!value && !Array.isArray(value) && value.type === 'LineString' && (
                <Typography
                  className={classes.value}
                >{`${segments} Segment(s), ${measurement} ${configUnits}`}</Typography>
              )}
            </>
          );
        }}
        renderComponent={toggleModal => {
          return <TGLocationField {...props} toggleModal={toggleModal} locationType={locationType} />;
        }}
      />
    </div>
  );
};

export default DefaultView;
