import React, { FunctionComponent } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import ColumnFilterListElement, { ColumnFilterListElementProps } from './ColumnFilterListElement';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import _ from 'lodash';

interface ColumnFilterBottomListProps {
  isSelected: ColumnFilterListElementProps['isSelected'];
  setIsSelected: ColumnFilterListElementProps['setIsSelected'];
  columns: ReadonlyArray<Column<AssetType>>;
  id: string;
  togglePinned: (key: string) => void;
}

const ColumnFilterBottomList: FunctionComponent<ColumnFilterBottomListProps> = props => {
  const { columns, isSelected, setIsSelected, togglePinned, id } = props;
  const classes = useStyles();
  return (
    <Droppable droppableId={id}>
      {provided => (
        <div ref={provided.innerRef} {...provided.droppableProps}>
          <div className={classes.stickyItems}>
            {columns.map<React.ReactElement>((column, index: number) => (
              <ColumnFilterListElement
                column={column}
                index={index}
                isSelected={isSelected}
                setIsSelected={setIsSelected}
                togglePinned={togglePinned}
                key={column.key}
              />
            ))}
          </div>
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stickyItems: {
      backgroundColor: colors.white,
      minHeight: 65,
      border: `1px solid ${colors.black05}`,
      borderRadius: 3,
      position: 'relative',
      padding: 18,
      gap: 20,
      display: 'flex',
      flexDirection: 'column',
    },
  })
);
export default ColumnFilterBottomList;
