import { useCallback, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useAuthContext, useCurrentLocation, useUserInfo } from '@terragotech/gen5-shared-components';
import { v4, NIL as NIL_UUID } from 'uuid';
import { useInfoContext } from '../contexts/InfoContext/infoContext';
import magicText from 'i18next';
import { useFilter } from '../contexts/FilterContext/filterContext';
import { detect } from 'detect-browser';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import { useSelectedLocation } from '../contexts/selectedLocationContext';

export interface CommandType {
  target: string;
  aggregateType: string;
  commandName: string;
  version: number;
  commandData: object;
  metadata?: object;
  isSynchronous: boolean;
  isPublic?: boolean;
  hasNoAggregateIdCommand: boolean;
}
interface GraphQLCommandType {
  target: string;
  aggregateType: string;
  commandName: string;
  version: number;
  commandData: object;
  metadata?: object;
}

interface AuthRecord {
  username: string;
  email: string;
  givenName: string;
  familyName: string;
}

interface clientType {
  name?: string;
  version?: string;
  isMobile: boolean;
}

const useSubmitCommands = () => {
  const authContext = useAuthContext();
  const { openSnackbar } = useInfoContext();
  const { setDrawtoolFilter } = useFilter();
  const bestLocation = useCurrentLocation(); // best location is used to determine proximity
  const { roles, roleString, permissionString, permissions } = useUserInfo();
  const [clientType, setClientType] = useState<clientType>();
  const { selectedProjects } = useSelectedProject();
  const { selectedLocation } = useSelectedLocation();

  const timeOpened = new Date(); // we collect this data for analytics

  const mutation = gql`
    mutation submitCommands($commands: [Commands!]!) {
      submitCommands(commands: $commands) {
        message
      }
    }
  `;
  const [addCommands] = useMutation<{}, { commands: Array<GraphQLCommandType> }>(mutation);
  useEffect(() => {
    const browser = detect();
    if (browser) {
      const browserInformation = {
        name: browser.name,
        version: browser.version ? browser.version : 'unknown',
        isMobile: false,
      };
      setClientType(browserInformation);
    }
  }, []);

  const submitCommands = useCallback(
    (commands: Array<CommandType>) => {
      const cmdSendFunction = (auth?: AuthRecord) => {
        const { familyName, givenName, username, email } = auth || {};
        const securedCommands = commands.map(cmd => ({
          ...cmd,
          target: cmd.hasNoAggregateIdCommand ? NIL_UUID : cmd.target,
          commandId: v4(), // all commands require a uuid now.
          metadata: {
            timeFormOpened: timeOpened,
            latitude: bestLocation?.latitude,
            longitude: bestLocation?.longitude,
            selectedLatitude: selectedLocation?.lat,
            selectedLongitude: selectedLocation?.lon,
            locationAccuracy: bestLocation?.accuracy,
            userInfo: {
              familyName,
              givenName,
              userName: username,
              email,
              roles,
              roleString,
              authPayload: authContext,
              authExpiration: authContext && authContext.authExpiration,
              permissionString,
              permissions,
            },
            clientType,
            selectedProjects,
          },
          isSynchronous: undefined,
          hasNoAggregateIdCommand: undefined,
        }));
        return addCommands({ variables: { commands: securedCommands as any } })
          .then(result => {
            setDrawtoolFilter(false);
            openSnackbar({ title: magicText.t('action completed successfully'), type: 'SUCCESS' });
            return result;
          })
          .catch(e => {
            openSnackbar({ title: magicText.t('action failed'), type: 'ERROR' });
            throw e;
          });
      };
      const { familyName, givenName, username, email } = authContext;
      if (!familyName || !givenName) console.error('No family or given name. This error does not crash the app.');
      return Promise.resolve(
        cmdSendFunction({
          familyName: familyName ?? '',
          givenName: givenName ?? '',
          username: username ?? '',
          email: email ?? '',
        })
      );
    },
    [
      authContext,
      addCommands,
      timeOpened,
      bestLocation?.latitude,
      bestLocation?.longitude,
      bestLocation?.accuracy,
      roles,
      roleString,
      permissionString,
      permissions,
      clientType,
      setDrawtoolFilter,
      openSnackbar,
    ]
  );

  return {
    submitCommands,
  };
};

export default useSubmitCommands;
