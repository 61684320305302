import { Box, Button, Divider, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import React, { FunctionComponent, ReactNode, useRef } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import magicText from 'i18next';
import { colors } from '../styles/theme';
import { USERS_AND_ROLES_Z_INDEX } from '../utils/layers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsQuestionCheck } from '@fortawesome/pro-light-svg-icons';
import { END_USER_AGREEMENT_URL, MOBILE_BREAKPOINT, MOBILE_HEADER_HEIGHT } from '../utils/utilityHelper';
import AppLogo from './AppLogo';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const LeftBarWithPadding = 170;
const MobileScreenPadding = 60;
const ModifiedBreakPoint = MOBILE_BREAKPOINT + 1;

const styles = (theme: Theme) => {
  const mobileBreakpoint = theme.breakpoints.down(ModifiedBreakPoint);
  return createStyles({
    root: {
      zIndex: USERS_AND_ROLES_Z_INDEX,
      position: 'fixed',
      width: `calc(100vw - ${LeftBarWithPadding}px)`,
      height: '100vh',
      backgroundColor: colors.white,
      padding: '0px 50px',
      overflow: 'auto',
      left: 70,
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      [mobileBreakpoint]: {
        left: 0,
        width: `calc(100vw - ${MobileScreenPadding}px)`,
        padding: '0px 32px 0px 28px',
        height: `calc(100vh - ${MOBILE_HEADER_HEIGHT}px)`,
      },
    },
    logoHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 42,
      marginBottom: 26,
      [mobileBreakpoint]: {
        flexDirection: 'column',
        marginBottom: 22,
        marginTop: 20,
      },
    },
    logoContainer: {
      display: 'flex',
      gap: 14,
      [mobileBreakpoint]: {
        flexDirection: 'column',
        gap: 21,
      },
    },
    divider: {
      backgroundColor: colors.black10,
    },
    appNameContainer: {
      gap: 8,
      display: 'flex',
      flexDirection: 'column',
    },
    appName: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: '100%',
      [theme.breakpoints.up(ModifiedBreakPoint)]: {
        marginTop: 9,
      },
    },
    copyright: {
      color: colors.black54,
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '100%',
    },
    detailContainer: {
      marginTop: 38,
      display: 'flex',
      flexDirection: 'column',
      gap: 35,
      [mobileBreakpoint]: {
        marginTop: 21,
        gap: 27,
      },
    },
    detail: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
      [mobileBreakpoint]: {
        gap: 10,
      },
    },
    detailLabel: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '100%',
      [mobileBreakpoint]: {
        fontSize: 14,
      },
    },
    detailValue: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '100%',
      [mobileBreakpoint]: {
        fontSize: 16,
      },
    },
    agreementContainer: {
      border: `1px solid ${colors.black10} `,
      marginTop: 15,
      backgroundColor: colors.soapStone,
      borderRadius: 3,
      padding: '14px 11px 14px 14px',
      [mobileBreakpoint]: {
        marginTop: 14,
        padding: '11px 11px 14px 14px',
      },
    },
    agreementTitle: {
      fontSize: 15,
      fontWeight: 400,
      lineHeight: '100%',
      marginBottom: 3,
    },

    logo: {
      borderRadius: '50%',
      height: 60,
      width: 60,
      backgroundColor: colors.white,
      [mobileBreakpoint]: {
        height: 55,
        width: 55,
      },
    },
    supportBtn: {
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 34,
      color: theme.palette.primary.main,
      fontSize: 17,
      fontWeight: 400,
      padding: '8px 15px',
      height: 38,
      marginTop: 9,
      textTransform: 'none',
      '& .MuiButton-startIcon': {
        marginRight: 10,
      },
      [mobileBreakpoint]: {
        width: 119,
        height: 42,
        padding: '10px 14px',
        marginTop: 21,
        fontSize: 15,
        fontWeight: 500,
      },
    },
    link: {
      color: theme.palette.primary.main,
    }
  });
};

interface AboutMenuItemModalProps extends WithStyles<typeof styles> {
  openAbout: boolean;
  closeAboutModal: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AboutMenuItemModal: FunctionComponent<AboutMenuItemModalProps> = props => {
  let uiConfig = useConfig();
  const boxRef = useRef(null);
  const details = [
    {
      label: 'App Version',
      value: `V${uiConfig.appInfo?.version}`,
    },
    {
      label: 'CI Build Time:',
      value: uiConfig.appInfo?.ciBuildtimestamp,
    },
    {
      label: 'Commit:',
      value: uiConfig.appInfo?.commit,
    },
    {
      label: 'CI Build Number',
      value: uiConfig.appInfo?.ciBuildNumber,
    },
  ];

  return (
    <div className={props.classes.root} ref={boxRef}>
      <Box className={props.classes.logoHeader}>
        <Box className={props.classes.logoContainer}>
          <AppLogo className={props.classes.logo} />
          <Box className={props.classes.appNameContainer}>
            <Typography className={props.classes.appName}>{uiConfig && uiConfig.applicationName}</Typography>
            <Typography className={props.classes.copyright}>
              {magicText.t('Settings.about.copyRight', { year: new Date().getFullYear() }) as ReactNode}
            </Typography>
          </Box>
        </Box>
        <Button
          className={props.classes.supportBtn}
          href={magicText.t('Settings.about.supportLink')}
          target="_blank"
          startIcon={<FontAwesomeIcon icon={faCommentsQuestionCheck as IconProp} />}
        >
          {magicText.t('Settings.about.support') as string}
        </Button>
      </Box>
      <Divider className={props.classes.divider} />
      <Box className={props.classes.detailContainer}>
        {details.map((detail, index) => (
          <Box className={props.classes.detail} key={index}>
            <Typography className={props.classes.detailLabel}>{detail.label}</Typography>
            <Typography className={props.classes.detailValue}>{detail.value}</Typography>
          </Box>
        ))}
        <Box>
          <Typography className={props.classes.agreementTitle}>End User License Agreement</Typography>
          <a className={props.classes.link} href={END_USER_AGREEMENT_URL} target="_blank" rel="noopener noreferrer">{END_USER_AGREEMENT_URL}</a>
        </Box>
      </Box>
    </div>
  );
};

export default withStyles(styles)(AboutMenuItemModal);
