import React, { useCallback, useMemo } from 'react';
import TGOptionLazyModal from './TGOptionLazyModal/TGOptionLazyModal';
import { getValue } from './getterUtils';
import { isNotUndefined, useLazyArrayDataProvider, useSearch } from '@terragotech/gen5-shared-components';

type BaseValueType = string | number;
export type ValueType = BaseValueType[] | BaseValueType | undefined;

export interface OptionObject {
  [key: string]: unknown;
}

export type Option = OptionObject;
// advance option to override the default filter function.
export type OnFilterChangeCallbackType = ({ filteredOptions }: { filteredOptions: Option[] }) => void;
export type OnFilterChangeType = (filter: string, callback: OnFilterChangeCallbackType) => void;

export interface TGOptionSelectorProps {
  value: ValueType;
  options: Option[];
  pathToLabel?: string;
  pathToValue?: string;
  multiSelect?: boolean;
  filterPlaceHolder: string;
}

export interface TGOptionSelectorModalProps extends TGOptionSelectorProps {
  title: string;
  open: boolean;
  onCancel: () => void;
  onDone: (value: ValueType) => void;
  setOpen: (open: boolean) => void;
}
/**
 * Modal dialog option selector where options are a static array of values
 * @param props
 */
const TGOptionSelectorModal: React.FC<TGOptionSelectorModalProps> = props => {
  const { options, value, onDone, pathToValue, ...modalProps } = props;

  const arrayValue = useMemo(() => {
    const arrayValues = (() => {
      if (value === undefined) {
        return [];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [value];
    })();
    return arrayValues.map(x => options.find(y => x === getValue(y, pathToValue))).filter(isNotUndefined);
  }, [value, pathToValue, options]);

  const { fetchMoreData, fetchAllData, fetchedLength, currentData } = useLazyArrayDataProvider({
    data: options,
    recordsPerPage: 50,
  });

  const { searchText, handleSearchText } = useSearch();

  const handleDone = useCallback(
    (value: OptionObject[]) => {
      if (props.multiSelect) {
        onDone(value.map(val => getValue(val, pathToValue)));
      } else {
        onDone(value.length > 0 ? getValue(value[0], pathToValue) : undefined);
      }
    },
    [props.multiSelect, onDone, pathToValue]
  );

  return (
    <TGOptionLazyModal<Option>
      {...modalProps}
      onDone={handleDone}
      value={arrayValue}
      data={currentData.slice(0, fetchedLength)}
      fetchMoreData={fetchMoreData}
      refetch={(_, onUpdate) => {
        fetchAllData();
        onUpdate && onUpdate(currentData);
      }}
      searchText={searchText}
      handleSearchText={handleSearchText}
      isAllLoaded={currentData.length <= fetchedLength}
      totalCount={currentData.length}
      pathToValue={pathToValue}
    />
  );
};

export default TGOptionSelectorModal;
