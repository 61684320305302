import TableCell, { SortDirection } from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import { CircularProgress, TableSortLabel, Typography, makeStyles } from '@material-ui/core';
import { Import, SelectedImportType } from './ImportHistory';
import { colors } from '../../styles/theme';
import { Dispatch, SetStateAction, useMemo } from 'react';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { Props as DataProps } from './PendingJobRow';
import _ from 'lodash';

type SortConfig = {
  [key: string]: SortDirection | undefined;
};

interface Props {
  tableHeaders: {
    label: string;
    key: string;
  }[];
  sortOrder: SortConfig;
  handleSort: (key: string, completedTable?: boolean) => void;
  sortedJobs: Import[];
  setFileIdToView: (x: string | undefined) => void;
  selectedImport: SelectedImportType | undefined;
  setSelectedImport: Dispatch<SetStateAction<SelectedImportType | undefined>>;
  JobRow: (props: DataProps) => JSX.Element;
  emptyLabel: string;
  type: 'Pending' | 'Completed';
  loading?: boolean;
  isEmpty?: boolean;
}

const ImportTable: React.FC<Props> = ({
  tableHeaders,
  sortOrder,
  handleSort,
  sortedJobs,
  setFileIdToView,
  selectedImport,
  setSelectedImport,
  JobRow,
  emptyLabel,
  type,
  loading,
  isEmpty,
}: Props) => {
  const classes = useStyles({ loading, isEmpty });
  const EmptyView = useMemo(
    () => (
      <TableRow>
        <TableCell colSpan={tableHeaders.length} className={classes.EmptyTableCell}>
          <div className={classes.emptyWrapper}>
            <Typography className={classes.emptyLabel}>{emptyLabel}</Typography>
          </div>
        </TableCell>
      </TableRow>
    ),
    [emptyLabel, classes.emptyLabel, classes.emptyWrapper, tableHeaders]
  );
  return (
    <>
      <Typography
        className={[classes.label, type === 'Completed' ? classes.completed : classes.pending].join(' ')}
      >{`${type} Jobs`}</Typography>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead className={classes.tableHead}>
            <TableRow className={classes.tableHeaderRow}>
              {tableHeaders.map((header, index) => (
                <TableCell
                  key={index}
                  className={`${classes.cellHeaderFontStyle} ${index === 0 ? classes.firstColumnStyle : ''}`}
                  sortDirection={sortOrder[header.key]}
                >
                  {header.key !== 'actions' ? (
                    <TableSortLabel
                      className={classes.tableHeaderWithSortIcon}
                      active={sortOrder[header.key] ? true : false}
                      direction={sortOrder[header.key] as 'asc' | 'desc' | undefined}
                      onClick={() => handleSort(header.key, _.isEqual(type, 'Completed'))}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    <Typography className={classes.tableHeaderFont}>{header.label}</Typography>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {loading ? (
              <TableRow>
                <TableCell colSpan={tableHeaders.length} className={classes.EmptyTableCell}>
                  <div className={classes.spinner}>
                    <CircularProgress color="primary" />
                  </div>
                </TableCell>
              </TableRow>
            ) : isEmpty && sortedJobs.length === 0 ? (
              EmptyView
            ) : (
              sortedJobs.length !== 0 &&
              sortedJobs.map((job: any, index: number) => {
                const propData = {
                  selectedImport,
                  setSelectedImport,
                  imp: sortedJobs,
                  setFileIdToView,
                };
                return <JobRow data={propData} index={index} key={index} />;
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  tableContainer: (props: { loading?: boolean; isEmpty?: boolean }) => ({
    width: '100%',
    overflow: 'auto',
    border: `1px solid ${colors.black05}`,
    boxShadow: 'none',
    ...(props.loading || props.isEmpty ? {} : { flex: 1 }),
    minHeight: 100,
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 7,
      backgroundColor: colors.silver,
    },
  }),
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  EmptyTableCell: {
    height: 70,
    padding: 0,
    borderBottom: 'none',
    backgroundColor: colors.lotion,
  },
  tableHead: {
    height: 38,
    top: 0,
    left: 0,
    zIndex: 2,
    position: 'sticky',
  },
  tableHeaderRow: {
    backgroundColor: colors.whisper,
  },
  tableHeaderWithSortIcon: {
    height: 17,
    gap: 10,
    '& .MuiTableSortLabel-icon': {
      marginLeft: 0,
    },
  },
  tableBody: {
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 5,
      height: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 7,
      backgroundColor: colors.silver,
    },
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tableFlexContainer: {
    width: '99%',
    display: 'flex',
  },
  firstColumnStyle: {
    paddingLeft: '30px !important',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      paddingLeft: `24px !important`,
    },
  },
  tableHeaderFont: {
    fontSize: 15,
    fontWeight: 500,
    height: 17,
  },
  cellHeaderFontStyle: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 500,
    padding: '10px 12px',
    lineHeight: 'normal',
    color: colors.black0,
  },

  emptyWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 20,
    marginBottom: 15,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      fontSize: 18,
    },
  },
  completed: {
    marginTop: 35,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      marginTop: 24,
    },
  },
  pending: {
    marginTop: 12,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      marginTop: 4,
    },
  },
  emptyLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 15,
    lineHeight: 'normal',
    color: colors.black54,
    [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
      fontSize: 14,
    },
  },
}));

export default ImportTable;
