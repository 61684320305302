import { useState, useCallback, useMemo } from 'react';

interface UseProcessingArgs {
  onComplete: () => void;
}

export const useProcessing = ({ onComplete }: UseProcessingArgs) => {
  const [value, setValue] = useState(0);

  const startProcessing = useCallback(() => {
    setValue((prev) => prev + 1);
  }, [setValue]);

  const stopProcessing = useCallback(() => {
    setValue((prev) => {
      if (prev === 1 && onComplete) {
        setTimeout(() => {
          onComplete();
        });
      }
      return prev - 1;
    });
  }, [setValue, onComplete]);

  const isProcessing = useMemo(() => value > 0, [value]);

  return {
    isProcessing,
    startProcessing,
    stopProcessing,
  };
};
