import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { colors } from '../../styles/theme';
import clsx from 'clsx';

const useStyles = makeStyles({
    filterIcon: {
        cursor: 'pointer',
        height: 11,
        width: 13,
        color: colors.black35,
    },
    focusButton: {
        padding: 0,
    },
    redCircle: {
        width: '10px',
        height: '10px',
        borderRadius: '30px',
        border: `1.5px solid ${colors.white}`,
        backgroundColor: colors.pureRed,
        position: 'absolute',
        bottom: 5,
        left: 7,
    },
});

interface ActiveFilterIconProps {
    onClick: () => void;
    showIndicator?: boolean;
}

const ActiveFilterIcon: React.FC<ActiveFilterIconProps> = ({ onClick, showIndicator = false }) => {
    const classes = useStyles();

    return (
        <IconButton onClick={onClick} className={classes.focusButton}>
            <FontAwesomeIcon icon={faFilter as IconProp} className={clsx(classes.filterIcon)} />
            {showIndicator && <div className={classes.redCircle} />}
        </IconButton>
    );
};

export default ActiveFilterIcon;
