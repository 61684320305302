import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { MapAssetType } from '../types';

export const filterByQuickSearch = (assets: MapAssetType[], getQuickSearch: (key: string) => string) => {
  return assets.filter(asset => {
    const quickSearch = getQuickSearch(asset.recordTypeKey);
    if (quickSearch) {
      const includedVals = Object.values(asset).filter(value => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(quickSearch.toLowerCase());
        } else if (typeof value === 'object') {
          const lookupValue = (value as { label?: string })?.label ?? '';
          return lookupValue.toLowerCase().includes(quickSearch.toLowerCase());
        } else {
          return false;
        }
      });
      return includedVals.length > 0;
    }
    return true;
  });
};

export const filterByGlobalSearch = (
  assets: MapAssetType[],
  aggregateDefinitions: AggregateDefinition[],
  visibleAggregates: string[],
  searchText?: string
) => {
  if (searchText) {
    return assets.filter(asset => {
      return (
        Object.values(asset).some(value => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        }) &&
        !aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey)?.hiddenFromMapView &&
        asset.primaryLocation &&
        asset.primaryLocation.coordinates &&
        visibleAggregates.includes(asset.recordTypeKey)
      );
    });
  }
  return [];
};
