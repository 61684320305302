import { useContext, useMemo } from 'react';
import { settingsSection, SettingsItem } from '../../components/MapRightDrawer/SettingsSection';
import { DrawerItem } from '../../components/TGDrawer/TGDrawer';
import { useAccountModal } from '../../contexts/accountModalContext';
import magicText from 'i18next';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';
import { useAnalyticsModal } from '../../contexts/analyticsModalContext';
import { useImportModal } from '../../contexts/importModalContext';
import { UseConditionalImports } from '../useConditionalImports';
import { colors } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPieSimple, faCloudArrowUp, faCircleInfo, faHouse, faUserGroup } from '@fortawesome/pro-solid-svg-icons';
import { useAbout } from '../../contexts/aboutContext';
import { AssetsDashboardContext, pages } from '../../contexts/assetsDashboardContext';
import { useTheme } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface UseSettingsSection {
  section: DrawerItem;
}

export interface UseSettingsSectionArgs {
  name: string;
  email: string;
}

export const useSettingsSection = (props: UseSettingsSectionArgs): UseSettingsSection => {
  const { openUserAndRoles } = useAccountModal();
  const { openAbout } = useAbout();
  const { openImport } = useImportModal();
  const { openAnalytics } = useAnalyticsModal();
  const { buttons } = UseConditionalImports();
  const theme = useTheme();
  const { isAnalyticsUser, isAdmin } = useUserInfo();
  const { isMobileView, setCurrentPage, currentPage, setOpenDrawer } = useContext(AssetsDashboardContext);
  const uiConfig = useConfig();
  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);
  const styles = {
    menuIcon: {
      color: colors.black25,
      fontSize: 20,
    },
    activeMenuIcon: {
      color: theme.palette.primary.main,
      fontSize: 20,
    },
  };
  const handleMenuClick = (page: pages) => {
    setCurrentPage(page);
    setOpenDrawer(false);
  };
  const items: SettingsItem[] = useMemo(() => {
    const renderItems = [
      {
        title: 'Home',
        key: 'home',
        visibile: true,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faHouse} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: magicText.t('map.settings.users_and_roles'),
        key: 'usersAndRoles',
        visibile: isAdmin,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faUserGroup as IconProp} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: magicText.t('map.settings.analytics'),
        key: 'analytics',
        visibile: isAnalyticsUser && analyticsEnabled && !isMobileView,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faChartPieSimple} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: magicText.t('Settings.importText'),
        key: 'import',
        visibile: buttons && buttons.length,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faCloudArrowUp} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
      {
        title: magicText.t('map.settings.about'),
        key: 'about',
        visibile: true,
        icon: (isActive: boolean) => (
          <FontAwesomeIcon icon={faCircleInfo} style={isActive ? styles.activeMenuIcon : styles.menuIcon} />
        ),
      },
    ];
    const results: SettingsItem[] = [];

    renderItems.map(item => {
      if (item.visibile) {
        results.push({
          title: item.title,
          icon: item.icon(currentPage === item.key),
          clickHandler: () => handleMenuClick(item.key as pages),
          isActive: item.key === currentPage,
        });
      }
    });

    return results;
  }, [buttons, isAdmin, isAnalyticsUser, analyticsEnabled, openUserAndRoles, openAnalytics, openImport, currentPage]);

  const section = settingsSection({
    ...props,
    items,
  });
  return {
    section,
  };
};
