import { createStyles, Theme, makeStyles, Box } from '@material-ui/core';
import React, { FunctionComponent, useContext, useState, useEffect, useRef, ReactNode } from 'react';
import 'react-splitter-layout/lib/index.css';
import { EditModeContext } from '../../contexts/editModeContext';
import { SelectedAssetProvider } from '../../contexts/selectedAssetContext';
import './AssetsDashboard.css';
import UsersAndRoles from '../../containers/UsersAndRolesContainer';
import { useAccountModal } from '../../contexts/accountModalContext';
import { useAnalyticsModal } from '../../contexts/analyticsModalContext';
import { AggregatesContextProvider } from '../../contexts/AggregatesContext';
import { AssetsDashboardContext, pages } from '../../contexts/assetsDashboardContext';
import { ActionsExecutorProvider } from '../../hooks/useActionsExecutor';
import { AssetsTableStateContextProvider } from '../../contexts/assetsTableStateContext';
import { PhotoViewerProvider } from '../../hooks/usePhotoViewer';
import Analytics from '../../containers/AnalyticsContainer';
import { useImportModal } from '../../contexts/importModalContext';
import Import from '../../containers/ImportContainer';
import { useWatchAuthState } from '../../hooks/useAuth';
import { FileViewerProvider } from '../../hooks/useFileViewer';
import { useAuthContext, AlertDialog } from '@terragotech/gen5-shared-components';
import { LEFT_PANEL_WIDTH, MOBILE_BREAKPOINT, MOBILE_HEADER_HEIGHT } from '../../utils/utilityHelper';
import LeftPanel from '../../components/LeftPanel';
import MobileHeader from '../../components/MobileHeader';
import MobileDrawer from '../../components/MobileDrawer';
import AboutMenuItemModalContainer from '../../containers/AboutMenuItemModalContainer';
import { useAbout } from '../../contexts/aboutContext';
import { useLayoutContext } from '../../components/LayoutContext';
import Visibility from '../../components/Map/component/ControlsOverlay/VisibilityButton/Visibility';
import AssetDetailSection from '../../containers/AssetDetailSection';
import ColumnFilter from '../../components/Filters/ColumnFilter/ColumnFilter';
import { TableColumnContextProvider } from '../../contexts/TableColumnContext';
import { useRecordType } from '../../contexts/recordTypeContext';
import AssetDashboardFABContainer from '../../containers/AssetDashboardFABContainer';
import AssetLayout from './AssetLayout';
import { isEqual } from 'lodash';
import { MultiSelectContextProvider } from 'use-multiselect';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'fixed',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        flexDirection: 'column',
      },
    },
    root: {
      display: 'flex',
      minHeight: '100%',
    },
    topSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    bottomSection: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        width: '100%',
      },
    },
    leftSection: {
      display: 'flex',
      flex: 1,
    },
    topRightSection: {
      display: 'flex',
      flex: '1 1 auto',
    },
    customSplitter: {
      left: LEFT_PANEL_WIDTH,
      width: `calc(100% - ${LEFT_PANEL_WIDTH}px)`,
      '& .layout-splitter, .layout-pane:last-child': {
        zIndex: 1001,
      },
    },
    customMobileSplitter: {
      top: 64,
      height: `calc(100% - ${MOBILE_HEADER_HEIGHT}px)`,
    },
    layoutRoot: {
      padding: 0,
      maxWidth: '100%',
    },
  })
);

export type AssetsDashboardProps = {};
export const switchPointPercentage = 90;

interface leftBarMenu {
  component: ReactNode;
  pageName: pages;
}

function checkLocalStorage() {
  try {
    return parseInt(localStorage.getItem('width') || '200');
  } catch (e) {}
  return 200;
}

const pixelsToVerticalPercentage = (px: number) => (px / window.innerHeight) * 100;

const initialSideBarWidth = checkLocalStorage;

/**
 * AssetsDashboard - first component processed when a user successfully logs in
 *
 * Divides up the Dashboard.
 *
 *             top section
 *        ----------------------- (Can adjust the height by dragging the divide (SplitterLayout))
 *            bottom section
 *
 * Top section : top left (<Sidebar />) and top right (<AssetDetailSection />)
 *
 * Bottom: <AssetDetailContainer />
 *
 */

const AssetsDashboard: FunctionComponent<AssetsDashboardProps> = props => {
  const classes = useStyles();
  const [width, setWidth] = useState(initialSideBarWidth);
  const [height, setHeight] = useState((43 / 100) * window.innerHeight);
  const { isUserAndRolesOpen, closeUserAndRoles } = useAccountModal();
  const { isAboutOpen, closeAbout } = useAbout();
  const { editModeActive } = useContext(EditModeContext);
  const { isAnalyticsOpen, closeAnalytics } = useAnalyticsModal();
  const { isImportOpen, closeImport } = useImportModal();
  const {
    verticalPercentage,
    setVerticalPercentage,
    fullscreen,
    setFullscreen,
    isMobileView,
    isMapView,
    currentPage,
    setMapFabOpen,
    openDrawer,
    setOpenDrawer,
    toggleMapView,
    organizeTableOpen,
    setOrganizeTableOpen,
    setVisibilityModal,
    visibilityModal,
  } = useContext(AssetsDashboardContext);
  const { clearToken } = useAuthContext();
  const { dialogMessage, setDialogMessage } = useWatchAuthState();
  const { layout } = useLayoutContext();
  const layoutRef = useRef({ layout, verticalPercentage });
  layoutRef.current = { layout, verticalPercentage };
  const { selectedRecordType } = useRecordType();

  useEffect(() => {
    if (isImportOpen || isAnalyticsOpen || isUserAndRolesOpen || isAboutOpen) {
      setOpenDrawer(false);
    }
  }, [isImportOpen, isAnalyticsOpen, isUserAndRolesOpen, isAboutOpen]);

  const handleResize = () => {
    const { layout, verticalPercentage } = layoutRef.current;
    let vHeight = window.innerHeight;
    if (layout === 'Horizontal') {
      vHeight = (verticalPercentage / 100) * vHeight;
    }
    setHeight(vHeight);
  };

  useEffect(() => {
    handleResize();
  }, [layout]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onDragEnd = (verticalPercentage: number) => {
    if (verticalPercentage >= switchPointPercentage) {
      setVerticalPercentage(100);
    }
    setHeight((verticalPercentage / 100) * window.innerHeight);
    setFullscreen(isMobileView ? false : verticalPercentage >= switchPointPercentage);
  };

  const minimizeAndExpandTable = (verticalPercentage: number) => {
    setVerticalPercentage(verticalPercentage);
    setHeight((verticalPercentage / 100) * window.innerHeight);
    setFullscreen(verticalPercentage >= switchPointPercentage);
  };

  const openDrawerMenu = (v: boolean = true) => {
    setOpenDrawer(v);
  };
  const openVisibility = () => {
    setVisibilityModal(true);
  };

  const LeftBarMenus: leftBarMenu[] = [
    {
      component: <UsersAndRoles onBack={closeUserAndRoles} isMobileView={isMobileView} />,
      pageName: 'usersAndRoles',
    },
    {
      component: <Analytics onBack={closeAnalytics} />,
      pageName: 'analytics',
    },
    {
      component: <Import onBack={closeImport} />,
      pageName: 'import',
    },
    {
      component: <AboutMenuItemModalContainer openAbout={isAboutOpen} closeAboutModal={closeAbout} />,
      pageName: 'about',
    },
  ];

  const showFAB = (isMobileView && !visibilityModal) || !isMobileView;

  return (
    <>
      <AggregatesContextProvider>
        <AssetsTableStateContextProvider>
          <SelectedAssetProvider>
            {/** @ts-ignore */}
            <MultiSelectContextProvider>
              <PhotoViewerProvider>
                <FileViewerProvider>
                  <TableColumnContextProvider recordType={selectedRecordType}>
                    <ActionsExecutorProvider>
                      <Box className={classes.container}>
                        {isMobileView ? (
                          <>
                            {organizeTableOpen ? (
                              <ColumnFilter
                                open={organizeTableOpen}
                                setColumnFilterOpen={setOrganizeTableOpen}
                                isMobileView={isMobileView as boolean}
                              />
                            ) : (
                              <>
                                <MobileHeader
                                  {...{ openDrawerMenu, openVisibility, toggleMapView, isMapView, currentPage }}
                                />
                                <Visibility modalOpen={visibilityModal} setModalOpen={setVisibilityModal} />
                              </>
                            )}
                          </>
                        ) : (
                          <LeftPanel {...{ isAboutOpen, closeAbout }} />
                        )}
                        <AssetLayout
                          {...{
                            fullscreen,
                            classes,
                            secondaryPaneSize: verticalPercentage,
                            isMapView,
                            isMobileView,
                            editModeActive,
                            width,
                            height,
                            isVertical: layout === 'Horizontal',
                            setSecondaryPaneSize: setVerticalPercentage,
                            onDragEnd,
                            pixelsToVerticalPercentage,
                            setWidth,
                            minimizeAndExpandTable,
                          }}
                        />
                        {showFAB && (
                          <AssetDashboardFABContainer
                            isHidden={!isEqual(currentPage, 'home')}
                            setMapFabOpen={setMapFabOpen}
                            isMobileView={isMobileView}
                          />
                        )}
                        {LeftBarMenus.map(menu => (
                          <div key={menu.pageName}>{menu.pageName === currentPage && menu.component}</div>
                        ))}
                      </Box>
                      {isMobileView && <MobileDrawer {...{ open: openDrawer, toggleDrawer: openDrawerMenu }} />}
                      <AssetDetailSection />
                </ActionsExecutorProvider>
                  </TableColumnContextProvider>
                </FileViewerProvider>
              </PhotoViewerProvider>
            </MultiSelectContextProvider>
          </SelectedAssetProvider>
        </AssetsTableStateContextProvider>
      </AggregatesContextProvider>
      {dialogMessage && (
        <AlertDialog
          title={'Your session is about to expire and you must log in again.'}
          okText={'LOGOUT'}
          cancelText={'CONTINUE'}
          onOkPress={() => {
            clearToken();
            setDialogMessage('');
          }}
          onCancelPress={() => {
            setDialogMessage('');
            setTimeout(() => {
              clearToken();
            }, 1000 * 60);
          }}
        >
          {dialogMessage}
        </AlertDialog>
      )}
    </>
  );
};
export default AssetsDashboard;
