import React, { useContext } from 'react';
import {
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  ListItem,
  Divider,
  makeStyles,
  withStyles,
  Theme,
  Box,
} from '@material-ui/core';
import { colors } from '../../styles/theme';
import magicText from 'i18next';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';

export interface SettingsSectionProps {
  name: string;
  email: string;
  items: SettingsItem[];
}

export interface SettingsItem {
  title: string;
  icon: React.ReactNode;
  clickHandler: () => void;
  isActive?: boolean;
}

const NewContent = ({ items }: { items: SettingsItem[] }) => {
  const classes = useContentStyles();
  const { isMobileView } = useContext(AssetsDashboardContext);
  return (
    <>
      {items.map((menu, index) => {
        return (
          <Box
            key={menu.title}
            className={
              isMobileView
                ? classes.mobileMenuItem
                : `${classes.menuItem} ${index === 1 ? classes.menuItemRoot: ''} ${menu.isActive ? classes.activeMenuItem : ''}`
            }
            onClick={menu.clickHandler}
          >
            {!isMobileView && menu?.icon}
            <Typography
              className={
                isMobileView ? classes.menuText : `${classes.label} ${menu.isActive ? classes.activeLabel : ''}`
              }
            >
              {menu.title}
            </Typography>
          </Box>
        );
      })}
    </>
  );
};

const Menu = (props: SettingsSectionProps) => {
  const classes = useMenuStyles();
  return (
    <List>
      <ListItem>
        <ListItemText
          primary={`${props.name}`}
          secondary={
            <Typography component="span" variant="body2" style={{ color: colors.grayDescription }}>
              {props.email}
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      {props.items.map(item => (
        <MenuItem key={item.title} onClick={item.clickHandler}>
          <ListItemIcon className={classes.itemIcon}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.title} />
        </MenuItem>
      ))}
    </List>
  );
};

let NavigationIconComponent = (props: { theme: Theme }) => {
  const { theme } = props;
  return (
    <svg width="24" height="24" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.27778 0.570068C1.75963 0.570068 0.5 1.8297 0.5 3.34785V22.7923C0.5 24.3104 1.75963 25.5701 3.27778 25.5701H22.7222C24.2404 25.5701 25.5 24.3104 25.5 22.7923V3.34785C25.5 1.8297 24.2404 0.570068 22.7222 0.570068H3.27778ZM4.66667 3.34785C5.43333 3.34785 6.05556 3.97007 6.05556 4.73673C6.05556 5.5034 5.43333 6.12562 4.66667 6.12562C3.9 6.12562 3.27778 5.5034 3.27778 4.73673C3.27778 3.97007 3.9 3.34785 4.66667 3.34785ZM8.83333 3.34785C9.6 3.34785 10.2222 3.97007 10.2222 4.73673C10.2222 5.5034 9.6 6.12562 8.83333 6.12562C8.06667 6.12562 7.44444 5.5034 7.44444 4.73673C7.44444 3.97007 8.06667 3.34785 8.83333 3.34785ZM13 3.34785H22.7222V6.12562H13V3.34785ZM3.27778 8.9034H22.7222V22.7923H3.27778V8.9034Z"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};

let NavigationIcon = withStyles({}, { withTheme: true })(NavigationIconComponent);

export const settingsSection = (props: SettingsSectionProps) => ({
  icon: <NavigationIcon />,
  title: magicText.t('map.settings.header'),
  content: <NewContent items={props.items} />,
  iconMenu: <Menu {...props} />,
});

const useMenuStyles = makeStyles(theme => ({
  item: {
    padding: 0,
    cursor: 'pointer',
  },
  itemText: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: '13px',
  },
  itemIcon: { minWidth: 0, paddingRight: 10, marginBottom: 0.5 },
}));

const useContentStyles = makeStyles(theme => ({
  item: {
    padding: 0,
    cursor: 'pointer',
    marginTop: 10,
  },
  itemText: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: '13px',
  },
  itemIcon: { minWidth: 0, paddingRight: 5, marginBottom: 0.5 },

  menuItem: {
    width: 70,
    height: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '6px',
    flexShrink: 0,
  },
  menuItemRoot: {
    gap: 2,
  },
  activeMenuItem: {
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: '4px',
      backgroundColor: theme.palette.primary.main,
    },
  },
  menuIcon: {
    color: colors.black54,
  },
  label: {
    textAlign: 'center',
    fontSize: 12,
    fontFamily: 'Roboto',
    color: colors.black54,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  activeLabel: {
    color: theme.palette.primary.main,
  },
  mobileMenuItem: {
    padding: '15px 0',
    margin: '2px 0',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  menuText: {
    color: colors.black75,
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));
