//@ts-ignore
import LabelWrapper, {
  TGLabelWrapperProps,
} from '@terragotech/gen5-shared-components/dist/components/PageFields/TGPageLabelWrapper';
import React from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';

export type TGStreetViewFieldProps = TGLabelWrapperProps & { value?: any; height?: string };

const TGPageStreetViewField: React.FC<TGStreetViewFieldProps> = props => {
  const { lat, lon } = props.value || {};
  const config = useConfig();

  return lat && lon && config.googleMapsApiKey ? (
    <>
      <LabelWrapper required={props.required} readOnly={true} label={props.label} info={props.info} oneLine={false} />
      <div style={{ height: Number(props.height) || props.height || 400 }}>
        <iframe
          src={`https://www.google.com/maps/embed/v1/streetview?key=${config.googleMapsApiKey}&location=${lat},${lon}`}
          style={{ height: '100%', width: '100%' }}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

export default TGPageStreetViewField;
