import { makeStyles } from '@material-ui/core';

const HEADER_HEIGHT = 80;
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    height: `calc(100% - ${HEADER_HEIGHT}px)`,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
}));

const StyledTableEmpty = () => {
  const classes = useStyles();
  return <div className={classes.root}>No data to be shown</div>;
};

export default StyledTableEmpty;
