import React, { useState, useMemo } from 'react';
import TGOptionSelectorModal, { TGOptionSelectorModalProps } from '../Common/Modals/TGOptionSelectorModal';
import { TextField, makeStyles } from '@material-ui/core';
import ActionIcon from '../ActionIcon';
import { colors } from '../../styles/theme';

type Option = { label: string; value: string };
type SelectorModalProps = Omit<
  TGOptionSelectorModalProps,
  'open' | 'onDone' | 'onCancel' | 'filterPlaceHolder' | 'title' | 'options' | 'value' | 'setOpen'
> & {
  onChange: (value: unknown) => void;
  label: string;
  error?: string;
  options: Option[];
  value: Option[];
  allSelectedMessage: string;
};

const SelectorModal = (props: SelectorModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleCancel = () => setIsOpen(false);
  const classes = useStyles();
  const handleDone = (value: unknown) => {
    setIsOpen(false);
    const finalValue: Option[] = (value as string[]).map(value => ({
      value,
      label: props.options.find(option => option.value === value)?.label ?? value,
    }));
    props.onChange(finalValue);
  };
  const valuesArray = useMemo(() => props.value.map(option => option.value), [props.value]);
  const labelsArray = useMemo(() => props.value.map(option => option.label), [props.value]);
  return (
    <div>
      <TextField
        placeholder={`Select ${props.label.toLowerCase()}`}
        onClick={() => setIsOpen(true)}
        error={!!props.error}
        helperText={props.error}
        variant="outlined"
        className={classes.textField}
        value={labelsArray.length === props.options.length ? props.allSelectedMessage : labelsArray.join(', ')}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <div className={classes.container}>
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="13" viewBox="0 0 8 13" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1.5L1.5 0L8 6.5L1.5 13L0 11.5L5 6.5L0 1.5Z" fill="#AAAAAA" />
              </svg>
            </div>
          ),
        }}
      />
      <TGOptionSelectorModal
        {...props}
        value={valuesArray}
        open={isOpen}
        onCancel={handleCancel}
        onDone={handleDone}
        filterPlaceHolder={'Search'}
        title={props.label}
        setOpen={setIsOpen}
      />
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  textField: {
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: 0,
      fontSize: 16,
      fontFamily: 'Roboto',
      fontWeight: 400,
      color: colors.black0,
      cursor: 'pointer',
      lineHeight: 'normal',
      '&::placeholder': {
        color: colors.black35,
        opacity: 1,
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 400,
        lineHeight: 'normal',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
      borderRadius: 5,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    }
  },
  container: {position: 'absolute', right: 0, bottom: 10, cursor: 'pointer'}
}));
export default SelectorModal;
