import React, { useContext, useState } from 'react';

interface AnalyticsModalContextType {
  openAbout: () => void;
  closeAbout: () => void;
  isAboutOpen: boolean;
}

const aboutContext = React.createContext<AnalyticsModalContextType>({
  openAbout: () => {},
  closeAbout: () => {},
  isAboutOpen: false,
});

const useAbout = () => {
  const context = useContext(aboutContext);
  if (!context) {
    throw Error('useAbout must be within a UserProvider');
  }
  return context;
};

const AboutProvider = (props: { children: React.ReactNode }) => {
  const [isAboutOpen, setIsAboutOpen] = useState(false);

  const openAbout = () => {
    setIsAboutOpen(true);
  };
  const closeAbout = () => {
    setIsAboutOpen(false);
  };

  return (
    <aboutContext.Provider
      value={{
        isAboutOpen,
        openAbout,
        closeAbout,
      }}
      {...props}
    >
      {props.children}
    </aboutContext.Provider>
  );
};

export { useAbout, AboutProvider };
