import { Box, FormControl, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import distance from '@turf/distance';
import { Units } from '@turf/helpers';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';
import {
  useConfig,
  distanceDisplayString,
  round,
  TGLabelWrapper,
  TGLabelWrapperProps,
  FieldHeader,
} from '@terragotech/gen5-shared-components';
import DialogueWrapper, { ModalProps } from '../../Common/DialogueWrapper';
import HideNonSelectableButtons from '../../Map/component/HideNonSelectableButton';
import { ValidationFunction } from '../../Workflow/FormHelpers';
import { useStyles } from '../Common';
import { TGPolylineEditor } from './TGPolylineEditor';
import useHideNonSelectable from '../../Map/component/useHideNonSelectable';

export interface TGPolylineFieldProps extends TGLabelWrapperProps {
  value: GeoJSON.LineString | null | undefined;
  label: string;
  placeholder: string;
  onChange: (options: GeoJSON.LineString | null) => void;
  readOnly?: boolean;
  validator?: ValidationFunction;
  required?: boolean;
  error?: boolean;
  warning?: boolean;
  helperText?: string;
}

type LocationFieldProps = TGPolylineFieldProps & { toggleModal: ModalProps['toggleModal'] };

const TGPolylineField: React.FC<LocationFieldProps> = props => {
  const { value, label, onChange, toggleModal } = props;
  const [capturedLocation, setCapturedLocation] = useState<GeoJSON.LineString | null | undefined>(value);
  const [isValidLocation, setIsValidLocation] = useState<boolean>(false);
  const { assetData, handleHidingNonSelectableRecords, hiding } = useHideNonSelectable();

  const onDonePress = () => {
    if (capturedLocation && capturedLocation?.type === 'LineString') {
      onChange(capturedLocation);
    }
    toggleModal && toggleModal();
  };
  const onCancelPress = () => {
    toggleModal && toggleModal();
  };

  return (
    <FormControl>
      <FieldHeader title={label} canSave={isValidLocation} onDonePress={onDonePress} onCancelPress={onCancelPress} />
      <Box m={1} pt={1} pl={3} pr={3}>
        <Box mb={2}>
          <Typography>
            Click on the map to draw a polyline. Midpoints can also be clicked and dragged to create additional line
            segments.
          </Typography>
        </Box>
        <HideNonSelectableButtons buttonCaption="Other Records" paddingRight={true} {...{ handleHidingNonSelectableRecords, hiding }} />
        <TGPolylineEditor
          lineLocation={capturedLocation}
          setLineLocation={setCapturedLocation}
          setIsValidLocation={setIsValidLocation}
          assetData={assetData}          
        />
      </Box>
    </FormControl>
  );
};

const DefaultView: React.FC<TGPolylineFieldProps> = props => {
  const { label, value, required, readOnly, error, warning, helperText, info, onChange } = props;
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);

  const uiConfig = useConfig();
  const geographic = uiConfig.geographic;
  const configUnits: Units = geographic?.polylineUnitOfMeasurement ? geographic?.polylineUnitOfMeasurement : 'feet';
  const configRoundingPrecision = geographic?.polylineRoundingPrecision
    ? geographic?.polylineRoundingPrecision
    : 'ones';

  const totalDistance = useMemo(() => {
    let totalDistance = 0;
    let previousLocation: number[] | undefined;
    if (value?.coordinates) {
      value?.coordinates.forEach((location: number[]) => {
        if (previousLocation) {
          const distanceInUnits = distance(location, previousLocation, { units: configUnits });
          totalDistance += round(distanceInUnits, configRoundingPrecision);
        }
        previousLocation = location;
      });
    }
    return distanceDisplayString(totalDistance, configRoundingPrecision);
  }, [value?.coordinates, configUnits]);

  return (
    <div onMouseEnter={() => readOnly && setTooltip(true)} onMouseLeave={() => readOnly && setTooltip(false)}>
      <DialogueWrapper
        {...props}
        clearValue={() => onChange(null)}
        type={'location'}
        position={'top'}
        renderHeader={() => {
          return (
            <InputLabel error={error} className={clsx(warning && classes.warningText)}>
              <TGLabelWrapper required={required} readOnly={readOnly} label={label} info={info} tooltip={tooltip} />
            </InputLabel>
          );
        }}
        renderValue={() => {
          return (
            <>
              <Typography className={classes.value}>
                {value?.coordinates?.length
                  ? value?.coordinates?.length - 1 + ' Segment(s), ' + totalDistance + ' ' + configUnits
                  : ''}
              </Typography>
              <FormHelperText error={error} className={clsx(warning && classes.warningText)}>
                {helperText}
              </FormHelperText>
            </>
          );
        }}
        renderComponent={toggleModal => {
          return <TGPolylineField {...props} toggleModal={toggleModal} />;
        }}
      />
    </div>
  );
};

export default DefaultView;
