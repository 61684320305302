import { useCallback, useState, useMemo } from 'react';
import { useMultiSelect } from 'use-multiselect';
import { useTableColumns } from '../contexts/TableColumnContext';
import { useSearch } from '@terragotech/gen5-shared-components';
import { AssetType } from '../contexts/AggregatesContext/types';
import { Column } from './tableHooks/useColumns';

type ColumnsArray = Array<Column<AssetType>>;

export interface UseColumnFilterState {
  searchText: string;
  defaultColumns: ColumnsArray;
  areColumnsChanged: boolean;
  columns: ColumnsArray;
  areAllSelected: boolean;
  setSearchText: (text: string) => void;
  isSelected: (key: string) => boolean;
  setIsSelected: (key: string, value: boolean) => void;
  setColumns: (columns: ColumnsArray) => void;
  filterColumnsWithSearchText: (columns: ColumnsArray) => ColumnsArray;
  getAllSelectedKeys: () => string[];
}

const compareState = (column1: Column<AssetType>, column2: Column<AssetType>) =>
  column1.key === column2.key && !!column1.sticky === !!column2.sticky && !!column1.hidden === !!column2.hidden;

const areColumnArraysEqual = (array1: Array<Column<AssetType>>, array2: Array<Column<AssetType>>) =>
  array1.length === array2.length &&
  array1.reduce<boolean>((result, element, index) => result && compareState(element, array2[index]), true);

export const useColumnFilterState = (): UseColumnFilterState => {
  const { searchText, handleSearchText } = useSearch('');
  const { columns, defaultColumnsState } = useTableColumns();
  const columnsWithoutGear = columns.filter((column) => column.key !== 'gear');
  const defaultColumnsWithoutGear = defaultColumnsState.filter((column) => column.key !== 'gear');

  const defaultColumns: Column<AssetType>[] = useMemo(
    () =>
      defaultColumnsWithoutGear.map((column) => {
        const currentState = columns.find((currentColumn) => currentColumn.key === column.key)!;
        return {
          ...currentState,
          sticky: column.sticky,
          hidden: column.hidden,
        };
      }),
    [defaultColumnsWithoutGear, columns]
  );

  // Mapping is done in order to be able to detect changes
  const [localColumns, setLocalColumns] = useState<Array<Column<AssetType>>>(
    columnsWithoutGear.map((column) => ({ ...column }))
  );

  const areColumnsChanged = useMemo(() => !areColumnArraysEqual(localColumns, defaultColumns), [
    defaultColumns,
    localColumns,
  ]);

  const visibleColumnsKeys: Array<string> = columnsWithoutGear
    .filter((column) => column.hidden === true)
    .map((c) => c.key);
  const { isSelected, setSelected, getAllSelectedKeys } = useMultiSelect({
    isMultiSelectActive: true,
    allSelected: true,
    exceptions: visibleColumnsKeys || [],
  });

  const filterColumnsWithSearchText = useCallback(
    (columns: ColumnsArray) =>
      columns.sort(
        (a, b) =>
          Number(b.name.toLowerCase().includes(searchText.toLowerCase())) -
          Number(a.name.toLowerCase().includes(searchText.toLowerCase()))
      ),
    [searchText]
  );

  const allSelected = useMemo(() => {
    const filteredKeys = filterColumnsWithSearchText(columnsWithoutGear).map((column) => column.key);
    return getAllSelectedKeys(filteredKeys).length === filteredKeys.length;
  }, [getAllSelectedKeys, filterColumnsWithSearchText, columnsWithoutGear]);

  const value = useMemo(
    () => ({
      searchText,
      defaultColumns,
      areColumnsChanged,
      columns: localColumns,
      areAllSelected: allSelected,
      setSearchText: handleSearchText,
      isSelected,
      setIsSelected: setSelected,
      setColumns: setLocalColumns,
      filterColumnsWithSearchText,
      getAllSelectedKeys: () => getAllSelectedKeys(localColumns.map((column) => column.key)),
    }),
    [
      searchText,
      defaultColumns,
      areColumnsChanged,
      localColumns,
      allSelected,
      handleSearchText,
      isSelected,
      setSelected,
      setLocalColumns,
      filterColumnsWithSearchText,
      getAllSelectedKeys,
    ]
  );

  return value;
};
