import {colors} from "../../styles/theme";

const PendingJobsWarningIcon = (props: { fill: string; size: number | string }) => {
  return (
    <svg width={props.size} height={props.size} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width={props.size} height={props.size} fill={colors.platinum} />
      <g clip-path="url(#clip0_1559_0)">
        <rect width="1440" height="900" transform="translate(-135 -364)" fill="white" />
        <rect x="-42" y="-109" width="1226" height="243" rx="8" fill={colors.lotion} />
        <path
          d="M17.1993 10.1958L25.5944 1.80078V10.1958H17.1993ZM8.80424 15.7943L0.40918 24.1894V15.7943H8.80424Z"
          fill={props.fill}
        />
        <path
          d="M13.0018 0.40625C6.05748 0.40625 0.40918 6.05718 0.40918 12.9988H3.20841C3.20841 7.59714 7.6027 3.20548 13.0018 3.20548C16.8976 3.20548 20.2609 5.49838 21.8376 8.80131H24.8598C23.1257 3.91906 18.4717 0.40625 13.0018 0.40625ZM13.0018 22.7922C9.10594 22.7922 5.74267 20.4993 4.16597 17.1964H1.14375C2.87785 22.0786 7.53186 25.5914 13.0018 25.5914C19.9461 25.5914 25.5944 19.9431 25.5944 12.9988H22.7951C22.7951 18.3979 18.4008 22.7922 13.0018 22.7922Z"
          fill={props.fill}
        />
        <path
          d="M14.3027 7L14.0918 16.0264H12.2812L12.0615 7H14.3027ZM12 18.7949C12 18.4727 12.1055 18.2031 12.3164 17.9863C12.5332 17.7637 12.832 17.6523 13.2129 17.6523C13.5879 17.6523 13.8838 17.7637 14.1006 17.9863C14.3174 18.2031 14.4258 18.4727 14.4258 18.7949C14.4258 19.1055 14.3174 19.3721 14.1006 19.5947C13.8838 19.8115 13.5879 19.9199 13.2129 19.9199C12.832 19.9199 12.5332 19.8115 12.3164 19.5947C12.1055 19.3721 12 19.1055 12 18.7949Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1559_0">
          <rect width="1440" height="900" fill="white" transform="translate(-135 -364)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PendingJobsWarningIcon;
