import { RasterSource } from 'mapbox-gl';

export const rasterSourceData: RasterSource = {
    type: 'raster',
    tileSize: 256,
  };
export const wmsParams = {
    // Required
    service: 'WMS',
    version: '1.3.0',
    request: 'GetMap',
    // layers: null,  // Handled in Sources
    styles: '',  // Handled in Sources; default to empty string for compatibility with servers which require the parameter to be present.
    crs: 'EPSG:3857',
    srs: 'EPSG:3857', // Required by some APIs as alternate to crs
    bbox: '{bbox-epsg-3857}',
    width: rasterSourceData.tileSize,
    height: rasterSourceData.tileSize,
    format: 'image/png',

    // Optional
    transparent: true,
    bgcolor: null,
    exceptions: null,
    time: null,
    elevation: null,
  };

export const arcGisParams = {
    // Required
    bbox: '{bbox-epsg-3857}',

    // Optional
    bboxSR: '3857',
    // layers: null,  // Handled in Sources
    layerDefs: null,
    size: null,
    imageSR: '3857',
    historicMoment: null,
    format: 'png',
    transparent: true,
    dpi: null,
    time: null,
    timeRelation: 'esriTimeRelationOverlaps',
    layerTimeOptions: null,
    dynamicLayers: null,
    gdbVersion: null,
    mapScale: null,
    rotation: null,
    datumTransformations: null,
    layerParameterValues: null,
    mapRangeValues: null,
    layerRangeValues: null,
    clipping: null,
    spatialFilter: null,
    f: 'image', // Response Format
  };