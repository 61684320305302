import React, { useContext, useState, useCallback } from 'react';
import TGAlertDialog from '../components/TGAlertDialog';

interface ErrorDialogContextType {
  setErrorDialogMessage: (message: string | null) => void;
  setErrorDialogTitle: (title: string | null) => void;
  setOnConfirm: (callback: () => void) => void;
}

const errorDialogContext = React.createContext<ErrorDialogContextType>({
  setErrorDialogMessage: () => {},
  setErrorDialogTitle: () => {},
  setOnConfirm: () => {},
});

const useErrorDialog = () => {
  const context = useContext(errorDialogContext);
  if (!context) {
    throw Error('useErrorDialog must be used within a ErrorDialogProvider');
  }
  return context;
};

const ErrorDialogProvider = (props: { children: React.ReactNode; onConfirm: () => void }) => {
  const [errorDialogMessage, setErrorDialogMessage] = useState<string | null>(null);
  const [errorDialogTitle, setErrorDialogTitle] = useState<string | null>(null);
  const [onConfirm, setOnConfirm] = useState<(() => void) | null>(null);

  const closeDialog = useCallback(() => {
    setErrorDialogMessage(null);
    setErrorDialogTitle(null);
    setOnConfirm(null);
  }, []);

  return (
    <errorDialogContext.Provider
      value={{
        setErrorDialogMessage,
        setErrorDialogTitle,
        setOnConfirm,
      }}
    >
      {errorDialogMessage && errorDialogTitle && (
        <TGAlertDialog
          title={errorDialogTitle}
          onConfirm={() => {
            onConfirm ? onConfirm() : props.onConfirm();
            closeDialog();
          }}
          open={true}
          confirmButtonTitle={'Ok'}
        >
          {errorDialogMessage}
        </TGAlertDialog>
      )}
      {!errorDialogMessage && props.children}
    </errorDialogContext.Provider>
  );
};

export { useErrorDialog, ErrorDialogProvider };
