import { ExportToCsv } from 'ts-export-to-csv';
import { User } from '../../containers/UsersContainer/types';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';

/**
 * @param {rows} User[] - data to export
 * @yields {alaCartExportCSV} () => void - Use this to export your rowData in CSV format
 **/

const useAdminExport = (rows: User[]) => {
  const alaCartExportCSV = () => {
    const exportOptions = {
      keySeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      title: 'csvExport',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    // format data and get it ready for export
    const exportData = (rows || []).map((row: any) => {
      if (row.adminPermissions) {
        return {
          Email: row.email,
          'First Name': row.firstName,
          'Last Name': row.lastName,
          Username: row.username,
          Roles: row.roles.join(', '),
          Permissions: permissionLabel(row).join(','),
        };
      }
    });

    // handle csv export
    const csvExporter = new ExportToCsv(exportOptions);
    if (exportData && exportData.length) {
      csvExporter.generateCsv(exportData);
    }
  };

  return {
    alaCartExportCSV,
  };
};

export default useAdminExport;
