/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from 'react';
import SlideDialog from '../SlideDialog';
import SetFilterDialog from './SetFilterDialog';
import { ValueType } from '../../hooks/useTable';
import { useRecordType } from '../../contexts/recordTypeContext';

export interface SetFilterProps {
  open: boolean;
  setFilterOpen: (value: boolean) => void;
  uniqueFieldGetter: () => Promise<string[]> | ValueType[];
  displayName: string;
  field: string;
  shouldFetchLocalFields: boolean;
  fetchMoreFields: (
    field: string,
    limit?: number,
    offset?: number,
    searchText?: string
  ) => Promise<string[]> | ValueType[];
}
/**
 * SetFilter - appears at the top of each column for every asset that is not a Date
 *
 * All columns have either SetFilter or CalendarFilter
 *
 * Finds all unique values and gives option to filter by any of them, such as: Status: "Complete", etc.
 *
 * @param open
 * @param setFilterOpen
 * @param classes
 * @param field
 * @param displayName
 * @param uniqueFieldGetter
 * @param fieldData
 * @param shouldFetchLocalFields
 * @param fetchMoreFields
 */
const SetFilter: FunctionComponent<SetFilterProps> = props => {
  const { open, setFilterOpen, field, displayName, uniqueFieldGetter, shouldFetchLocalFields, fetchMoreFields } = props;
  const [fieldData, setFieldData] = useState<string[] | ValueType[]>([]);
  const [loading, setLoading] = useState(false);
  const { selectedRecordTypeDefinition } = useRecordType();
  const handleClose = () => {
    setFilterOpen(false);
  };
  const fetchFieldData = async () => {
    setLoading(true);
    const data = await uniqueFieldGetter();
    setFieldData(data);
    setLoading(false);
  };
  useEffect(() => {
    if (open) fetchFieldData();
  }, [open]);

  return (
    <SlideDialog open={open} onClose={handleClose}>
      <SetFilterDialog
        displayName={displayName}
        field={field}
        onClose={handleClose}
        valueLookupKey={shouldFetchLocalFields ? 'title' : 'label'}
        loading={loading}
        fieldData={
          selectedRecordTypeDefinition.isReferenceType || shouldFetchLocalFields
            ? (uniqueFieldGetter() as ValueType[])
            : fieldData
        }
        fetchMoreFields={fetchMoreFields}
      />
    </SlideDialog>
  );
};
export default SetFilter;
