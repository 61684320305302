import React from 'react';
import Legend, { Symbol } from '../components/Legend';
import { useConfig } from '@terragotech/gen5-shared-components';

export interface LegendContainerProps {
  symbols?: Symbol[];
  symbolsOnly?: boolean;
}

const LegendContainer: React.FunctionComponent<LegendContainerProps> = props => {
  const config = useConfig();
  return <Legend symbolsOnly={props.symbolsOnly} symbols={props.symbols || config.symbolLegend || []} />;
};

export default LegendContainer;
