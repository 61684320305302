import React, { FunctionComponent, useState, useEffect, useMemo, Dispatch, SetStateAction } from 'react';
import { withStyles, WithStyles, createStyles, Theme, ThemeProvider } from '@material-ui/core/styles';
import { FormControl, RadioGroup, FormControlLabel, Radio, Box, Typography } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { subYears } from 'date-fns';
import magicText from 'i18next';
import { colors } from '../../styles/theme';
import moment from 'moment';
import SlideDialog from '../SlideDialog';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';
import _ from 'lodash';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { calendarTheme } from '../Common/CalendarTheme';

const HEADER_FOOTER_HEIGHT = 180;

const styles = (theme: Theme) =>
  createStyles({
    dialog: {
      width: 680,
      borderRadius: 5,
      boxShadow: 'none',
    },
    calendarView: {
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        minWidth: 680,
      },
    },
    root: {
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      padding: '6px 0',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: '2px 0',
      },
    },
    body: {
      height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
      overflow: 'auto',
      padding: '22px 30px',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        padding: 22,
      },
    },
    selectBox: {
      display: 'flex',
      width: '100%',
      marginBottom: 8,
      '& .MuiFormGroup-root': {
        gap: 18,
      },
    },
    formLabel: {
      marginRight: 0,
      marginLeft: 0,
      gap: 9,
      '& .MuiTypography-root': {
        color: colors.black0,
        fontSize: 15,
        fontWeight: 400,
      },
      '& .MuiRadio-root': {
        padding: 0,
        width: 22,
        height: 22,
      },
    },
    calendars: {
      display: 'flex',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        flexDirection: 'column',
      },
    },
    calendarLabelText: {
      fontWeight: 500,
      fontSize: '16px',
      color: colors.black,
      marginBottom: 5,
    },
    calendarBlock: {
      width: 300,
      padding: '10px 5px',
      alignSelf: 'center',
    },
    clearBtn: {
      color: theme.palette.primary.main,
      fontSize: 16,
      fontWeight: 500,
      marginBottom: 18,
      cursor: 'pointer',
    },
  });

interface CalendarModalProps extends WithStyles<typeof styles> {
  open: boolean;
  setFilterOpen: any;
  displayName: any;
  setFilterState: any;
  field: any;
  filterState: any;
  filterValue: string;
  setDayFilter: Dispatch<SetStateAction<string>>;
}
/**
 * CalenderFilter - filters assets in columns with dates by selecting date range criteria, such as
 *
 * Last Month, Last Year, and Custom Date Range
 *
 * @param classes
 * @param open
 * @param setFilterOpen
 * @param displayName
 * @param field
 * @param setFilterState
 * @param filterState
 */
const CalendarModal: FunctionComponent<CalendarModalProps> = props => {
  const {
    classes,
    open,
    setFilterOpen,
    displayName,
    field,
    setFilterState,
    filterState,
    filterValue,
    setDayFilter,
  } = props;

  const currentDate = new Date();
  const [startValue, setStartDate] = useState(currentDate);
  const [endValue, setEndDate] = useState(currentDate);
  const [selectValue, setSelectValue] = useState(filterValue || 'All Time');
  const [searchValue, setSearchValue] = useState('');

  const changeStartDate = (date: any) => {
    setStartDate(moment(date).startOf('day').toDate());
  };
  const changeEndDate = (date: any) => {
    setEndDate(moment(date).endOf('day').toDate());
  };
  const handleClose = () => {
    setFilterOpen(false);
  };

  useEffect(() => {
    if (filterValue === 'Custom Date Range') {
      const [start, end] = filterState[field];
      changeStartDate(start);
      changeEndDate(end);
    }
  }, [filterValue]);

  const handleApply = () => {
    setDayFilter(selectValue);
    if (selectValue === 'All Time') {
      setFilterState((prevState: any) => {
        if (prevState[field]) {
          delete prevState[field];
          return { ...prevState };
        }
        return { ...prevState };
      });
      handleClose();
    } else {
      const values = [startValue, endValue];
      setFilterState((prevState: any) => {
        return { ...prevState, [field]: values };
      });
      handleClose();
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectValue(e.target.value as string);
    switch (e.target.value) {
      case 'Today':
        changeStartDate(currentDate);
        changeEndDate(currentDate);
        break;
      case 'Yesterday':
        changeStartDate(moment(currentDate).subtract(1, 'days').startOf('day').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'days').endOf('day').toDate());
        break;
      case 'Month to date':
        changeStartDate(moment(currentDate).startOf('month').toDate());
        changeEndDate(currentDate);
        break;
      case 'Last Month':
        changeStartDate(moment(currentDate).subtract(1, 'months').startOf('month').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'months').endOf('month').toDate());
        break;
      case 'Year to date':
        changeStartDate(moment(currentDate).startOf('year').toDate());
        changeEndDate(currentDate);
        break;
      case 'Last Year':
        changeStartDate(moment(currentDate).subtract(1, 'years').startOf('year').toDate());
        changeEndDate(moment(currentDate).subtract(1, 'years').endOf('year').toDate());
        break;
      case 'Custom Date Range':
        changeStartDate(currentDate);
        changeEndDate(currentDate);
        break;
      default:
        break;
    }
  };

  const handleFilterSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(_.toLower(e.target.value));
  };

  const DateValues = [
    {
      value: 'All Time',
      label: magicText.t('AssetTable.filters.All Time'),
    },
    {
      value: 'Today',
      label: magicText.t('AssetTable.filters.Today'),
    },
    {
      value: 'Yesterday',
      label: magicText.t('AssetTable.filters.Yesterday'),
    },
    {
      value: 'Month to date',
      label: magicText.t('AssetTable.filters.Month to date'),
    },
    {
      value: 'Last Month',
      label: magicText.t('AssetTable.filters.Last Month'),
    },
    {
      value: 'Year to date',
      label: magicText.t('AssetTable.filters.Year to date'),
    },
    {
      value: 'Last Year',
      label: magicText.t('AssetTable.filters.Last Year'),
    },
    {
      value: 'Custom Date Range',
      label: magicText.t('AssetTable.filters.Custom Date Range'),
    },
  ];
  const values = useMemo(() => {
    const newValues = [...DateValues];
    return searchValue?.length > 0 ? newValues.filter(o => _.toLower(o.label).includes(searchValue)) : newValues;
  }, [searchValue]);

  const onClear = () => {
    setSelectValue('All Time');
  };

  return (
    <SlideDialog
      open={open}
      onClose={handleClose}
      dialogClass={`${classes.dialog} ${selectValue === 'Custom Date Range' ? classes.calendarView : ''}`}
    >
      <div className={classes.root}>
        <ModalHeader {...{ displayName, onClose: handleClose, handleFilterSearch }} />
        <Box className={classes.body}>
          <Typography className={classes.clearBtn} onClick={onClear}>
            Clear Selection
          </Typography>
          <FormControl className={classes.selectBox}>
            <RadioGroup aria-label="gender" name="gender1" value={selectValue} onChange={handleSelectChange}>
              {values.map(item => {
                return (
                  <FormControlLabel
                    key={item.label}
                    className={classes.formLabel}
                    value={item.value}
                    control={<Radio color="primary" />}
                    label={item.label}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
          {selectValue === 'Custom Date Range' && (
            <div className={props.classes.calendars}>
              <ThemeProvider theme={calendarTheme}>
                <div className={props.classes.calendarBlock}>
                  <div className={props.classes.calendarLabelText}>
                    <>{magicText.t('Common.fromLabel')}</>
                  </div>
                  <DatePicker
                    orientation="portrait"
                    variant="static"
                    onChange={changeStartDate}
                    value={startValue}
                    minDate={subYears(currentDate, 70)}
                  />
                </div>
                <div className={props.classes.calendarBlock}>
                  <div className={props.classes.calendarLabelText}>
                    <>{magicText.t('Common.toLabel')}</>
                  </div>
                  <DatePicker
                    orientation="portrait"
                    variant="static"
                    onChange={changeEndDate}
                    value={endValue}
                    minDate={startValue}
                  />
                </div>
              </ThemeProvider>
            </div>
          )}
        </Box>
        <ModalFooter {...{ handleDone: handleApply, handleCancel: handleClose }} />
      </div>
    </SlideDialog>
  );
};

export default withStyles(styles)(CalendarModal);
