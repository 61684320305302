import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box } from '@material-ui/core';
import { colors } from '../../styles/theme';
import magicText from 'i18next';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import _ from 'lodash';

interface BottomBarProps {
  onDoneClick: (() => void) | undefined;
  onCancelClick: (() => void) | undefined;
  buttonText?: string;
  values?: unknown;
  type?: string;
  isRoles?: boolean | undefined;
  disableDone?: boolean;
}

const useStyles = makeStyles(theme => ({
  bottomBar: {
    display: 'flex',
    padding: '14px 30px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    gap: '10px',
    alignSelf: 'stretch',
    background: colors.white,
    boxShadow: `0px -2px 4px 0px ${colors.black05}`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '14px 22px',
    },
  },
  container: {
    display: 'flex',
    gap: 14,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      justifyContent: 'space-between',
      width: '100%',
    },
  },
  cancelbutton: {
    display: 'flex',
    padding: '10px 35px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '5px',
    border: `1px solid ${theme.palette.primary.main}`,
    background: colors.white,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    height: 39,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
    },
  },
  donebutton: {
    display: 'flex',
    padding: '10px 35px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '5px',
    background: theme.palette.primary.main,
    color: colors.white,
    border: 'none',
    textTransform: 'capitalize',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    height: 39,
    '&:hover': {
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
    },
    '&.MuiButton-outlined.Mui-disabled': {
      border: 'none',
    },
    '&.MuiButton-root.Mui-disabled': {
      background: colors.black15,
      color: colors.white
    },
  },
  radioButtonSpacer: {
    width: '68px', //width of done button
  },
}));
interface User {
  email: string;
  firstName: string;
  lastName: string;
  roles: string[];
}
interface Roles {
  roleTitle?: string;
}
const BottomBar: React.FC<BottomBarProps> = (props: BottomBarProps) => {
  const { onCancelClick, onDoneClick, buttonText = 'Submit', disableDone } = props;
  const classes = useStyles();

  return (
    <Box className={classes.bottomBar}>
      <Box className={classes.container}>
        <Button className={classes.cancelbutton} variant="outlined" onClick={onCancelClick}>
          <> {magicText.t('Common.cancelLabel')}</>
        </Button>
        {onDoneClick && (
          <Button disabled={disableDone} className={classes.donebutton} variant="outlined" onClick={onDoneClick}>
            <> {buttonText}</>
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default BottomBar;
