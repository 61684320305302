import React, { FunctionComponent, useMemo } from 'react';
import { DragDropContext, DragDropContextProps, DraggableLocation } from 'react-beautiful-dnd';
import { Column } from '../../../hooks/tableHooks/useColumns';
import { AssetType } from '../../../contexts/AggregatesContext/types';
import { ColumnFilterListElementProps } from './ColumnFilterListElement';
import {
  reorder,
  moveAndToggleSticky,
  moveDragged,
  ColumnsArray,
  PINNED_SECTION_ID,
  NOT_PINNED_SECTION_ID,
} from './utils';
import ColumnFilterTopList from './ColumnFilterTopList';
import ColumnFilterBottomList from './ColumnFilterBottomList';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';

interface ColumnFilterListsProps {
  isSelected: ColumnFilterListElementProps['isSelected'];
  setIsSelected: ColumnFilterListElementProps['setIsSelected'];
  setColumns: (columns: ColumnsArray) => void;
  columns: ReadonlyArray<Column<AssetType>>;
  filterColumnsWithSearchText: (columns: ColumnsArray) => ColumnsArray;
}

const ColumnFilterLists: FunctionComponent<ColumnFilterListsProps> = props => {
  const { columns, isSelected, setIsSelected, setColumns, filterColumnsWithSearchText } = props;

  const pinnedColumns = useMemo(() => columns.filter(column => column.sticky), [columns]);
  const notPinnedColumns = useMemo(() => columns.filter(column => !column.sticky), [columns]);

  const setLocalColumns = (pinnedColumns: ColumnsArray, notPinnedColumns: ColumnsArray) => {
    setColumns([...pinnedColumns, ...notPinnedColumns]);
  };

  const changeOrderInSameList = (source: DraggableLocation, destination: DraggableLocation) => {
    if (source.droppableId === PINNED_SECTION_ID) {
      const newPinnedColumns = reorder(pinnedColumns, source.index, destination.index);
      setLocalColumns(newPinnedColumns, notPinnedColumns);
    } else if (source.droppableId === NOT_PINNED_SECTION_ID) {
      const newNotPinnedColumns = reorder(notPinnedColumns, source.index, destination.index);
      setLocalColumns(pinnedColumns, newNotPinnedColumns);
    }
  };

  const onDragEnd: DragDropContextProps['onDragEnd'] = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      changeOrderInSameList(source, destination);
    } else {
      const result = moveDragged(pinnedColumns, notPinnedColumns, source, destination);
      setLocalColumns(result.pinned, result.notPinned);
    }
  };

  const togglePinned = (key: string) => {
    const column = columns.find(column => column.key === key);
    if (column) {
      if (column.sticky) {
        const sourceIndex = pinnedColumns.findIndex(pinnedColumn => pinnedColumn.key === column.key);
        const result = moveAndToggleSticky(pinnedColumns, notPinnedColumns, sourceIndex, 0);
        setLocalColumns(result.source, result.destination);
      } else {
        const sourceIndex = notPinnedColumns.findIndex(notPinnedColumn => notPinnedColumn.key === column.key);
        const result = moveAndToggleSticky(notPinnedColumns, pinnedColumns, sourceIndex, 0);
        setLocalColumns(result.destination, result.source);
      }
    }
  };
  const classes = useStyles();
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={classes.root}>
        <ColumnFilterTopList
          id={PINNED_SECTION_ID}
          columns={filterColumnsWithSearchText(pinnedColumns)}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          togglePinned={togglePinned}
        />
        <ColumnFilterBottomList
          id={NOT_PINNED_SECTION_ID}
          columns={filterColumnsWithSearchText(notPinnedColumns)}
          isSelected={isSelected}
          setIsSelected={setIsSelected}
          togglePinned={togglePinned}
        />
      </div>
    </DragDropContext>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 15,
    },
  })
);
export default ColumnFilterLists;
