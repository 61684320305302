import React, { useContext, useRef, useState } from 'react';
import ConfirmModal from '../components/ConfirmModal';
import { useMediaQuery } from '@material-ui/core';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';

type AlertType = 'confirmation' | 'error';

interface ConfirmProps {
  title?: string;
  description?: JSX.Element;
  question?: string;
  type?: AlertType;
  confirmationText?: string;
  cancellationText?: string;
  dialogContainerStyle?: React.CSSProperties;
  showSubmit?: boolean;
}

type ResolveType = 'confirm' | 'cancel';

interface Props {
  openConfirmation: (props: ConfirmProps) => Promise<ResolveType>;
}

interface PromiseProps {
  resolve: (value: ResolveType) => void;
  reject: () => void;
}

const AlertContext = React.createContext<Props>({
  openConfirmation: Promise.reject,
});

const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw Error('useAlert must be within a AlertProvider');
  }
  return context;
};

const AlertProvider = (props: { children: React.ReactNode }) => {
  const awaitingPromiseRef = useRef<PromiseProps>();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [title, setTitle] = useState<string>();
  const [showSubmit, setShowSubmit] = useState<boolean>(true);
  const [type, setType] = useState<AlertType>('confirmation');
  const [question, setQuestion] = useState<string>();
  const [description, setDescription] = useState<JSX.Element>();
  const [confirmationText, setConfirmationText] = useState<string>();
  const [cancellationText, setCancellationText] = useState<string>();
  const [dialogContainerStyle, setDialogContainerStyle] = useState<React.CSSProperties>();
  const isMobileView = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);

  const openConfirmation = (props: ConfirmProps): Promise<ResolveType> => {
    setTitle(props?.title);
    setDescription(props?.description);
    setType(props?.type || 'confirmation');
    setQuestion(props?.question);
    setConfirmationText(props?.confirmationText);
    setCancellationText(props.cancellationText);
    setShowSubmit(typeof props?.showSubmit === 'boolean' ? props.showSubmit : true);
    setShowConfirmDialog(true);
    setDialogContainerStyle(props?.dialogContainerStyle);
    return new Promise((resolve: (value: ResolveType) => void, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleAction = (action: ResolveType) => {
    awaitingPromiseRef.current?.resolve(action);
    setTitle(undefined);
    setDescription(undefined);
    setConfirmationText(undefined);
    setCancellationText(undefined);
    setShowConfirmDialog(false);
    setDialogContainerStyle(undefined);
  };

  return (
    <AlertContext.Provider value={{ openConfirmation }}>
      {props.children}

      <ConfirmModal
        showModal={showConfirmDialog}
        title={title}
        confirmationText={confirmationText}
        cancellationText={cancellationText}
        description={description}
        type={type}
        question={question}
        handleAction={handleAction}
        dialogContainerStyle={dialogContainerStyle}
        isMobileView={isMobileView}
        showSubmit={showSubmit}
      />
    </AlertContext.Provider>
  );
};

export { useAlert, AlertProvider };
