import React, { useMemo, CSSProperties, MouseEvent } from 'react';
import { makeStyles, CardActions, Menu, MenuItem } from '@material-ui/core';
import AssetCardAction, { Action, ASSET_CARD_ACTION_SPACING } from './AssetCardAction';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons';
import { ActionButton } from './ActionButton';

const ESTIMATED_LETTER_WIDTH = 10;
const ESTIMATED_ICON_SIZE = 16;

interface AssetCardProps {
  actions: Action[];
  style?: CSSProperties;
  className?: string;
}

const AssetCardActionsRow = (props: AssetCardProps) => {
  const { actions } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const sizes = useMemo(
    () =>
      actions.map(
        action => (action.label?.length ?? 0) * ESTIMATED_LETTER_WIDTH + ASSET_CARD_ACTION_SPACING + ESTIMATED_ICON_SIZE
      ),
    [actions]
  );

  const summedSizesToElements = useMemo(
    () =>
      sizes.reduce<{ sizes: number[]; currentSize: number }>(
        (acc, size) => ({
          currentSize: acc.currentSize + size,
          sizes: [...acc.sizes, acc.currentSize + size],
        }),
        { sizes: [], currentSize: 0 }
      ),
    [sizes]
  );

  const numberOfVisibleElements = useMemo(() => {
    const firstBiggerIndex = summedSizesToElements.sizes.findIndex(size => size > 470);
    return firstBiggerIndex === -1 ? sizes.length : firstBiggerIndex;
  }, [sizes, summedSizesToElements]);

  const areAnyActionsHidden = useMemo(() => numberOfVisibleElements < sizes.length, [numberOfVisibleElements, sizes]);

  const injectCloseMenu = (action: Action) => {
    return {
      ...action,
      onClick: () => {
        closeMenu();
        if (action.onClick) {
          action.onClick();
        }
      },
    };
  };

  const handleClickAction = (action: Action) => (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick();
  };
  const repeatClass = `repeat${numberOfVisibleElements + (areAnyActionsHidden ? 1 : 0)}` as
    | 'repeat2'
    | 'repeat3'
    | 'repeat4';

  return (
    <CardActions classes={{ root: classes.actions }} style={props.style} className={props.className}>
      <div className={`${classes.actionsButtons} ${classes[repeatClass]}`}>
        {props.actions.slice(0, numberOfVisibleElements).map(action => (
          <ActionButton
            key={action.label}
            {...{
              action: handleClickAction(action),
              style: { color: action.color },
              label: action.label,
              icon: action.icon,
            }}
          />
        ))}
        {areAnyActionsHidden && (
          <ActionButton
            {...{
              action: openMenu,
              label: 'More',
              icon: <FontAwesomeIcon icon={faEllipsisV} />,
            }}
          />
        )}
      </div>

      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={closeMenu}>
        {props.actions.slice(numberOfVisibleElements).map(action => (
          <MenuItem
            key={action.label}
            onClick={action.disabled ? undefined : injectCloseMenu(action).onClick}
            className={clsx(action.disabled && classes.disabledMenuItem, classes.menu)}
            disableRipple={action.disabled}
          >
            <AssetCardAction action={injectCloseMenu(action)} transparent />
          </MenuItem>
        ))}
      </Menu>
    </CardActions>
  );
};

const useStyles = makeStyles(theme => ({
  actions: {
    padding: 10,
    backgroundColor: 'rgb(244, 244, 244)',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
  },
  actionsButtons: {
    display: 'grid',
    paddingTop: '0px',
    gap: 10,
    width: '100%',
    gridTemplate: '1fr / 1fr',
  },
  repeat2: {
    gridTemplate: '1fr / repeat(2, 1fr)',
  },
  repeat3: {
    gridTemplate: '1fr / repeat(3, 1fr)',
  },
  repeat4: {
    gridTemplate: '1fr / repeat(4, 1fr)',
  },
  disabledMenuItem: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  moreButton: {
    height: '18px',
    width: '18px',
    marginRight: '-6px',
  },
  moreButtonIcon: {
    height: '22px',
  },
  menu: {
    '& .MuiButton-startIcon': {
      marginLeft: 0,
      marginRight: 10,
      '& > div, svg': {
        width: '15px !important',
        height: '15px !important',
      },
    },
  },
}));

export default AssetCardActionsRow;
