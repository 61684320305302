import { gql } from '@apollo/client';

export const QUERY = gql`
  {
    roles {
      id
      name
    }
    users {
      email
      firstName
      lastName
      isUserAdmin
      isRoleAndDataAdmin
      isSingleSignOnUser
      isAnalyticsUser
      isMapServicesUser
      roles {
        id
      }
      username
    }
    permissionUserStats{
      analyticsUserCount
    }
  }
`;

export interface AddRoleVariables {
  roleId: number;
  username: string;
}

export interface AddRoleResponse {
  addRoleToUser: {
    username: string;
  };
}

export const ADD_ROLE = gql`
  mutation AddRoleToUser($username: String!, $roleId: Int!) {
    addRoleToUser(roleId: $roleId, username: $username) {
      username
    }
  }
`;

export interface AddUserVariables {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  rolesIds: number[];
}

export interface AddUserResponse {
  createUser: {
    status: string;
  };
}

export const ADD_USER = gql`
  mutation AddUser($username: String!, $firstName: String!, $lastName: String!, $email: String!, $rolesIds: [Int!]!) {
    createUser(username: $username, firstName: $firstName, lastName: $lastName, email: $email, roleIds: $rolesIds) {
      status
    }
  }
`;

export interface UpdateUserVariables {
  username: string;
  userAttributes: { firstName: string; lastName: string; email: string; phoneNumber: string };
}

export interface UpdateUserResponse {
  updateUserAttributes: {
    status: string;
  };
}

export const UPDATE_USER_ATTRIBUTES = gql`
  mutation UpdateUser($username: String!, $userAttributes: UserAttribute!) {
    updateUserAttributes(username: $username, userAttributes: $userAttributes) {
      status
    }
  }
`;

export interface RemoveUserVariables {
  username: string;
}

export interface RemoveUserResponse {
  deleteUser: {
    status: string;
  };
}

export const REMOVE_USER = gql`
  mutation RemoveUser($username: String!) {
    deleteUser(username: $username) {
      status
    }
  }
`;

export interface RemoveRoleVariables {
  roleId: number;
  username: string;
}

export interface RemoveRoleResponse {
  removeRoleFromUser: {
    username: string;
  };
}

export const REMOVE_ROLE = gql`
  mutation RemoveRoleFromUser($username: String!, $roleId: Int!) {
    removeRoleFromUser(roleId: $roleId, username: $username) {
      username
    }
  }
`;

export interface UpdatePermissionsVariables {
  isRoleAndDataAdmin: boolean;
  isUserAdmin: boolean;
  isAnalyticsUser: boolean;
  isMapServicesUser: boolean;
  username: string;
}

export interface UpdatePermissionsResponse {
  status: string;
}

export const UPDATE_PERMISSIONS = gql`
  mutation SetAdminPermissions(
    $username: String!
    $isRoleAndDataAdmin: Boolean
    $isUserAdmin: Boolean
    $isAnalyticsUser: Boolean
    $isMapServicesUser: Boolean
  ) {
    setAdminPermissions(
      isRoleAndDataAdmin: $isRoleAndDataAdmin
      isUserAdmin: $isUserAdmin
      isAnalyticsUser: $isAnalyticsUser
      isMapServicesUser: $isMapServicesUser
      username: $username
    ) {
      status
    }
  }
`;
