import { Box, makeStyles } from '@material-ui/core';
import ModalHeader from '../../components/Filters/ModalHeader';
import SlideDialog from '../../components/SlideDialog';
import { ReactNode } from 'react';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import ModalFooter from '../../components/Filters/ModalFooter';

interface Props {
  open: boolean;
  handleClose: () => void;
  displayName: string;
  handleFilterSearch: (value: string) => void;
  handleApply: () => void;
  handleCancel: () => void;
  children: ReactNode;
}
const HEADER_FOOTER_HEIGHT = 180;

const PermissionModal = ({
  open,
  handleClose,
  displayName,
  handleFilterSearch,
  handleApply,
  handleCancel,
  children,
}: Props) => {
  const classes = useStyles();
  return (
    <SlideDialog open={open} onClose={handleClose} dialogClass={classes.dialog}>
      <div className={classes.root}>
        <ModalHeader
          {...{ displayName, onClose: handleClose, handleFilterSearch: e => handleFilterSearch(e.target.value) }}
        />
        <Box className={classes.body}>{children}</Box>
        <ModalFooter {...{ handleDone: handleApply, handleCancel: handleCancel, applyText: 'Ok' }} />
      </div>
    </SlideDialog>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '6px 0',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '2px 0',
    },
  },
  body: {
    height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
    overflow: 'auto',
    padding: '22px 34px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 22,
    },
  },
  dialog: {
    width: 600,
  },
}));

export default PermissionModal;
