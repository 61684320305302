import { useCallback } from 'react';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';
import { FilterState } from './types';
import { useLocallyStoredFilterValue } from './useLocallyStoredFilterValue';

export const PREFERENCE_KEY = 'filterState';

export interface UseFilterStateArgs {
  selectedRecordType: string;
  aggregateDefinitions: AggregateDefinition[];
}

const defaultValue: FilterState = {};

export const useFilterState = (props: UseFilterStateArgs) => {
  const {
    currentAggregateValue: filterState,
    getValueForRecordType: getFilterState,
    setCurrentAggregateValue: setFilterState,
  } = useLocallyStoredFilterValue<FilterState>({
    ...props,
    preferenceKey: PREFERENCE_KEY,
    defaultValue,
  });

  const resetAllFilters = useCallback(() => {
    setFilterState(defaultValue);
  }, [setFilterState]);

  return {
    resetAllFilters,
    filterState,
    getFilterState,
    setFilterState,
  };
};
