import AssetsTableContainer from '../../containers/AssetsTableContainer';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import BottomRecordTypeSelectorContainer from '../../containers/BottomRecordTypeSelectorContainer';
import { useContext } from 'react';
import { AssetsDashboardContext } from '../../contexts/assetsDashboardContext';
const MOBILE_HEADER_BAR = 51;

interface TabeViewProps {
  isMobileView: boolean;
  height: number;
  minimizeAndExpandTable?: (value: number) => void;
  classes: ClassNameMap<string>;
  isVertical?: boolean;
}

const TableView = ({ isMobileView, classes, minimizeAndExpandTable, height, isVertical = false }: TabeViewProps) => {
  const { visibilityModal } = useContext(AssetsDashboardContext);
  const showFooter = (!visibilityModal && isMobileView) || !isMobileView;

  return (
    <div className={classes.bottomSection}>
      <AssetsTableContainer
        height={isMobileView ? window.innerHeight - MOBILE_HEADER_BAR : height}
        isMobileView={isMobileView}
        isVertical={isVertical}
        minimizeAndExpandTable={minimizeAndExpandTable}
      />
      {showFooter && <BottomRecordTypeSelectorContainer />}
    </div>
  );
};

export default TableView;
