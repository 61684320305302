import { Box, makeStyles, Typography } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import distance from '@turf/distance';
import { useConfig, round, getUnitAbbreviation } from '@terragotech/gen5-shared-components';
import { LineString, Units } from '@turf/helpers';
import _ from 'lodash';
import { calculateMeasurement } from './measurementUtils';

interface Props {
  header: ReactElement;
  lineString: GeoJSON.LineString | null | undefined;
  isMobileView: boolean;
}
export interface measurementDataProps {
  segments: number;
  measurement: number;
  configUnits: Units;
  unitsAbbreviation: string;
}
const TGLineSidebar = ({ header, lineString, isMobileView }: Props) => {
  const classes = useStyles();
  const [measurementData, setMeasurementData] = useState<measurementDataProps | null>(null);
  const { geographic } = useConfig();

  useEffect(() => {
    const data = calculateMeasurement(lineString, geographic);
    setMeasurementData(data as measurementDataProps);
  }, [lineString, geographic]);

  if (!measurementData) return null;

  const { segments, measurement, unitsAbbreviation, configUnits } = measurementData;
  return (
    <Box className={classes.container}>
      {!isMobileView && header}
      <Box className={classes.innerContainer}>
        <Box className={classes.segmentContainer}>
          <Typography className={classes.txt}>Number of Segment(s)</Typography>
          <Box className={classes.badge}>
            <Typography className={classes.badgeTxt}>{segments}</Typography>
          </Box>
        </Box>
        <Box className={classes.segmentContainer}>
          <Typography className={classes.txt}>
            Total {_.capitalize(configUnits)}({unitsAbbreviation})
          </Typography>
          <Box className={classes.badge}>
            <Typography className={classes.badgeTxt}>{`${measurement} ${configUnits}`}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    width: 400,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
    },
  },
  innerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: '22px 30px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: 22,
    },
  },
  segmentContainer: {
    border: `1px solid ${colors.black10}`,
    borderRadius: 8,
    backgroundColor: colors.white,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '9px 16px',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '10px 16px',
    },
  },
  txt: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
    color: colors.black0,
  },
  badge: {
    backgroundColor: colors.ghostWhite,
    border: `1px solid ${colors.black10}`,
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px 10px',
  },
  badgeTxt: {
    fontSize: 15,
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));

export default TGLineSidebar;
