import { AuthConnector } from '@terragotech/gen5-shared-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFileExtensionFromBlob } from '../utils/fileHelper';
import { DOC_API_URL } from '../utils/docApiURL';

interface FileStatus {
  status: number;
}
const AssetDownload = () => {
  const { GUID } = useParams() as { GUID: string };
  const [fileStatus, setStatus] = useState<FileStatus | null>(null);
  const downloadFile = async () => {
    const token = await AuthConnector.getToken();
    const fileUrl = DOC_API_URL + GUID;
    const response = await fetch(fileUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: 'Bearer ' + token,
      }),
    });
    setStatus({ status: response.status });
    if (response.status !== 500) {
      const blob = await response.blob();
      const fileExtension = await getFileExtensionFromBlob(blob);
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileExtension && !GUID.includes('.') ? `${GUID}.${fileExtension}` : GUID;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };
  useEffect(() => {
    downloadFile();
  }, [GUID]);
  return (
    <div style={styles.root}>
      {fileStatus && fileStatus.status === 500 && <div style={styles.txt}>No file found</div>}
    </div>
  );
};
const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
  },
  txt: {
    fontSize: 20,
  },
};
export default AssetDownload;
