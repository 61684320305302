import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import {Link} from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		height: "35vh",
		alignItems: "center",
		justifyContent: "center"
	},
	container: {
		width: '70%',
		display: 'flex',
		alignItems: "center",
		color: "#585858"
	},

	primaryText: {
		fontSize: 54,
		fontWeight: 500,
		lineHeight: 1.5
	},
	secondaryText: {
		fontSize: 28,
		fontWeight: 400,
	},
	link: {
		color: '#56ccf2',
		fontSize: 18,
	},
}));


const UnauthorizedDisplay = () => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<div className={classes.root}>
				<div className={classes.container}>
					<div>
						<LockIcon style={{fontSize: "150px"}}/>
					</div>
					<div>
						<div className={classes.primaryText}>Sorry, no access</div>
						<div className={classes.secondaryText}>You do not have the correct permissions to access this
							view.
							<br/>
							<Link href={'https://knowledge.terragotech.com/hc/en-us'} target="_blank"
								  className={classes.link}>
								Contact Support
							</Link>
						</div>
					</div>

				</div>
			</div>

		</React.Fragment>
	);

}

export default UnauthorizedDisplay;
