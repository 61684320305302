import React, { ReactElement, MouseEvent } from 'react';
import { Typography, makeStyles, Button } from '@material-ui/core';
import clsx from 'clsx';
import TGTooltip from '../../TGTooltip';

export interface Action {
  disabled?: boolean;
  tooltipText?: string;
  label?: string;
  icon?: ReactElement;
  onClick?: () => void;
  color?: string;
}

interface AssetCardActionProps {
  action: Action;
  transparent?: boolean;
}

export const ASSET_CARD_ACTION_SPACING = 16;

const AssetCardAction = (props: AssetCardActionProps) => {
  const classes = useStyles();
  const { action } = props;

  const handleClick = (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    event.stopPropagation();
    if (action.onClick) action.onClick();
  };

  return (
    <TGTooltip title={action.tooltipText || ''}>
      <span>
        <Button
          size="small"
          className={clsx(classes.container, props.transparent ? classes.noBackground : '')}
          onClick={handleClick}
          startIcon={action.icon}
          disableRipple={props.transparent}
          disabled={action.disabled}
          style={{ color: action.color }}
        >
          <Typography className={classes.title} style={{ color: action.color }}>
            {action.label}
          </Typography>
        </Button>
      </span>
    </TGTooltip>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    marginRight: ASSET_CARD_ACTION_SPACING,
  },
  noBackground: {
    backgroundColor: 'transparent',
    '&:hover': { backgroundColor: 'transparent' },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    height: '13px',
    width: 'auto',
    marginRight: 5,
    overflow: 'hidden',
    fontSize: 13,
  },
  title: {
    fontSize: 13,
    lineHeight: '13px',
  },
}));

export default AssetCardAction;
