import { Project, RecordType, Role } from './types';
import { AddRoleVariables, QueryResult, SetMultiPermissionsVariables } from './graphql';

export interface RolesTableContainerData {
  projects: Project[];
  roles: Role[];
  recordTypes: Array<{
    name: string;
    value: string;
    toString: () => string;
  }>;
}

export const mapQueryResultToData = (data: QueryResult | undefined): RolesTableContainerData => {
  if (data) {
    const recordTypes = data.webUIConfig.aggregateDefinitions.map((aggregate) => ({
      name: aggregate.singular,
      value: aggregate.name,
      toString() {
        return this.name;
      },
    }));
    const projects = data.projects.map((project) => ({
      ...project,
      toString() {
        return this.title;
      },
    }));
    return {
      projects,
      roles: data.roles.map(
        (role): Role => {
          let temp: Role = {
            id: role.id,
            name: role.name,
            data: role.data,
            dashboards: role.dashboards ?? [],
          };
          const projectIds = projects.map((project) => project.id);
          //Now we need an entry for each aggregate type
          role.aggregateTypePermissions &&
            role.aggregateTypePermissions.forEach((agg) => {
              //and let's go ahead and resolve the ids to the actual project ids through inclusion/exclusion her in the query converter
              temp[agg.aggregateDefinition.name] = convertIncludeExcludeToProjectIds(
                projectIds,
                agg.projectPermissions
              );
            });
          return temp;
        }
      ),
      recordTypes,
    };
  }
  return {
    projects: [],
    roles: [],
    recordTypes: [],
  };
};

export const getRoleName = (role: Role) => role.name;

const convertRoleToBaseVariables = (role: Role, numberOfProjects: number): Omit<AddRoleVariables, 'roleName'> => ({
  recordTypesNames: [], //role.recordTypes.map((recordType: any) => recordType.value),
  isDataRole: true
});

export const convertRoleToAddMutationVariables = (role: Role, numberOfProjects: number): AddRoleVariables => ({
  roleName: role.name,
  ...convertRoleToBaseVariables(role, numberOfProjects),
  dashboards: role.dashboards,
  isDataRole: role.data,
});

export const saveRoles = (roles: readonly Role[], updateRole: (role: Role) => void) => {
  roles.forEach((role) => updateRole(role));
};

const convertIncludeExcludeToProjectIds = (
  allProjectIds: string[],
  inclusionDef: { inclusionType: 'include' | 'exclude'; projectIds: string[] }
): string[] => {
  const { inclusionType, projectIds } = inclusionDef;
  switch (inclusionType) {
    case 'exclude':
      return allProjectIds.filter((id) => !projectIds.includes(id));
    case 'include':
      return allProjectIds.filter((id) => projectIds.includes(id));
  }
};
export const convertRolesToSetPermissionsVariables = (
  roles: readonly Role[],
  recordTypes: readonly RecordType[],
  projects: readonly Project[]
): SetMultiPermissionsVariables => {
  // We have to cycle through each type to grab the list of projectIds
  const roleUpdates = roles.reduce<SetMultiPermissionsVariables['roleUpdates']>((acc, role) => {
    const updates = [...acc];
    recordTypes.forEach((type) => {
      const aggregateType = type.value;
      if (aggregateType in role) {
        //we should always get here, but we have to be sure for typescript
        const selectedProjects = role[aggregateType] as string[];
        // we should determine inclusion/exclusion for each role
        if (selectedProjects.length > projects.length / 2) {
          //then we should send the exclusion list
          const excludedProjectIds = projects
            .map((project) => project.id)
            .filter((id) => !selectedProjects.includes(id));
          updates.push({
            inclusionType: 'exclude',
            projectIds: excludedProjectIds,
            roleId: role.id,
            type: aggregateType,
          });
        } else {
          //send the inclusion list
          updates.push({
            inclusionType: 'include',
            projectIds: selectedProjects,
            roleId: role.id,
            type: aggregateType,
          });
        }
      }
    });
    return updates;
  }, []);
  return { roleUpdates };
};
