import React from 'react';
import { List, ListItem, FormGroup, FormControlLabel, Checkbox, Link } from '@material-ui/core';
import { DrawerItem } from '../TGDrawer/TGDrawer';

export interface CheckboxesSectionProps {
  options: string[];
  values: boolean[];
  onToggle: (value: string) => void;
  icon: React.ReactNode;
  title: string;
  showToggleAll?: boolean;
  onToggleAll?: (selectAll: boolean) => void;
}

export const checkboxesSection = (props: CheckboxesSectionProps): DrawerItem => {
  const selectedCount = props.values.reduce<number>((acc: number, value: boolean) => acc + (value ? 1 : 0), 0);
  const styles = {
    linkTextStyle: {
      marginTop: 11,
      marginBottom: 5,
      fontSize: 14,
      fontFamily: 'Roboto',
      width: 'fit-content',
    },
  };
  return {
    icon: props.icon,
    closeMenuOnClick: false,
    iconMenu: (
      <List>
        <ListItem>
          <FormGroup>
            {props.showToggleAll && (
              <Link
                component="button"
                underline="always"
                style={styles.linkTextStyle}
                onClick={() => props.onToggleAll?.(selectedCount === 0)}
              >
                {selectedCount > 0 ? 'Deselect All' : 'Select All'}
              </Link>
            )}
            {props.options.map((option, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={props.values[index]}
                    onChange={() => props.onToggle(option)}
                    color="primary"
                    style={{ paddingTop: 5, paddingBottom: 5 }}
                  />
                }
                label={option}
              />
            ))}
          </FormGroup>
        </ListItem>
      </List>
    ),
    title: props.title,
    content: (
      <>
        {props.showToggleAll && (
          <Link
            component="button"
            underline="always"
            style={{ marginTop: 11, marginBottom: 5, fontSize: 14, fontFamily: 'Roboto' }}
            onClick={() => props.onToggleAll?.(selectedCount === 0)}
          >
            {selectedCount > 0 ? 'Deselect All' : 'Select All'}
          </Link>
        )}
        <FormGroup>
          {props.options.map((option, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={props.values[index]}
                  onChange={() => props.onToggle(option)}
                  color="primary"
                  style={{ paddingTop: 5, paddingBottom: 5 }}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </>
    ),
  };
};
