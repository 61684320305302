import { Box, Button, makeStyles } from '@material-ui/core';
import magicText from 'i18next';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';

const FOOTER_MOBILE_BREAKPOINT = 450;

interface Props {
  handleCancel: () => void;
  handleDone: () => void;
  applyText?: string;
}

const ModalFooter = ({ handleDone, handleCancel, applyText }: Props) => {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Button color="primary" variant="outlined" className={classes.button} onClick={handleCancel}>
        Cancel
      </Button>
      <Button disableElevation color="primary" variant="contained" className={classes.button} onClick={handleDone}>
        {applyText || magicText.t('Apply') as string}
      </Button>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  footer: {
    padding: '14px 30px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 14,
    boxShadow: `0px -2px 4px 0px ${colors.black05}`,
    position: 'sticky',
    bottom: 0,
    background: colors.white,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '14px 22px',
      justifyContent: 'space-between',
    },
    [theme.breakpoints.down(FOOTER_MOBILE_BREAKPOINT)]: {
      justifyContent: 'space-between',
    },
  },
  button: {
    width: 129.5,
    height: 39,
    textTransform: 'none',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 'normal',
    fontFamily: 'Roboto',
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      width: '100%',
    },
  },
}));

export default ModalFooter;
