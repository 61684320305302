import { withStyles, Tooltip } from '@material-ui/core';

const TGTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#676767',
    color: theme.palette.common.white,
    // boxShadow: theme.shadows[1],
    fontSize: 13,
    margin: 6,
  },
}))(Tooltip);

export default TGTooltip;
