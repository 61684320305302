const scrollbarWidth = () => {
  if (typeof document === 'undefined') {
    return 0;
  }

  const body = document.body;
  const box = document.createElement('div');

  box.style.position = 'absolute';
  box.style.top = box.style.left = '-9999px';
  box.style.width = box.style.height = '100px';
  box.style.overflow = 'scroll';

  body.appendChild(box);

  const width = box.offsetWidth - box.clientWidth;

  body.removeChild(box);

  return width;
};

export default scrollbarWidth;
