import i18next from 'i18next';

i18next.init({
  lng: 'en',
  ns: ['common', 'native'],
  defaultNS: 'native',
  fallbackNS: 'common',
  resources: {
    en: {
      // common: require('../../common/magic/dictionary.json'),
      native: require('./dictionary.json'),
    },
  },
  interpolation: {
    escapeValue: false,
  },
});
