import { useEffect } from 'react';
import { useAuthContext, useConfig } from '@terragotech/gen5-shared-components';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import { useCardMapper } from '../../../hooks/useCardMapper';
import AssetCard from './AssetCard';

interface AssetCardContainerProps {
  assetId: string;
  onCloseClick?: () => void;
  onSelect?: (item: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  refreshCard?: () => void;
  asset?: MapAssetType;
  onFormSubmit?: () => void;
  elevation?: boolean;
  multiple?: boolean;
  fetchingAssets?: boolean;
}

const AssetCardContainer = (props: AssetCardContainerProps) => {
  const {
    assetId,
    onCloseClick,
    onSelect,
    mapEditor,
    selectedAggregateId,
    selectableAggregateTypes,
    asset,
    onFormSubmit,
    elevation,
    multiple,
  } = props;
  const { token } = useAuthContext();
  const { cardProps, cardHasFinishedLoading } = useCardMapper({
    onCloseClick,
    assetId,
    asset,
    onFormSubmit,
    isAuthenitcated: !!token,
  });
  const { aggregateDefinitions } = useConfig();

  useEffect(() => {
    if (cardHasFinishedLoading && props?.refreshCard) {
      props.refreshCard();
    }
  }, [cardHasFinishedLoading]);

  // Exits the component if the neccesary data is not available
  if (!assetId || !cardProps || !asset) {
    return (
      <AssetCard
        asset={null}
        assetId={assetId}
        fetchingAssets={props.fetchingAssets}
        onCloseClick={onCloseClick}
        title={''}
        mapEditor={mapEditor}
        attributes={[]}
        secondaryActions={[]}
      />
    );
  }

  // Grab "labelProperty" property off of relevant aggregateDefinition
  let labelValue: string | undefined = '';
  const aggregateDefinition = aggregateDefinitions.find(i => i.queryKey === asset.recordTypeKey);
  if (!!aggregateDefinition?.cardDefinition.showTitleLabel) {
    labelValue = aggregateDefinition
      ? aggregateDefinition.propertyDefinitions.find(x => x.field === aggregateDefinition.labelProperty)?.label
      : '';
  }
  return (
    <AssetCard
      {...cardProps}
      labelValue={labelValue}
      assetId={assetId}
      symbolKey={asset.symbolKey}
      aggregateType={aggregateDefinition?.singular}
      onSelect={onSelect}
      mapEditor={mapEditor}
      selectedAggregateId={selectedAggregateId}
      selectableAggregateTypes={selectableAggregateTypes}
      elevation={elevation}
      multiple={multiple}
    />
  );
};
export default AssetCardContainer;
