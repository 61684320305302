import { useMemo, useState, useEffect } from 'react';
import { useDataMapping } from './useDataMapping';
import { ConditionalButtonMapping } from '@terragotech/gen5-config-lib';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { ActionButtonState } from './useConditionalCardActionButtons';
import { ActionButton, useConfig } from '@terragotech/gen5-shared-components';
import { AssetType } from '../contexts/AggregatesContext/types';
import _ from 'lodash';

const getStateFromMapResults = (result: ConditionalButtonMapping): ActionButtonState['state'] => {
  return result.isHidden ? 'hidden' : result.isDisabled ? 'disabled' : 'enabled';
};
interface Workflow extends ActionButton {
  state: ActionButtonState['state'];
  disabledMessage?: string;
}
export const useConditionalEditWorkflows = (target?: AssetType): Workflow[] => {
  const { aggregateDefinitions, functionDefinitions } = useConfig();
  const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === target?.recordTypeKey);
  const definedWorkflows = aggregateDefinition?.editorWorkflows;
  const [workflows, setWorkflows] = useState<Workflow[]>(
    definedWorkflows?.map(workflow => ({ ...workflow, state: 'loading' })) || []
  );
  useEffect(() => setWorkflows(definedWorkflows?.map(workflow => ({ ...workflow, state: 'loading' })) || []), [
    definedWorkflows,
  ]);

  const dataMapping = useDataMapping();
  const dataMappingContext = useMemo(() => {
    return dataMapping(target);
  }, [dataMapping, target]);
  // any time our data mapping context changes, we want to reevaluate the state for all workflows
  useEffect(() => {
    const workflowPromises = workflows.map(async workflow => {
      let result = {};
      if (workflow.conditionalMap) {
        result = await mapScenarios.BUTTON_STATE.evaluate(
          workflow.conditionalMap,
          dataMappingContext.accessors,
          functionDefinitions
        ).then(results => ({
          ...results,
          workflow,
        }));
      }
      return Promise.resolve({ isDisabled: false, isHidden: false, disabledMessage: '', ...result, workflow });
    });
    Promise.all(workflowPromises).then(buttonResults => {
      const newState = buttonResults.reduce<Workflow[]>((acc, buttonMappingResult) => {
        const state = getStateFromMapResults(buttonMappingResult);
        const disabledMessage = state == 'disabled' ? buttonMappingResult.disabledMessage : undefined;
        return [...acc, { ...buttonMappingResult.workflow, state, ...(disabledMessage && { disabledMessage }) }];
      }, []);
      // now we have to make sure that our state has changed before we set, or we get stuck in an infinite loop
      if (!_.isEqual(newState, workflows)) {
        setWorkflows(newState);
      }
    });
    //only bother with this if we are a conditional button
  }, [dataMappingContext, workflows]);
  const visibleWorkflows = useMemo(() => {
    return workflows.filter(workflow => workflow.state !== 'hidden');
  }, [workflows]);
  return visibleWorkflows;
};
