import React, { Dispatch, FunctionComponent, SetStateAction, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TopSearchBar from './TopSearchBar';
import { FitBounds } from 'react-mapbox-gl/lib/map';
import { useLocation, useParams } from 'react-router-dom';
import { AssetsDashboardContext } from '../../../../contexts/assetsDashboardContext';
import SearchBox from '../../../TGCommonSearchBar';

interface MapControlsOverlayProps {
  setBounds: (bounds: FitBounds | undefined) => void;
  setVisibilityModal: Dispatch<SetStateAction<boolean>>;
  isModalOpen: boolean;
  mapContainerRef: HTMLDivElement | null;
  hideVisibility?: boolean;
}
const MapSearchControlOverlay: FunctionComponent<MapControlsOverlayProps> = props => {
  const { setBounds, setVisibilityModal, isModalOpen, mapContainerRef, hideVisibility } = props;
  const classes = useStyles();
  const location = useLocation();
  const { isMobileView } = useContext(AssetsDashboardContext);
  const { pageName } = useParams() as {
    pageName: string;
  };
  const isPageContainer = location.pathname.includes('/page/') && pageName;
  return (
    <div className={classes.mapCenterControls}>
      <div className={isMobileView && isPageContainer ? '' : classes.mapHeader}>
        {isMobileView && isPageContainer ? (
          <SearchBox pageContainer />
        ) : (
          <TopSearchBar
            setBounds={setBounds}
            setVisibilityModal={setVisibilityModal}
            isModalOpen={isModalOpen}
            mapContainerRef={mapContainerRef}
            hideVisibility={hideVisibility}
          />
        )}
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  mapCenterControls: {
    flex: 1,
    alignItems: 'center',
    paddingLeft: 23,
    display: 'flex',
  },
  mapHeader: {
    marginTop: 26,
    top: 0,
    zIndex: 1000,
    position: 'absolute',
    justifyContent: 'center',
  },
}));

export default MapSearchControlOverlay;
