import React, { useCallback, useRef } from 'react';
import { TextField } from '@material-ui/core';
import useStyles, { getClasses } from './Common';
import { StyledTableEditorRef, StyledTableEditorProps } from '../../../hooks/tableHooks/useColumns';
import { debounce } from 'lodash';

const StyledTableEditor = React.forwardRef<StyledTableEditorRef, StyledTableEditorProps>((props, ref) => {
  const { column, row, onRowChange } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const [value, setValue] = React.useState(row[column.key] || '');
  const debouncedCallback = useCallback(
    debounce(value => {
      onRowChange({ ...row, [column.key]: value, __changes: { [column.key]: value } });
    }, 300),
    []
  );

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = event.target;
    setValue(value);
    debouncedCallback(value);
  };
  return (
    <div className={getClasses(props, classes, false, 'string').join(' ')}>
      <TextField
        value={value}
        onChange={e => onChange(e)}
        InputProps={{ disableUnderline: true, autoFocus: true }}
        inputProps={{ className: classes.input }}
        innerRef={inputRef}
        onKeyDown={e => {
          const { key } = e.nativeEvent;
          const disabledButtons = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

          if (disabledButtons.includes(key)) e.stopPropagation();
        }}
      />
    </div>
  );
});

export default StyledTableEditor;
