import React, { useCallback } from 'react';
import magicText from 'i18next';
import { useAggregates } from '../../contexts/AggregatesContext';
import { checkboxTreeSection } from '../../components/MapRightDrawer/CheckboxTreeSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const useMapServiceSection = () => {
  const { setVisibleMapServiceKeys, visibleMapServiceKeys, mapServices, mapServiceLayers } = useAggregates();
  const sourceVisibility: Record<string, boolean> = {};
  [...mapServices, ...mapServiceLayers].forEach(x => sourceVisibility[x.id] = visibleMapServiceKeys.includes(x.id));

  const toggleVisibleMapServiceKey = useCallback((
    (id: string) => {
      if (visibleMapServiceKeys.includes(id)) {
        setVisibleMapServiceKeys(visibleMapServiceKeys.filter((key) => key !== id));
      } else {
        setVisibleMapServiceKeys([...visibleMapServiceKeys, id]);
      }
    }
  ), [visibleMapServiceKeys, setVisibleMapServiceKeys])

  const toggleSourceVisibility = useCallback(
    (value: string, checked: boolean) => {
      const service = mapServices.find((service) => service.id === value);
      const layer = mapServiceLayers.find((layer) => layer.id === value);

      if (service) {
        toggleVisibleMapServiceKey(service.id);
        // TODO: Handle checkboxes to sync all children. Also prevent parent expand/collapse when toggling (should this occur in CheckboxTreeSection.tsx?).
      }
      else if (layer) {
        toggleVisibleMapServiceKey(layer.id);
      }
    },
    [mapServices, mapServiceLayers, toggleVisibleMapServiceKey]
  );

  const section = checkboxTreeSection({
    title: magicText.t('map.mapServiceVisibility.header'),
    icon: <FontAwesomeIcon size="sm" icon={["fas", "object-ungroup"]} />,
    options: mapServices.map((service) => ({
      id: service.id,
      label: service.label,
      subOptions: service.layers.map(layer => ({ id: `${service.id}-${layer.layerName}`, label: layer.layerTitle || layer.layerName })),
    })),
    values: sourceVisibility,
    onToggle: toggleSourceVisibility,
  });

  return { section };
};
