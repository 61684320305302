import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core';
import { colors } from '../../../../../styles/theme';

export interface searchBarProps {
  searchValue: string;
  searchOnChange: (value: string) => void;
  focusOut: boolean;
}

export interface tabProps {
  SearchbarSection: React.ElementType<searchBarProps>;
}

export const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  },

  expanded: {
    margin: '0px !important',
  },
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    flexDirection: 'row-reverse',
    padding: 0,
    paddingRight: 10,
    fontSize: 15,
    fontWeight: 500,
    minHeight: 0,
    marginLeft: -10,
    color: colors.black0,
    '&$expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '12px 0px',
      marginLeft: 0,
      minHeight: 0,
    },
    '& .MuiIconButton-root': {
      padding: 4,
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: 0,
    },
  },
  expandIcon: {
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
  },
  content: {
    marginLeft: 5,
    '&$expanded': {
      margin: '1px 0',
      marginLeft: 5,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
    paddingLeft: 20,
    paddingRight: 12,
    display: 'flex',
    fontSize: 15,
    fontWeight: 400,
    flexDirection: 'column',
    '& .MuiBox-root': {
      alignItems: 'center',
      marginLeft: -5,
    },
    '& .MuiIconButton-root': {
      padding: 9,
      paddingRight: 12,
    },
  },
}))(MuiAccordionDetails);
