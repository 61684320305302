import React from 'react';
import { FormControlLabel, Tooltip } from '@material-ui/core';
import { DrawerItem } from '../TGDrawer/TGDrawer';
import { TGIosSwitch } from './IosSwitch';

export interface OnOffSectionProps {
  onIcon?: React.ReactNode;
  offIcon?: React.ReactNode;
  title: string;
  onToggle: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  isOn: boolean;
  tooltip?: string;
  disable?: boolean;
}

export const onOffSection = (props: OnOffSectionProps): DrawerItem => ({
  icon: props.onIcon && props.offIcon && (props.isOn ? props.onIcon : props.offIcon),
  title: props.title,
  content: (
    <React.Fragment>
      <Tooltip title={props.tooltip || ''} placement="top-start">
        <FormControlLabel
          control={<TGIosSwitch value={props.isOn} onChange={props.onToggle} disabled={props.disable}/>}
          style={{ textTransform: 'capitalize', marginLeft: 0 }}
          label=''
        />
      </Tooltip>
    </React.Fragment>
  ),
  iconClickHandler: props.onToggle,
});
