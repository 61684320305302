import React, { useCallback, useEffect, useState } from 'react';
import {
  Dialog,
  makeStyles,
  createStyles,
  Button,
  DialogActions,
  DialogTitle,
  DialogContentText,
  TextField,
  Typography,
} from '@material-ui/core';
import PasswordChangeView from '../PasswordChangeView';
import magicText from 'i18next';
import { AuthConnector } from '@terragotech/gen5-shared-components';
import { colors } from '../../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHistory } from 'react-router-dom';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import PasswordUpdated from './PasswordUpdate';

export interface ForgotPasswordFlowProps {
  title: string;
  okText?: string;
  onClose: () => void;
}

const ForgotPasswordFlow: React.FC<ForgotPasswordFlowProps> = props => {
  const classes = useStyles();
  const { okText, title, onClose } = props;
  const [password, setPassword] = useState<string | undefined>();
  const [email, setEmail] = useState<string>('');
  const [emailCaptured, setEmailCaptured] = useState(false);
  const [back, setBack] = useState<boolean>(false);
  const [code, setCode] = useState('');
  const [error, setError] = useState();
  const history = useHistory();
  const [passwordCaptured, setPasswordCaptured] = useState(false);
  const handlePassword = useCallback((newPassword?: string) => {
    setPassword(newPassword);
  }, []);
  const handleForgotPasswordSubmit = useCallback(() => {
    if (email && code && password) {
      setError(undefined);
      AuthConnector.forgotPasswordSubmit(email, code, password)
        .then(data => {
          setPasswordCaptured(true);
        })
        .catch(err => {
          setError(err.message);
        });
    }
  }, [code, email, password]);

  const handleEmailSubmitted = useCallback(() => {
    if (email) {
      AuthConnector.forgotPassword(email).then(() => {
        setEmailCaptured(true);
        setBack(false);
      });
    }
  }, [email]);
  const onGoBack = () => {
    if (emailCaptured) {
      setBack(true);
    } else {
      history.push('/');
    }
  };
  useEffect(() => {
    if (emailCaptured && back) {
      setEmailCaptured(false);
    }
  }, [onGoBack]);
  return (
    <Dialog
      open={true}
      onClose={onClose}
      className={classes.dialogBox}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {passwordCaptured ? (
      <PasswordUpdated />
       ) : (
      <>
      <div className={classes.goBackContainer} onClick={onGoBack}>
        <FontAwesomeIcon icon={faArrowLeft as IconProp} className={classes.backIcon} />
        <Typography className={classes.backText}>Back</Typography>
      </div><div className={classes.rootContainer}>
          <DialogTitle style={styles.root as React.CSSProperties} className={classes.title}>
            {emailCaptured ? 'Reset Your Password' : title}
          </DialogTitle>
          <div>
            {!emailCaptured && (
              <>
                <div className={[classes.titleContainer, classes.backText].join(' ')}>
                  <>{magicText.t('PasswordReset.EnterEmailText')}</>
                </div>
                <TextField
                  placeholder={magicText.t('PasswordReset.EnterEmailLabel')}
                  required
                  className={[classes.input, classes.pb].join(' ')}
                  value={email}
                  variant="outlined"
                  onChange={ev => setEmail(ev.target.value)} />
              </>
            )}
            {emailCaptured && (
              <>
                <div className={[classes.emailRoot, classes.backText].join(' ')}>
                  <>{magicText.t('PasswordReset.EnterCodeText')}</>
                </div>
                <TextField
                  placeholder={magicText.t('PasswordReset.VerificationCode')}
                  required
                  className={[classes.input, classes.inputRoot].join(' ')}
                  value={code}
                  inputProps={{
                    autoComplete: 'off',
                  }}
                  variant="outlined"
                  onChange={ev => setCode(ev.target.value)} />
                <PasswordChangeView {...props} onPasswordChange={handlePassword} classesStyle={classes.input} />
              </>
            )}
            {error && <DialogContentText color="error">{error}</DialogContentText>}
          </div>
          <DialogActions className={classes.dialogActions}>
            {!emailCaptured && (
              <Button
                className={classes.btn}
                disabled={!email}
                onClick={handleEmailSubmitted}
                color="primary"
                variant="contained"
              >
                <> {okText || magicText.t('Submit')}</>
              </Button>
            )}
            {emailCaptured && (
              <Button
                className={[classes.btn, classes.btnFs].join(' ')}
                onClick={handleForgotPasswordSubmit}
                color="primary"
                variant="contained"
                disabled={!password && !code}
              >
                <> {okText || magicText.t('Change Password')}</>
              </Button>
            )}
          </DialogActions>
        </div>
        </>
        )}
    </Dialog>
  );
};
const styles = {
  root: { textAlign: 'center', padding: 0, paddingBottom: 12 },
};
const useStyles = makeStyles(theme =>
  createStyles({
    emailRoot: {
      textAlign: 'center',
      padding: 0,
    },
    rootContainer: {
      width: 400,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        width: 344,
      },
    },
    goBackContainer: {
      display: 'flex',
      gap: 5,
      marginTop: 24,
      marginLeft: 24,
      cursor: 'pointer',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        marginTop: 20,
        marginLeft: 19,
      },
    },
    backIcon: {
      fontSize: 22,
      color: colors.black54,
    },
    backText: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black75,
    },
    titleContainer: {
      textAlign: 'center',
    },
    dialogBox: {
      height: '100%',
      width: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      '& .MuiPaper-root.MuiDialog-paper': {
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        margin: 0,
        borderRadius: 0,
      },
    },
    title: {
      '& h2': {
        fontSize: 28,
        fontWeight: 500,
        color: colors.black0,
        lineHeight: 'normal',
        [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
          fontSize: 25,
        },
      },
    },
    btn: {
      letterSpacing: '1.25px',
      fontWeight: 500,
      fontSize: 16,
      width: '100%',
      padding: '12px 80px',
      color: colors.white,
      borderRadius: 5,
      textTransform: 'capitalize',
      '&.MuiButton-contained.Mui-disabled': {
        background: colors.black15,
        color: colors.white,
        textTransform: 'capitalize',
      },
    },
    btnFs: {
      fontSize: 16,
    },
    dialogActions: {
      '&.MuiDialogActions-root': {
        padding: 0,
      },
    },
    inputRoot: {
      paddingTop: 25,
    },
    pb: {
      paddingTop: 25,
      paddingBottom: 20,
    },
    input: {
      display: 'flex',
      width: '100%',
      '& .MuiInputBase-input': {
        color: colors.black0,
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 400,
        opacity: 1,
        '&::placeholder': {
          color: colors.black45,
          fontSize: '15px',
          fontStyle: 'normal',
          fontWeight: 400,
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-input': {
        padding: '0px',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: `1px solid ${colors.black25}`,
          borderRadius: 5,
        },
        '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.black25}`,
          borderRadius: 5,
        },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: `1px solid ${colors.black25}`,
        },
        '&.MuiOutlinedInput-root': {
          padding: '11px 18px',
        },
      },
    },
  })
);

export default ForgotPasswordFlow;
