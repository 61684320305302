import React, { useState, useCallback, useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import RecyclerList from '../../../utils/RecyclerList';
import ErrorDisplay from './ErrorDisplay';
import { RowRendererProps } from '../../../utils/RecyclerList';

export const DEFAULT_MIN_BUFFER_SIZE = 10;
export const DEFAULT_LIST_WIDTH = '100%';
export const DEFAULT_LIST_HEIGHT = '100%';

export interface TGLazyModalRowRendererProps<DataType, StateType, RowRendererPropsType>
  extends RowRendererProps<DataType> {
  state: StateType;
  setState: (state: StateType) => void;
  refresh: () => void;
  generalProps?: RowRendererPropsType;
}

export interface TGLazyModalListProps<DataType, StateType, RowRendererPropsType> {
  data: DataType[];
  loading?: boolean;
  error?: unknown;
  isAllLoaded: boolean;
  minBufferSize?: number;
  searchText: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  listDataKey?: unknown;
  rowRendererProps?: RowRendererPropsType;
  rowHeight: number;
  state: StateType;
  setState: (state: StateType) => void;
  rowRenderer: (props: TGLazyModalRowRendererProps<DataType, StateType, RowRendererPropsType>) => JSX.Element;
  fetchMoreData: () => void;
}

/**
 * A component that combines DialogWrapper header with lazy list
 * @param props
 */
const TGLazyModalList = <DataType extends unknown, StateType extends unknown, RowRendererPropsType extends unknown>(
  props: TGLazyModalListProps<DataType, StateType, RowRendererPropsType>
) => {
  const {
    searchText,
    state,
    setState,
    width,
    height,
    listDataKey,
    rowRendererProps,
    rowHeight,
    minBufferSize,
    data,
    error,
    loading,
    fetchMoreData,
    isAllLoaded,
  } = props;

  const [currentDataKey, setCurrentDataKey] = useState(0);
  const [scroll, setScroll] = useState(0);
  const expectedScroll = useRef<number>(0);

  const refresh = useCallback(() => {
    setCurrentDataKey((currentKey) => currentKey + 1);
  }, []);

  useEffect(() => {
    expectedScroll.current = 0;
    setScroll(0);
    // When search text changes we need to scroll to the top of the list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    expectedScroll.current = scroll;
    // We only need to update scroll when data is changed, because then the list rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const MemorizedRowRenderer = useCallback(
    (rendererProps: RowRendererProps<DataType>) => (
      <props.rowRenderer
        {...rendererProps}
        state={state}
        setState={setState}
        refresh={refresh}
        generalProps={rowRendererProps}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state, setState, refresh, rowRendererProps]
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  } else if (loading) {
    return <CircularProgress size={22} />;
  }

  return (
    <RecyclerList
      data={data}
      width={width ?? DEFAULT_LIST_WIDTH}
      height={height ?? DEFAULT_LIST_HEIGHT}
      rowHeight={rowHeight}
      rowRenderer={MemorizedRowRenderer}
      dataKey={`${listDataKey}-${currentDataKey}`}
      minFetchedBufferSize={minBufferSize ?? DEFAULT_MIN_BUFFER_SIZE}
      isAllDataFetched={isAllLoaded}
      onShouldFetchData={fetchMoreData}
      setScroll={setScroll}
      expectedScroll={expectedScroll.current}
    />
  );
};

export default TGLazyModalList;
