import { useCallback } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { FileRejection, FileWithPath, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import csvFileIcon from '../../images/csvFileIcon.svg';
import xlsFileIcon from '../../images/xlsFileIcon.svg';
import { UseConditionalImport } from '../../hooks/useConditionalImports';
import { useInfoContext } from '../../contexts/InfoContext/infoContext';
import { colors } from '../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { isUndefined } from 'lodash';

const useDropzoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      backgroundColor: colors.lotion,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        borderTop: `1px solid ${colors.black10}`,
      },
    },
    uploadedContainer: {
      width: 223,
      height: 225,
      borderRadius: 4,
      border: `1px solid ${colors.black10}`,
      backgroundColor: colors.flashWhite,
      textAlign: 'right',
    },
    uploadInnerContainer: {
      height: '139px',
      width: '195px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: 4,
      gap: 26,
      marginLeft: 14,
    },
    fileContainer: {
      width: '100%',
      height: '537px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 11,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        width: '191px',
        height: '158px',
      },
    },
    fileLabel: {
      height: 33,
      textAlign: 'center',
      fontSize: 14,
      fontWeight: 400,
    },
    title: {
      position: 'relative',
      textAlign: 'center',
      height: 22,
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: 18,
      paddingBottom: 5,
      color: theme.palette.primary.main,
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      '&.disabled': {
        cursor: 'inherit !important',
        color: colors.dimGray,
        borderBottom: 'inherit !important',
      },
    },
    errorText: {
      color: colors.danger,
      position: 'relative',
      textAlign: 'center',
      marginTop: '30px',
    },
    dragAndDropText: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: '24px',
      height: 24,
      color: colors.black54,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        fontSize: 20,
      },
    },
    typeText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '18px',
      color: colors.black54,
      lineHeight: 'normal',
      paddingTop: 15,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        whiteSpace: 'nowrap',
      },
    },
    subText: {
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      color: colors.black54,
      lineHeight: 'normal',
      paddingTop: 4,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        whiteSpace: 'nowrap',
      },
    },
    fileContainerRoot: {
      width: '100%',
      height: '537px',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        width: '191px',
        height: '158px',
      },
    },
    imgCircle: {
      height: 65,
      width: 65,
      color: colors.black25,
    },
    orText: {
      fontFamily: 'Roboto',
      fontWeight: 300,
      fontSize: '20px',
      height: 29,
      color: colors.dimGray,
    },
    closeIcon: {
      height: 20,
      width: 20,
    },
    closeBtn: {
      backgroundColor: colors.black05,
      padding: 5,
      borderRadius: 30,
      height: 30,
      width: 30,
      marginTop: 9,
      marginRight: 9,
    },
    fileIcon: {
      height: 80,
      width: 80,
    },
    uploadIcon: {
      width: 85,
      height: 51,
      [theme.breakpoints.between(0, MOBILE_BREAKPOINT + 1)]: {
        width: 74,
        height: 44,
      },
    },
  })
);

interface Props {
  onBack?: () => void;
  changeHandler: (e: FileWithPath[]) => void;
  isSelected: boolean;
  selectedFile: FileWithPath | undefined;
  clearSelection: () => void;
  disabled: boolean;
  selected?: UseConditionalImport;
}

const ImportDropZone = (props: Props) => {
  const { changeHandler, isSelected, selectedFile, clearSelection, disabled, selected } = props;
  const dropZoneStyles = useDropzoneStyles();
  const { openSnackbar } = useInfoContext();
  const onDrop = useCallback(
    (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[]) => {
      if (acceptedFiles && acceptedFiles.length === 1) {
        changeHandler(acceptedFiles);
      }
      if (acceptedFiles && acceptedFiles.length > 1) {
        openSnackbar({
          type: 'ERROR',
          title: 'You can only select one file',
        });
      }
      if (rejectedFiles && rejectedFiles.length) {
        openSnackbar({
          type: 'ERROR',
          title: 'Invalid file selected: ' + rejectedFiles.map(f => f.file.name + ' - ' + f.errors.map(e => e.message)),
        });
      }
    },
    [changeHandler]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: selected?.fileTypes?.split(',').map(ft => '.' + ft) || ['.csv', '.xlsx'],
    onDrop: onDrop,
    noClick: true,
    disabled: disabled,
  });

  const fileExtension: string | undefined = selectedFile?.name.split('.').pop();
  const isXLS: boolean = fileExtension === 'xlsx' || fileExtension === 'xls';
  const importTypeNotEmpty = !isUndefined(selected);
  return (
    <Box className={dropZoneStyles.root}>
      <input {...getInputProps()} />
      {isSelected && selectedFile ? (
        <Box className={dropZoneStyles.uploadedContainer}>
          <IconButton className={dropZoneStyles.closeBtn} onClick={clearSelection}>
            <Close className={dropZoneStyles.closeIcon} />
          </IconButton>
          <Box className={dropZoneStyles.uploadInnerContainer}>
            <img src={isXLS ? xlsFileIcon : csvFileIcon} className={dropZoneStyles.fileIcon} alt="File Icon" />
            <Typography className={dropZoneStyles.fileLabel}>{selectedFile?.name}</Typography>
          </Box>
        </Box>
      ) : (
        <div
          className={importTypeNotEmpty ? dropZoneStyles.fileContainer : dropZoneStyles.fileContainerRoot}
          {...getRootProps()}
        >
          {importTypeNotEmpty ? (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 75 46"
                fill="none"
                className={dropZoneStyles.uploadIcon}
              >
                <path
                  d="M60.1625 17.6516C58.0658 8.03326 48.7233 0.8125 37.5 0.8125C28.5892 0.8125 20.85 5.38472 16.9958 12.0758C7.715 12.9679 0.5 20.0771 0.5 28.6919C0.5 37.9199 8.79417 45.4195 19 45.4195H59.0833C67.5933 45.4195 74.5 39.1745 74.5 31.4798C74.5 24.1197 68.1792 18.1535 60.1625 17.6516ZM43.6667 25.9039V37.0557H31.3333V25.9039H22.0833L37.5 11.9643L52.9167 25.9039H43.6667Z"
                  fill="black"
                  fillOpacity="0.25"
                />
              </svg>
              <Typography className={dropZoneStyles.dragAndDropText}>{'Drag & Drop file here'}</Typography>
              <Typography className={dropZoneStyles.orText}>or</Typography>
              <Typography onClick={open} className={clsx(dropZoneStyles.title, disabled ? 'disabled' : '')}>
                Select a file from device
              </Typography>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCircleExclamation as IconProp} className={dropZoneStyles.imgCircle} />
              <Typography className={dropZoneStyles.typeText}>{'Import Type Not Selected'}</Typography>
              <Typography onClick={open} className={dropZoneStyles.subText}>
                Please select an Import Type to upload a file.
              </Typography>
            </>
          )}
        </div>
      )}
    </Box>
  );
};

export default ImportDropZone;
