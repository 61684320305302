import * as React from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ActionIconProps {
  name: string;
  color?: string;
  size?: FontAwesomeIconProps['size'];
  style?: React.CSSProperties;
}
// TODO: We need to be able to specify a size for these icons.
const ActionIcon: React.FunctionComponent<ActionIconProps> = props => {
  const iconStyle: React.CSSProperties = {
    width: 24,
    height: 24,
    ...props.style,
  };
  const { name, color } = props;
  let icon = null; //return null if nothing else
  if (name && name.startsWith('fa')) {
    icon = <FontAwesomeIcon icon={name.split(/-(.*)/s, 2) as IconProp} color={color} style={iconStyle} />;
  } else {
    /*if (Object.keys(builtInActions).includes(name)) {
      icon = <img src={builtInActions[name].icon} alt={name} />;
    }*/
  }
  return <div style={props.style}>{icon}</div>;
};
export default ActionIcon;
