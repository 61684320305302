import * as React from 'react';
import { Dialog, makeStyles } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    height: '90%',
    width: 680,
    maxHeight: '100%',
    position: 'inherit',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: '100vh',
      width: '100%',
      borderRadius: 0,
      '&.MuiDialog-paperWidthSm': {
        width: '100%',
        maxWidth: '100%',
      },
      '&.MuiDialog-paper': {
        margin: 0,
      },
    },
  },
}));
export interface SlideDialogProps {
  open: boolean;
  onClose: () => void;
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
  children?: React.ReactNode;
  dialogClass?: string;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const SlideDialog: React.FunctionComponent<SlideDialogProps> = props => {
  const { open, onClose, maxWidth, dialogClass = '' } = props;
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      classes={{ paperScrollPaper: `${classes.dialogPaper} ${dialogClass}` }}
      TransitionComponent={Transition}
      onClose={onClose}
      fullWidth={true}
      maxWidth={maxWidth || 'sm'}
    >
      {props.children}
    </Dialog>
  );
};
export default SlideDialog;
