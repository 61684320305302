import { atom, selector } from 'recoil';
import { forcedClusteringState } from './forcedClustering';
const MENU_SELECTION_PREFERENCE_KEY = 'menuSelectionsState';
export const MAP_LABEL_PREFERENCE_KEY = 'mapLabelVisibility';
export const CARD_VIEW_PREFERENCE_KEY = 'cardViewVisibility';
export const PIN_CLUSTER_PREFERENCE_KEY = 'pinClusteringEnabled';
const loadPref = (key: string): unknown => {
  try {
    const menuLocalStorageState = localStorage.getItem(key);
    if (menuLocalStorageState) {
      return JSON.parse(menuLocalStorageState);
    }
  } catch (e) {
    // no worries, just ignore the whole value
  }
  return null;
};

let getDefaults = () => {
  const localStorage = loadPref(MENU_SELECTION_PREFERENCE_KEY) as any;
  return {
    [MAP_LABEL_PREFERENCE_KEY]: localStorage ? localStorage[MAP_LABEL_PREFERENCE_KEY] === true : (false as boolean),
    [CARD_VIEW_PREFERENCE_KEY]: localStorage ? localStorage[CARD_VIEW_PREFERENCE_KEY] === true : (true as boolean),
    [PIN_CLUSTER_PREFERENCE_KEY]: localStorage ? localStorage[PIN_CLUSTER_PREFERENCE_KEY] === true : (true as boolean),
  };
};

export const menuSelectionsState = atom({
  key: 'menuSelectionsState',
  default: getDefaults(),
  effects: [
    ({ onSet }) => {
      onSet(newValue => {
        try {
          localStorage.setItem(MENU_SELECTION_PREFERENCE_KEY, JSON.stringify(newValue));
        } catch (e) {}
      });
    },
  ],
});

export const mapLabelSelector = selector({
  key: 'mapLabelSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    return menuSelections[MAP_LABEL_PREFERENCE_KEY] as boolean;
  },
});

export const cardViewSelector = selector({
  key: 'cardViewSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    return menuSelections[CARD_VIEW_PREFERENCE_KEY] as boolean;
  },
});
export const cardVisibleState = atom({
  key: 'cardVisibleState',
  default: false,
});
export const pinClusteringSelector = selector({
  key: 'pinClusteringSelector',
  get: ({ get }) => {
    let menuSelections = get(menuSelectionsState);
    let isForcedClusteringEnabled = get(forcedClusteringState);
    return (menuSelections[PIN_CLUSTER_PREFERENCE_KEY] as boolean) || isForcedClusteringEnabled;
  },
});
