import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import '@fontsource/roboto';
import { RecoilRoot } from 'recoil';
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router basename="/gen5">
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </Router>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
