import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { useRecordType } from '../contexts/recordTypeContext';
import RecursiveAssetEditor from './RecursiveAssetEditor';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { useTableColumns } from '../contexts/TableColumnContext';

export interface AssetDetailSectionProps {
  height?: any;
  width?: number;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * AssetDetailSection - is a container for two children,
 *      MapOverviewContainer
 *      SingleAssetEditor
 *
 * @param width
 * @param height
 * @param searchText
 *
 */

const AssetDetailSection: React.FunctionComponent<AssetDetailSectionProps> = () => {
  const { selectedRecordType } = useRecordType();
  const history = useHistory();
  const { currentAssetId } = useTableColumns();
  //if there is no current asset selected, just show the full map with all assets
  //if there is a current asset selected, we need to be either the map view or the difference view
  //  let's use a URL parameter to determine which
  let match: { params: { assetId?: string; comparison?: string }; path: string } | null = useRouteMatch({
    path: `/${selectedRecordType}/:assetId?/:comparison(map|data|edit)?`,
  });

  let assetId = match?.params.assetId || '';
  let comparison = match && match.params && match.params.comparison;

  const handleClose = () => {
    const path = `/${selectedRecordType}${currentAssetId?.id ? `/${currentAssetId.id}` : ''}`;
    history.push(path);
  };

  return (
    <>
      <Dialog
        open={Boolean(assetId && comparison === 'edit')}
        onClose={handleClose}
        fullScreen
        TransitionComponent={Transition}
      >
        <RecursiveAssetEditor
          aggregateType={selectedRecordType}
          aggregateId={assetId}
          open={Boolean(assetId && comparison === 'edit')}
          pathname={`/${selectedRecordType}/${assetId}/edit`}
          crumbs={[]}
        />
      </Dialog>
    </>
  );
};
export default AssetDetailSection;
