import { Box, Typography, makeStyles } from '@material-ui/core';
import { colors } from '../styles/theme';

interface Props {
  tabItems: { label: string; count: number }[];
  activeTab: string;
  updateActiveTab: (value: string) => void;
}

const RecordsTab = ({ tabItems, activeTab, updateActiveTab }: Props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {tabItems.map((item, index) => {
        const active = activeTab === item.label;
        return (
          <Box
            key={index}
            className={[classes.button, active && classes.activeBtn].join(' ')}
            onClick={() => updateActiveTab(item.label)}
          >
            <Typography
              className={[classes.buttonText, active && classes.activeTxt].join(' ')}
            >{`${item.label} (${item.count})`}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

const useStyles = makeStyles(theme => {
  const primary = theme.palette.primary.main;
  return {
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 7,
    },
    button: {
      padding: '6px 10px',
      borderRadius: 5,
      backgroundColor: colors.black05,
      cursor: 'pointer',
      boxSizing: 'border-box',
      border: `1px solid transparent`,
    },
    buttonText: {
      padding: '2px 5px',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 'normal',
      color: colors.black54,
    },
    activeBtn: {
      border: `1px solid ${primary}`,
    },
    activeTxt: {
      color: primary,
      fontWeight: 500,
    },
  };
});

export default RecordsTab;
