import { useCallback, useEffect, useState } from 'react';
import { useAggregates } from '../../../contexts/AggregatesContext';

const useHideNonSelectable = () => {
  const assetRecord = useAggregates();
  const [assetData, setAssetData] = useState(assetRecord.filteredAssets);
  const [defaultTypes] = useState(assetRecord.visibleAggregateTypesNames);
  const [hiding, setHiding] = useState(false);
  const [visibleAggregateTypesNames, setVisibleAggregateTypesNames] = useState<string[]>([...defaultTypes]);

  const handleHidingNonSelectableRecords = (selectableAggregateTypes: string[] = []) => {
    if (!!hiding) {
      // Make all types visable
      setVisibleAggregateTypesNames([...defaultTypes]);
      setHiding(false);
    } else {
      //  hide non clickable
      selectableAggregateTypes &&
        setVisibleAggregateTypesNames([...selectableAggregateTypes.filter(type => defaultTypes.includes(type))]);
      setHiding(true);
    }
  };

  const updateAssetData = useCallback(() => {
    let data = [...assetRecord.filteredAssets];
    // console.log('visl..', visibleAggregateTypesNames, data);
    const result = data.filter(o => visibleAggregateTypesNames.includes(o.__typename as string));
    setAssetData(result);
  }, [assetRecord, visibleAggregateTypesNames]);

  useEffect(() => {
    updateAssetData();
  }, [visibleAggregateTypesNames, updateAssetData]);

  return { handleHidingNonSelectableRecords, assetData, visibleAggregateTypesNames, hiding };
};

export default useHideNonSelectable;
