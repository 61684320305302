"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFieldVisibility = exports.VisibilityProvider = exports.VisibilityContext = exports.usePageDataMappingSources = exports.PageDataMappingContext = exports.PageReadOnlyContext = exports.usePageControl = exports.useControlProvider = exports.ControlProviderContext = exports.usePageState = exports.PageStateContextProvider = exports.PageStateContext = exports.useFileManager = exports.FileManagementContextProvider = exports.FileManagementContext = exports.useFieldValidation = exports.FieldValidationContextProvider = exports.FieldValidationContext = void 0;
const react_1 = __importStar(require("react"));
const uuid_1 = require("uuid");
const defaultControlMapValue = {
    RootWrapper: () => null,
    GridWrapper: () => null,
    GridElementWrapper: () => null,
    GroupWrapper: () => null,
    RepeatWrapper: () => null,
    StyleWrapper: () => null,
};
exports.FieldValidationContext = react_1.default.createContext({
    updateControlPathLocalFieldWarning: (_controlPath, _messages) => {
        console.warn('No field validation provided');
    },
});
const FieldValidationContextProvider = props => {
    const fieldValidationWarnings = react_1.useRef({});
    const updateControlPathLocalFieldWarning = react_1.useCallback((controlPath, messages) => {
        fieldValidationWarnings.current[controlPath] = messages;
    }, []);
    const value = react_1.useMemo(() => {
        return { fieldValidationWarnings: fieldValidationWarnings.current, updateControlPathLocalFieldWarning };
    }, [updateControlPathLocalFieldWarning]);
    return react_1.default.createElement(exports.FieldValidationContext.Provider, { value: value }, props.children);
};
exports.FieldValidationContextProvider = FieldValidationContextProvider;
const useFieldValidation = () => react_1.useContext(exports.FieldValidationContext);
exports.useFieldValidation = useFieldValidation;
exports.FileManagementContext = react_1.default.createContext({
    addFile: (_file) => {
        console.warn('No File manager provided');
        return '';
    },
    removeFile: (_id) => {
        console.warn('No File manager provided');
    },
    getFile: (_id) => {
        console.warn('No File manager provided');
    },
    getFiles: () => {
        return [];
    },
});
const FileManagementContextProvider = props => {
    const files = react_1.useRef([]);
    const handlers = react_1.useMemo(() => ({
        addFile: (file, id) => {
            const newId = id || uuid_1.v4();
            files.current = [...files.current, { id: newId, file }];
            return newId;
        },
        removeFile: (id) => {
            files.current = files.current.filter(entry => entry.id !== id);
        },
        getFile: (id) => {
            const ob = files.current.find(file => file.id === id);
            return ob ? ob.file : null;
        },
        getFiles: () => {
            return [...files.current];
        },
    }), []);
    return react_1.default.createElement(exports.FileManagementContext.Provider, { value: handlers }, props.children);
};
exports.FileManagementContextProvider = FileManagementContextProvider;
const useFileManager = () => react_1.useContext(exports.FileManagementContext);
exports.useFileManager = useFileManager;
exports.PageStateContext = react_1.default.createContext({
    pageState: {},
    setPageState: () => {
        console.warn('No Page State manager provided');
    },
});
const PageStateContextProvider = props => {
    const [pageState, setPageState] = react_1.useState({});
    return react_1.default.createElement(exports.PageStateContext.Provider, { value: { pageState, setPageState } }, props.children);
};
exports.PageStateContextProvider = PageStateContextProvider;
const usePageState = () => react_1.useContext(exports.PageStateContext);
exports.usePageState = usePageState;
exports.ControlProviderContext = react_1.default.createContext({
    controlMap: defaultControlMapValue,
    functionDefinitions: {},
});
const useControlProvider = () => react_1.useContext(exports.ControlProviderContext);
exports.useControlProvider = useControlProvider;
//TODO: I give up.Trying to specify this cleaner then any breaks a bunch of stuff. We'll come back to this one.
// Takes form element def and returns the proper form element from controlMap
const usePageControl = (component) => {
    const { controlMap } = exports.useControlProvider();
    const { type } = component;
    //given a type, return a control renderer
    return controlMap[type] || null;
};
exports.usePageControl = usePageControl;
exports.PageReadOnlyContext = react_1.default.createContext(false);
const defaultAccessor = {
    accessors: { METADATA: () => ({ timePageOpened: new Date() }), STATE: () => ({}) },
};
exports.PageDataMappingContext = react_1.default.createContext(defaultAccessor);
const usePageDataMappingSources = () => react_1.useContext(exports.PageDataMappingContext);
exports.usePageDataMappingSources = usePageDataMappingSources;
const defaultVisibile = {
    isVisible: true,
    updateVisibility: () => null,
};
exports.VisibilityContext = react_1.default.createContext(defaultVisibile);
const VisibilityProvider = ({ children }) => {
    const [isVisible, setIsVisible] = react_1.useState(true);
    const updateVisibility = (value) => {
        setIsVisible(value);
    };
    return react_1.default.createElement(exports.VisibilityContext.Provider, { value: { isVisible, updateVisibility } }, children);
};
exports.VisibilityProvider = VisibilityProvider;
const useFieldVisibility = () => react_1.useContext(exports.VisibilityContext);
exports.useFieldVisibility = useFieldVisibility;
