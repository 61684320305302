import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { filterNonMapAggregates, filterMapServiceAssets, reduceMapServicesToLayers } from './aggregateUtils';
import { AggregatesContextType } from './types';
import { useConfig } from '@terragotech/gen5-shared-components';
import { record, useAssetsLoader } from './useAssetsLoader';
import { useVisibleAggregatesState } from './useVisibleAggregatesState';
import { useAssetsCount } from './useAssetsCount';
import { useFilteredAssets } from './useFilteredAssets';
import { usePusher } from '../pusherContext';
import _ from 'lodash';
import { useSelectedProject } from '../selectedProjectContext';
import { useMapServiceState } from './useMapServiceState';
import { pusherChannelNotificationName, REFERENCE_TYPE_AGGREGATE } from '@terragotech/gen5-shared-utilities';

const DELAY_BETWEEN_REFRESH_SECONDS = 10;
const AggregatesContext = createContext<AggregatesContextType | undefined>(undefined);

export const AggregatesContextProvider: React.FC<{ children?: React.ReactNode }> = props => {
  const { aggregateDefinitions } = useConfig();
  const { subscribe, unSubscribe } = usePusher();
  const { selectedProjects } = useSelectedProject();
  const [multiSelectCommandsLoading, setMultiSelectCommandsLoading] = useState(false);
  const mapAggregateDefinitions = useMemo(() => filterNonMapAggregates(aggregateDefinitions), [aggregateDefinitions]);
  const lastScrolledRef = useRef(null);
  const [visibleRecordTypes, setVisibleRecordTypes] = useState<record[] | undefined>(undefined);
  const pluralProjectName = useMemo(
    () => aggregateDefinitions.find(aggregateDef => aggregateDef.name === 'Project')?.plural ?? 'Projects',
    []
  );

  const {
    visibleAggregateTypesKeys,
    visibleAggregateTypesNames,
    setVisibleAggregateTypesNames,
  } = useVisibleAggregatesState({
    aggregateDefinitions: mapAggregateDefinitions,
  });
  const {
    refetchAll,
    isDataLoading,
    assets,
    referenceTypeAssets,
    recordTypesData,
    fetchNextDataSet,
    initialAssetsFetch,
    updateScrollPosition,
    update,
    deleteAssets,
    fetchAssetsForFilter,
    setSortingInfo,
    updateAllAssets,
    getSearchResults,
    handleAssetClick,
    forceReloadCount,
    getAssetsForMultiSelect
  } = useAssetsLoader({
    aggregateDefinitions: mapAggregateDefinitions,
    visibleAggregateTypesNames,
  });

  const { allAssetsCount, currentTypeAssetsCount } = useAssetsCount({
    assets,
  });

  const {
    currentTypeFilteredAssets,
    allFilteredAssets,
    getCountOfRecordType,
    getAssetForGlobalSearch,
  } = useFilteredAssets({
    aggregateDefinitions: mapAggregateDefinitions,
    visibleAggregateTypesKeys,
    allAssets: assets,
  });

  const mapServices = useMemo(() => filterMapServiceAssets(referenceTypeAssets), [referenceTypeAssets]);
  const mapServiceLayers = useMemo(() => reduceMapServicesToLayers(mapServices), [mapServices]);

  const { visibleMapServiceKeys, visibleMapServiceNames, setVisibleMapServiceKeys } = useMapServiceState({
    mapServices: mapServices,
    mapServiceLayers: mapServiceLayers,
  });

  const toRefresh = useRef<{
    [proj: string]: string[];
  }>({});
  const lastTimestamp = useRef<number>(Date.now());
  const timeoutRef = useRef<{ timeout: NodeJS.Timeout | null }>({ timeout: null });
  const projectSubscriptions = useRef<string[]>([]);

  const executeRefresh = useCallback(() => {
    lastTimestamp.current = Date.now();
    if (timeoutRef.current.timeout) {
      clearTimeout(timeoutRef.current.timeout);
      timeoutRef.current.timeout = null;
    }
    toRefresh.current = {};
    lastTimestamp.current = Date.now();
    checkRefresh();
  }, [projectSubscriptions]);

  const checkRefresh = useCallback(() => {
    const total = Object.keys(toRefresh.current).length;
    if (
      Date.now() - lastTimestamp.current > DELAY_BETWEEN_REFRESH_SECONDS * 1000 &&
      !timeoutRef.current.timeout &&
      total
    ) {
      executeRefresh();
    } else {
      if (!timeoutRef.current.timeout && total) {
        timeoutRef.current.timeout = setTimeout(() => {
          executeRefresh();
        }, DELAY_BETWEEN_REFRESH_SECONDS * 1000 - Date.now() + lastTimestamp.current);
      }
    }
  }, [toRefresh, lastTimestamp, executeRefresh, timeoutRef]);

  useEffect(() => {
    subscribe(pusherChannelNotificationName.AGGREGATE, 'delete', (m: Record<string, string[]>) => {
      deleteAssets(m);
    });
  }, [subscribe]);

  useEffect(() => {
    subscribe(pusherChannelNotificationName.PROJECT_CHANGED, 'change', (m: any) => {
      const toDelete: {
        [proj: string]: string[];
      } = {};
      for (const item of m) {
        const [id, from, to] = item.split(',');
        if (id && from && to) {
          if (selectedProjects.includes(from) && !selectedProjects.includes(to)) {
            if (toDelete[from]) {
              toDelete[from].push(id);
            } else {
              toDelete[from] = [id];
            }
          }
        }
      }
      if (Object.keys(toDelete).length) {
        // TODO: It'll need later
        // deleteMultipleFromProjects(toDelete);
      }
    });
  }, [subscribe, selectedProjects]);

  useEffect(() => {
    const selectedProjectsWithAdhoc = [...selectedProjects, ...[REFERENCE_TYPE_AGGREGATE]];

    const toUnsub = _.without(projectSubscriptions.current, ...selectedProjectsWithAdhoc);
    const toSub = _.without(selectedProjectsWithAdhoc, ...projectSubscriptions.current);
    for (const proj of toSub) {
      subscribe(pusherChannelNotificationName.AGGREGATE, proj.toUpperCase() + '-update', (m: string[]) => {
        update(m, proj);
      });
    }

    for (const proj of toUnsub) {
      unSubscribe('aggregates', proj.toUpperCase() + '-update');
    }
    projectSubscriptions.current = _.cloneDeep(selectedProjects);
  }, [selectedProjects, subscribe, unSubscribe, projectSubscriptions]);

  useEffect(() => {
    const mapVisibleRecordTypes = _.filter(recordTypesData, ({ value }) => {
      const recordDef = aggregateDefinitions.find(def => def.name === value)
      if(recordDef && recordDef.hiddenFromMapView && !recordDef.hiddenFromTableView){
        return true;
      }
      if(recordDef && recordDef.hiddenFromTableView){
        return false;
      }
      return (
        _.findIndex(visibleAggregateTypesNames, val => {
          return value == val;
        }) >= 0
      );
    });
    setVisibleRecordTypes(() => (mapVisibleRecordTypes.length != 0 ? mapVisibleRecordTypes : undefined));
  }, [visibleAggregateTypesNames, recordTypesData]);

  const value: AggregatesContextType = useMemo(() => {
    return {
      filteredAssets: allFilteredAssets,
      filteredCurrentTypeAssets: currentTypeFilteredAssets,
      getCountOfRecordType: getCountOfRecordType,
      assets: assets,
      loading: isDataLoading,
      refetchAll,
      setVisibleAggregateTypesNames,
      visibleAggregateTypesNames,
      assetsCount: allAssetsCount,
      currentTypeAssetsCount,
      mapServices: mapServices,
      mapServiceLayers: mapServiceLayers,
      visibleMapServiceKeys,
      visibleMapServiceNames,
      setVisibleMapServiceKeys,
      recordTypesData: visibleRecordTypes,
      fetchNextDataSet,
      initialAssetsFetch,
      updateScrollPosition,
      lastScrolledRef,
      fetchAssetsForFilter,
      setSortingInfo,
      getAssetForGlobalSearch,
      multiSelectCommandsLoading,
      setMultiSelectCommandsLoading,
      updateAllAssets,
      getSearchResults,
      handleAssetClick,
      pluralProjectName,
      forceReloadCount,
      getAssetsForMultiSelect,
    };
  }, [
    allFilteredAssets,
    currentTypeFilteredAssets,
    getCountOfRecordType,
    assets,
    isDataLoading,
    refetchAll,
    setVisibleAggregateTypesNames,
    visibleAggregateTypesNames,
    allAssetsCount,
    currentTypeAssetsCount,
    mapServices,
    mapServiceLayers,
    visibleMapServiceKeys,
    visibleMapServiceNames,
    setVisibleMapServiceKeys,
    visibleRecordTypes,
    fetchNextDataSet,
    initialAssetsFetch,
    updateScrollPosition,
    lastScrolledRef,
    fetchAssetsForFilter,
    setSortingInfo,
    getAssetForGlobalSearch,
    multiSelectCommandsLoading,
    setMultiSelectCommandsLoading,
    updateAllAssets,
    getSearchResults,
    handleAssetClick,
    pluralProjectName,
    forceReloadCount,
    getAssetsForMultiSelect
  ]);

  return <AggregatesContext.Provider value={value} {...props} />;
};

export const useAggregates = (): AggregatesContextType => {
  const context = useContext(AggregatesContext);
  if (!context) {
    throw new Error('useAggregates must be used within an MapAssetProvider');
  }

  return context;
};
