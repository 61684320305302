import React from 'react';
import TGAlertDialog from '../TGAlertDialog';
import magicText from 'i18next';

interface DeleteDialogProps {
  open: boolean;
  resourceNameKey: string;
  toDelete: string[];
  onDelete: () => void;
  onCancel: () => void;
}

export default function DeleteDialog({ open, resourceNameKey, toDelete, onDelete, onCancel }: DeleteDialogProps) {
  const isPlural = toDelete.length > 1;
  const name = magicText.t(resourceNameKey, { count: toDelete.length });
  return (
    <TGAlertDialog
      open={open}
      title={`Delete ${name}`}
      onCancel={onCancel}
      onConfirm={onDelete}
      confirmButtonTitle={magicText.t('Delete')}
    >
      Are you sure you want to delete {isPlural ? 'those' : 'this'} {name}:
      <ul>
        {toDelete.map((name) => (
          <li key={name}>{name}</li>
        ))}
      </ul>
    </TGAlertDialog>
  );
}
