import React, { useState } from 'react';
import { Dialog, Theme, IconButton, FormHelperText } from '@material-ui/core';
import EditComponentButton, { IconType } from './EditComponentButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TGLocationFieldProps } from '../FormFields/TGLocationField/TGLocationField';
import { TGPolylineFieldProps } from '../FormFields/TGLocationField/TGPolylineField';
import { useStyles } from '../FormFields/Common';
import DeleteIcon from '@material-ui/icons/Delete';
import { TGMapAggregateSelectorFieldProps } from '../FormFields/TGMapAggregateSelectorField';
import { TGBarcodeInputProps, MediaFieldProps, MediaFileFieldProps } from '@terragotech/gen5-shared-components';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { colors } from '../../styles/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
export interface ModalProps {
  renderComponent?: (toggleModal: ToggleModal) => React.ReactNode;
  renderValue?: () => React.ReactNode;
  renderHeader?: () => React.ReactNode;
  position?: 'top' | 'bottom';
  toggleModal?: ToggleModal;
  type: IconType;
  clearValue?: () => void;
  errors?: () => React.ReactNode;
}

export type ToggleModal = () => void;
/**
  DialogueWrapper - wraps Media Fields and Location Modals
  Different from DialogWrapper which wraps lazy loaded lists and has search functionality
*/
type DialogProps = (
  | TGBarcodeInputProps
  | TGLocationFieldProps
  | TGPolylineFieldProps
  | MediaFieldProps
  | MediaFileFieldProps
  | TGMapAggregateSelectorFieldProps
) &
  ModalProps;
const PADDING_SPACE = 12;
export const DialogWrapper: React.FC<DialogProps> = props => {
  const classes = useStyles();

  const { type, value, renderComponent, position, renderValue, renderHeader, readOnly, clearValue, errors } = props;
  const dialogStyles = useDialogStyles(props);
  const [open, setOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setOpen(!open);
  };

  const isNotEmpty = Array.isArray(value) ? value.length > 0 : !!value;

  return (
    <>
      <Dialog open={open} classes={{ paper: dialogStyles.dialogPaper }}>
        {renderComponent && renderComponent(toggleModal)}
      </Dialog>
      {renderHeader && renderHeader()}
      {position === 'top' && isNotEmpty &&  <div className={dialogStyles.innerContainer}>
      {renderValue && renderValue()} {isNotEmpty && (
        <IconButton aria-label="clear" onClick={clearValue} disabled={readOnly} className={dialogStyles.iconBtn}>
        <FontAwesomeIcon icon={faTrashCan as IconProp} className={dialogStyles.deleteIcon} />
        </IconButton>
      )}
      </div>}
      <EditComponentButton readOnly={!!readOnly} type={type} mode={value ? 'edit' : 'add'} onClick={toggleModal} />
      {position === 'bottom' && renderValue && renderValue()}
      {position === 'top' && errors && errors()}
    </>
  );
};

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: (props: DialogProps) => ({
      height: '96vh',
      maxHeight: '100%',
      width: '100vw',
      maxWidth: '100%',
      overflow: 'hidden',
      padding: 0,
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        maxHeight: '100%',
        margin: 0,
        width: '100%',
        maxWidth: '100%',
        height: `calc(100% - ${PADDING_SPACE}px)`,
        padding: '6px 0px',
        borderRadius: 0,
      },
    }),
    rootContainer: {border: `1px solid ${colors.black10}`, padding: '18px 20px', borderRadius: 5},
    deleteIcon: {
      width: 14,
      height: 15.077,
      flexShrink: 0,
      fill: colors.dimGrey,
    },
    iconBtn: {padding: 5, backgroundColor: colors.veryLightGrey},
    innerContainer: {display: 'flex', paddingBottom: 10, alignItems: 'center', gap: 5}
  })
);

export default DialogWrapper;
