import React, { createContext, FunctionComponent, useEffect } from 'react';
import { noop } from 'lodash';
import useUserPreferences from '../hooks/useUserPreferences';

const MAP_TYPE_PREFERENCE_KEY = 'mapType';

export type MapTypes = 'light' | 'dark' | 'satellite' | 'streets' | 'satellite-hybrid' | 'nearmaps';

export const MapStyleContext = createContext<{
  mapStyle: string;
  setMapType: (style: MapTypes) => void;
  mapType: string;
}>({
  mapStyle: 'mapbox://styles/mapbox/satellite-streets-v11',
  setMapType: noop,
  mapType: 'satellite-hybrid',
});

export const MapStyleProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const { getPreferenceValue, setPreferenceValue } = useUserPreferences();
  const [mapType, setMapType] = React.useState(
    getPreferenceValue<string>(MAP_TYPE_PREFERENCE_KEY) ?? 'satellite-hybrid'
  );

  useEffect(() => {
    setPreferenceValue(MAP_TYPE_PREFERENCE_KEY, mapType);
  }, [mapType, setPreferenceValue]);

  const mapStyle = React.useMemo(() => {
    switch (mapType) {
      case 'dark':
        return `mapbox://styles/mapbox/dark-v10`;
      case 'light':
        return `mapbox://styles/mapbox/light-v10`;
      case 'satellite':
        return `mapbox://styles/mapbox/satellite-v9`;
      case 'satellite-hybrid':
        return `mapbox://styles/mapbox/satellite-streets-v11`;
      case 'streets':
        return `mapbox://styles/mapbox/streets-v11`;
      case 'nearmaps':
        return `mapbox://styles/mapbox/satellite-v9`;
      default:
        return `mapbox://styles/mapbox/satellite-streets-v11`;
    }
  }, [mapType]);

  return (
    <MapStyleContext.Provider value={{ mapStyle, setMapType, mapType }}>{props.children}</MapStyleContext.Provider>
  );
};
