import React, { createContext, useState } from 'react';

interface FormParentValue {
  parentValue: any;
  handleFormParentValueChange: any;
}

export const FormParentValueContext = createContext<FormParentValue>({
  parentValue: null,
  handleFormParentValueChange: () => {},
});

export const FormParentValueProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [parentValue, setParentValue] = useState();

  const handleFormParentValueChange = (prop: any) => setParentValue(prop);

  return (
    <FormParentValueContext.Provider value={{ parentValue, handleFormParentValueChange }}>
      {children}
    </FormParentValueContext.Provider>
  );
};
