import { FilterContextType } from '../../FilterContext/types';
import { MapAssetType } from '../types';
import { Position } from 'geojson';

export const filterByMap = (assets: MapAssetType[], filters: FilterContextType) => {
  // basically if the mapfilter extent is enabled and the map bounds change, update our map filtered assets
  if (filters.mapFilterState.extentFilterEnabled) {
    return assets.filter((asset) => {
      const coordinates = asset?.primaryLocation?.coordinates;
      const type = asset?.primaryLocation?.type;
      const mapBounds = filters?.mapFilterState?.mapBounds;
      if (coordinates && mapBounds) {
        const { _sw, _ne } = mapBounds;
        if (type === 'Point') {
          const lng = coordinates[0];
          const lat = coordinates[1];
          return _sw.lng <= lng && _sw.lat <= lat && _ne.lng >= lng && _ne.lat >= lat;
        } else if (type === 'LineString') {
          const isAssetLocationInsideMapBounds = (coordinates as Position[]).some((coordinate) => {
            const lng = coordinate[0];
            const lat = coordinate[1];
            return _sw.lng <= lng && _sw.lat <= lat && _ne.lng >= lng && _ne.lat >= lat;
          });
          return isAssetLocationInsideMapBounds;
        }
      }
      return false;
    });
  }
  return assets;
};
