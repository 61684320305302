import React, { FunctionComponent, useContext, useEffect } from 'react';
import LoginScreenContainer from './pages/LoginScreenContainer';
import { Route, Switch, Redirect } from 'react-router-dom';
import FilterContextProvider from './contexts/FilterContext/filterContext';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SelectedProjectProvider } from './contexts/selectedProjectContext';
import { RecordTypeProvider } from './contexts/recordTypeContext';
import { AccountModalProvider } from './contexts/accountModalContext';
import AssetsDashboard from './pages/AssetsDashboard/AssetsDashboard';
import ResetPassword from './pages/ResetPassword';
import { AnalyticsModalProvider } from './contexts/analyticsModalContext';
import ProtectedRoute from './components/ProtectedRoute';
import { MuiThemeProvider } from '@material-ui/core/styles';
import './googleAnalytics';
import './components/magic/magicText';
import { EditModeProvider } from './contexts/editModeContext';
import { AssetsDashboardProvider } from './contexts/assetsDashboardContext';
//Initialize font awesome with all the things
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { MultipleAssetsProvider } from './contexts/assetCardContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { NetworkStatusProvider } from './contexts/networkStatusContext';
import { InfoContextProvider } from './contexts/InfoContext/infoContext';
import { MapStyleProvider } from './contexts/mapStyle';
import { ImportModalProvider } from './contexts/importModalContext';
import { PusherProvider } from './contexts/pusherContext';
import { useHistory } from 'react-router-dom';
import {
  Apollo as ApolloProvider,
  ErrorBoundaryWeb,
  AuthContext,
  useAuthState,
  ConfigProvider,
  SpinnerContextProvider,
  ServerPublicMetaContextProvider,
  ServerPublicMetaContext,
  UserInfoProvider,
  MapBoundsProvider,
  theme,
} from '@terragotech/gen5-shared-components';
import PageContainer from './pages/PageContainer';
import { PhotoViewerProvider } from './hooks/usePhotoViewer';
import { SelectedLocationProvider } from './contexts/selectedLocationContext';
import AssetDownload from './pages/AssetDownload';
import { AboutProvider } from './contexts/aboutContext';
import { LayoutProvider } from './components/LayoutContext';
import { AlertProvider } from './contexts/AlertModalContext';
import { ProjectsProvider } from './contexts/projectsContext';
import { UtilProvider } from "./contexts/utilContext";
import LegalDisclaimerView from './components/Login/LegalDisclaimerView';
import { setDisclaimerState } from './utils/utilityHelper';
library.add(fas);

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React);
}

/**
 * App.tsx = The Root Component
 *
 * @remarks App.tsx is the root component that contains the routing for the entire web app.
 *
 * <Switch /> definition - Renders the first child <Route> or <Redirect> that matches the location.
 *
 * It initially checks if path matches '/login', which displays LoginScreenContainer
 *
 * Secondly, checks if the path is '/'
 *    Logic Inside <ProtectedRoute /> component
 *        If user is not logged in, redirects to '/login'
 *        If user is logged in, displays Component prop passed to ProtectedRoute, which is AssetsDashboard
 *
 * Lastly, if path was not '/login' or '/', it directly changes the path to '/'
 *
 */
const App: FunctionComponent = () => {
  const authState = useAuthState();
  const history = useHistory();

  console.log(React.version);

  const handleOnDisclaimerAccepted = () =>{
    setDisclaimerState(true)
    history.push('/')
  }

  return (
    <NetworkStatusProvider>
        <ApolloProvider
          token={authState.token}
          uri={
            process.env.NODE_ENV === 'production'
              ? '/graphql'
              : `${process.env.REACT_APP_SERVER_URL || 'http://localhost:3002'}/graphql`
          }
          onTokenExpired={authState.clearToken}
          onRedirectLogin={() => history.push('/')}
        >
          <ServerPublicMetaContextProvider>
            <ServerPublicMetaContext.Consumer>
              {serverPublicMetaContext => {
                return (
                  <LayoutProvider>
                    <MuiThemeProvider
                      theme={theme(serverPublicMetaContext.theme.primary, serverPublicMetaContext.theme.secondary)}
                    >
                      <UtilProvider>
                      <ErrorBoundaryWeb>
                      <AlertProvider>
                        <SpinnerContextProvider>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <AuthContext.Provider value={authState}>
                              <SelectedLocationProvider>
                                <UserInfoProvider>
                                  <EditModeProvider>
                                    <InfoContextProvider>
                                      <AssetsDashboardProvider>
                                        <ConfigProvider>
                                          <PusherProvider>
                                            <MapStyleProvider>
                                              <MapBoundsProvider>
                                                <RecordTypeProvider>
                                                  <FilterContextProvider>
                                                    <ProjectsProvider>
                                                      <SelectedProjectProvider>
                                                        <MultipleAssetsProvider>
                                                          <DndProvider backend={HTML5Backend}>
                                                            <AnalyticsModalProvider>
                                                              <ImportModalProvider>
                                                                <AccountModalProvider>
                                                                  <AboutProvider>
                                                                    <Switch>
                                                                      <Route path="/page/:pageName/:agg?">
                                                                        <PhotoViewerProvider>
                                                                          <PageContainer />
                                                                        </PhotoViewerProvider>
                                                                      </Route>
                                                                      <Route path="/login">
                                                                        <LoginScreenContainer
                                                                          onLoginSuccess={authState.loginSuccess}
                                                                        />
                                                                      </Route>
                                                                      <Route path="/legalDisclaimer">
                                                                        <LegalDisclaimerView onDisclaimerAccepted={()=>handleOnDisclaimerAccepted()} containerStyle/>
                                                                      </Route>
                                                                      <Route path="/resetPassword">
                                                                        <ResetPassword />
                                                                      </Route>
                                                                      <ProtectedRoute path="/doc">
                                                                        <Route path="/doc/download/:GUID">
                                                                          <AssetDownload />
                                                                        </Route>
                                                                      </ProtectedRoute>
                                                                      <ProtectedRoute path="/">
                                                                        <AssetsDashboard />
                                                                      </ProtectedRoute>
                                                                      <Redirect to="/" />
                                                                    </Switch>
                                                                  </AboutProvider>
                                                                </AccountModalProvider>
                                                              </ImportModalProvider>
                                                            </AnalyticsModalProvider>
                                                          </DndProvider>
                                                        </MultipleAssetsProvider>
                                                      </SelectedProjectProvider>
                                                    </ProjectsProvider>
                                                  </FilterContextProvider>
                                                </RecordTypeProvider>
                                              </MapBoundsProvider>
                                            </MapStyleProvider>
                                          </PusherProvider>
                                        </ConfigProvider>
                                      </AssetsDashboardProvider>
                                    </InfoContextProvider>
                                  </EditModeProvider>
                                </UserInfoProvider>
                              </SelectedLocationProvider>
                            </AuthContext.Provider>
                          </MuiPickersUtilsProvider>
                        </SpinnerContextProvider>
                      </AlertProvider>
                      </ErrorBoundaryWeb>
                      </UtilProvider>
                    </MuiThemeProvider>
                  </LayoutProvider>
                );
              }}
            </ServerPublicMetaContext.Consumer>
          </ServerPublicMetaContextProvider>
        </ApolloProvider>
    </NetworkStatusProvider>
  );
};

export default App;
