import { useConfig } from '@terragotech/gen5-shared-components';
import streetlightsLogo from '../images/streetlights-logo.png';

const AppLogo = ({ className }: { className: string }) => {
  const configContext = useConfig();
  return (
    <img
      src={(configContext.theme.logoUrl.length > 0 && configContext.theme.logoUrl) || streetlightsLogo}
      alt="logo"
      className={className}
    />
  );
};

export default AppLogo;
