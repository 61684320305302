import { Button, makeStyles, Theme, createStyles, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { colors } from '../../styles/theme';

export interface WorkflowCommandButtonProps {
  disabled: boolean;
  disabledMessage?: string;
  label?: string;
  icon?: string;
  onClick: () => void;
}

const WorkflowCommandButton = ({ disabled, disabledMessage, label, icon, onClick }: WorkflowCommandButtonProps) => {
  const classes = useStyles();

  if (disabled && disabledMessage) {
    return null;
  }
  return (
    <Button
      variant="outlined"
      color="secondary"
      className={classes.commandText}
      onClick={onClick}
      disabled={disabled}
      startIcon={icon && <FontAwesomeIcon icon={['fas', icon as IconName]} />}
    >
      <Typography>{label}</Typography>
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    commandText: {
      height: 34,
      padding: '6px 10px',
      borderRadius: 5,
      border: 'none',
      color: colors.black54,
      boxShadow: `0px 1px 4px 0px ${colors.black15}`,
      '&:hover': {
        border: 'none',
      },
      '& .MuiButton-label': {
        gap: 2,
      },
      '& .MuiButton-startIcon': {
        width: 22,
        height: 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        color: colors.black54,
      },
      '& .MuiTypography-root': {
        fontWeight: 400,
        fontSize: 14,
        lineHeight: 'normal',
        fontFamily: 'Roboto',
        textTransform: 'none',
        textAlign: 'left',
        padding: '2px 5px',
        color: colors.black75,
      },
    },
    disabledButton: {
      width: 'fit-content',
      cursor: 'default',
    },
  })
);

export default WorkflowCommandButton;
