import { useState, useMemo, useCallback } from 'react';
import useUserPreferences from '../../hooks/useUserPreferences';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';

const MAP_VISIBILITY_PREFERENCE_KEY = 'recordTypesVisibility';

export interface UseVisibleAggregatesStateArgs {
  aggregateDefinitions: AggregateDefinition[];
}

export interface UseVisibleAggregatesStateReturnType {
  visibleAggregateTypesKeys: string[];
  visibleAggregateTypesNames: string[];
  setVisibleAggregateTypesNames: (names: string[]) => void;
}

export const useVisibleAggregatesState = ({
  aggregateDefinitions,
}: UseVisibleAggregatesStateArgs): UseVisibleAggregatesStateReturnType => {
  const { getPreferenceValue, setPreferenceValue } = useUserPreferences();

  const [visibleAggregateTypesKeys, setVisibleAggregateTypesKeys] = useState(
    getPreferenceValue<string[]>(MAP_VISIBILITY_PREFERENCE_KEY) ?? aggregateDefinitions.map((x) => x.queryKey)
  );
  const visibleAggregateTypesNames = useMemo(
    () =>
      aggregateDefinitions
        .filter((definition) => visibleAggregateTypesKeys.includes(definition.queryKey))
        .map((definition) => definition.name),
    [aggregateDefinitions, visibleAggregateTypesKeys]
  );
  const setVisibleAggregateTypesNames = useCallback(
    (names: string[]) => {
      const newKeys = aggregateDefinitions
        .filter((definition) => names.includes(definition.name))
        .map((definition) => definition.queryKey);
      setVisibleAggregateTypesKeys(newKeys);
      setPreferenceValue(MAP_VISIBILITY_PREFERENCE_KEY, newKeys);
    },
    [aggregateDefinitions, setPreferenceValue]
  );

  return {
    visibleAggregateTypesKeys,
    visibleAggregateTypesNames,
    setVisibleAggregateTypesNames,
  };
};
