import React from 'react';
import { Theme, createStyles, withStyles, WithStyles } from '@material-ui/core';
import { Input, InputAdornment } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import magicText from 'i18next';
import { colors } from '../../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import clsx from 'clsx';

interface SearchInputProps extends WithStyles<typeof styles> {
  name?: string;
  value: string;
  setValue: (value: string) => void;
  isIOSSafari?: boolean;
  commonClasses?: {safariDefaultFontSize: string};
}

const SearchInput = ({ classes, name, value, setValue, isIOSSafari, commonClasses }: SearchInputProps) => (
  <div className={classes.assetSearchBar}>
    <Input
      startAdornment={
        <InputAdornment position="start">
          <Search className={classes.searchIcon} />
        </InputAdornment>
      }
      placeholder={magicText.t('Search')}
      value={value}
      className={clsx(classes.input, (isIOSSafari && commonClasses) && commonClasses.safariDefaultFontSize)}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
      }}
    />
  </div>
);

const styles = (theme: Theme) =>
  createStyles({
    assetSearchBar: {
      backgroundColor: colors.white,
      color: theme.palette.grey[200],
      padding: '5px 10px',
      display: 'inline-flex',
      flexGrow: 1,
      border: `1px solid ${colors.black10}`,
      borderRadius: 5,
      maxWidth: 332,
      height: 24,
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        maxWidth: '100%',
      },
    },
    searchIcon: {
      height: 24,
      width: 24,
      color: colors.black35,
    },
    input: {
      position: 'unset',
      width: '100%',
      fontSize: 14,
      color: colors.black0,
      '&::placeholder': {
        color: colors.black35,
        fontSize: 14,
        fontWeight: 400,
      },
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
  });

export default withStyles(styles)(SearchInput);
