import React, { createContext, FunctionComponent, useContext, useState } from 'react';

interface SelectedLocationContextType {
  selectedLocation?: {lat:number,lon:number}
  setSelectedLocation: (newLoc?: {lat:number,lon:number}) => void;
}

const SelectedLocationContext = createContext<SelectedLocationContextType>({
  setSelectedLocation: () => {},
});

export const SelectedLocationProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = (props) => {
  const [selectedLocation,setSelectedLocation] = useState<{lat:number,lon:number}>()

  const value: SelectedLocationContextType = { selectedLocation,setSelectedLocation };

  return (
    <SelectedLocationContext.Provider value={value}>
      {props.children}
    </SelectedLocationContext.Provider>
  );
};

export const useSelectedLocation = () => {
  const context = useContext(SelectedLocationContext);
  if (!context) {
    throw new Error('useSelectedLocation should be used inside SelectedLocationProvider');
  }
  return context;
};
