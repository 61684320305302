import React from 'react';
import DialogWrapper, { DialogWrapperProps } from './DialogWrapper';
import TGLazyModalList, { TGLazyModalListProps } from './TGLazyModalList';
import { isEmpty } from 'lodash';

export interface TGOptionSelectorModalProps<DataType, StateType, RowRendererPropsType>
  extends Omit<TGLazyModalListProps<DataType, StateType, RowRendererPropsType>, 'searchText'> {
  searchText: string;
  handleSearchText: (text: string) => void;
  title: string;
  open: boolean;
  filterPlaceHolder: string;
  actions: DialogWrapperProps['actions'];
  onCancel: DialogWrapperProps['onCancel'];
  onDone?: DialogWrapperProps['onDone'];
}
/**
 * A component that combines DialogWrapper header with lazy list
 * @param props
 */
const TGLazyModal = <DataType extends unknown, StateType extends unknown, RowRendererPropsType extends unknown>(
  props: TGOptionSelectorModalProps<DataType, StateType, RowRendererPropsType>
) => {
  const {
    open,
    title,
    filterPlaceHolder,
    actions,
    onDone,
    onCancel,
    searchText,
    handleSearchText,
    ...modalListProps
  } = props;
  return (
    <DialogWrapper
      title={title}
      open={open}
      filterPlaceHolder={filterPlaceHolder}
      searchText={searchText}
      handleSearchText={handleSearchText}
      actions={actions}
      onCancel={onCancel}
      onDone={onDone}
      isRoles={!isEmpty(modalListProps.state) as boolean}
    >
      <TGLazyModalList {...modalListProps} searchText={searchText} />
    </DialogWrapper>
  );
};

export default TGLazyModal;
