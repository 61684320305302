import { useMemo, useState } from 'react';
import { Checkbox, InputAdornment, TextField, Theme, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import magicText from 'i18next';
import { permissionLabel } from '@terragotech/gen5-shared-utilities';
import { chain, toLower } from 'lodash';
import { Editor2Props } from '@terragotech/react-data-grid';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { useConfig, useUserInfo } from '@terragotech/gen5-shared-components';

import { TableData } from '../../hooks/useTable';
import { User, UserWithoutId } from './types';
import useStyles, { getClasses } from '../../components/StyledTable/Editors/Common';
import PermissionModal from './PermissionModal';
import { colors } from '../../styles/theme';

export interface AdminPermissionEditorModalProp extends Editor2Props<TableData> {
  numberOfUsersWithAnalyticsPermissions: number;
}

const AdminPermissionsEditorModal = (props: AdminPermissionEditorModalProp) => {
  const { row, onRowChange, onClose, numberOfUsersWithAnalyticsPermissions } = props;
  const classes = useLocalStyles();
  const tableClasses = useStyles();
  const { isTgoAdmin } = useUserInfo();
  const uiConfig = useConfig();

  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);
  const analyticsMaxSeatCount = useMemo(() => uiConfig.analyticsMaxSeatCount, [uiConfig]);
  const mapServicesEnabled = useMemo(() => uiConfig.integrations?.mapServices, [uiConfig]);
  const [currentPermission, setCurrentPermission] = useState('');

  const canModifyAnalyticsStatus = isTgoAdmin;
  const canModifyMapServicesPermissions = isTgoAdmin;

  const [isModalOpen, setIsModalOpen] = useState(false);
  //I don't like these casts, but nested objects don't work well with ValueType
  const [isUserAdmin, setIsUserAdmin] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isUserAdmin
  );
  const [isRoleAndDataAdmin, setIsRoleAndDataAdmin] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isRoleAndDataAdmin
  );
  const [isAnalyticsUser, setIsAnalyticsUser] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isAnalyticsUser
  );
  const [isMapServicesUser, setIsMapServicesUser] = useState(
    (row.adminPermissions as UserWithoutId['adminPermissions'])?.isMapServicesUser
  );

  const [currentAnalyticsSeatCount, setCurrentAnalyticsSeatCount] = useState(numberOfUsersWithAnalyticsPermissions);
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleOnClick = () => {
    setIsModalOpen(true);
  };
  const handleOkClicked = () => {
    onRowChange(
      {
        ...row,
        adminPermissions: {
          isUserAdmin,
          isRoleAndDataAdmin,
          isAnalyticsUser,
          isMapServicesUser,
        },
        __changes: {
          adminPermissions:
            row.isUserAdmin !== isUserAdmin ||
            row.isRoleAndDataAdmin !== isRoleAndDataAdmin ||
            row.isAnalyticsUser !== isAnalyticsUser ||
            row.isMapServicesUser !== isMapServicesUser
              ? {
                  isUserAdmin: isUserAdmin,
                  isRoleAndDataAdmin: isRoleAndDataAdmin,
                  isAnalyticsUser: isAnalyticsUser,
                  isMapServicesUser: isMapServicesUser,
                }
              : undefined,
        },
      },
      true // Commit change immediately to the rowData
    );
    handleClose();
  };
  const handleCancelClicked = () => {
    handleClose();
    onClose(false);
  };

  const handleFilterSearch = (value: string) => {
    setCurrentPermission(value);
  };

  const permissions = useMemo(() => {
    return chain([
      {
        key: 'user',
        checked: isUserAdmin,
        onChange: (value?: boolean) => setIsUserAdmin(typeof value === 'boolean' ? value : !isUserAdmin),
        title: magicText.t('UsersAdministration.manage_users'),
        description: magicText.t('UsersAdministration.manage_users_description'),
      },
      {
        key: 'role',
        checked: isRoleAndDataAdmin,
        onChange: (value?: boolean) => setIsRoleAndDataAdmin(typeof value === 'boolean' ? value : !isRoleAndDataAdmin),
        title: magicText.t('UsersAdministration.manage_roles'),
        description: magicText.t('UsersAdministration.manage_roles_description'),
      },
      canModifyMapServicesPermissions &&
        mapServicesEnabled && {
          key: 'mapService',
          checked: isMapServicesUser,
          onChange: (value?: boolean) => setIsMapServicesUser(typeof value === 'boolean' ? value : !isMapServicesUser),
          title: magicText.t('UsersAdministration.map_services_users'),
          description: magicText.t('UsersAdministration.map_services_users_description'),
        },
      canModifyAnalyticsStatus &&
        analyticsEnabled && {
          key: 'analytics',
          checked: isAnalyticsUser,
          onChange: (value?: boolean) => {
            setCurrentAnalyticsSeatCount(
              isAnalyticsUser ? currentAnalyticsSeatCount - 1 : currentAnalyticsSeatCount + 1
            );
            setIsAnalyticsUser(typeof value === 'boolean' ? value : !isAnalyticsUser);
          },
          title: magicText.t('UsersAdministration.view_analytics_module'),
          description: magicText.t('UsersAdministration.view_analytics_module_description'),
        },
    ])
      .compact()
      .filter(value => toLower(value.title).includes(toLower(currentPermission)))
      .value();
  }, [
    isUserAdmin,
    isRoleAndDataAdmin,
    isMapServicesUser,
    isAnalyticsUser,
    currentAnalyticsSeatCount,
    analyticsEnabled,
    canModifyAnalyticsStatus,
    mapServicesEnabled,
    canModifyMapServicesPermissions,
    currentPermission,
  ]);

  const isAllSelected = permissions.every(x => x.checked);

  const toggleSelection = () => {
    permissions.forEach(p => p.onChange(!isAllSelected));
  };

  return (
    <div className={getClasses(props, tableClasses).join(' ')}>
      <TextField
        value={permissionLabel((row as User).adminPermissions).join(', ')}
        /*onClick={handleOnClick}*/
        inputProps={{ 'aria-label': `change data`, className: classes.input }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown color="primary" className={classes.icon} />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        onClick={handleOnClick}
      />
      <PermissionModal
        {...{
          open: isModalOpen,
          handleClose,
          displayName: magicText.t('UsersAdministration.adminPermissionModalTitle'),
          handleFilterSearch,
          handleApply: handleOkClicked,
          handleCancel: handleCancelClicked,
        }}
      >
        <Box className={classes.content}>
          <Typography className={classes.headerDescription}>
            {magicText.t('UsersAdministration.adminPermissionModalDescription') as string}
          </Typography>
          <Typography className={classes.toggleButton} onClick={toggleSelection}>
            {isAllSelected ? 'Des' : 'S'}elect All ({permissions.length})
          </Typography>
          {permissions.map(permission => {
            return (
              <Box className={classes.permissionContainer} key={permission.key}>
                <Checkbox
                  className={classes.checkbox}
                  checked={Boolean(permission.checked)}
                  onChange={() => permission.onChange()}
                  color={'primary'}
                  disableRipple
                />
                <Box className={classes.descriptionContainer}>
                  <Typography className={classes.titleText}>{permission.title}</Typography>
                  <Typography className={classes.descriptionText}>{permission.description}</Typography>
                  {permission.key === 'analytics' && (
                    <div
                      className={`${classes.license} 
                        ${
                          currentAnalyticsSeatCount < analyticsMaxSeatCount
                            ? classes.availableLicense
                            : classes.unavailableLicense
                        }
                      `}
                    >
                      <>
                        {' '}
                        {magicText.t('UsersAdministration.licenses_available')}:{' '}
                        {`${analyticsMaxSeatCount - currentAnalyticsSeatCount} of ${analyticsMaxSeatCount}.`}{' '}
                        {currentAnalyticsSeatCount >= analyticsMaxSeatCount && (
                          <span className={classes.normalFont}>
                            <>{magicText.t('UsersAdministration.licenses_unavailable_description')}</>
                          </span>
                        )}
                      </>
                    </div>
                  )}
                </Box>
              </Box>
            );
          })}
        </Box>
      </PermissionModal>
    </div>
  );
};

const useLocalStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  checkbox: {
    padding: 0,
  },
  headerTitle: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    paddingBottom: '26px',
  },
  headerDescription: {
    color: colors.black54,
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 'normal',
  },
  toggleButton: {
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.primary.main,
    lineHeight: 'normal',
    cursor: 'pointer',
  },
  permissionContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 10,
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  titleText: {
    color: colors.black0,
    fontSize: 15,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  descriptionText: {
    color: colors.black54,
    fontSize: 14,
    lineHeight: 'normal',
    fontWeight: 400,
  },
  input: {
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    padding: 0,
    verticalAlign: 'middle',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
  license: {
    fontSize: '13px',
    lineHeight: '128.9%',
    fontWeight: 500,
  },
  availableLicense: {
    color: `${theme.palette.success.main}`,
  },
  unavailableLicense: {
    color: `${theme.palette.error.main}`,
  },
  normalFont: {
    fontWeight: 'normal',
  },
}));

export default AdminPermissionsEditorModal;
