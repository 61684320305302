import { Button, Typography, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { isCoordinatesValid } from '@terragotech/gen5-shared-utilities';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ActionIcon from '../../ActionIcon';
import { BaseLocationMapProps } from './CommonEditorUtils';
import TGCoordinateTextField from './TGCoordinateTextField';
import { colors } from '../../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';

interface TGMultipleLocationSidebarProps extends BaseLocationMapProps {
  value: GeoJSON.Point | GeoJSON.Point[] | GeoJSON.LineString | null | undefined;
  capturedLocation: GeoJSON.Point | GeoJSON.Point[] | null | undefined;
  setCapturedLocation: Dispatch<SetStateAction<GeoJSON.Point | GeoJSON.Point[] | null | undefined>>;
  setManualMode: Dispatch<SetStateAction<boolean>>;
  maximum: number | undefined;
  unlimited: boolean | undefined;
  selectedPoint:
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined;
  setSelectedPoint: React.Dispatch<
    React.SetStateAction<
      | {
          coordinates: number[];
          indexOfCoords: number;
        }
      | undefined
    >
  >;
  selectedPointRef: React.MutableRefObject<
    | {
        coordinates: number[];
        indexOfCoords: number;
      }
    | undefined
  >;
  setOpenAddLocationAlert: React.Dispatch<React.SetStateAction<boolean>>;
  children?: React.ReactElement;
}
export const PADDING_SPACE = 49;
const HEADER_FOOTER_HEIGHT = 127;
 export const CONTAINER_HEIGHT_MOB = 166;
const useStyles = makeStyles(theme => ({
  text: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    padding: 0,
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0em',
    fontStyle: 'normal',
    '&.MuiButton-root.Mui-disabled': {
      color: colors.black35,
    },
  },
  addLocationIcon: {
    '&.disabled': {
      filter: 'sepia(0%) saturate(5424%) hue-rotate(322deg) brightness(136%)',
    },
  },
  rootContainer: {
    height: `calc(100% - ${PADDING_SPACE}px)`,
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      height: `100%`,
    },
  },
  mainContainer: {
    padding: '22px 30px',
    height: `calc(100% - ${HEADER_FOOTER_HEIGHT}px)`,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      padding: '22px',
      paddingTop: 20,
      height: `100%`,
    },
  },
}));

const TGMultipleLocationSidebar = (props: TGMultipleLocationSidebarProps) => {
  const {
    maximum,
    unlimited,
    capturedLocation,
    setCapturedLocation,
    setIsValidLocation,
    selectedPoint,
    setSelectedPoint,
    selectedPointRef,
    setOpenAddLocationAlert,
    children,
  } = props;
  const classes = useStyles();
  const [blankField, setBlankField] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (Array.isArray(capturedLocation)) {
      let invalidLocation = capturedLocation.some(
        (location: GeoJSON.Point) =>
          !location ||
          !isCoordinatesValid(location.coordinates[1], location.coordinates[0]) ||
          location.type !== 'Point'
      );
      if (invalidLocation) {
        setIsValidLocation(false);
      } else {
        setIsValidLocation(capturedLocation.length > 0);
      }
    }
  }, [capturedLocation, blankField]);

  const canCaptureLocation = useMemo(() => {
    // Location must be array or null or undefined
    return (capturedLocation && Array.isArray(capturedLocation)) || !capturedLocation;
  }, [capturedLocation]);

  const canAddLocation = useMemo(() => {
    // If maximum exists, and capturedLocation is not null or undefined, the length must be less than the maximum. Unless unlimited is true
    const maxCheck =
      (!!maximum && Array.isArray(capturedLocation) && (capturedLocation || []).length < maximum) ||
      !capturedLocation ||
      unlimited;
    return canCaptureLocation && maxCheck;
  }, [canCaptureLocation, capturedLocation, maximum, unlimited]);

  useEffect(() => {
    if (!canAddLocation) {
      setBlankField(false);
    }
  }, [blankField, canAddLocation]);

  const calculatedHeight = useMemo(() => {
    if (capturedLocation && Array.isArray(capturedLocation)) {
      return capturedLocation.length * 38;
    } else {
      return 0;
    }
  }, [capturedLocation]);

  return (
    <div className={classes.rootContainer}>
      {children}
      <div className={classes.mainContainer}>
        {
          <DragDropContext
            onDragStart={() => {
              setIsDragging(true);
            }}
            onDragEnd={(result, provided) => {
              setIsDragging(false);
              if (!result.destination) {
                return;
              }
              let temp;
              if (!Array.isArray(capturedLocation) && capturedLocation) {
                temp = [capturedLocation];
              } else {
                temp = capturedLocation;
              }
              if (!temp) return;
              const newItems = [...temp];
              const [removed] = newItems.splice(result.source.index, 1);
              newItems.splice(result.destination.index, 0, removed);
              setCapturedLocation(newItems);
            }}
          >
            <Droppable droppableId="uniqueDroppableId">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: calculatedHeight }}>
                  {capturedLocation &&
                    Array.isArray(capturedLocation) &&
                    (capturedLocation || []).map((x: GeoJSON.Point, index: number) => {
                      return (
                        <Draggable index={index} draggableId={String(index)} key={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <TGCoordinateTextField
                                index={index}
                                capturedLocation={capturedLocation}
                                setCapturedLocation={setCapturedLocation}
                                specificLocation={x}
                                setBlankField={setBlankField}
                                selectedPoint={selectedPoint}
                                setSelectedPoint={setSelectedPoint}
                                selectedPointRef={selectedPointRef}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        }
        {blankField && canAddLocation && (
          <TGCoordinateTextField
            index={Array.isArray(capturedLocation) ? capturedLocation.length : 0}
            capturedLocation={capturedLocation}
            setCapturedLocation={setCapturedLocation}
            specificLocation={undefined}
            blankField={blankField}
            setBlankField={setBlankField}
            setSelectedPoint={setSelectedPoint}
            selectedPointRef={selectedPointRef}
          />
        )}
        {isDragging === false && (
          <div style={{ marginTop: 18 }}>
            <Button
              className={classes.text}
              color="primary"
              aria-label="Add Location"
              disabled={blankField || !canCaptureLocation}
              onClick={() => {
                if (canAddLocation) {
                  setBlankField(true);
                } else {
                  setOpenAddLocationAlert(true);
                }
              }}
            >
              <ActionIcon name="fa-circle-plus" color={(blankField || !canCaptureLocation) ? colors.black25 : theme.palette.primary.main} />{' '}
              <span style={{ paddingLeft: 8, textTransform: 'none' }}>Add Location</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TGMultipleLocationSidebar;
