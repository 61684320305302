import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import TempPasswordModal from '../components/Login/TempPasswordModal';
import { AuthConnector } from '@terragotech/gen5-shared-components';

// custom hook example from react-router docs, nifty
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: React.FC = (props: any) => {
  const query = useQuery();

  let code = query.get('code') || '';
  let username = query.get('username') || '';

  const reset = useCallback(
    (password: any) => {
      if (username && code && password)
        AuthConnector.forgotPasswordSubmit(username, code, password).then(() => {
          // nothing is returned, if this succeeds, we can log in using the same creds
          AuthConnector.login(username, password);
        });
    },
    [code, username]
  );
  return (
    <div>
      <TempPasswordModal title={'Reset Your Password'} onOkPress={reset} />
    </div>
  );
};

export default ResetPassword;
