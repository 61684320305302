import { makeStyles } from '@material-ui/core';
import { EDITOR_Z_INDEX } from '../../../utils/layers';
import { StyledTableEditorProps } from '../../../hooks/tableHooks/useColumns';
import { colors } from '../../../styles/theme';

const useStyles = makeStyles(theme => ({
  editor: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '30px',
    backgroundColor: colors.white,
    boxSizing: 'border-box',
    paddingLeft: '2px',
    marginTop: 4,
    marginLeft: -4,
    width: '-webkit-fill-available',
    zIndex: EDITOR_Z_INDEX,
    position: 'absolute',
    marginRight: 4,
    border: `solid 1px grey`,
    '& > div, > div > div': {
      height: '100%',
      width: '100%',
      boxSizing: 'border-box',
    },
  },
  clickable: {
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },
  },
  switchRoot: {
    height: '24px',
    width: '40px',
    padding: 0,
  },
  switchBase: {
    height: '24px',
    padding: '0',
  },
  switchTrack: {
    height: '12px',
    margin: '6px 0',
  },
  switchThumb: {
    height: '20px',
    width: '20px',
    padding: 0,
  },
  input: {
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    fontSize: '13px',
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    padding: 0,
    verticalAlign: 'middle',
  },
  icon: {
    width: '15px',
    height: '15px',
  },
  textEditorCursor: {
    cursor: 'text',
  },
}));

export const getClasses = (
  props: StyledTableEditorProps,
  classes: { editor: string; clickable: string; textEditorCursor: string },
  clickable = true,
  dataType?: string
) => {
  return [classes.editor, ...(clickable && dataType !== 'string' ? [classes.clickable] : [classes.textEditorCursor])];
};

export default useStyles;
