import React from 'react';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

const ErrorDisplay = ({ error }: { error: unknown }) => (
  <Tooltip title={`Error: ${error}`} aria-label={`Error: ${error}`}>
    <ErrorIcon />
  </Tooltip>
);

export default ErrorDisplay;
