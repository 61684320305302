import { useRouteMatch } from 'react-router-dom';

interface RouteParams {
    isAssetDetailsOpen: boolean;
}
interface Props {
    selectedRecordType: string;
    path?: string;
}
const useRouteParams = (props: Props): RouteParams => {
    const {selectedRecordType, path} = props;
    const defaultPath = `/${selectedRecordType}/:assetId?/:comparison(map|data|edit)?`;
    const match = useRouteMatch<{ assetId?: string; comparison?: 'map' | 'data' | 'edit' }>(path ?? defaultPath);
    let assetId = match?.params.assetId || '';
    let comparison = match && match.params && match.params.comparison; 
  return {
    isAssetDetailsOpen: Boolean(assetId && comparison === 'edit')
  };
};

export default useRouteParams;
