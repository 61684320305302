import { makeStyles } from '@material-ui/core';
import { colors } from '../../../../../styles/theme';

const SEARCH_BAR_HEIGHT = 95;

export const useCommonStyles = makeStyles(theme => ({
  container: {
    overflow: 'hidden',
    height: '100%',
  },
  outer: {
    paddingRight: 6,
    height: `calc(100% - ${SEARCH_BAR_HEIGHT}px)`,
  },

  detailContainer: {
    height: '100%',
    padding: '18px 28px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
  },
  linkBtn: {
    fontSize: 15,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textDecoration: 'none',
    lineHeight: 'normal',
    display: 'flex',
  },
  bp: {
    paddingBottom: 4,
  },
}));
