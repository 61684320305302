import { useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import csvSuccess from '../../images/csvSuccess.svg';
import csvError from '../../images/csvError.svg';
import xlsSuccess from '../../images/xlsSuccess.svg';
import xlsError from '../../images/xlsError.svg';
import { SelectedImportType } from './ImportHistory';
import { useConfig } from '@terragotech/gen5-shared-components';
import { Import } from './ImportHistory';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { downloadFile } from './FileToView';
import { DOC_API_URL } from '../../utils/docApiURL';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import { useRowStyles } from './useRowStyles';

const useStyles = makeStyles(theme => ({
  fileIconContainer: {
    display: 'flex',
    padding: '5px 12px',
    gap: 10,
  },
  button: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    width: 181,
    height: 34,
    textTransform: 'none',
    padding: '8px 19px',
    fontSize: 15,
    fontWeight: 500,
    lineHeight: 'normal',
    borderRadius: 4,
  },
  fileIcon: {
    width: 27,
    height: 27,
  },
}));

interface DataProp {
  selectedImport?: SelectedImportType;
  setSelectedImport: React.Dispatch<React.SetStateAction<SelectedImportType | undefined>>;
  imp: Import[];
  setFileIdToView: (x: string) => void;
}
interface Props {
  data: DataProp;
  index: number;
}

const CompletedJobRow = ({ data, index }: Props) => {
  const classes = useStyles();
  const commonClasses = useRowStyles();
  const specifiedImport = data?.imp[index];
  const { defaultDateTimeFormat } = useConfig();

  const fileExtension: string | undefined = specifiedImport?.filename.split('.').pop();
  const isXLS: boolean = fileExtension === 'xlsx' || fileExtension === 'xls';

  const status = useMemo(() => {
    if (specifiedImport?.commandError != null) {
      return 'Error';
    } else if (specifiedImport?.success) {
      return 'Imported';
    } else if (specifiedImport?.errorsWarnings.filter(e => e.is_error).length || specifiedImport?.committed) {
      return 'Failed';
    } else {
      return 'Cancelled';
    }
  }, [specifiedImport]);

  const showDetailsButton = useMemo(() => {
    return !specifiedImport?.commandError || specifiedImport?.commandError > 10130; // HandleImportValidate_UpdateImport (services/graphql_api/src/services/service.import.ts)
  }, [specifiedImport]);

  let importSource = '';
  if (isXLS) {
    if (specifiedImport.success) {
      importSource = xlsSuccess;
    } else {
      importSource = xlsError;
    }
  } else {
    if (specifiedImport.success) {
      importSource = csvSuccess;
    } else {
      importSource = csvError;
    }
  }

  return (
    <TableRow className={commonClasses.tableRow} key={index}>
      <TableCell className={commonClasses.fileCell}>
        <Box className={classes.fileIconContainer}>
          <div className={classes.fileIcon}>
            <img style={styles.fileImg} src={importSource} alt="File Type Icon" />
          </div>
          <div className={commonClasses.fileNameRow}>
            {specifiedImport?.filename ? specifiedImport?.filename : 'TempFile'}
          </div>
        </Box>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{specifiedImport?.type}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{status}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{specifiedImport?.metadata?.userInfo?.email}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <span className={commonClasses.cellItem}>
          {specifiedImport?.timestamp
            ? moment(String(specifiedImport?.timestamp)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
                  defaultDateTimeFormat?.isSwitch
                )
              )
            : ''}
        </span>
      </TableCell>
      <TableCell className={commonClasses.btnCellStyle}>
        <Box className={commonClasses.btnContainer}>
          {!specifiedImport.isintegration && showDetailsButton && (
            <Button
              variant="outlined"
              onClick={() => {
                data.setFileIdToView(specifiedImport?.id);
                data.setSelectedImport(specifiedImport);
              }}
              className={classes.button}
            >
              View Records
            </Button>
          )}
          {specifiedImport.isintegration && showDetailsButton && (
            <Button
              variant="outlined"
              className={classes.button}
              onClick={() => {
                downloadFile(
                  `${DOC_API_URL}${specifiedImport?.id}`,
                  `${
                    specifiedImport?.filename.substring(0, specifiedImport?.filename.lastIndexOf('.')) ||
                    specifiedImport?.filename
                  }`,
                  undefined,
                  specifiedImport?.filename.substring(specifiedImport?.filename.lastIndexOf('.') + 1)
                );
              }}
            >
              Download
            </Button>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  fileImg: {
    height: 27,
    width: 27,
  },
};

export default CompletedJobRow;
