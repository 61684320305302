import { useState, useEffect, useRef } from 'react';
import useUserPreferences from './useUserPreferences';
import _ from 'lodash';
import { useConfig, useAuthContext, AuthConnector, useInterval } from '@terragotech/gen5-shared-components';

export const MAXRefreshTime = 1000 * 60 * 60 * 24; // 20 days

export const useWatchAuthState = () => {
  const { token, loadExistingAuth, clearToken } = useAuthContext();
  const { getPreferenceForRecordType } = useUserPreferences();
  const [dialogMessage, setDialogMessage] = useState('');
  const dialogRef = useRef<boolean>(false);
  const uiConfig = useConfig();

  useEffect(() => {
    if (!token) {
      loadExistingAuth();
    }
  }, [token, loadExistingAuth]);

  useInterval(
    async () => {
      let login_timestamp: object | null | undefined = getPreferenceForRecordType({
        key: 'auth',
        recordType: 'login_timestamp',
      });
      const isUserLoggedIn = await AuthConnector.isUserLoggedIn();
      if (!isUserLoggedIn) {
        setDialogMessage('');
        dialogRef.current = false;
        return;
      }
      login_timestamp = _.get(login_timestamp, 'value');
      const payload = await AuthConnector.getSessionIdPayload();
      if (!_.isEmpty(payload) && !_.isEmpty(login_timestamp)) {
        const parsed_logout_timestamp =
          _.toNumber(login_timestamp) + MAXRefreshTime * (uiConfig.refreshTokenTimeout || 30);
        if (payload.exp * 1000 > Date.now() && parsed_logout_timestamp > Date.now()) {
          if (parsed_logout_timestamp - Date.now() <= 5 * 60 * 1000) {
            if (!dialogMessage && !dialogRef.current) {
              dialogRef.current = true;
              setDialogMessage(
                'You can logout now, or continue what you are doing and you will be automatically logged out in one minute.'
              );
            }
          }
        } else {
          setDialogMessage('');
          dialogRef.current = false;
          clearToken();
        }
      }
    },
    5000,
    true
  );
  return { dialogMessage, setDialogMessage };
};
