import React, { createContext, useContext, useEffect, useState } from 'react';

interface UtilContextProps {
    isIOSSafari: boolean;
    isFirefox: boolean;
}
// Create a context with default value
const UtilContext = createContext<UtilContextProps>({
    isIOSSafari: false,
    isFirefox: false,
});

// Provider component
export const UtilProvider = ({ children }: { children: React.ReactNode }) => {
    const [isIOSSafari, setIsIOSSafari] = useState(false);
    const [isFirefox, setIsFirefox] = useState(false);

    useEffect(() => {
        const ua = window.navigator.userAgent;
        const uaLowerCase = ua.toLowerCase();
        const iOS = /iP(hone|od|ad)/.test(ua);
        const webkit = /WebKit/.test(ua);
        const isSafari = /Safari/.test(ua) && !/CriOS/.test(ua) && !/FxiOS/.test(ua);
        setIsIOSSafari(iOS && webkit && isSafari);
        setIsFirefox(uaLowerCase.includes('firefox'));
    }, []);
    const value: UtilContextProps = {isIOSSafari, isFirefox};
    return (
        <UtilContext.Provider value={value}>
            {children}
        </UtilContext.Provider>
    );
};

// Custom hook to use the context
export const useUtils = () => useContext(UtilContext);
