import React, { useMemo, useState, useImperativeHandle, useEffect, useCallback } from 'react';
import { ValueType } from '../../hooks/useTable';
import useRowChanges from '../../hooks/useRowChanges';
import { Role, User, QueryResult, UsersTableRef, UserWithoutId } from './types';
import { useQuery, useMutation, MutationHookOptions } from '@apollo/client';
import {
  QUERY,
  ADD_ROLE,
  REMOVE_ROLE,
  ADD_USER,
  REMOVE_USER,
  AddRoleVariables,
  AddRoleResponse,
  AddUserResponse,
  AddUserVariables,
  RemoveUserResponse,
  RemoveUserVariables,
  RemoveRoleResponse,
  RemoveRoleVariables,
  UpdateUserResponse,
  UPDATE_USER_ATTRIBUTES,
  UpdateUserVariables,
  UPDATE_PERMISSIONS,
  UpdatePermissionsVariables,
  UpdatePermissionsResponse,
} from './graphql';
import { saveUsers, mapQueryResultToRoles, mapQueryResultToUsers } from './utils';
import UsersAndRolesTable from '../../components/UsersAndRoles/UsersAndRolesTable';
import AddNewUserDialog from '../../components/UsersAndRoles/AddNewUserDialog';
import { ActionsMenuButton } from '../../components/ActionsMenuUI';
import { useTheme, Theme } from '@material-ui/core';
import { generateColumns } from './columnsGenerator';
import { useProcessing } from '../../hooks/userAdministrationHooks/useProcessing';
import { useDeleteDialog } from '../../hooks/userAdministrationHooks/useDeleteDialog';
import { useErrorDialog } from '../../contexts/errorDialogContext';
import { useConfig, AuthConnector } from '@terragotech/gen5-shared-components';
import useAdminExport from '../../hooks/userAdministrationHooks/useAdminExport';
import { TableDataProvider } from '../../hooks/useTableData';
import { EditModeData } from '../../contexts/editModeContext';

interface UsersTableProps {
  height: number;
  onUpdate: (numberOfChanges: number, changedData?: EditModeData) => void;
  onEditModeOn: () => void;
  refresh: () => void;
  isEditModeOn: boolean;
  isAddDialogOpen: boolean;
  setIsAddDialogOpen: (value: boolean) => void;

}

const getUserName = (user: User) => user.username;
const generateGetActions = (theme: Theme, openDeleteDialog: (users: User[]) => void) => (
  users: User[]
): ActionsMenuButton[] => {
  const { alaCartExportCSV } = useAdminExport(users);
  return [
    {
      label: 'Export to CSV',
      icon: 'fa-download',
      onClick: () => alaCartExportCSV(),
    },
    {
      label: 'Reset Password',
      icon: 'fa-lock',
      onClick: () => {
        users.forEach((user) => AuthConnector.forgotPassword(user.username));
      },
    },
    {
      label: 'Delete User',
      icon: 'fa-trash',
      onClick: () => {
        openDeleteDialog(users);
      },
      color: theme.palette.error.main,
    },
  ];
};

const UsersTable = React.forwardRef((props: UsersTableProps, ref) => {
  const { onUpdate, height, onEditModeOn, refresh, isEditModeOn,isAddDialogOpen, setIsAddDialogOpen } = props;
  const theme = useTheme();
  const uiConfig = useConfig();
  const [users, setUsers] = useState<User[]>([]);
  const { error, data, loading } = useQuery<QueryResult>(QUERY, { fetchPolicy: 'network-only' });
  const { handleRowChange } = useRowChanges();

  const { isProcessing, startProcessing, stopProcessing } = useProcessing({
    onComplete: refresh,
  });

  const [analyticsPermissionCount, setAnalyticsPermissionCount] = useState(0);
  const { setErrorDialogMessage, setErrorDialogTitle } = useErrorDialog();

  const analyticsEnabled = useMemo(() => uiConfig.enabledFeatures?.analyticsModule, [uiConfig]);
  const analyticsTrustedServerUrl = useMemo(() => uiConfig.analyticsTrustedServerUrl, [uiConfig]);
  const roles: Role[] = useMemo(() => mapQueryResultToRoles(data), [data]);
  const initialUsers: User[] = useMemo(
    () => mapQueryResultToUsers(data).map((user) => ({ ...user, id: user.username })),
    [data]
  );
  const columns = useMemo(() => {
    return generateColumns(roles, analyticsPermissionCount);
  }, [roles, analyticsPermissionCount]);

  useEffect(() => {
    setAnalyticsPermissionCount(data?.permissionUserStats?.analyticsUserCount || 0);
  }, [data?.permissionUserStats?.analyticsUserCount]);

  useEffect(() => {
    setUsers([
      ...initialUsers.map((user) => ({
        ...user,
        roles: [...user.roles],
      })),
    ]);
  }, [initialUsers]);

  const getMutationOptions = <TResponse, TVariables>(): MutationHookOptions<TResponse, TVariables> => ({
    onCompleted: stopProcessing,
    onError: (error) => {
      setErrorDialogMessage(error.message);
      setErrorDialogTitle(error.name);
    },
  });

  const [addRole] = useMutation<AddRoleResponse, AddRoleVariables>(ADD_ROLE, getMutationOptions());
  const [updateUser] = useMutation<UpdateUserResponse, UpdateUserVariables>(
    UPDATE_USER_ATTRIBUTES,
    getMutationOptions()
  );
  const [updatePermissions] = useMutation<UpdatePermissionsResponse, UpdatePermissionsVariables>(
    UPDATE_PERMISSIONS,
    getMutationOptions()
  );
  const [deleteUser] = useMutation<RemoveUserResponse, RemoveUserVariables>(REMOVE_USER, getMutationOptions());
  const [removeRole] = useMutation<RemoveRoleResponse, RemoveRoleVariables>(REMOVE_ROLE, getMutationOptions());

  const analyticsSync = async (toAdd: Array<string>, toRemove: Array<string>) => {
    if (!analyticsEnabled || !analyticsTrustedServerUrl) {
      return;
    }
    try {
      await fetch(`${analyticsTrustedServerUrl}/tableau/users/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await AuthConnector.getToken()}`,
        },
        body: JSON.stringify({ toAdd, toRemove }),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteUsers = (users: User[]) => {
    users.forEach((user) => {
      startProcessing();
      deleteUser({
        variables: {
          username: user.username,
        },
      });
    });
  };

  const { openDeleteDialog, deleteDialog } = useDeleteDialog({
    resourceNameKey: 'UsersAdministration.user',
    onDelete: handleDeleteUsers,
    getRecordName: getUserName,
  });

  /*
    It necessary here as we have bar with save button in the higher component. 
  */
  useImperativeHandle(
    ref,
    (): UsersTableRef => ({
      save() {
        startProcessing();
        const hadChanges = saveUsers(
          users,
          initialUsers,
          (username: string, roleId: number) => addRole({ variables: { username, roleId } }),
          (username: string, roleId: number) => removeRole({ variables: { username, roleId } }),
          (username: string, attributes) => updateUser({ variables: { username, userAttributes: attributes } }),
          (username: string, isRoleAndDataAdmin: boolean, isUserAdmin: boolean, isAnalyticsUser: boolean, isMapServicesUser: boolean) =>
            updatePermissions({ variables: { username, isRoleAndDataAdmin, isUserAdmin, isAnalyticsUser, isMapServicesUser } }),
          analyticsSync
        );
        if (!hadChanges) {
          stopProcessing();
        }
      },
    })
  );

  const updatedAnalyticsSeatCount = (currentValueHasAnalytics: boolean, updatedValueHasAnalytics: boolean): void => {
    if (currentValueHasAnalytics || updatedValueHasAnalytics) {
      //if new value has analytics and old value doesn't increment counter, else decrease counter
      setAnalyticsPermissionCount((previousValue) =>
        !currentValueHasAnalytics && updatedValueHasAnalytics ? previousValue + 1 : previousValue - 1
      );
    }
  };

  const handleUpdate = (id: string, columnId: string, _value: unknown) => {
    const value = _value as ValueType;
    const userIndex = users.findIndex((user) => user.id === id);
    if (userIndex >= 0) {
      const newUser = { ...users[userIndex] };
      //need to set new seat count if analytics permissions are modified.
      updatedAnalyticsSeatCount(
        newUser.adminPermissions.isAnalyticsUser as boolean,
        !!(value && (value as User).isAnalyticsUser) as boolean
      );

      newUser[columnId] = value;
      setUsers([...users.slice(0, userIndex), newUser, ...users.slice(userIndex + 1)]);
      onUpdate(handleRowChange(userIndex), { id, label: `${newUser.firstName} ${newUser.lastName}` });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getActions = useCallback(generateGetActions(theme, openDeleteDialog), [theme, openDeleteDialog]);
  const handleEditModeOn = useCallback((_row: User) => {
    onEditModeOn && onEditModeOn();
  }, [onEditModeOn]);

  return (
    <>
      <TableDataProvider>
        <UsersAndRolesTable<User>
          data={users}
          columns={columns}
          height={height}
          onEditModeOn={handleEditModeOn}
          onChange={handleUpdate}
          loading={loading || isProcessing}
          error={error ?? null}
          getActions={getActions}
          fabIconName="fa-user-plus"
          singular="user"
          isEditModeOn={isEditModeOn}
        />
        {deleteDialog}
      </TableDataProvider>
    </>
  );
});

export default UsersTable;
