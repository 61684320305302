import { FunctionComponent, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import PagePage from '../components/Page/PagePage';
import { gql, useQuery } from '@apollo/client';
import { AssetDataEntry, AssetDataQueryResult } from '../containers/WorkflowPageContainer';
import {
  graphqlToAssetTransform,
  useConfig,
  getAggregateDataFields,
  useSpinner,
  useAuthContext,
} from '@terragotech/gen5-shared-components';
import { ProjectsProvider } from '../contexts/projectsContext';
import { AggregatesContextProvider } from '../contexts/AggregatesContext';

const PageContainer: FunctionComponent = () => {
  const { pageName, agg } = useParams() as {
    pageName: string;
    agg?: string;
  };
  const { pageDefinitions } = useConfig();
  const { token, ready, loadExistingAuth } = useAuthContext();

  useEffect(() => {
    if (!token) {
      loadExistingAuth();
    }
  }, [token, loadExistingAuth]);

  const aggregateType = pageDefinitions && pageDefinitions[pageName]?.aggregateType;

  if (!ready) {
    return null;
  }
  if (!token && !(pageDefinitions && pageDefinitions[pageName]?.isPublic)) {
    return <Redirect to="/login" />;
  }

  let useMargins = true;
  if (
    pageDefinitions &&
    pageDefinitions[pageName]?.elements &&
    Object.keys(pageDefinitions[pageName].elements).length === 1
  ) {
    useMargins =
      pageDefinitions[pageName].elements[Object.keys(pageDefinitions[pageName].elements)[0]].component.type !==
      'aggregateMap';
  }

  return (
    <ProjectsProvider>
      <AggregatesContextProvider>
        <div
          style={useMargins ? { paddingLeft: 6, paddingTop: 6, width: 'calc(100% - 6px)', height: 'calc(100% - 6px)' } : {}}
        >
          {agg && aggregateType ? (
            <PageAggContainer noPadding={!useMargins} />
          ) : (
            <PagePage noPadding={!useMargins} page={pageName} fabContainerKey="PageContainer" display="block" />
          )}
        </div>
      </AggregatesContextProvider>
    </ProjectsProvider>
  );
};

const PageAggContainer: FunctionComponent<{ noPadding?: boolean }> = ({ noPadding }) => {
  const { pageName, agg } = useParams() as {
    pageName: string;
    agg?: string;
  };
  const { aggregateDefinitions, pageDefinitions } = useConfig();

  const aggregateType = pageDefinitions && pageDefinitions[pageName]?.aggregateType;
  const aggregateDefinition = aggregateDefinitions.find(d => d.name === aggregateType);
  const aggregateTypeName = aggregateDefinition?.queryKey || '';
  const { toggleSpinner } = useSpinner();

  const { data, loading } = useQuery<AssetDataQueryResult>(
    gql`
      query AssetData($aggregateId: UUID!) {
        ${aggregateTypeName}(filter: {id: {equalTo: $aggregateId}}) {
          id
          label
          assetAttributes
          modifiedDateTime
          ${getAggregateDataFields(aggregateDefinition)}
        }
      }
    `,
    { variables: { aggregateId: agg }, fetchPolicy: 'no-cache' }
  );
  useEffect(() => {
    toggleSpinner(loading);
  }, [loading, toggleSpinner]);

  if (loading) {
    return <></>;
  }

  const record: AssetDataEntry | null =
    graphqlToAssetTransform({
      flattenRelationships: true,
      result: data && data[aggregateTypeName][0],
      aggDef: aggregateDefinition,
    }) || null;

  return <PagePage noPadding={noPadding} page={pageName} target={record} fabContainerKey="PageAggContainer" display="block" />;
};

export default PageContainer;
