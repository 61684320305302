import React, { useRef, useState, useContext } from 'react';
import { Box, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { ArrowBack, Close, Search } from '@material-ui/icons';
import PlacesAutocomplete from 'react-places-autocomplete';
import GlobalSearchSuggestion from './Map/component/ControlsOverlay/GlobalSearchSuggestion';
import { FilterContext } from '../contexts/FilterContext/filterContext';
import { useUtils } from '../contexts/utilContext';
import clsx from 'clsx';
import _ from 'lodash';
import { useCommonStyles } from '../styles/commonStyles';
import { colors } from '../styles/theme';
import { handleAddressSelected } from './Map/component/ControlsOverlay/boundsUtils';
import { AssetsDashboardContext } from '../contexts/assetsDashboardContext';

interface SearchBoxProps {
  pageContainer?: boolean;
}

const SearchBox: React.FC<SearchBoxProps> = ({ pageContainer }) => {
  const [searchActive, setSearchActive] = useState(false);
  const [searchText, setSearchText] = useState('');
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isIOSSafari } = useUtils();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const preventBlur = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  };
  const { setMapLocalBounds } = useContext(FilterContext);
  const { setIsAddDialogOpen, toggleMapView } = useContext(AssetsDashboardContext);
  const onAddressClick = async (address: string, placeId: string) => {
    toggleMapView(true);
    const bounds: number[][] = (await handleAddressSelected(address, placeId, setSearchText)) as number[][];
    setMapLocalBounds(bounds);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };
  const styles = {
    rootContainer: {
      display: 'flex',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
      zIndex: 999999,
      width: searchActive ? '100%' : '85%',
      height: searchActive ? '100%' : '100px',
    },
    rootContainerSub: { height: '50px' },
    innerContainer: { marginTop: '26px', width: '100%', marginInline: 20 },
  };
  return (
    <div style={pageContainer ? styles.rootContainer : styles.rootContainerSub}>
      <Box className={classes.searchBox} style={pageContainer ? styles.innerContainer : {}}>
        <PlacesAutocomplete value={searchText} onChange={setSearchText} onSelect={onAddressClick}>
          {({ getInputProps, suggestions, getSuggestionItemProps }) => (
            <div>
              <TextField
                {...getInputProps()}
                variant="outlined"
                className={clsx(
                  classes.input,
                  searchActive ? classes.activeSearch : classes.textInput,
                  isIOSSafari && commonClasses.safariInnerInputFontSize
                )}
                placeholder="Search"
                onFocus={() => setSearchActive(true)}
                onBlur={() => setSearchActive(false)}
                InputProps={{
                  inputRef: inputRef,
                  startAdornment: (
                    <InputAdornment position="start">
                      {searchActive ? (
                        <IconButton>
                          <ArrowBack />
                        </IconButton>
                      ) : (
                        <Search className={classes.searchIcon} />
                      )}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchText.length > 0 ? (
                        <IconButton onClick={() => setSearchText('')} onMouseDown={preventBlur}>
                          <Close className={classes.clearIcon} />
                        </IconButton>
                      ) : null}
                    </InputAdornment>
                  ),
                }}
              />
              {searchActive && (
                <Box className={classes.suggestionArea} onMouseDown={preventBlur}>
                  {!_.isEmpty(searchText) && (
                    <GlobalSearchSuggestion
                      setSearchText={setSearchText}
                      inputRef={inputRef.current}
                      searchText={searchText}
                      getSuggestionItemProps={getSuggestionItemProps}
                      suggestions={suggestions}
                      toggleMapView={toggleMapView}
                    />
                  )}
                </Box>
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </Box>
    </div>
  );
};
const useStyles = makeStyles(theme => ({
  clearIcon: {
    height: 22,
    width: 22,
    color: colors.black54,
  },
  searchBox: {
    width: '70%',
  },
  searchIcon: {
    color: colors.black35,
    width: 22,
    height: 22,
  },
  input: {
    borderRadius: 4,
    width: '100%',
    padding: 0,
    '& .MuiInputBase-root': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: colors.cultured,
      },
      '& input': {
        fontSize: 15,
        fontWeight: 400,
        color: colors.black,
      },
    },
  },
  textInput: {
    backgroundColor: colors.cultured,
    height: 35,
    '& .MuiInputBase-root': {
      height: 35,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  activeSearch: {
    backgroundColor: colors.white,
    height: 64,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 10,
    borderBottom: `1px solid ${colors.black10}`,
    '& .MuiInputBase-root': {
      height: 64,
    },
  },
  suggestionArea: {
    position: 'absolute',
    zIndex: 9999,
    left: 0,
    top: 64,
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
  },
}));
export default SearchBox;
