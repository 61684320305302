import { Box, Checkbox, Link, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useProjects } from '../../../../../contexts/projectsContext';
import { FolderProp, ProjectArray, useSelectedProject } from '../../../../../contexts/selectedProjectContext';
import { Accordion, AccordionDetails, AccordionSummary, tabProps } from './Accordions';
import { KeyboardArrowRightOutlined } from '@material-ui/icons';
import useUserPreferences from '../../../../../hooks/useUserPreferences';
import _ from 'lodash';
import { colors } from '../../../../../styles/theme';
import { hasIncludes, PROJECT_SELECTED_KEY } from '../../../../../utils/utilityHelper';
import { useCommonStyles } from './useStyles';

const PREFERENCE_KEY = 'openFolders';

const ProjectTab = (props: tabProps) => {
  const { SearchbarSection } = props;
  const {
    selectedProjects,
    toggleSelectedProject,
    setSelectedProjects,
    selectAllProjects,
    getAllProjectIds,
  } = useSelectedProject();
  const { getPreference, setPreference, setPreferenceValue } = useUserPreferences();
  const [searchText, setSearchText] = useState('');
  const [openFolders, setOpenFolders] = React.useState<Record<string, boolean>>(getPreference(PREFERENCE_KEY) || {});
  const [canFocusOut, setCanFocusOut] = useState(false);

  const handleFolderClick = (folderName: string) => {
    setOpenFolders(f => {
      const value = f[folderName];
      return { ...f, [folderName]: typeof value === 'boolean' ? !value : false };
    });
  };
  const { folders, projects } = useProjects();

  const classes = useStyles();
  const commonClasses = useCommonStyles();

  const folderHasDescendantProjects = (folder: FolderProp): boolean =>
    folder.projects.length > 0 || (folder.subFolders?.some(sf => folderHasDescendantProjects(sf)) ?? false);

  const unsortedFolder: FolderProp = { label: 'Unsorted', id: 'Unsorted', open: !!openFolders['Unsorted'], projects: [] };
  const localFolders: FolderProp[] = useMemo(() => {
    if (folders.length === 0 && projects.length > 0) {
      return [{ ...unsortedFolder, projects: projects }];
    } else if (folders.length > 0) {
      const mainFolders = folders.filter(folder => !folder.parentFolderId && folderHasDescendantProjects(folder));
      const mappedFolders = mainFolders
        .map<FolderProp>(folder => {
          return {
            ...folder,
            projects: folder.projects,
            subFolders: folder.subFolders,
            parentFolderId: folder?.parentFolderId,
            open: !!openFolders[folder.id],
          };
        })
        .filter(Boolean);
      const sortedFolders = mappedFolders.slice().sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      const nonFolderProjects = projects.filter(p => !p.folderId);
      if (nonFolderProjects.length > 0) {
        sortedFolders.push({ ...unsortedFolder, projects: nonFolderProjects });
      }
      return sortedFolders;
    } else {
      return [];
    }
  }, [folders, projects, openFolders]);

  const [sortedFolders, setSortedFolders] = useState<FolderProp[]>(localFolders);

  React.useEffect(() => {
    if (projects && projects.length > 0) {
      var savedSelectedProjects = selectedProjects;
      const projectIds = projects.map(project => project.id);
      savedSelectedProjects = savedSelectedProjects.filter(name => projectIds.includes(name));
      if (selectedProjects.length !== savedSelectedProjects.length) {
        setSelectedProjects(savedSelectedProjects);
      }
    }
  }, [projects, selectedProjects, setSelectedProjects]);
  const isSelected = (id: string) => {
    return selectedProjects.includes(id);
  };

  function filterProjectsAndSubfolders(folder: FolderProp, searchText: string): FolderProp | undefined {
    const filteredProjects = folder.projects?.filter(project => hasIncludes(project.label, searchText));
    const filteredTitle = hasIncludes(folder.label, searchText);
    const filteredSubfolders = folder.subFolders
      ?.map(subfolder => {
        const filteredSubfolder = filterProjectsAndSubfolders(subfolder, searchText);
        if (
          (filteredSubfolder?.projects && filteredSubfolder?.projects?.length > 0) ||
          (filteredSubfolder?.subFolders && filteredSubfolder?.subFolders?.length > 0)
        ) {
          return {
            ...filteredSubfolder,
            label: subfolder.label,
            id: subfolder.id,
          };
        }
        return undefined;
      })
      .filter(Boolean);
    if (filteredProjects?.length || filteredSubfolders?.length || filteredTitle) {
      return {
        label: folder.label,
        id: folder.id,
        projects: filteredTitle ? folder.projects : filteredProjects,
        subFolders: filteredTitle ? folder.subFolders : (filteredSubfolders as FolderProp[]),
      };
    }
  }

  useEffect(() => {
    if (searchText && searchText !== '') {
      let filteredFolders = localFolders.map(folder => filterProjectsAndSubfolders(folder, searchText)).filter(Boolean);
      if (filteredFolders) {
        setSortedFolders(filteredFolders as FolderProp[]);
      }
    } else {
      setSortedFolders(localFolders);
    }
  }, [searchText, localFolders]);

  React.useEffect(() => {
    setPreference(PREFERENCE_KEY, openFolders);
  }, [openFolders, setPreference]);

  const getSelectedProjectsCount = (folder: FolderProp) => {
    let selectedCount = 0;
    let totalCount = 0;
    totalCount += folder.projects?.length || 0;
    selectedCount += folder.projects?.filter(project => selectedProjects.includes(project.id)).length || 0;
    if (folder.subFolders) {
      folder.subFolders.forEach(subfolder => {
        const { selectedCount: subSelectedCount, totalCount: subTotalCount } = getSelectedProjectsCount(subfolder);
        selectedCount += subSelectedCount;
        totalCount += subTotalCount;
      });
    }
    return { selectedCount, totalCount };
  };
  const renderProjects = (projects: ProjectArray) => {
    return projects?.map((project, index) => (
      <Box className={classes.accordionSummary} key={index}>
        <Checkbox
          color="primary"
          checked={isSelected(project.id)}
          onChange={() => {
            toggleSelectedProject(project.id);
          }}
        />
        <Typography className={classes.project}>{project.label}</Typography>
      </Box>
    ));
  };
  const renderSubfolders = (subfolders: FolderProp[]) => {
    return subfolders.map((folder, index) => {
      const { selectedCount, totalCount } = getSelectedProjectsCount(folder);
      const selectedProjectsCount = selectedCount !== 0 ? (totalCount === selectedCount ? 'All' : selectedCount) : 0;
      return (
        <Accordion key={index} expanded={openFolders[folder.label]}>
          <AccordionSummary
            expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
            onClick={() => handleFolderClick(folder.label)}
          >
            {folder.label}
            {selectedProjectsCount !== 0 && ` (${selectedProjectsCount})`}
          </AccordionSummary>
          <AccordionDetails>
            {folder.subFolders &&
              folder.subFolders.some(folderHasDescendantProjects) &&
              renderSubfolders(folder.subFolders)}
            {folder.projects && renderProjects(folder.projects)}
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  const enableAccordions = async () => {
    const folderData = folders
      .map(o => ({ [o.label]: true }))
      .reduce((a, c) => {
        const [key, value] = Object.entries(c)[0];
        a[key] = value;
        return a;
      }, {});
    setOpenFolders(f => ({ ...folderData, ...f }));
  };

  const toggleAllProjects = () => {
    const allProjectIds = getAllProjectIds();
    selectAllProjects(allProjectIds);
    if (selectedProjects.length === 0) {
      enableAccordions();
      setPreferenceValue(PROJECT_SELECTED_KEY, 'all');
    } else {
      setPreferenceValue(PROJECT_SELECTED_KEY, 'none');
      selectAllProjects([]);
    }
  };

  useEffect(() => {
    enableAccordions();
  }, [folders]);

  const selectedItems = _.chain(sortedFolders)
    .map(folder => {
      const { selectedCount } = getSelectedProjectsCount(folder);
      return selectedCount;
    })
    .sum()
    .value();

  return (
    <Box className={commonClasses.container}>
      <SearchbarSection searchValue={searchText} focusOut={canFocusOut} searchOnChange={setSearchText} />
      <Box className={commonClasses.outer}>
        <Box className={commonClasses.detailContainer} onScroll={() => setCanFocusOut(true)}>
          <Link
            className={`${commonClasses.linkBtn} ${commonClasses.bp}`}
            component="button"
            underline="always"
            onClick={() => toggleAllProjects()}
          >
            {selectedProjects.length ? `Deselect All (${selectedItems})` : 'Select All'}
          </Link>
          {sortedFolders.map(folder => {
            const { selectedCount, totalCount } = getSelectedProjectsCount(folder);
            const selectedProjectsCount =
              selectedCount !== 0 ? (totalCount === selectedCount ? 'All' : selectedCount) : 0;
            return (
              <Accordion defaultExpanded expanded={openFolders[folder.label]} key={folder.label}>
                <AccordionSummary
                  expandIcon={<KeyboardArrowRightOutlined className={classes.expandIcon} />}
                  onClick={() => handleFolderClick(folder.label)}
                >
                  {folder.label}
                  {selectedProjectsCount !== 0 && ` (${selectedProjectsCount})`}
                </AccordionSummary>
                <AccordionDetails>
                  {folder.subFolders &&
                    folder.subFolders.map(subFolder =>
                      folderHasDescendantProjects(subFolder) ? renderSubfolders([subFolder]) : null
                    )}
                  {folder.projects && renderProjects(folder.projects)}
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accordionSummary: {
      display: 'flex',
    },
    project: {
      fontSize: 15,
      fontWeight: 400,
      color: colors.black0,
    },
    expandIcon: {
      color: colors.title,
    },
  })
);
export default ProjectTab;
