import React from 'react';
import { makeStyles, Zoom } from '@material-ui/core';
import ActionIcon from './ActionIcon';

const FloatingActionButtonIcon = (props: { openIcon?: string; baseIcon: string; isOpen: boolean }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <Zoom in={!props.isOpen} mountOnEnter unmountOnExit>
          <div>
            <ActionIcon name={props.baseIcon} size="2x" color="white" />
          </div>
        </Zoom>
      </div>
      <div className={classes.icon}>
        <Zoom in={props.isOpen} mountOnEnter unmountOnExit>
          <div>
            <ActionIcon name={props.openIcon ?? props.baseIcon} size="2x" color="white" />
          </div>
        </Zoom>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    boxShadow: `0px 2px 8px 0px #00000040`,
    overflow: 'hidden',
  },
  icon: { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', lineHeight: '0px' },
}));

export default FloatingActionButtonIcon;
