import React, { FunctionComponent } from 'react';
import { Button, createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core';
import { colors } from '../../../styles/theme';
import magicText from 'i18next';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';

interface ColumnFilterInstructionBoxProps extends WithStyles<typeof styles> {
  handleSelectAll?: () => void;
  areAllSelected?: boolean;
  totalKeyCount?: number;
  selectedKeyCount?: number;
}

const ColumnFilterInstructionBox: FunctionComponent<ColumnFilterInstructionBoxProps> = props => {
  const { classes } = props;
  return (
    <>
      <div className={classes.instructionContainer}>
        <Typography variant="body1" className={classes.instructionText}>
          <> {magicText.t('ColumnFilter.Instructions')}</>
        </Typography>
      </div>
      <div className={classes.selectAll}>
        <Button className={classes.text} onClick={props.handleSelectAll}>
          <>
            {props.areAllSelected
              ? magicText.t(`ColumnFilter.DeselectAll`, { count: props.selectedKeyCount })
              : magicText.t(`ColumnFilter.SelectAll`, { count: props.totalKeyCount })}
          </>
        </Button>
      </div>
    </>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    instructionContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
        paddingRight: 22,
      },
    },
    selectAll: {
      display: 'flex',
      alignItems: 'center',
      height: 19,
      minHeight: 19,
      backgroundColor: colors.white,
      color: theme.palette.primary.main,
      padding: '15px 0px',
    },
    text: {
      color: theme.palette.primary.main,
      paddingLeft: 0,
      textTransform: 'capitalize',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 'normal',
    },
    instructionText: {
      fontSize: 14,
      color: colors.black54,
      fontWeight: 400,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      lineHeight: 'normal',
      width: '100%',
      marginRight: '0',
    },
    resetButton: {
      width: '210px',
      marginRight: 25,
      fontSize: 14,
      textTransform: 'uppercase',
      color: colors.danger,
    },
  });

export default withStyles(styles)(ColumnFilterInstructionBox);
