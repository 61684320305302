import { Dispatch, SetStateAction, useRef } from 'react';
import SplitterLayout from 'react-splitter-layout';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import TopSplitter from '../../components/TopSplitter';
import TableView from './TableView';
import { ASSET_TYPE_BAR_SIZE } from '../../utils/utilityHelper';
import { Box, Container } from '@material-ui/core';

interface Props {
  fullscreen: boolean;
  secondaryPaneSize: number;
  isMapView: boolean;
  isMobileView: boolean;
  editModeActive: boolean;
  width: number;
  height: number;
  classes: ClassNameMap<string>;
  isVertical: boolean;
  setWidth: Dispatch<SetStateAction<number>>;
  minimizeAndExpandTable: (value: number) => void;
  setSecondaryPaneSize: (value: number) => void;
  onDragEnd: (value: number) => void;
  pixelsToVerticalPercentage: (value: number) => number;
}

const AssetLayout = ({
  fullscreen,
  classes,
  secondaryPaneSize,
  isMapView,
  isMobileView,
  editModeActive,
  width,
  height,
  isVertical,
  setSecondaryPaneSize,
  onDragEnd,
  pixelsToVerticalPercentage,
  setWidth,
  minimizeAndExpandTable,
}: Props) => {
  const splitterLayoutRef = useRef<SplitterLayout | null>(null);

  const onMinimize = (value: number) => {
    minimizeAndExpandTable(value);
    splitterLayoutRef?.current && splitterLayoutRef.current.setState({ secondaryPaneSize: value });
  };
  const BoxContainer = isMobileView ? Box : Container;
  return (
    <BoxContainer className={classes.layoutRoot}>
      {!fullscreen ? (
        <SplitterLayout
          ref={splitterLayoutRef}
          percentage
          vertical={isVertical}
          secondaryInitialSize={isMobileView ? 0 : secondaryPaneSize}
          customClassName={isMobileView ? classes.customMobileSplitter : classes.customSplitter}
          onSecondaryPaneSizeChange={setSecondaryPaneSize}
          onDragEnd={() => onDragEnd(secondaryPaneSize)}
          secondaryMinSize={isMobileView ? 0 : pixelsToVerticalPercentage(ASSET_TYPE_BAR_SIZE)}
        >
          {isMapView && (
            <TopSplitter
              {...{
                height,
                width,
                setWidth,
                isMapView,
                isVertical,
                isMobileView,
                inProp: !editModeActive,
              }}
            />
          )}
          {(!isMobileView || (isMobileView && !isMapView)) && (
            <TableView {...{ isMobileView, classes, isVertical, minimizeAndExpandTable: onMinimize, height }} />
          )}
        </SplitterLayout>
      ) : (
        <TopSplitter
          {...{
            height,
            width,
            setWidth,
            isMapView,
            isVertical,
            isMobileView,
            onDragEnd,
            inProp: !editModeActive,
            minimizeAndExpandTable: onMinimize,
          }}
        />
      )}
    </BoxContainer>
  );
};

export default AssetLayout;
