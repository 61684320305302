import * as React from 'react';
import { formatDistanceStrict } from 'date-fns';
import { withStyles, WithStyles, createStyles, Tooltip } from '@material-ui/core';
import { useRef } from 'react';
import { useAggregates } from '../contexts/AggregatesContext';
import DrawButton from './TGButton';
import magicText from 'i18next';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface RefreshDataButtonProps {
  isMobileView?: boolean;
}

const RefreshDataButton: React.FunctionComponent<RefreshDataButtonProps> = props => {
  const assetInfo = useAggregates();
  const [refreshTime, setRefreshTime] = React.useState('Refresh Assets');
  const lastRefreshed = useRef(new Date());
  const handleRefreshTable = async () => {
    await assetInfo.refetchAll();
    setRefreshTime('0 secs ago');
    lastRefreshed.current = new Date();
  };
  return (
    <Tooltip
      title={refreshTime}
      placement="top"
      onOpen={() => {
        if (refreshTime !== 'Refresh Assets') setRefreshTime(getRefreshTimeText(lastRefreshed.current));
      }}
    >
      <div>
        <DrawButton
          onClick={() => {
            handleRefreshTable();
          }}
          srcImage={faArrowsRotate as IconProp}
          title={magicText.t('SearchBar.REFRESH')}
          isMobileView={props.isMobileView}
        />
      </div>
    </Tooltip>
  );
};
export default RefreshDataButton;

export function getRefreshTimeText(lastRefreshed?: Date): string {
  let time: string = '';
  let current: Date = new Date();
  let lastRefresh: Date = (lastRefreshed as unknown) as Date;

  if (lastRefresh) {
    time = formatDistanceStrict(lastRefresh, current, { roundingMethod: 'floor' });
    //check plural first otherwise the 's' will not be replaced.
    if (time.includes('hour'))
      time.includes('hours') ? (time = time.replace('hours', ' hrs ago')) : (time = time.replace(' hour', ' hr ago'));
    else if (time.includes('minute'))
      time.includes('minutes')
        ? (time = time.replace('minutes', ' mins ago'))
        : (time = time.replace(' minute', ' min ago'));
    else
      time.includes('seconds')
        ? (time = time.replace('seconds', ' secs ago'))
        : (time = time.replace('second', ' sec ago'));
  }
  return time;
}
