import { useState } from 'react';

const useRowChanges = () => {
  const [changedRowsIndexes, setChangedRowsIndexes] = useState([] as number[]);

  const handleRowChange = (rowIndex: number) => {
    const oldRowIndexNumber = changedRowsIndexes.length;
    if (changedRowsIndexes.includes(rowIndex)) {
      return oldRowIndexNumber;
    } else {
      setChangedRowsIndexes([...changedRowsIndexes, rowIndex]);
      return oldRowIndexNumber + 1;
    }
  };

  return {
    numberOfChanges: changedRowsIndexes.length,
    handleRowChange,
  };
};

export default useRowChanges;
