import React, { createContext, FunctionComponent, useContext, useState, useMemo } from 'react';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useRecordType } from './recordTypeContext';
import { useRecoilState } from 'recoil';
import { cardVisibleState } from '../recoil/atoms/menuSelections';

export interface Coordinates {
  lng: number;
  lat: number;
}

interface AssetCardContextConfig {
  assetIds: string[];
  setAssetsIds: (ids: string[]) => void;
  coordinates: Coordinates | null;
  setCoordinates: (coordinates: Coordinates | null) => void;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  areCardsDefined: boolean;
  locationAssetIds: string[];
  setLocationAssetIds: (ids: string[]) => void;
  isCardVisible: boolean;
  setIsCardVisible: (v: boolean) => void;
}

export const AssetCardContext = createContext<AssetCardContextConfig>({
  assetIds: [],
  setAssetsIds: () => {},
  coordinates: null,
  setCoordinates: () => {},
  refresh: false,
  setRefresh: () => {},
  areCardsDefined: false,
  locationAssetIds: [],
  setLocationAssetIds: () => {},
  isCardVisible: false,
  setIsCardVisible: () => {},
});
export const MultipleAssetsProvider: FunctionComponent<{
  children?: React.ReactNode;
}> = props => {
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [isCardVisible, setIsCardVisible] = useRecoilState(cardVisibleState);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedLocationAssets, setSelectedLocationAssets] = useState<string[]>([]);
  const [selectedAssetsCoordinates, setSelectedAssetsCoordinates] = useState<Coordinates | null>(null);
  const { aggregateDefinitions } = useConfig();
  const { selectedRecordType } = useRecordType();
  const aggregateDefinition = useMemo(() => aggregateDefinitions.find(d => d.name === selectedRecordType), [
    aggregateDefinitions,
    selectedRecordType,
  ]);
  const areCardsDefined = useMemo(() => !!aggregateDefinition?.cardDefinition?.titleKey, [aggregateDefinition]);

  const value: AssetCardContextConfig = {
    assetIds: selectedAssets,
    setAssetsIds: setSelectedAssets,
    coordinates: selectedAssetsCoordinates,
    setCoordinates: setSelectedAssetsCoordinates,
    areCardsDefined,
    refresh,
    setRefresh,
    locationAssetIds: selectedLocationAssets,
    setLocationAssetIds: setSelectedLocationAssets,
    isCardVisible,
    setIsCardVisible,
  };

  return <AssetCardContext.Provider value={value}>{props.children}</AssetCardContext.Provider>;
};
export const useAssetCards = () => useContext(AssetCardContext);
