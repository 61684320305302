import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const ClusteringIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 26 26">
      <circle cx="13" cy="13.0703" r="12.5" />
      <svg width="13" height="13" x="6.5" y="6.5">
        <path
          d="M0.649414 6.11426C0.649414 5.37858 0.74707 4.6722 0.942383 3.99512C1.14095 3.31803 1.43555 2.7028 1.82617 2.14941C2.2168 1.59603 2.62207 1.2054 3.04199 0.977539L3.22754 1.57324C2.75228 1.93783 2.36165 2.49447 2.05566 3.24316C1.75293 3.99186 1.58691 4.83008 1.55762 5.75781L1.55273 6.17285C1.55273 7.42936 1.78223 8.51986 2.24121 9.44434C2.5179 9.99772 2.84668 10.4307 3.22754 10.7432L3.04199 11.2949C2.60905 11.054 2.19564 10.6504 1.80176 10.084C1.03353 8.97721 0.649414 7.65397 0.649414 6.11426ZM7.15332 6.99805H5.86426L5.47363 9H4.77539L5.16602 6.99805H3.99902V6.3291H5.29297L5.62988 4.60059H4.37012V3.92188H5.76172L6.16211 1.89062H6.86035L6.45996 3.92188H7.74902L8.14941 1.89062H8.85254L8.45215 3.92188H9.44824V4.60059H8.32031L7.9834 6.3291H9.08203V6.99805H7.85645L7.46582 9H6.7627L7.15332 6.99805ZM5.99121 6.3291H7.28027L7.61719 4.60059H6.32812L5.99121 6.3291ZM12.3486 6.16309C12.3486 6.889 12.2526 7.58561 12.0605 8.25293C11.8717 8.91699 11.5804 9.5306 11.1865 10.0938C10.7959 10.6569 10.3857 11.0573 9.95605 11.2949L9.76562 10.7432C10.2669 10.359 10.6689 9.76335 10.9717 8.95605C11.2777 8.14551 11.4339 7.24707 11.4404 6.26074V6.10449C11.4404 5.4209 11.3688 4.78613 11.2256 4.2002C11.0824 3.611 10.8822 3.08366 10.625 2.61816C10.3711 2.15267 10.0846 1.78971 9.76562 1.5293L9.95605 0.977539C10.3857 1.21517 10.7943 1.6123 11.1816 2.16895C11.5723 2.72559 11.8636 3.33919 12.0557 4.00977C12.251 4.68034 12.3486 5.39811 12.3486 6.16309Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};
