import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfig } from '@terragotech/gen5-shared-components';
import { useRecordType } from '../contexts/recordTypeContext';
import { useSelectedProject } from '../contexts/selectedProjectContext';
import BottomSelectionBar from '../components/BottomSelectionBar';
import { useAggregates } from '../contexts/AggregatesContext';
import { useTableColumns } from '../contexts/TableColumnContext';

const BottomRecordTypeSelectorContainer: React.FC = () => {
  const { selectedRecordType, setSelectedRecordType } = useRecordType();
  const { aggregateDefinitions } = useConfig();
  const history = useHistory();
  const { selectedProjects } = useSelectedProject();
  const [tabValue, setTabValue] = useState<string | null>(null);
  const { recordTypesData, updateScrollPosition, lastScrolledRef } = useAggregates();
  const { setCurrentAssetId } = useTableColumns();

  //clear tab override when a project is selected
  useEffect(() => {
    setTabValue(null);
  }, [selectedProjects]);

  useEffect(() => {
    if (selectedRecordType && recordTypesData && recordTypesData?.length) {
      const record = recordTypesData.find(data => data.value === selectedRecordType);
      if (!record) {
        const aggrDef = aggregateDefinitions.find(def => def.name === recordTypesData[0].value);
        if (!aggrDef) return;
        setSelectedRecordType(aggrDef);
      } else {
        return;
      }
    }
  }, [selectedRecordType, recordTypesData, selectedProjects]);

  const handleSelect = (value: string) => {
    const lastScrolledRow = lastScrolledRef.current;
    updateScrollPosition(tabValue as string, lastScrolledRow as number);
    if (aggregateDefinitions) {
      const selected = history.location.pathname.match(/^(\/\w+)\/([\w-]+)/)?.[2] || ''
      const path = `/${value}` + (selected ? '/'+selected : '');
      history.push(path);
      setTabValue(value);
      setCurrentAssetId(o => ({...o, location: 'tab'}))
    }
  };

  return <BottomSelectionBar value={selectedRecordType} options={recordTypesData || []} onSelect={handleSelect} />;
};

export default BottomRecordTypeSelectorContainer;
