import { useMemo } from 'react';
import magicText from 'i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Button, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { gql, useMutation } from '@apollo/client';
import { SelectedImportType } from './ImportHistory';
import { useConfig } from '@terragotech/gen5-shared-components';
import { Import } from './ImportHistory';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import PendingJobsWarningIcon from './PendingJobsWarningIcon';
import { downloadFile } from './FileToView';
import { DOC_API_URL } from '../../utils/docApiURL';
import moment from 'moment';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat } from '@terragotech/gen5-shared-utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateExclamation, faArrowsRotate } from '@fortawesome//pro-regular-svg-icons';
import { faTrashCanXmark } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { colors } from '../../styles/theme';
import { useAlert } from '../../contexts/AlertModalContext';
import { useRowStyles } from './useRowStyles';

const CANCEL_IMPORT = gql`
  mutation cancelImport($importId: String!) {
    cancelImport(importId: $importId) {
      message
    }
  }
`;

export interface CancelImportResponse {
  response: any;
}

export interface CancelImportVariables {
  importId: string;
}

export interface DataProp {
  selectedImport?: SelectedImportType;
  setSelectedImport: React.Dispatch<React.SetStateAction<SelectedImportType | undefined>>;
  imp: Import[];
  setFileIdToView: (x: string) => void;
}
export interface Props {
  data: DataProp;
  index: number;
}
const useStyles = makeStyles(theme => ({
  themeBackground: {
    backgroundColor: `${theme?.palette?.primary?.main || colors.defaultPrimary}`,
    width: 137,
    height: 34,
    color: colors.white,
    '&.Mui-disabled': {
      color: colors.white,
      backgroundColor: colors.black10
    }
  },
  fileNameWrapper: {
    display: 'flex',
    padding: '10px 12px',
    gap: 10,
  },
  iconContainer: {
    width: 20,
    height: 20,
  },
  pendingIcon: {
    width: 22,
    height: 21,
    color: colors.pending,
  },
  infoIcon: {
    width: 22,
    height: 21,
    color: theme.palette.primary.main,
  },
  deleteBtn: {
    width: 34,
    height: 34,
    padding: 10,
    borderRadius: 3,
    backgroundColor: colors.shadedWhite,
    '&.Mui-disabled': {
      backgroundColor: colors.shadedWhite,
      color: colors.black15,
      '& .svg-inline--fa': {
        color: colors.black15,
      },
    },
    '& .svg-inline--fa': {
      width: 15,
      height: 16,
      color: colors.black54,
    },
  },
}));

const PendingJobRow = (props: Props) => {
  const { data, index } = props;
  const classes = useStyles();
  const commonClasses = useRowStyles();
  const theme = useTheme();
  const specifiedImport = data?.imp[index];
  const { defaultDateTimeFormat } = useConfig();
  const { openConfirmation } = useAlert();

  const status = useMemo(() => {
    if (specifiedImport?.commandError != null) {
      return 'Error';
    } else if (specifiedImport?.committed === null) {
      return 'Uploading';
    } else if (specifiedImport?.committed === true) {
      return 'Importing';
    } else if (specifiedImport?.errorsWarnings.length) {
      return 'Needs Action';
    } else {
      return 'Pending';
    }
  }, [specifiedImport]);

  const reviewButtonDisabled = useMemo(() => {
    return status === 'Uploading' || status === 'Importing' || status === 'Error';
  }, [status]);

  const downloadButtonDisabled = useMemo(() => {
    return status === 'Uploading' || status === 'Error';
  }, [status]);

  const deleteButtonActive = useMemo(() => {
    return status === 'Pending';
  }, [status]);

  const [cancelImport] = useMutation<CancelImportResponse, CancelImportVariables>(CANCEL_IMPORT);
  const importIdToCancel = specifiedImport?.id;

  const isPending = status === 'Pending';

  const onDeleteRecord = async () => {
    const keyPath = 'import.cancelConfirm';
    const status = await openConfirmation({
      title: magicText.t(`${keyPath}.primaryText`),
      question: magicText.t(`${keyPath}.secondaryText`),
      confirmationText: magicText.t(`${keyPath}.confirmationText`),
    });
    if (status === 'confirm') {
      cancelImport({ variables: { importId: importIdToCancel } });
    }
  };
  const disabledDeleteToolTip = !deleteButtonActive ? 'Unable to delete, the file is being processed right now.' : '';

  return (
    <TableRow className={commonClasses.tableRow} key={index}>
      <TableCell className={commonClasses.fileCell}>
        <div className={classes.fileNameWrapper}>
          <div className={classes.iconContainer}>
            {status === 'Needs Action' ? (
              <PendingJobsWarningIcon fill={theme.palette.primary.main || colors.defaultPrimary} size={'100%'} />
            ) : (
              <FontAwesomeIcon
                icon={(isPending ? faRotateExclamation : faArrowsRotate) as IconProp}
                className={isPending ? classes.pendingIcon : classes.infoIcon}
              />
            )}
          </div>
          <div className={commonClasses.fileNameRow}>{specifiedImport?.filename}</div>
        </div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{specifiedImport?.type}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{status}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <div className={commonClasses.cellItem}>{specifiedImport?.metadata?.userInfo?.email}</div>
      </TableCell>
      <TableCell className={commonClasses.cellFontStyle}>
        <span className={commonClasses.cellItem}>
          {specifiedImport?.timestamp
            ? moment(String(specifiedImport?.timestamp)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS },
                  defaultDateTimeFormat?.isSwitch
                )
              )
            : ''}
        </span>
      </TableCell>
      <TableCell className={commonClasses.btnCellStyle}>
        <Box className={commonClasses.btnContainer}>
          {!specifiedImport.isintegration && (
            <Button
              disabled={reviewButtonDisabled}
              disableElevation
              color="primary"
              variant="contained"
              className={`${classes.themeBackground} ${commonClasses.button}`}
              onClick={() => {
                data.setFileIdToView(specifiedImport?.id);
                data.setSelectedImport(specifiedImport);
              }}
            >
              Review
            </Button>
          )}
          {specifiedImport.isintegration && (
            <Button
              disabled={downloadButtonDisabled}
              disableElevation
              color="primary"
              variant="contained"
              className={`${classes.themeBackground} ${commonClasses.button}`}
              onClick={() => {
                downloadFile(
                  `${DOC_API_URL}${specifiedImport?.id}`,
                  `${
                    specifiedImport?.filename.substring(0, specifiedImport?.filename.lastIndexOf('.')) ||
                    specifiedImport?.filename
                  }`,
                  undefined,
                  specifiedImport?.filename.substring(specifiedImport?.filename.lastIndexOf('.') + 1)
                );
              }}
            >
              Download
            </Button>
          )}
          <Tooltip key={index} title={disabledDeleteToolTip} placement="bottom">
            <div>
              <IconButton
                disabled={!deleteButtonActive}
                className={classes.deleteBtn}
                onClick={onDeleteRecord}
                aria-label="delete"
              >
                <FontAwesomeIcon icon={faTrashCanXmark} />
              </IconButton>
            </div>
          </Tooltip>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default PendingJobRow;
