import React, { useState } from 'react';
import { TextFieldProps, TextField } from 'mui-rff';
import ActionIcon from '../ActionIcon';

export type PasswordFieldProps = Omit<TextFieldProps, 'type' | 'InputProps.endAdornment'>;

const PasswordField = (props: PasswordFieldProps) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <TextField
      {...props}
      type={isVisible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <div onClick={() => setIsVisible((prev) => !prev)}>
            <ActionIcon name={isVisible ? 'fa-eye-slash' : 'fa-eye'} />
          </div>
        ),
      }}
    />
  );
};

export default PasswordField;
