import { Checkbox, CircularProgress, Zoom } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMultiSelectWithProvider } from 'use-multiselect';
import { MOBILE_BREAKPOINT } from '../utils/utilityHelper';
import { colors } from '../styles/theme';
import { useAssetsTableState } from '../contexts/assetsTableStateContext';
import { useFilter } from '../contexts/FilterContext/filterContext';
import { useAggregates } from '../contexts/AggregatesContext';
import { MAX_ASSET_LIMIT_MULTISELECT } from '../contexts/AggregatesContext/queryUtils';
import { useRecordType } from '../contexts/recordTypeContext';
import { MapAssetType } from '../contexts/AggregatesContext/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxContainer: {
      height: 39,
      width: 50,
      zIndex: 100,
      color: colors.white,
      backgroundColor: colors.lightGray,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkbox: {
      padding: 0,
    },
    indeterminate: {
      color: theme.palette.primary.main,
    },
  })
);

export interface SelectAllCheckboxProps {
  selectedAssetIds: string[];
  disabled?: boolean;
  forceSelect?: boolean;
  loading?: boolean;
}

/**
 * SelectAllCheckbox -
 * Uses the selectedCount and total count to determine the current selection state.
 * Shows as checked if all items are selected, unchecked if no items are selected and indeterminate if some are selected
 *
 * On click, either selects all or unselects all.
 *
 * @param props
 */
const SelectAllCheckbox: FunctionComponent<SelectAllCheckboxProps> = props => {
  const { disabled, forceSelect, loading } = props;
  const classes = useStyles();
  const { isMultiSelectActive, selectAll, deSelectAll } = useMultiSelectWithProvider();
  const {setIsSelectAll, setLimitAlert, setActionsMenuRefresh, isSelectAll, actionsMenuRefresh, setDataLoading, dataLoading} = useAssetsTableState()
  const {selectedRecordTypeDefinition} = useRecordType()
  const { multiSelectSelectedAssets, drawtoolFilter } = useFilter()
  const {getAssetsForMultiSelect, filteredCurrentTypeAssets} = useAggregates()


  const refresh = () =>{
    setActionsMenuRefresh(prev=> prev + 1)

  }
  const fetchAssets = async () => {
    setDataLoading(true)
    const data = await getAssetsForMultiSelect()
    if(data.length > MAX_ASSET_LIMIT_MULTISELECT){
      setLimitAlert(true)
      setIsSelectAll(false)
      multiSelectSelectedAssets.current = []
      deSelectAll()
      refresh()
      setDataLoading(false)
      return 
    }
    setDataLoading(false)
    multiSelectSelectedAssets.current = data
    selectAll()
    refresh()
  }

  // deselect automatically whenever multiselect is turned off
  useEffect(() => {
    if (!isMultiSelectActive) {
      deSelectAll();
      setIsSelectAll(false)
      multiSelectSelectedAssets.current = []
    }
  }, [isMultiSelectActive, deSelectAll]);

  useEffect(() => {
    if (forceSelect && !!!loading) {
      selectAll();
      fetchAssets();
      setIsSelectAll(true);
    }
  }, [forceSelect, loading]);

  const handleSelectAll = () =>{
    setIsSelectAll(true);
    if(drawtoolFilter){
      if(filteredCurrentTypeAssets.length > MAX_ASSET_LIMIT_MULTISELECT){
        setLimitAlert(true);
        setIsSelectAll(false);
        multiSelectSelectedAssets.current = [];
        deSelectAll();
        refresh();
        return;
      }
      multiSelectSelectedAssets.current = filteredCurrentTypeAssets;
      selectAll();
      refresh();
    }else{
      fetchAssets();
    }
  }

  const isIndeterminate = React.useMemo(()=>{
    const selectedAssetlength = multiSelectSelectedAssets.current.length
    if(selectedAssetlength !==0){
      if(isSelectAll){
        return false
      }
      return true
    }
    return false
  },[actionsMenuRefresh, isSelectAll])

  useEffect(() => {
    if (multiSelectSelectedAssets.current.length) {
      const recordTypeInSelectedArray = (multiSelectSelectedAssets.current[0] as MapAssetType).recordTypeKey
      if (selectedRecordTypeDefinition.queryKey !== recordTypeInSelectedArray) {
        multiSelectSelectedAssets.current = []
        setIsSelectAll(false)
        deSelectAll()
      }
    }
  }, [selectedRecordTypeDefinition])

  return (
    <Zoom in={isMultiSelectActive}>
      <div className={classes.checkboxContainer}>
        {!dataLoading ?
        <Checkbox
          disabled={disabled}
          classes={{ root: classes.checkbox, indeterminate: classes.indeterminate }}
          indeterminate={isIndeterminate}
          checked={isSelectAll}
          color="primary"
          onClick={() => {
            if (multiSelectSelectedAssets.current.length) {
              deSelectAll();
              setIsSelectAll(false)
              refresh()
              multiSelectSelectedAssets.current = []
            } else {
              handleSelectAll()
            }
          }}
        /> :
        <CircularProgress color='primary' size={20}/>
      }
      </div>
    </Zoom>
  );
};
export default SelectAllCheckbox;
