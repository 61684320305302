// when we import below items from the draw library typescript error occuring so disabling ts check below
//@ts-ignore
import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon';
//@ts-ignore
import { geojsonTypes, cursors, types, updateActions, modes, events } from '@mapbox/mapbox-gl-draw/src/constants';
//@ts-ignore
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates';
//@ts-ignore
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom';


const PolygonMode = { ...DrawPolygon };

interface PolygonState {
  polygon: any;
  currentVertexPosition: number;
}
PolygonMode.onSetup = function () {
  const polygon = this.newFeature({
    type: geojsonTypes.FEATURE,
    properties: {
      color: '#00FFFF',
    },
    geometry: {
      type: geojsonTypes.POLYGON,
      coordinates: [[]],
    },
  });

  this.addFeature(polygon);
  this.clearSelectedFeatures();
  doubleClickZoom.disable(this);

  // disable dragPan
  setTimeout(() => {
    if (!this.map || !this.map.dragPan) return;
    this.map.dragPan.disable();
  }, 0);

  this.updateUIClasses({ mouse: cursors.ADD });
  this.activateUIButton(types.POLYGON);
  this.setActionableState({
    trash: true,
  });

  return {
    polygon,
    currentVertexPosition: 0,
  };
};

PolygonMode.onClick = PolygonMode.onTouchStart = function (state: PolygonState, e: any) {
  if (state.currentVertexPosition === 0) {
    this.map.fire('polygonMode.drawActive', { active: true });
  }
  if (state.currentVertexPosition >= 3 && isEventAtCoordinates(e, state.polygon.coordinates[0][state.currentVertexPosition - 1])) {
    this.fireUpdate(state);
    this.map.fire('polygonMode.drawActive', { active: false });
    this.changeMode(modes.STATIC, { featureIds: [state.polygon.id] });
  }
  this.updateUIClasses({ mouse: cursors.ADD });
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
  state.currentVertexPosition++;
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat);
};

PolygonMode.fireUpdate = function (state: PolygonState) {
  this.map.fire(events.UPDATE, {
    action: updateActions.MOVE,
    features: state.polygon,
  });
};

PolygonMode.onStop = function (state: PolygonState) {
  DrawPolygon.onStop.call(this, state);
  doubleClickZoom.enable(this);
  setTimeout(() => {
    if (!this.map || !this.map.dragPan) return;
    this.map.dragPan.enable();
  }, 0);
};

export default PolygonMode;
