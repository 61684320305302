import { atom } from 'recoil';

export const mapBoundsState = atom<{ minLat: number; minLon: number; maxLat: number; maxLon: number } | null>({
  key: 'mapBoundsState',
  default: null,
});

export const cardIdsState = atom<string[]>({
  key: 'cardIdsState',
  default: [],
});
