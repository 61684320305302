import { useMemo, useState, useEffect } from 'react';
import { ConditionalButtonMapping } from '@terragotech/gen5-config-lib';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { useDataMapping } from './useDataMapping';
import { ImportActionButton, useConfig } from '@terragotech/gen5-shared-components';
import _ from 'lodash';

export interface ActionButtonState {
  state: 'loading' | 'disabled' | 'hidden' | 'enabled';
  disabledMessage?: string;
}
export type UseConditionalImport = ImportActionButton & ActionButtonState;
export interface UseConditionalImports {
  state: 'loading' | 'ready';
  buttons?: UseConditionalImport[];
}
export interface ConditionalFabButtonResults {
  isDisabled: boolean;
  disabledMessage: string;
  isHidden: boolean;
  button: ImportActionButton;
}
const buildButtonWithState = (
  conditionalButton: ImportActionButton,
  state: ActionButtonState['state'],
  disabledMessage?: string
): UseConditionalImport => ({
  aggregate: conditionalButton.aggregate,
  action: conditionalButton.action,
  icon: conditionalButton.icon,
  label: conditionalButton.label,
  conditionalMap: conditionalButton.conditionalMap,
  //TODO: move color processing into here as well so we can save on data lookups
  state: state,
  disabledMessage: disabledMessage,
  fileTypes: conditionalButton.fileTypes,
  isIntegrationImport: conditionalButton.isIntegrationImport
});
const buildInitialButtonState = (conditionalButton: ImportActionButton): UseConditionalImport => {
  return buildButtonWithState(conditionalButton, 'loading');
};
const getStateFromMapResults = (result: ConditionalButtonMapping): ActionButtonState['state'] => {
  return result.isHidden ? 'hidden' : result.isDisabled ? 'disabled' : 'enabled';
};
/**
 * This takes a target and determines what buttons should be displayed on a card for that target
 * This is necessary since we are evaluating the button conditions as a promise. Valid output states include:
 * loading, disabled, hidden
 * @param target The aggregate the button applies to
 */
export const UseConditionalImports = (): UseConditionalImports => {
  const { importActions, functionDefinitions } = useConfig();
  const [buttons, setButtons] = useState<UseConditionalImport[]>(importActions.map(buildInitialButtonState));

  const [cardButtons, setCardButtons] = useState<UseConditionalImports>({ state: 'loading' });
  const dataMapping = useDataMapping();
  // we may not need to memoize this, but it doesn't hurt for now.
  const dataMappingContext = useMemo(() => {
    return dataMapping();
  }, [dataMapping]);

  // any time our data mapping context changes, we want to reevaluate the button state for all buttons
  useEffect(() => {
    const accessors = dataMapping().accessors;
    const buttonPromises = buttons.map<Promise<ConditionalFabButtonResults>>((conditionalButton) => {
      if (conditionalButton.conditionalMap) {
        return mapScenarios.FAB_BUTTON_STATE.evaluate(
          conditionalButton.conditionalMap,
          accessors,
          functionDefinitions
        ).then((results) => {
          return { ...results, button: conditionalButton } as ConditionalFabButtonResults;
        });
      }
      return Promise.resolve({ isDisabled: false, isHidden: false, disabledMessage: '', button: conditionalButton });
    });
    Promise.all(buttonPromises).then((buttonResults) => {
      const newState = buttonResults.reduce<UseConditionalImport[]>((acc, buttonMappingResult) => {
        const state = getStateFromMapResults(buttonMappingResult);
        return [...acc, buildButtonWithState(buttonMappingResult.button, state, buttonMappingResult.disabledMessage)];
      }, []);
      // now we have to make sure that our state has changed before we set, or we get stuck in an infinite loop
      if (!_.isEqual(newState, buttons)) {
        setButtons(newState);
      }
    });
    //only bother with this if we are a conditional button
  }, [dataMappingContext, buttons, setButtons, dataMapping]);

  //Now whenever the state of our buttons changes, we need to reevaluate the state of all buttons on the card
  useEffect(() => {
    // If any button is still loading, then we are in a loading state
    const loadedButtons = buttons.filter((button) => button.state !== 'loading');
    const cardState: UseConditionalImports['state'] = loadedButtons.length !== buttons.length ? 'loading' : 'ready';
    //Now find the primary button
    const visibleButtons = buttons
      .filter((button) => button.state !== 'hidden')
      .map((button) => ({ ...button, isDisabled: button.state !== 'enabled' }));

    setCardButtons({ state: cardState, buttons: visibleButtons });
  }, [buttons]);

  return cardButtons;
};
