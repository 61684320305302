import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import { colors } from '../../styles/theme';
import React from 'react';

export enum TGLoginButtonType {
  primary = 'primary',
  secondary = 'secondary',
  small = 'small',
}

interface TGLoginButtonProps {
  type?: TGLoginButtonType;
  onClick?: (arg0: any) => void;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

const TGLoginButton: React.FC<TGLoginButtonProps> = (props: any) => {
  const { type, style } = props;
  const classes = useStyles();
  if (type === 'primary') {
    return (
      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.primaryButton}
        style={style}
        {...props}
      >
        {props.children}
      </Button>
    );
  }
  if (type === 'secondary') {
    return (
      <Button variant="contained" color="primary" type="submit" className={classes.secondaryButton} {...props}>
        {props.children}
      </Button>
    );
  }
  if (type === 'small') {
    return (
      <Button variant="contained" size="small" className={classes.smallButton} {...props}>
        {props.children}
      </Button>
    );
  }
  return <Button {...props}>{props.children}</Button>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      backgroundColor: `${theme.palette.primary.main} !important`,
      border: `${theme.palette.primary.main} 1px solid`,
      boxShadow: 'none',
      color: colors.white,
      padding: '12px 80px',
      lineHeight: 'normal',
      fontSize: 16,
      fontWeight: 500,
      borderRadius: 5,
      textTransform: 'capitalize',
      width: '100%',
      '&:hover': { opacity: 0.9, boxShadow: 'none' },
    },
    secondaryButton: {
      backgroundColor: `${colors.white} !important`,
      '&:hover': { opacity: 0.9, boxShadow: `0px 1px 4px 0px ${colors.black20}` },
      boxShadow: `0px 1px 4px 0px ${colors.black20}`,
      color: theme.palette.primary.main,
      padding: '12px 80px',
      lineHeight: 'normal',
      fontSize: 16,
      fontWeight: 500,
      borderRadius: 5,
    },
    smallButton: {
      boxShadow: '0px 0.662791px 1.98837px rgba(0, 0, 0, 0.4)',
      color: colors.black,
      '&:hover': { opacity: 0.9 },
      marginLeft: 20,
    },
  })
);

export default TGLoginButton;
