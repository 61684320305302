import React, { useMemo, useCallback, useEffect } from 'react';
import useUserPreferences from '../hooks/useUserPreferences';
import { Action } from '../components/FloatingActionButton';
import MapFab from '../components/MapFab';
import { ControlPosition } from 'react-draggable';
import { useActionsExecutor } from '../hooks/useActionsExecutor';
import { v4 as uuid4 } from 'uuid';
import { useConditionalFabButtons } from '../hooks/useConditionalFabButtons';
import { pages } from '../contexts/assetsDashboardContext';

const PREFERENCE_KEY = 'assetDashboardFABPosition';

interface AssetDashboardFABContainerProps {
  isHidden?: boolean;
  setMapFabOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  isMobileView?: boolean;
}

const AssetDashboardFABContainer = (props: AssetDashboardFABContainerProps) => {
  const { setMapFabOpen, isMobileView } = props;
  const { getPreference, setPreference } = useUserPreferences();
  const { buttons } = useConditionalFabButtons();
  const { processAction } = useActionsExecutor();

  const savedPosition = useMemo(
    () =>
      (getPreference(PREFERENCE_KEY) as ControlPosition | undefined) ?? {
        x: window.innerWidth - 100,
        y: window.innerHeight - 100,
      },
    [getPreference]
  );

  const handlePositionChange = useCallback(
    (newPosition: ControlPosition) => {
      setPreference(PREFERENCE_KEY, newPosition);
    },
    [setPreference]
  );

  const actions: Action[] = useMemo(
    () =>
      buttons
        ? buttons.map(command => {
            return {
              icon: command.icon,
              name: command.label,
              onClick: () => {
                processAction(command, [], uuid4(), () => {}, true, command.aggregate.name)?.onClick();
              },
              isDisabled: command.state !== 'enabled',
            };
          })
        : [],
    [buttons, processAction]
  );
  if (props.isHidden || actions.length === 0) {
    return <></>;
  }
  return (
    <MapFab
      icon="fas-bolt"
      closeIcon="fas-times"
      actions={actions}
      onPositionChange={handlePositionChange}
      defaultPosition={savedPosition}
      setMapFabOpen={setMapFabOpen}
      isMobileView={isMobileView}
    />
  );
};

export default AssetDashboardFABContainer;
