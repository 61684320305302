import { Fade, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DATETIME_TOKEN_CONVERSION, getDateTimeFormat, getDateFormat } from '@terragotech/gen5-shared-utilities';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { AggregateDefinition, useConfig, graphqlToAssetTransform } from '@terragotech/gen5-shared-components';
import { useFileViewer } from '../hooks/useFileViewer';
import { usePhotoViewer } from '../hooks/usePhotoViewer';
import { DOC_API_URL } from '../utils/docApiURL';
import { ImageWithHeaders } from './ImageWithHeaders';
import { downloadFile } from '../components/Import/FileToView';
import PagePage from './Page/PagePage';
import { colors } from '../styles/theme';

const borderStyle = `1px solid ${colors.black10}`;

const useStyles = makeStyles(theme => ({
  dataReviewWrapper: {
    background: colors.white,
  },
  title: {
    alignItems: 'center',
    fontWeight: 500,
    height: 40,
    display: 'flex',
    paddingLeft: 20,
    borderBottom: borderStyle,
  },
  assetDataWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    borderTop: borderStyle,
    borderLeft: borderStyle,
  },
  attribute: {
    borderRight: borderStyle,
    borderBottom: borderStyle,
  },
  lastChild: {
    borderRight: 'none',
    borderBottom: 'none',
  },
  listItem: {
    padding: '12.5px 15px',
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  listItemPrimaryText: {
    color: colors.black0,
    fontSize: 14,
    fontWeight: 400,
  },
  attributeText: {
    color: colors.black0,
    fontSize: 14,
    fontWeight: 300,
    overflow: 'auto',
  },
  attributeImages: {
    display: 'flex',
    overflow: 'auto',
  },
  header: {
    height: 46,
    minHeight: 46,
    backgroundColor: theme.palette.secondary.main,
    paddingLeft: 12,
    display: 'grid',
    gridTemplate: '1fr / 200px 1fr 200px',
    alignItems: 'center',
  },
  headerText: {
    color: colors.white,
    textTransform: 'none',
    fontSize: 18,
    textAlign: 'center',
  },
  button: {
    color: colors.white,
    '& input': {
      color: theme.palette.primary.main,
    },
    justifySelf: 'end',
  },
  photoContainer: {
    height: 80,
    width: 80,
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    cursor: 'pointer',
  },
  photo: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  fileIcon: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}));

export interface assetInfo {
  id: string;
  label: string;
  assetAttributes: Record<string, unknown>;
  modifiedBy: string;
  modifiedDateTime: string;
}

interface AssetDataProps {
  assetData: assetInfo;
  attributeLabels?: Record<string, string>;
  aggregateDefinition?: AggregateDefinition | undefined;
}

const AssetData: React.FunctionComponent<AssetDataProps> = props => {
  const { assetData, attributeLabels, aggregateDefinition } = props;
  const classes = useStyles();
  const photoViewer = usePhotoViewer();
  const fileViewer = useFileViewer();
  const { defaultDateTimeFormat } = useConfig();

  const createFieldType = useCallback(() => {
    const tempFieldType: { [key: string]: string } = {};
    (aggregateDefinition?.propertyDefinitions || []).forEach(column => {
      tempFieldType[column.field] = column.type;
    });
    return tempFieldType;
  }, [aggregateDefinition]);
  const fieldType: { [key: string]: string } = createFieldType();
  // TODO: PHOTOS: Remove mock
  let assetAttributes = { ...assetData?.assetAttributes };

  const handlePhotoClick = (assetKey: string, selectedPhoto: string) => {
    photoViewer.open({
      images: assetAttributes[assetKey] as string[],
      selectedPhoto,
    });
  };

  const handleFileClick = (assetKey: string, selectedFile: { id: string; name: string }) => {
    fileViewer.open({
      images: assetAttributes[assetKey] as { id: string; name: string }[],
      selectedFile,
    });
  };

  const record = useMemo(
    () =>
      graphqlToAssetTransform({
        flattenRelationships: true,
        result: assetData as any,
        aggDef: aggregateDefinition,
      }),
    [assetData, aggregateDefinition]
  );

  return (
    <div className={classes.dataReviewWrapper}>
      <Fade in style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
        {aggregateDefinition?.detailPage ? (
          <PagePage
            page={aggregateDefinition.detailPage}
            target={record}
            fabContainerKey="AssetData"
            noPadding
            noMargin
            showLoader
          />
        ) : (
          <div className={classes.assetDataWrapper}>
            {Object.keys(assetAttributes || {}).map((key, index) => {
              const value = (assetAttributes[key] as unknown) as string;

              return (
                <div key={index} className={classes.attribute}>
                  <ListItemText
                    className={classes.listItem}
                    classes={{
                      primary: classes.listItemPrimaryText,
                      secondary: fieldType[key] === 'PhotoCollection' ? classes.attributeImages : classes.attributeText,
                    }}
                    primary={(attributeLabels && attributeLabels[key]) || key}
                    secondary={
                      fieldType[key] === 'PhotoCollection'
                        ? Array.isArray(value) &&
                          value.map((item, index) => (
                            <div
                              key={'photo' + index}
                              className={classes.photoContainer}
                              onClick={() => handlePhotoClick(key, item)}
                            >
                              <ImageWithHeaders url={DOC_API_URL + item} className={classes.photo} />
                            </div>
                          ))
                        : fieldType[key] === 'FileCollection'
                        ? Array.isArray(value) &&
                          value.map((item, index) => (
                            <div
                              onClick={() =>
                                downloadFile(
                                  DOC_API_URL + item.id,
                                  item.name.substring(0, item.name.lastIndexOf('.')),
                                  undefined,
                                  item.name.substring(item.name.lastIndexOf('.') + 1)
                                )
                              }
                              className={classes.fileIcon}
                            >
                              {item.name}
                            </div>
                          ))
                        : fieldType[key] === 'DateTime' && value
                        ? moment(String(value)).format(
                            getDateTimeFormat(
                              defaultDateTimeFormat?.dateFormatType,
                              defaultDateTimeFormat?.dateFormat,
                              defaultDateTimeFormat?.dateSeperator,
                              defaultDateTimeFormat?.timeFormat,
                              { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                            )
                          )
                        : fieldType[key] === 'Date' && value
                        ? moment
                            .utc(String(value))
                            .format(
                              getDateFormat(
                                defaultDateTimeFormat?.dateFormatType,
                                defaultDateTimeFormat?.dateFormat,
                                defaultDateTimeFormat?.dateSeperator
                              )
                            )
                        : value && value !== ' '
                        ? String(value)
                        : 'None'
                    }
                  />
                </div>
              );
            })}
          </div>
        )}
      </Fade>
    </div>
  );
};

export default AssetData;
