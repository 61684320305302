import { Card, CardContent, makeStyles, Typography, IconButton, Paper, Button, Box } from '@material-ui/core';
import AssetAttributeElement, { AssetAttribute, Asset } from './AssetAttributeElement';
import MiddleCropText from './MiddleCropText';
import AssetCardActionsRow from './AssetCardActionsRow';
import { Action } from './AssetCardAction';
import AssetCardPrimaryAction from './AssetCardPrimaryAction';
import CloseIcon from '@material-ui/icons/Close';
import { convertToCSSTransition } from './MultipleAssetCards';
import { getSvgImageString } from '@terragotech/svg-symbol-lib';
import { useConfig, graphqlToAssetTransform } from '@terragotech/gen5-shared-components';
import _ from 'lodash';
import PagePage from '../../Page/PagePage';
import { MapAssetType } from '../../../contexts/AggregatesContext/types';
import moment from 'moment';
import {
  DATETIME_TOKEN_CONVERSION,
  getDateFormat,
  getDateTimeFormat,
  middleTruncate,
} from '@terragotech/gen5-shared-utilities';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { colors } from '../../../styles/theme';
import {
  DEFAULT_SYMBOL_OPTION,
  isValidLocation,
  itemHasValidPrimaryLocation,
  MAP_CARD_WIDTH,
  MOBILE_BREAKPOINT,
} from '../../../utils/utilityHelper';
import { AssetsDashboardContext } from '../../../contexts/assetsDashboardContext';
import { useRecordType } from '../../../contexts/recordTypeContext';
import useRouteParams from '../../Common/useRouteParams';

const ASSET_ICON_SIZE = 45;

export interface AssetCardProps {
  title: string;
  description?: string;
  attributes: readonly AssetAttribute[];
  asset: Asset | null;
  primaryAction?: Action;
  secondaryActions: Action[];
  contentOpacity?: number;
  onTitleClick?: () => void;
  onCloseClick?: () => void;
  labelValue?: string;
  assetId?: string;
  symbolKey?: string;
  aggregateType?: string;
  onSelect?: (props: any) => void;
  mapEditor?: boolean;
  selectedAggregateId?: string;
  selectableAggregateTypes?: string[];
  pageName?: string;
  fullAsset?: MapAssetType;
  elevation?: boolean;
  multiple?: boolean;
  fetchingAssets?: boolean;
}
const CLUSTER_HEADER_HEIGHT = 137;
const SINGLE_MAPCARD_HEADER_HEIGHT = 97;
const PRIMARY_ACTION_BUTTON_HEIGHT = 60.5;
const CARD_INITIAL_HEIGHT = 197;
const AssetCard = (props: AssetCardProps) => {
  const { aggregateDefinitions, defaultDateTimeFormat } = useConfig();
  const {
    asset,
    labelValue,
    symbolKey,
    mapEditor,
    assetId,
    selectedAggregateId,
    selectableAggregateTypes,
    pageName,
    fullAsset,
    elevation,
    multiple,
  } = props;
  const assetCardRef = useRef<HTMLDivElement>(null);
  const { mapContainerElementRef, isMobileView, minimizedTableView, hideMapCard, setMapCardHeight } = useContext(
    AssetsDashboardContext
  );
  const containerHeightRef = useRef<number | null>(null);
  useEffect(() => {
    if (mapContainerElementRef && mapContainerElementRef.current) {
      containerHeightRef.current = mapContainerElementRef.current.clientHeight;
    }
  }, [mapContainerElementRef, asset]);
  useEffect(() => {
    if (assetCardRef.current) {
      setMapCardHeight(assetCardRef.current.clientHeight);
    }
  }, [assetCardRef.current]);
  const classes = useStyles({ hideMapCard: hideMapCard as boolean });
  const secondaryButtonRef = useRef<HTMLDivElement | null>(null);
  const [seccontainerHeight, setSeccontainerHeight] = useState<number | undefined>(undefined);
  const { selectedRecordType } = useRecordType();
  const { isAssetDetailsOpen } = useRouteParams({
    selectedRecordType,
  });
  useEffect(() => {
    const height = secondaryButtonRef.current?.clientHeight;
    setSeccontainerHeight(height);
  }, [props.secondaryActions]);
  let itemLocationStatus = false;
  if (fullAsset) {
    itemLocationStatus =
      isValidLocation([fullAsset.lon, fullAsset.lat]) || itemHasValidPrimaryLocation(fullAsset, 'primaryLocation');
  }
  const assetCardHeight = useMemo(() => {
    return multiple
      ? (containerHeightRef.current as number) - CLUSTER_HEADER_HEIGHT
      : isAssetDetailsOpen
      ? (containerHeightRef.current as number)
      : (containerHeightRef.current as number) - SINGLE_MAPCARD_HEADER_HEIGHT;
  }, [containerHeightRef.current, multiple, minimizedTableView, isAssetDetailsOpen]);
  const record = useMemo(
    () =>
      graphqlToAssetTransform({
        flattenRelationships: true,
        result: fullAsset as any,
        aggDef: aggregateDefinitions.find(d => d.queryKey === props.aggregateType),
      }),
    [fullAsset, aggregateDefinitions]
  );

  if (!asset && !mapEditor) {
    if (props?.fetchingAssets || !assetId) return null;
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No asset found</Typography>
        </CardContent>
      </Card>
    );
  }
  if (!props.title && props.secondaryActions.length === 0 && props.attributes.length === 0 && !pageName) {
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography>No card definition found for this asset type</Typography>
        </CardContent>
      </Card>
    );
  }

  const isSelectableType = () => {
    const definition = aggregateDefinitions.find(d => d.name === props.aggregateType);
    if (_.isEmpty(selectableAggregateTypes)) {
      return true;
    }
    return _.includes(selectableAggregateTypes, definition?.name);
  };

  const isDate = (asset: Asset) => {
    let updatedAsset: Asset = { ...asset };
    const definition = aggregateDefinitions.find(d => d.queryKey === props.aggregateType);
    if (definition?.propertyDefinitions) {
      _.mapKeys(asset, function (value, key) {
        const property = _.find(definition.propertyDefinitions, function (o) {
          return o.label === key || o.field === key;
        });
        if (property && property.type === 'DateTime' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment(String(dateValue)).format(
                getDateTimeFormat(
                  defaultDateTimeFormat?.dateFormatType,
                  defaultDateTimeFormat?.dateFormat,
                  defaultDateTimeFormat?.dateSeperator,
                  defaultDateTimeFormat?.timeFormat,
                  { tokenConversion: DATETIME_TOKEN_CONVERSION.MomentJS }
                )
              )
            : '';
          updatedAsset[key] = dateValue;
        } else if (property && property.type === 'Date' && value) {
          let dateValue = value;
          dateValue = dateValue
            ? moment
                .utc(String(dateValue))
                .format(
                  getDateFormat(
                    defaultDateTimeFormat?.dateFormatType,
                    defaultDateTimeFormat?.dateFormat,
                    defaultDateTimeFormat?.dateSeperator
                  )
                )
            : '';
          updatedAsset[key] = dateValue;
        }
      });
    }
    return updatedAsset;
  };

  const getMinimumCardHeight = () => {
    let height = CARD_INITIAL_HEIGHT;
    if (props.secondaryActions.length && seccontainerHeight) {
      height += seccontainerHeight;
    }
    if (props.primaryAction) {
      height += PRIMARY_ACTION_BUTTON_HEIGHT;
    }
    return height;
  };
  const styles = {
    rootCardWrapper: {
      maxHeight: assetCardHeight,
      minHeight: getMinimumCardHeight(),
    },
  };
  return (
    <Paper
      elevation={0}
      className={[classes.card, elevation && classes.elevation].join(' ')}
      style={styles.rootCardWrapper}
      ref={assetCardRef}
    >
      <CardContent className={classes.cardContent} style={{ opacity: props.contentOpacity }}>
        <div className={classes.topRow}>
          <div className={classes.titleGroup}>
            {symbolKey && !!itemLocationStatus && (
              <img className={classes.assetIcon} src={getSvgImageString(symbolKey, DEFAULT_SYMBOL_OPTION)} />
            )}
            <Box className={classes.header}>
              <Typography className={classes.aggregateType}>{_.capitalize(props.aggregateType)}</Typography>
              <MiddleCropText
                className={mapEditor ? classes.title : classes.linkTitle}
                onClick={mapEditor ? () => {} : props.onTitleClick}
                label={!mapEditor ? labelValue : ''}
              >
                {middleTruncate(props.title, 20) as string}
              </MiddleCropText>
            </Box>
          </div>
          <IconButton classes={{ root: classes.closeButton }} onClick={props.onCloseClick}>
            <CloseIcon />
          </IconButton>
        </div>
        {props.description && (
          <Typography className={classes.description} component="div">
            {props.description}
          </Typography>
        )}
        {pageName ? (
          <PagePage
            target={record}
            page={pageName}
            isAssetCard
            fabContainerKey="AssetCard"
            isMobileView={isMobileView}
          />
        ) : (
          <div
            className={
              !isMobileView
                ? isAssetDetailsOpen
                  ? [classes.assetAttributeContainer, classes.editPage].join(' ')
                  : classes.assetAttributeContainer
                : ''
            }
          >
            <div className={classes.attributes}>
              {props.attributes
                .filter(x => x.isVisible === true)
                .map(attribute => (
                  <AssetAttributeElement key={attribute.key} attribute={attribute} asset={isDate(asset as Asset)} />
                ))}
            </div>
          </div>
        )}
        {!mapEditor && props.primaryAction && (
          <div className={classes.primaryActionGroup}>
            <AssetCardPrimaryAction action={props.primaryAction} onClick={props.onCloseClick} />
          </div>
        )}
        {mapEditor && (
          <div className={classes.bottomRow}>
            {isSelectableType() ? (
              <Button
                variant="contained"
                disabled={assetId === selectedAggregateId || !isSelectableType()}
                onClick={() => {
                  props.onSelect &&
                    props.onSelect({
                      assetId,
                      aggregateType: props.aggregateType,
                      label: labelValue,
                    });
                  props.onCloseClick && props.onCloseClick();
                }}
                color="primary"
                className={classes.selectButton}
              >
                {'Select'}
              </Button>
            ) : (
              <Typography className={classes.description} component="div">
                {'Non selectable record'}
              </Typography>
            )}
          </div>
        )}
      </CardContent>
      {!!props.secondaryActions.length && (
        <div ref={secondaryButtonRef}>
          <AssetCardActionsRow
            actions={props.secondaryActions}
            className={classes.actionsRow}
            style={{ opacity: props.contentOpacity }}
          />
        </div>
      )}
    </Paper>
  );
};

const X_BUTTON_SIZE = 48;
const CARD_PADDING = 16;

const useStyles = makeStyles(theme => ({
  elevation: {
    boxShadow: `0px 4px 4px 0px ${colors.black25}`,
  },
  card: (props: { hideMapCard: boolean }) => ({
    width: MAP_CARD_WIDTH,
    height: '100%',
    display: props.hideMapCard ? 'none' : 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px 0',
    overflow: 'hidden',
    backgroundColor: colors.whiteSmoke,
    borderRadius: 5,
  }),
  assetAttributeContainer: {
    paddingRight: 5,
    display: 'flex',
    minHeight: 100,
    background: colors.white,
    paddingTop: 5,
  },
  editPage: {
    display: 'block',
  },
  cardContent: {
    overflow: 'hidden',
    height: 'auto',
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    transition: convertToCSSTransition(['opacity'], 0.4),
    '&:last-child': {
      padding: 0,
    },
  },
  actionsRow: {
    transition: convertToCSSTransition(['opacity'], 0.4),
    margin: 0,
    padding: 10,
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgb(244, 244, 244)',
    padding: '15px 18px',
  },
  description: {
    fontSize: 15,
    color: colors.grayDescription,
  },
  attributes: {
    padding: '12px 18px',
    backgroundColor: colors.white,
    flex: 1,
    overflowY: 'auto',
    paddingBottom: 9,
    paddingTop: 7,
    minHeight: 100,
    '&::-webkit-scrollbar': {
      width: 7,
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 5,
      backgroundColor: colors.scrollBar,
    },
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      flex: 'none',
      height: '100px',
      minHeight: '100px',
    },
  },
  closeButton: {
    top: -13,
    width: 24,
    height: 24,
    '& svg': {
      color: colors.black54,
    },
  },
  bottomRow: {
    height: 'auto',
    paddingTop: 18,
    paddingBottom: 10,
    paddingInline: 18,
  },
  linkTitle: {
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '17px',
    cursor: 'pointer',
    marginTop: 2,
    letterSpacing: -0.5,
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  aggregateType: {
    color: theme.palette.primary.main,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: '17px',
    letterSpacing: -0.5,
    fontFamily: 'Roboto',
  },
  title: {
    color: colors.black,
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    lineHeight: '17px',
    cursor: 'pointer',
    letterSpacing: -0.5,
    maxWidth: 500 - X_BUTTON_SIZE - CARD_PADDING * 2,
  },
  titleGroup: {
    display: 'flex',
  },
  primaryActionGroup: {
    backgroundColor: 'rgb(244, 244, 244)',
    padding: '12px 18px',
  },
  pb0: {
    paddingBottom: 0,
  },
  actionRowGroup: {
    paddingTop: 5,
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    gap: 5,
  },
  assetIcon: {
    width: ASSET_ICON_SIZE,
    height: ASSET_ICON_SIZE,
    borderRadius: ASSET_ICON_SIZE / 2,
    backgroundColor: colors.white,
    boxShadow: `0px 2px 4px 0px ${colors.black10}`,
  },
  selectButton: {
    color: colors.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    gap: 5,
    padding: 10,
    height: 37,
  },
}));

export default AssetCard;
