import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AssetCardProps } from '../components/Map/AssetCard/AssetCard';
import { Action } from '../components/Map/AssetCard/AssetCardAction';
import { ColorDef, useConfig } from '@terragotech/gen5-shared-components';
import { MapAssetType } from '../contexts/AggregatesContext/types';
import { useActionsExecutor } from './useActionsExecutor';
import {
  convertActionButtonToAction,
  convertAssetToStringRecord,
  getCardTitle,
  getColorString,
  getStringAttributeValue,
} from './useCardMapperUtils';
import { useConditionalCardActionButtons } from './useConditionalCardActionButtons';
import { useDataMapping } from './useDataMapping';

interface CardMapperProps {
  onCloseClick?: () => void;
  assetId?: string;
  asset?: MapAssetType;
  onFormSubmit?: () => void;
  isAuthenitcated?: boolean | null;
}

/**
 *  This hook provides the props needed to render an asset card, given the asset Id
 * @param onCloseClick Handler for closing the card
 * @param assetId the asset Id of the card
 * @returns asset card props
 */
export const useCardMapper = ({ onCloseClick, asset, onFormSubmit, isAuthenitcated }: CardMapperProps) => {
  const { functionDefinitions } = useConfig();
  const { aggregateDefinitions } = useConfig();
  const { processAction } = useActionsExecutor();
  const history = useHistory();
  const dataMapping = useDataMapping();

  const [cardHasFinishedLoading, setCardHasFinishedLoading] = useState(false);

  const cardButtons = useConditionalCardActionButtons(asset);
  const [visibility, setVisibility] = useState<Record<string, boolean>>({});
  const dataMappingContext = useMemo(() => {
    return dataMapping(asset);
  }, [dataMapping, asset]);

  useEffect(() => {
    if (asset) {
      const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey);
      const cardDefinition = aggregateDefinition?.cardDefinition;

      if (!aggregateDefinition || !cardDefinition) {
        return undefined;
      }

      const { otherAttributes } = cardDefinition;
      const promises =
        otherAttributes?.map(attr => {
          if (attr.conditionalMap) {
            return mapScenarios.CARD_ELEMENT_MAP.evaluate(
              attr.conditionalMap,
              dataMappingContext.accessors,
              functionDefinitions
            ).then(results => {
              if (results.isVisible !== undefined) {
                return Promise.resolve({ [attr.itemKey]: results.isVisible });
              } else {
                return Promise.resolve({ [attr.itemKey]: true });
              }
            });
          } else {
            return Promise.resolve({ [attr.itemKey]: true });
          }
        }) || [];
      Promise.allSettled(promises).then(results => {
        setCardHasFinishedLoading(true);
        const result = {
          ...visibility,
          ...results.reduce((prev, item) => ({ ...prev, ...(item.status === 'fulfilled' ? item.value : {}) }), {}),
        };
        if (!_.isEqual(result, visibility)) {
          setVisibility(result);
        }
      });
    } else {
      setCardHasFinishedLoading(true);
    }
  }, [asset, dataMappingContext.accessors]);

  const cardProps = useMemo(() => {
    if (!asset) {
      return undefined;
    }

    const aggregateDefinition = aggregateDefinitions.find(d => d.queryKey === asset.recordTypeKey);
    const cardDefinition = aggregateDefinition?.cardDefinition;

    if (!aggregateDefinition || !cardDefinition) {
      return undefined;
    }

    const { titleKey, otherAttributes, descriptionKey, pageName } = cardDefinition;

    const title = getCardTitle(asset, titleKey);

    const colorMapper = (color: ColorDef) => getColorString(asset, color);

    const primaryActionButton = cardButtons.primaryButton
      ? processAction(
          cardButtons.primaryButton,
          [asset],
          asset.id,
          undefined,
          undefined,
          aggregateDefinition.name,
          onFormSubmit
        )
      : null;

    const primaryAction: Action | null = primaryActionButton
      ? convertActionButtonToAction(primaryActionButton, cardButtons.primaryButton, colorMapper)
      : null;
        const styles = {
          height: 20,
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: 0,
        };

    const secondaryActions: Action[] =
      (cardButtons.secondaryButtons
        ?.map(button => {
          const actionButton = button
            ? processAction(button, [asset], asset.id, undefined, undefined, aggregateDefinition.name, onFormSubmit)
            : null;
          if (!actionButton) {
            return null;
          }
          return convertActionButtonToAction(actionButton, button, colorMapper, styles);
        })
        .filter(b => b) as Action[]) ?? [];

    const attributes: AssetCardProps['attributes'] = pageName
      ? []
      : otherAttributes?.map(attr => ({
          title: attr.itemLabel,
          key: attr.itemKey,
          color: getColorString(asset, attr.color),
          isVisible: visibility[attr.itemKey],
        })) ?? [];

    const stringAsset = convertAssetToStringRecord(asset, otherAttributes);

    const description = descriptionKey ? getStringAttributeValue(asset[descriptionKey]) : undefined;

    const handleTitleClick = () => {
      isAuthenitcated && history.push(`/${aggregateDefinition.name}/${asset.id}/edit`);
    };

    return {
      fullAsset: asset,
      asset: stringAsset,
      attributes,
      title,
      secondaryActions,
      primaryAction: primaryAction ?? undefined,
      description,
      onCloseClick,
      onTitleClick: handleTitleClick,
      pageName: pageName,
    };
  }, [
    aggregateDefinitions,
    asset,
    cardButtons.primaryButton,
    cardButtons.secondaryButtons,
    history,
    onCloseClick,
    processAction,
    visibility,
  ]);

  return {
    cardProps,
    cardHasFinishedLoading,
  };
};
