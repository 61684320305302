import distance from '@turf/distance';
import { round, getUnitAbbreviation, WebUIConfig } from '@terragotech/gen5-shared-components';
import { LineString, Units } from '@turf/helpers';
import _ from 'lodash';

export function calculateMeasurement(
  lineString: LineString | null | undefined,
  geographic?: WebUIConfig['geographic']
) {
  const defaultConfig = {
    polylineUnitOfMeasurement: 'meters' as Units,
    polylineRoundingPrecision: 'tenths' as 'ones' | 'tenths' | 'hundredths' | 'thousandths',
  };

  const config = geographic || defaultConfig;

  const polylineUnitOfMeasurement = config.polylineUnitOfMeasurement;
  const polylineRoundingPrecision = config.polylineRoundingPrecision;

  if (!lineString || !lineString.coordinates.length) {
    return {
      segments: 0,
      measurement: 0,
      configUnits: polylineUnitOfMeasurement,
      unitsAbbreviation: getUnitAbbreviation(polylineUnitOfMeasurement as Units),
    };
  }

  const configRoundingPrecision: 'ones' | 'tenths' | 'hundredths' | 'thousandths' =
    polylineRoundingPrecision || 'tenths';

  const configUnits: Units | undefined = polylineUnitOfMeasurement;

  let previousLocation: number[] | undefined;
  let totalDistance = 0;

  lineString.coordinates.forEach((location: number[]) => {
    if (previousLocation) {
      const distanceInUnits = distance(location, previousLocation, { units: configUnits });
      totalDistance += distanceInUnits;
    }
    previousLocation = location;
  });

  const total = round(totalDistance, configRoundingPrecision);
  const segments = lineString.coordinates.length;
  const unitsAbbreviation = getUnitAbbreviation(configUnits as Units);

  return {
    segments,
    measurement: total,
    configUnits,
    unitsAbbreviation,
  };
}
