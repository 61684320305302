import { makeStyles } from '@material-ui/core/styles';
import { USERS_AND_ROLES_Z_INDEX } from '../../utils/layers';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { ErrorWarningType } from './FileToView';
import SheetTableRow from './SheetTableRow';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import magicText from 'i18next';
import FluidStyledTable from '../StyledTable/FluidStyledTable';
import { Column, FilterRendererProps } from '../../hooks/tableHooks/useColumns';
import { AssetType } from '../../contexts/AggregatesContext/types';
import ColumnSearch from '../AssetTable/ColumnSearch';
import { colors } from '../../styles/theme';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 600,
  },
  tableContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
  root: {
    zIndex: USERS_AND_ROLES_Z_INDEX,
    position: 'fixed',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '100vw',
    height: '100vh',
    backgroundColor: colors.white,
  },
  body: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 80,
  },
  importBody: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  radioText: {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '20px',
    lineHeight: '23px',
  },
  underLineOnHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  highlightOnHover: {
    color: colors.defaultPrimary,
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.defaultPrimary,
      color: colors.white,
    },
  },
  selectStatusBox: {
    display: 'flex',
    height: 46,
    borderBottom: `1px solid ${colors.grayLine}`,
    width: 120,
    margin: 20,
  },
  tableHeader: {
    marginLeft: '24px',
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: 22,
    lineHeight: '26px',
    color: colors.dimGray,
    marginTop: '26px',
    marginBottom: '13px',
  },
}));

export interface IndividualImportResult {
  id: string;
  errorsWarnings: readonly {
    message: string;
    is_error: boolean;
    is_form_level: boolean;
    row: number;
    column: number;
    sheet: string;
    column_name: string;
  }[];
  aggregateType: string;
  commandName: string;
  committed?: boolean;
  filename: string;
  metadata?: object;
  data?: object;
  target: string;
  timestamp: Date;
  type: string;
  version: string;
}

interface Props {
  tableHeaders: {
    label: string;
    key: string;
  }[];
  length: number;
  fetchedData?: IndividualImportResult;
  errors: ErrorWarningType[];
  warnings: ErrorWarningType[];
  sheetNumber: number;
  sheets: {
    label: string;
    key: string;
  }[];
}

type ObjectStringKey = { [key: string]: any };

const SheetTable = (props: Props) => {
  const { length, fetchedData, errors, warnings, sheetNumber, tableHeaders, sheets } = props;
  const TopSpaceAboveTable = 450;
  const getTableContainerHeight = (): number => {
    return window.innerHeight - TopSpaceAboveTable;
  };

  const classes = useStyles();
  const [selectStatusValue, setSelectStatusValue] = useState('showAll');
  const [dataColumns, setDataColumns] = useState<Column<AssetType>[]>([]);
  const [height, setHeight] = useState(getTableContainerHeight());
  const minimumTableHeight = 200;
  const bottomRecordTypeBarHeight = 48;
  const STATUS_COLUMN_KEY = 'importStatusColumnKey';

  const onResize = () => {
    setHeight(getTableContainerHeight());
  };
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);
  const handleSelectStatusChange = (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    setSelectStatusValue(e.target.value as string);
  };

  const filterRenderer = useCallback(
    ({ column }: FilterRendererProps<AssetType>) => <ColumnSearch displayName={column.name} field={column.key} />,
    []
  );

  const aggregateFilterDelegate = (index: number) => (ew: ErrorWarningType) =>
    ew?.row && ew?.row === index + 2 && sheets[sheetNumber].key === ew?.sheet;

  const aggregatedData = useMemo(() => {
    return Object.values(fetchedData?.data || {})
      [sheetNumber]?.map((row: ObjectStringKey, index: number, a: ObjectStringKey[]) => ({
        ...row,
        warnings: warnings.filter(aggregateFilterDelegate(index)),
        errors: errors.filter(aggregateFilterDelegate(index)),
      }))
      .map((row: ObjectStringKey, index: number, a: ObjectStringKey[]) => ({
        ...row,
        successFlag: row.warnings.length === 0 && row.errors.length === 0,
        warningFlag: row.warnings.length > 0,
        errorFlag: row.errors.length > 0,
      }));
  }, [fetchedData, fetchedData?.data, sheetNumber, warnings, errors]);

  const filteredData = useMemo(() => {
    return aggregatedData?.filter((row: ObjectStringKey, index: number, array: ObjectStringKey[]) => {
      let showRow: boolean = true;
      switch (selectStatusValue) {
        case 'success':
          showRow = showRow && row.successFlag;
          break;
        case 'warning':
          showRow = showRow && row.warningFlag;
          break;
        case 'error':
          showRow = showRow && row.errorFlag;
          break;
        default:
          break;
      }
      return showRow;
    });
  }, [aggregatedData, selectStatusValue]);

  useEffect(() => {
    setDataColumns(columns =>
      [
        {
          key: STATUS_COLUMN_KEY,
          label: 'Row Status',
        },
        ...tableHeaders,
      ].map(x => ({
        key: x.key,
        name: x.label,
        filterRenderer: false,
        sortable: false,
        sticky: true,
        width: x.key === STATUS_COLUMN_KEY ? 112 : undefined,
        resizable: false,
        cellRenderer: props => (
          <Fragment key={`sheetTableRow-${sheetNumber}-${props.rowIdx}-${props.column.key}`}>
            <SheetTableRow
              sheetNumber={sheetNumber}
              data={props.row}
              index={props.rowIdx}
              tableHeaders={tableHeaders}
              columnKey={props.column.key}
              columnName={props.column.name}
              statusColumnKey={STATUS_COLUMN_KEY}
            />
          </Fragment>
        ),
      }))
    );
  }, [sheetNumber, length, aggregatedData, errors, warnings, tableHeaders, sheets]);
  const getTableHeight = () => {
    if (height > minimumTableHeight) {
      return height - bottomRecordTypeBarHeight;
    }
    return minimumTableHeight;
  };
  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
        <Grid item>
          <Typography className={classes.tableHeader}>
            {length} Record(s) | {warnings?.length || 0} Warning(s) | {errors?.length || 0} Error(s)
          </Typography>
        </Grid>
        <Grid item>
          <FormControl className={classes.selectStatusBox}>
            <InputLabel htmlFor="status-selection">{`${magicText.t('import.statusFilters.filterBy')}:`}</InputLabel>
            <Select
              value={selectStatusValue}
              onChange={handleSelectStatusChange}
              inputProps={{
                id: 'status-selection',
              }}
            >
              <MenuItem value="showAll">{magicText.t('import.statusFilters.showAll')}</MenuItem>
              <MenuItem value="success">{magicText.t('import.statusFilters.success')}</MenuItem>
              <MenuItem value="warning">{magicText.t('import.statusFilters.warning')}</MenuItem>
              <MenuItem value="error">{magicText.t('import.statusFilters.error')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {(tableHeaders || []).length === 0 || !filteredData ? (
        <div></div>
      ) : (
        <div className={classes.tableContainer}>
          <FluidStyledTable
            data={filteredData}
            columns={dataColumns}
            height={getTableHeight()}
            filterRenderer={filterRenderer}
            onColumnsMove={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default SheetTable;
