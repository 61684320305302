import { useState, useCallback, Dispatch, SetStateAction } from 'react';
import { SortDirection } from '@terragotech/react-data-grid';

const useSort = (
  defaultKey?: string,
  recordType?: string,
  setAllSorts?: Dispatch<
    SetStateAction<{
      [key: string]: {
        sortKey: string;
        sortDirection: SortDirection;
      };
    }>
  >
) => {
  const [[sortKey, sortDirection], setSort] = useState<[string, SortDirection]>([defaultKey || '', 'ASC']);

  const handleSort = useCallback(
    (columnKey: string, direction: SortDirection) => {
      setSort([columnKey, direction]);
      if (recordType && setAllSorts) {
        setAllSorts(a => ({
          ...a,
          [recordType]: {
            sortKey: columnKey,
            sortDirection: direction,
          },
        }));
      }
    },
    [recordType]
  );

  return { sortKey, sortDirection, handleSort };
};

export default useSort;
