import { useState } from 'react';

const defaultValue = false;

export const useMapExtentFilter = () => {
  const [mapExtentFiltering, setMapExtentFiltering] = useState<boolean>(defaultValue);
  return {
    mapExtentFiltering,
    setMapExtentFiltering,
  };
};
