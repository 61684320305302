import { Theme, createStyles, withStyles, WithStyles, Typography, CircularProgress } from '@material-ui/core';
import magicText from 'i18next';
import { colors } from '../../../styles/theme';
import { MOBILE_BREAKPOINT } from '../../../utils/utilityHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { useMemo } from 'react';

interface InfoSectionProps extends WithStyles<typeof styles> {
  isLoadingData: boolean;
  numberOfFilters: number;
  numberOfElements?: number;
  numberOfVisible?: number;
  handleFilterReset: () => void;
}

const InfoSection = ({
  classes,
  numberOfFilters,
  isLoadingData,
  numberOfElements,
  numberOfVisible,
  handleFilterReset,
}: InfoSectionProps) => {

  const showVisibleElements = useMemo(()=>{return numberOfVisible !== undefined ? true : false},[numberOfElements,numberOfVisible])

  return (
    <div className={classes.showingSection}>
      {!!numberOfFilters && (
        <div className={classes.activeFilterSection}>
          <FontAwesomeIcon icon={faCircleInfo} className={classes.info} />
          {`${magicText.t('SearchBar.ACTIVE FILTERS')} (${numberOfFilters})${showVisibleElements ? ':' : ''}`}
         {showVisibleElements && <span className={classes.showingTxt}>{` ${magicText.t('SearchBar.Showing')}
          ${numberOfVisible} ${magicText.t('SearchBar.of')} ${numberOfElements}`}</span>}
        </div>
      )}
      {numberOfFilters ? (
        <span className={classes.clearFilters} onClick={handleFilterReset}>
          <> {magicText.t('SearchBar.CLEAR ALL FILTERS')}</>
        </span>
      ) : (
        ''
      )}
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    showingSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 20,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down(MOBILE_BREAKPOINT + 1)]: {
        paddingLeft: 0,
        paddingBottom: 10,
      },
    },
    info: {
      width: 20,
      height: 20,
      color: theme.palette.primary.main,
    },
    activeFilterSection: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      fontWeight: 500,
      fontSize: 14,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      color: colors.black0,
    },
    showingTxt: {
      fontWeight: 400,
    },
    progressSpinnerSection: {
      marginLeft: 5,
      marginRight: 5,
      marginTop: 3,
    },
    filterNumberSpan: {
      color: colors.danger,
    },
    clearFilters: {
      color: theme.palette.primary.main,
      fontWeight: 500,
      fontSize: 13,
      fontStyle: 'normal',
      fontFamily: 'Roboto',
      marginLeft: 10,
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '6px 10px',
      borderRadius: 4,
      '&:hover': {
        cursor: 'pointer',
      },
    },
  });

export default withStyles(styles)(InfoSection);
