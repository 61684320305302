import React from 'react';
import { AggregateDefinition } from '@terragotech/gen5-shared-components';

type Setter<ValueType extends unknown> = (prevValue: ValueType) => ValueType;

export const setStateWrapper = <ValueType extends unknown>(
  arg: React.SetStateAction<ValueType>,
  prevState: ValueType
): ValueType => {
  if (typeof arg === 'function') {
    return (arg as Setter<ValueType>)(prevState);
  } else {
    return arg;
  }
};

export const createInitialStates = <T extends unknown>(
  aggregates: AggregateDefinition[],
  getPreferenceForRecordType: (recordType: string) => T
) =>
  aggregates
    .map((definition) => definition.name)
    .reduce<Record<string, T>>((result, recordName) => {
      result[recordName] = getPreferenceForRecordType(recordName);
      return result;
    }, {});
