import React, { useMemo } from 'react';
import { makeStyles, Button, Slide } from '@material-ui/core';
import { USER_ROLES_BOTTOM_BAR_Z_INDEX } from '../../utils/layers';
import { colors } from '../../styles/theme';

const useStyles = makeStyles((theme) => ({
  bar: {
    position: 'fixed',
    bottom: '0',
    left: 0,
    width: '100%',
    zIndex: USER_ROLES_BOTTOM_BAR_Z_INDEX,
    height: '57px',
    borderTop: '3px solid',
    borderTopColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
  },
  message: {
    marginRight: '20px',
  },
  button: {
    height: '40px',
    marginRight: '10px',
  },
}));

interface BottomChangesBarProps {
  numberOfChanges: number;
  singularName: string;
  pluralName?: string;
  onSave?: () => void;
  onDiscard?: () => void;
  open: boolean;
}

const BottomChangesBar = ({
  numberOfChanges,
  singularName,
  pluralName,
  onDiscard,
  onSave,
  open,
}: BottomChangesBarProps) => {
  const classes = useStyles();

  const name = useMemo(() => {
    if (numberOfChanges > 1) {
      if (pluralName) {
        return pluralName;
      }
      return `${singularName}s`;
    }
    return singularName;
  }, [numberOfChanges, singularName, pluralName]);

  return (
    <Slide in={open} direction="up" timeout={500}>
      <div className={classes.bar}>
        <p className={classes.message}>
          <b>{numberOfChanges}</b> {name} will be updated
        </p>
        <Button
          onClick={onSave}
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={numberOfChanges === 0}
        >
          Save
        </Button>{' '}
        <Button onClick={onDiscard} variant="outlined" color="primary" className={classes.button}>
          Discard
        </Button>
      </div>
    </Slide>
  );
};

export default BottomChangesBar;
