import { useMemo, useState, useEffect } from 'react';
import { ConditionalButtonMapping } from '@terragotech/gen5-config-lib';
import { mapScenarios } from '@terragotech/gen5-datamapping-lib';
import { AssetType } from '../contexts/AggregatesContext/types';
import { useDataMapping } from './useDataMapping';
import { CardActionButton, useConfig } from '@terragotech/gen5-shared-components';
import _ from 'lodash';

export interface ActionButtonState {
  state: 'loading' | 'disabled' | 'hidden' | 'enabled';
  disabledMessage?: string;
}
export type UseConditionalCardActionButton = CardActionButton & ActionButtonState & { canBePrimary: boolean };
export interface UseConditionalCardActionButtons {
  state: 'loading' | 'ready';
  primaryButton?: UseConditionalCardActionButton;
  secondaryButtons?: UseConditionalCardActionButton[];
}
export interface ConditionalButtonResults {
  isDisabled: boolean;
  disabledMessage: string;
  isHidden: boolean;
  button: CardActionButton;
}
const buildButtonWithState = (
  conditionalButton: CardActionButton,
  state: ActionButtonState['state'],
  disabledMessage?: string
): UseConditionalCardActionButton => ({
  action: conditionalButton.action,
  //TODO: move color processing into here as well so we can save on data lookups
  color: conditionalButton.color,
  state: state,
  icon: conditionalButton.icon,
  label: conditionalButton.label,
  canBePrimary: conditionalButton.canBePrimary || false,
  disabledMessage: disabledMessage,
});
const buildInitialButtonState = (conditionalButton: CardActionButton): UseConditionalCardActionButton => {
  return buildButtonWithState(conditionalButton, 'loading');
};
const getStateFromMapResults = (result: ConditionalButtonMapping): ActionButtonState['state'] => {
  return result.isHidden ? 'hidden' : result.isDisabled ? 'disabled' : 'enabled';
};
/**
 * This takes a target and determines what buttons should be displayed on a card for that target
 * This is necessary since we are evaluating the button conditions as a promise. Valid output states include:
 * loading, disabled, hidden
 * @param target The aggregate the button applies to
 */
export const useConditionalCardActionButtons = (target?: AssetType): UseConditionalCardActionButtons => {
  const { aggregateDefinitions, functionDefinitions } = useConfig();
  const aggregateDefinition = aggregateDefinitions.find((d) => d.queryKey === target?.recordTypeKey);
  const cardDef = aggregateDefinition?.cardDefinition;
  const conditionalButtons = useMemo(
    () => (cardDef && cardDef.template === 'conditionalCard' && cardDef.buttons) || [],
    [cardDef]
  );
  const [buttons, setButtons] = useState<UseConditionalCardActionButton[]>(
    conditionalButtons.map(buildInitialButtonState)
  );

  const [cardButtons, setCardButtons] = useState<UseConditionalCardActionButtons>({ state: 'loading' });
  // we need to memoize this as pulling the data from realm is expensive
  const dataMapping = useDataMapping();
  const dataMappingContext = useMemo(() => {
    return dataMapping(target);
  }, [dataMapping, target]);

  // any time our data mapping context changes, we want to reevaluate the button state for all buttons
  useEffect(() => {
    const buttonPromises = conditionalButtons.map<Promise<ConditionalButtonResults>>((conditionalButton) => {
      if (conditionalButton.conditionalMap) {
        return mapScenarios.BUTTON_STATE.evaluate(conditionalButton.conditionalMap, dataMappingContext.accessors, functionDefinitions).then(
          (results) => {
            return { ...results, button: conditionalButton } as ConditionalButtonResults;
          }
        );
      }
      return Promise.resolve({ isDisabled: false, isHidden: false, disabledMessage: '', button: conditionalButton });
    });
    Promise.all(buttonPromises).then((buttonResults) => {
      const newState = buttonResults.reduce<UseConditionalCardActionButton[]>((acc, buttonMappingResult) => {
        const state = getStateFromMapResults(buttonMappingResult);
        return [...acc, buildButtonWithState(buttonMappingResult.button, state, buttonMappingResult.disabledMessage)];
      }, []);
      // now we have to make sure that our state has changed before we set, or we get stuck in an infinite loop
      if (!_.isEqual(newState, buttons)) {
        setButtons(newState);
      }
    });
    //only bother with this if we are a conditional button
  }, [dataMappingContext, buttons, setButtons, conditionalButtons]);

  //Now whenever the state of our buttons changes, we need to reevaluate the state of all buttons on the card
  useEffect(() => {
    // If any button is still loading, then we are in a loading state
    const loadedButtons = buttons.filter((button) => button.state !== 'loading');
    const cardState: UseConditionalCardActionButtons['state'] =
      loadedButtons.length !== conditionalButtons.length ? 'loading' : 'ready';
    //Now find the primary button
    const visibleButtons = buttons
      .filter((button) => button.state !== 'hidden')
      .map((button) => ({ ...button, isDisabled: button.state !== 'enabled' }));
    const primaryButton = visibleButtons.find((button) => button.canBePrimary);
    const secondaryButtons = visibleButtons.filter((button) => button !== primaryButton);

    setCardButtons({ state: cardState, primaryButton, secondaryButtons });
  }, [buttons, conditionalButtons.length]);

  return cardButtons;
};
