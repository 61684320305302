import React, { useMemo, useCallback, useState, useEffect, useContext, useRef } from 'react';
import { TableData, ValueType } from '../../hooks/useTable';
import FluidStyledTableWithData from '../StyledTable/FluidStyledTableWithDataManagement';
import UserRolesLoading from './UserRolesLoading';
import { Column } from '../../hooks/tableHooks/useColumns';
import { StyledTableProps } from '../StyledTable/StyledTable';
import gearColumn from '../../utils/gearColumn';
import ThreeDotMenuCellRenderer from '../StyledTable/ThreeDotsMenuCell';
import UsersAndRolesSearchBar from './SearchBar/UsersAndRolesSearchBar';
import { makeStyles, Checkbox, Typography } from '@material-ui/core';
import { DIALOG_Z_INDEX } from '../../utils/layers';
import { capitalCase } from 'change-case';
import { colors } from '../../styles/theme';
import { ApolloError } from '@apollo/client';
import { useAccountModal } from '../../contexts/accountModalContext';
import { ActionsMenuButton } from '../ActionsMenuUI';
import { useTheme } from '@material-ui/core';
import { TableDataContext } from '../../hooks/useTableData';
import { EditModeContext, EditModeData } from '../../contexts/editModeContext';
import { isArray } from 'lodash';
import { MOBILE_BREAKPOINT } from '../../utils/utilityHelper';
import { useAlert } from '../../contexts/AlertModalContext';

const SEARCH_BAR_HEIGHT = 32;

interface UsersAndRolesTableProps<DataType extends TableData> {
  height: number;
  onChange: StyledTableProps<DataType>['onChange'];
  onEditModeOn: (row: DataType) => void;
  data: readonly DataType[];
  columns: readonly Column<DataType>[];
  loading: boolean;
  error: ApolloError | null;
  getActions: (rows: DataType[]) => readonly ActionsMenuButton[];
  fabIconComponent?: React.ReactElement;
  fabIconName?: string;
  onFabClick?: () => void;
  singular: string;
  isEditModeOn?: boolean;
}
type EditActionType = {
  label: string;
  state: 'enabled' | 'disabled';
  icon: string;
  onClick: () => void;
};
interface EditProps {
  id: ValueType,
  username: ValueType
}
const addGearColumn = <DataType extends TableData>(
  getActions: UsersAndRolesTableProps<DataType>['getActions'],
  options: {
    columns: readonly Column<DataType>[];
    isMultiSelectActive: boolean;
    isSelected: (row: DataType) => boolean;
    toggleSelected: (row: DataType) => void;
    selectedRows: DataType[];
    singular: string;
    plural?: string;
    numberOfSelected: number;
    selectAllFilteredRows: () => void;
    resetSelectedRows: () => void;
    allRowData: readonly DataType[];
    primaryColor: string;
    secondaryColor: string;
  },
  headerStyle: string,
  checkBoxStyle: string,
  setEditModeActive: (action: boolean) => void,
  editModeActive: boolean,
  setIsEditModeOnUser: (action: boolean) => void,
  setEditModeData:  (val: EditModeData | EditModeData[] | null) => void,
) => {
  const plural = options.plural || `${options.singular}s`;
  const statusText = `${options.numberOfSelected === 0 ? 1 : options.numberOfSelected} ${capitalCase(
    options.numberOfSelected === 1 ? options.singular : plural
  )} Selected:`;
  const {
    primaryColor,
    secondaryColor,
    isMultiSelectActive,
    selectedRows,
    toggleSelected,
    isSelected
  } = options;

  return [
    gearColumn<DataType>(
      // handle how the cell body is rendered
      ({ row }) => {
        const actionsArray = getActions([row]);
        const onEditClick = () => {
          setEditModeData({ id: row.id, label: row.username } as EditModeData);
          setEditModeActive(true);
          setIsEditModeOnUser(true);
        };
        const getEditAction = (): EditActionType => {
          return {
            label: 'EDIT',
            state: editModeActive ? 'disabled' : 'enabled',
            icon: 'fa-pencil',
            onClick: onEditClick,
          };
        };
        const actions = row.isSingleSignOnUser
          ? [...actionsArray.slice(0, 1), getEditAction()]
          : [...actionsArray, getEditAction()];
        return (
          <ThreeDotMenuCellRenderer
            isSelected={isSelected(row)}
            isMultiSelectActive={isMultiSelectActive}
            toggleSelected={() => toggleSelected(row)}
            actions={actions}
            statusText={statusText}
          />
        );
      },
      // handle how the cell header is rendered
      () => <></>,
      () => {
        if (isMultiSelectActive) {
          const { allRowData, selectAllFilteredRows, resetSelectedRows } = options;
          const indeterminateIsActive = selectedRows.length !== allRowData.length && selectedRows.length > 0;
          const handleCheckboxClick = () => {
            if (selectedRows.length === 0 && isMultiSelectActive) {
              selectAllFilteredRows();
            } else {
              resetSelectedRows();
            }
          };
          return (
            <Checkbox
              checked={selectedRows.length > 0}
              onClick={handleCheckboxClick}
              indeterminate={indeterminateIsActive}
              className={checkBoxStyle}
              color="primary"
            />
          );
        }
        return <Typography className={headerStyle}>Action</Typography>;
      }
    ),
    ...options.columns,
  ];
};

const UsersAndRolesTable = <DataType extends TableData>(props: UsersAndRolesTableProps<DataType>) => {
  const {
    onChange,
    data,
    columns,
    onEditModeOn,
    loading,
    error,
    height,
    getActions,
    fabIconName,
    onFabClick,
    singular,
    isEditModeOn,
  } = props;
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const [selectedRows, setSelectedRows] = useState<DataType[]>([]);
  const isRowSelected = useCallback((row: DataType) => selectedRows.some(sr => sr.id === row.id), [selectedRows]);
  const { closeUserAndRoles } = useAccountModal();
  const { openConfirmation } = useAlert();
  const theme = useTheme();
  const { filteredData } = useContext(TableDataContext);
  const hasOpenedConfirmation = useRef(false);
  const { editModeActive, editModeData, setIsEditModeOnUser, setEditModeActive, setEditModeData, isEditModeOnUser } = useContext(EditModeContext);
  const editRowComparator = useCallback(
    (row: DataType) => (isArray(editModeData) ? editModeData.some(x => x.id === row.id) : row.id === editModeData?.id),
    [editModeData]
  );

  const resetSelectedRows = useCallback(() => {
    setSelectedRows([]);
  }, [setSelectedRows]);

  const selectAllFilteredRows = useCallback(() => {
    if (filteredData.length >= 1) {
      setSelectedRows(filteredData as DataType[]);
    }
  }, [setSelectedRows, filteredData]);

  const columnsWithGear = useMemo(() => {
    return addGearColumn(
      getActions,
      {
        columns,
        isMultiSelectActive: checked,
        isSelected: isRowSelected,
        toggleSelected: (row: DataType) => {
          if (isRowSelected(row)) {
            setSelectedRows(prev => prev.filter(selectedRow => row.id !== selectedRow.id));
          } else {
            setSelectedRows(prev => [...prev, row]);
          }
        },
        selectedRows,
        singular,
        numberOfSelected: selectedRows.length,
        selectAllFilteredRows,
        resetSelectedRows,
        allRowData: data,
        primaryColor: theme.palette.primary.main,
        secondaryColor: theme.palette.secondary.main,
      },
      classes.headerStyle,
      classes.checkBoxStyle,
      setEditModeActive,
      isEditModeOnUser,
      setIsEditModeOnUser,
      setEditModeData,
    );
  }, [selectedRows, checked, isRowSelected, data, editModeActive, resetSelectedRows, selectAllFilteredRows]);

  const areAnyActionsSelected = useMemo(() => selectedRows.some((sr: DataType) => isRowSelected(sr)), [selectedRows]);
  const statusText = useMemo(() => `${selectedRows.length > 1 ? selectedRows.length : 1} 
    ${capitalCase(selectedRows.length > 1 ? `${props.singular}s` : props.singular)} Selected:`,
  [selectedRows.length, props.singular]);

  const searchBar = useCallback(
    () => (
      <UsersAndRolesSearchBar
        height={SEARCH_BAR_HEIGHT}
        checked={checked}
        isLoadingData={loading}
        setChecked={setChecked}
        resetSelectedRows={resetSelectedRows}
        statusText={statusText}
        actions={{ exportAction: exportAction, deleteUserAction: deleteUserAction }}
        areAnyActionsSelected={areAnyActionsSelected}
      />
    ),
    [loading, checked, areAnyActionsSelected, statusText]
  );

  const openConfirmationDialog = useCallback(async () => {
    if (hasOpenedConfirmation.current) return;
    hasOpenedConfirmation.current = true;
    const status = await openConfirmation({
      title: `Could not Load Data`,
      question: error?.message ?? `Application was not able to fetch any data`,
      cancellationText: 'Ok',
      type: 'error',
      showSubmit: false,
    });
    if (status === 'cancel') {
      closeUserAndRoles();
    }
  }, [openConfirmation, closeUserAndRoles]);
  useEffect(() => {
    if (error || !data) {
      openConfirmationDialog();
    }
  }, [error, data, openConfirmationDialog]);

  const actions = useMemo(() => getActions(selectedRows), [getActions, selectedRows]);
  const [exportAction, , deleteUserAction] = actions;

  if (loading) {
    return <UserRolesLoading />;
  }

  return (
    <div className={classes.container}>
      <FluidStyledTableWithData<DataType>
        data={data}
        columns={columnsWithGear}
        searchBar={searchBar}
        height={height}
        searchBarHeight={SEARCH_BAR_HEIGHT}
        onEditModeOn={onEditModeOn}
        onChange={onChange}
        editRowComparator={editModeActive ? editRowComparator : undefined}
      />
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
  },
  fab: {
    position: 'fixed',
    bottom: 25,
    right: 72,
    zIndex: DIALOG_Z_INDEX,
    float: 'right',
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      bottom: 10,
      right: 27,
    },
  },
  headerStyle: {
    fontWeight: 400,
    fontSize: 13,
    color: colors.black0,
    fontStyle: 'normal',
    fontFamily: 'Roboto',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  checkBoxStyle: {
    width: 50,
    height: 39,
  },
}));

export default UsersAndRolesTable;
